import {useAtom} from 'jotai';
import {useMemo} from 'react';

import {filterAtom} from '../components/Filter/atoms/filterAtom';

export default function useSerialFilter(customTableKey) {
  const [filterParams] = useAtom(filterAtom);
  const {tableKey, conditions} = filterParams;
  const curTableKey = customTableKey || tableKey;

  const serialfilterConditions = useMemo(() => {
    const filterConditions =
      conditions[curTableKey?.split('__')[0] ?? ''] || {};
    return Object.keys(filterConditions)
      .map((key) => {
        const curCondition =
          Object.keys(filterConditions[key]).length > 0
            ? {
                aliasName: key,
                ...filterConditions[key],
              }
            : false;
        if (curCondition?.valueList) {
          curCondition.valueList = curCondition.valueList.map((v) =>
            encodeURIComponent(v)
          );
        }
        return curCondition;
      })
      .filter(Boolean);
  }, [conditions, curTableKey]);

  return serialfilterConditions;
}
