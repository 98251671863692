import request from '../../helpers/request';

/** 用户头像上传 */
export function uploadUserAvatar(data) {
  return request({
    url: '/user/user/profile/avatar',
    method: 'post',
    data,
  });
}

/** 用户修改密码 */
export function uploadPassword(data) {
  return request({
    url: '/user/user/updatePwd',
    method: 'post',
    data,
  });
}

/** 获取临时用户倒计时 */
export const GET_TEMP_COUNT_DOWN = '/user/user/getTempCountdown';

/** 获取当前登录的用户分组角色信息 */
export const GET_USER_GROUP_ROLE = '/user/group/getCurGroupRole';

/** 获取当前登录的用户个人信息 */
export const GET_USER_INFO = '/user/user/getUserInfo';

/** 获取当前登录的用户在页面内的权限 */
export const GET_USER_PAGE_PERM = '/user/menu/getPermByMenu';

/** 更新用户信息 */
export function updateUserInfo(data) {
  return request({
    // url: '/user/user',
    // method: 'put',
    url: '/user/user/editSelf',
    method: 'post',
    data,
  });
}

/** 个人中心-账号权限 */
export const GET_USER_CENTER_PERM = '/user/user/getUserCenterPerm';
export function getUserCenterPermission() {
  return request({
    url: GET_USER_CENTER_PERM,
    method: 'get',
  });
}

/** 查询表单、按钮、菜单是否有权限 */
export const GET_PERM_STATUS = '/user/perm/getPermStatus';

/** 个人中心-日志 */
export const PERSONAL_OPERATION_LOG = '/user/operlog/personalList';

/** 个人中心-日志导出 */
export const PERSONAL_OPERATION_LOG_EXPORT = '/user/operlog/exportSelf';

/*  🔽↓ 🔽↓ 🔽↓ 一体化系统集成基础平台-新公共用户中心 🔽↓ 🔽↓ 🔽↓  */
/* 新增角色 */
export function createRole(data) {
  return request({
    url: '/user/role/add',
    method: 'post',
    data,
  });
}

/* 编辑角色 */
export function editRole(data) {
  return request({
    url: '/user/role/edit',
    method: 'post',
    data,
  });
}

/* 删除角色 */
export function removeRole(data) {
  return request({
    url: '/user/role/remove',
    method: 'post',
    data,
  });
}

/* 获取空角色 菜单权限 */
export const ROLE_MENU_PERMS = '/user/role/getRoleMenuPermDetail';
export function getNewRoleMenuPerms(params) {
  return request({
    url: ROLE_MENU_PERMS,
    method: 'get',
    params,
  });
}

/* 查询角色列表 */
export const ROLES_LIST = '/user/role/list';
export function getRolesList(params) {
  return request({
    url: ROLES_LIST,
    method: 'get',
    params,
  });
}

/* 角色用户 */
/* 查询角色用户列表-添加账号弹窗-全量 */
export const ROLES_USER_LIST = '/user/role/roleuser/adduser/list';
export function getRoleUsersList(params) {
  return request({
    url: ROLES_USER_LIST,
    method: 'get',
    params,
  });
}
/* 查询角色用户列表-已添加的用户 */
export const ROLES_ADDED_USER_LIST = '/user/role/roleuser/list';
export function getRoleAddedUsersList(params) {
  return request({
    url: ROLES_ADDED_USER_LIST,
    method: 'get',
    params,
  });
}

/* 角色用户-添加账号保存 */
export function addBindRoleUser(data) {
  return request({
    url: '/user/role/roleuser/adduser/save',
    method: 'post',
    data,
  });
}

/* 角色用户-取消关联 */
export function cancelBindRoleUser(data) {
  return request({
    url: '/user/role/roleuser/cancelBind',
    method: 'post',
    data,
  });
}

/* 组织管理-新建/编辑组织 */
export function editSaveOrg(data) {
  return request({
    url: '/user/group/addSave',
    method: 'post',
    data,
  });
}
/* 组织管理-组织详情 */
export const ORG_DETAIL = '/user/group/groupDetail';
export function orgDetail(params) {
  return request({
    url: ORG_DETAIL,
    method: 'get',
    params,
  });
}
/* 组织管理-调整组织顺序 */
export function orderOrg(data) {
  return request({
    url: '/user/group/adjustGroupRank',
    method: 'post',
    data,
  });
}

/* 组织管理-停用/启用组织 */
export function toggleOrgStatus01(data) {
  return request({
    url: '/user/group/edit',
    method: 'post',
    data,
  });
}
/* 组织管理-删除组织 */
export function deleteOrg(data) {
  return request({
    url: '/user/group/remove',
    method: 'post',
    data,
  });
}

/* 组织管理-获取当前用户分组及角色 */
export const ORG_MENU_PERMS = '/user/group/getCurGroupRole';
export function getOrgMenuPerms(params) {
  return request({
    url: ORG_MENU_PERMS,
    method: 'get',
    params,
  });
}

/* 组织管理-组织已添加用户列表 */
export const ORG_USER_LIST = '/user/group/groupUser';
export function getOrgUserList(params) {
  return request({
    url: ORG_USER_LIST,
    method: 'get',
    params,
  });
}
/* 组织管理-组织添加用户弹窗列表 */
export const ORG_ALL_USER_LIST = '/user/group/getUserListByCode';
export function getOrgAllUserList(params) {
  return request({
    url: ORG_ALL_USER_LIST,
    method: 'get',
    params,
  });
}
/* 组织管理-组织管理员下拉列表 */
export const MANAGER_LIST = '/user/group/getUserList';
export function getManagerList(params) {
  return request({
    url: MANAGER_LIST,
    method: 'get',
    params,
  });
}

/* 组织管理-批量添加账号 */
export function multiBindOrgUsers(data) {
  return request({
    url: '/user/group/batchAddUser',
    method: 'post',
    data,
  });
}
/* 组织管理-批量取消关注 */
export function multiUnBindOrgUsers(data) {
  return request({
    url: '/user/group/batchRemoveUser',
    method: 'post',
    data,
  });
}
/* 组织管理-组织树 */
export const ORG_TREE = '/user/group/tree';
export function getOrgTree(params) {
  return request({
    url: ORG_TREE,
    method: 'get',
    params,
  });
}
/* 组织管理-获取上级组织区划数据(编辑组织时) */
export const ORG_AREA_DATA = '/user/group/getAreaData';
export function getOrgAreaData(params) {
  return request({
    url: ORG_AREA_DATA,
    method: 'get',
    params,
  });
}

/* ↑🔼 ↑🔼 ↑🔼 一体化系统集成基础平台-新公共用户中心 ↑🔼 ↑🔼 ↑🔼 */
