import {restrictToHorizontalAxis} from '@dnd-kit/modifiers';
import {horizontalListSortingStrategy} from '@dnd-kit/sortable';
import {css} from '@emotion/react';
import {useAtom} from 'jotai';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {useSearchParams} from 'react-router-dom';

import {logicEditAtom} from '../../../../../../atoms/logicEditAtom';
import TableSheetCopy from '../../../../../../components/TableSheetCopy';
import {deepClone} from '../../../../../../helpers/utils';
import {calcTableXColHideRepeatStyle} from '../../helpers';

const PreviewTableX = () => {
  const tableRef = useRef();

  const [tableScrollLeft, setTableScrollLeft] = useState(null);

  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {
    showHideFields,
    previewXData,
    previewXOriginColumns,
    previewTableHeight,
    previewTableLoading,
  } = totalStatus;

  const previewXColumns = useMemo(() => {
    const finalColumns = showHideFields
      ? calcTableXColHideRepeatStyle(previewXOriginColumns)
      : calcTableXColHideRepeatStyle(
          previewXOriginColumns.filter((col) => col.isShow)
        );
    return finalColumns.sort((a, b) => a.indexRank - b.indexRank);
  }, [previewXOriginColumns, showHideFields]);

  const formatSortColumns = (newColumns) => {
    const clone = deepClone(previewXOriginColumns);
    clone.forEach((p, pIdx) => {
      const index = newColumns.findIndex((n) => n.sortKeys === p.sortKeys);
      if (index >= 0) {
        newColumns.splice(index, 1, p);
      } else {
        newColumns.splice(pIdx, 0, p);
      }
    });
    return newColumns.map((n, nIdx) => ({...n, indexRank: nIdx}));
  };

  useEffect(() => {
    setTimeout(() => {
      if (previewXColumns.length > 0) {
        const tableTHEl =
          document
            ?.getElementsByClassName('previewTableX')[0]
            ?.getElementsByClassName('ant-table-thead')[0]
            ?.getElementsByClassName('resizable-title') ?? [];

        [...tableTHEl].forEach((thel) => {
          const thSpan = thel.getElementsByTagName('span')[0];
          thSpan.removeAttribute('title');
        });
      }
    }, 1000);
    if (tableScrollLeft !== null) {
      const tableBodyEle =
        document?.getElementsByClassName('ant-table-body')[0];
      tableBodyEle?.scrollTo({left: tableScrollLeft, behavior: 'smooth'});
    }
  }, [previewXColumns, tableScrollLeft]);

  return (
    <div className="previewTableX" style={{marginTop: 6}}>
      {previewXColumns.length > 0 ? (
        <TableSheetCopy
          headerDraggable
          clearFilter={false}
          columns={previewXColumns}
          css={css`
            th.ant-table-cell {
              padding: 0 !important;
            }
            td.ant-table-cell {
              padding: 0 !important;
            }
            .ant-table-header {
              ::-webkit-scrollbar {
                display: none;
              }
              overflow-y: scroll !important;
            }
          `}
          dataSource={previewXData}
          dragSortConfig={{
            modifiers: [restrictToHorizontalAxis],
            strategy: horizontalListSortingStrategy,
          }}
          loading={previewTableLoading}
          pagination={false}
          ref={tableRef}
          refresh={false}
          rowKey="sortKeys"
          scroll={{y: previewTableHeight}}
          storeColumnWidthKey={`microIndexPreviewTable__X__${wideId}`}
          onHeaderDragEnd={(event) => {
            const {newColumns = []} = event;
            const ele =
              event.collisions[0]?.data.droppableContainer.node.current;
            setTableScrollLeft(ele.offsetLeft);
            setTotalStatus((pre) => ({
              ...pre,
              previewXOriginColumns: formatSortColumns([...newColumns]),
            }));
          }}
        />
      ) : (
        <div style={{height: 80, textAlign: 'center', color: '#cccccc'}}>
          无显示字段展示
        </div>
      )}
    </div>
  );
};

export default PreviewTableX;
