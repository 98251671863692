import {InfoCircleOutlined} from '@ant-design/icons';
import {Checkbox, Form, Radio, Tooltip} from 'antd';
import React from 'react';

import FormRequireLabel from '../../../../../components/FormRequireLabel';
import dayjs from '../../../../../helpers/extendedDayjs';
import EmailFormItem from './EmailFormItem';
import Frequency from './Frequency';

export default function SyncFormItems({
  formValues,
  userMailList,
  hasEmail = true,
}) {
  const {syncJobType = 0} = formValues;
  return (
    <>
      <Form.Item
        required
        label={<FormRequireLabel title="同步方式" />}
        name="syncJobType"
        rules={[
          {
            required: true,
            message: '请选择同步方式',
          },
        ]}
      >
        <Radio.Group>
          <Radio value={0}>定时同步</Radio>
          <Radio value={1}>手动同步</Radio>
        </Radio.Group>
      </Form.Item>
      {syncJobType === 0 ? (
        <Form.Item
          label={<FormRequireLabel title="同步频率" />}
          name="jobCronJson"
          rules={[
            {
              validator(rule, value) {
                let message = '';
                const checkRule = new Map([
                  [
                    2,
                    {
                      field: 'stepHours',
                      reg: /^(([1-9])|(1\d)|(2[0-3]))$/,
                      msg: '请填写间隔小时数(1 ~ 23范围整数)',
                    },
                  ],
                  [
                    3,
                    {
                      field: 'stepDays',
                      reg: /^(([1-9])|([1-2]\d)|(3[0-1]))$/,
                      msg: '请填写间隔天数(1 ~ 31范围整数)',
                    },
                  ],
                  [
                    5,
                    {field: ['months', 'monthDays'], msg: '请选择月份和日期'},
                  ],
                ]);
                const {frequency, startTime} = value;
                const okDateTimeVal = dayjs().isBefore(dayjs(startTime));

                [...checkRule.keys()].forEach((key) => {
                  if (key === frequency) {
                    let isValid = false;
                    const {field, reg, msg} = checkRule.get(key);
                    if (Array.isArray(field)) {
                      isValid = field.every((item) => value[item].length > 0);
                    } else {
                      const curFieldVal = value[field];
                      isValid = reg.test(curFieldVal);
                    }

                    if (!isValid) {
                      message = msg;
                    }
                  }
                });

                if (frequency === 1 && !okDateTimeVal) {
                  message = '请选择当前系统时间之后的时间';
                }
                return message === ''
                  ? Promise.resolve()
                  : Promise.reject(new Error(message));
              },
            },
          ]}
        >
          <Frequency />
        </Form.Item>
      ) : null}
      {hasEmail && (
        <>
          <Form.Item label="同步失败提醒" name="syncResultRemind">
            <Checkbox.Group>
              {/* <Checkbox value={1}>站内消息</Checkbox> */}
              <Checkbox value={2}>
                邮箱
                <Tooltip title="读取用户中心的邮箱">
                  <InfoCircleOutlined />
                </Tooltip>
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
          {formValues.syncResultRemind?.includes(2) && (
            <EmailFormItem userMailList={userMailList} />
          )}
        </>
      )}
    </>
  );
}
