import {css} from '@emotion/react';
import {Select, InputNumber} from 'antd';
import React, {useEffect, useState, useMemo, useCallback} from 'react';

const styleProperties = {
  border: '1px solid #f9b1b4',
  background: '#f9b1b4',
};

const indexTypeOptions = [
  {value: 'bigint', label: '数值'},
  {value: 'decimal', label: '数值（小数）'},
  {value: 'datetime', label: '时间'},
  {value: 'varchar', label: '文本 '},
  {value: 'text', label: '长文本'},
];

// 非受控组件
const SelectFieldType = ({
  delFlag = null,
  options = indexTypeOptions,
  defaultTypeValue = 'bigint',
  defaultPrecisionValue = 2,
  defaultValue = {type: null, precision: null},
  onChange,
  ...restProps
}) => {
  // 类型
  const [typeValue, setTypeValue] = useState(() => defaultTypeValue);
  // 小数位精度
  const [precisionValue, setPrecisionValue] = useState(
    () => defaultPrecisionValue
  );
  // 组件内部状态，不受控
  const isDecimal = useMemo(() => typeValue === 'decimal', [typeValue]);

  // init
  useEffect(() => {
    const [type, precision] = [
      defaultValue.type ?? defaultTypeValue,
      defaultValue.precision ?? defaultPrecisionValue,
    ];
    setTypeValue(type);
    setPrecisionValue(precision);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeType = useCallback(
    (val) => {
      setTypeValue(val);
      const params = {type: val};
      if (val === 'decimal') {
        params.precision = precisionValue;
      }
      onChange?.(params);
    },
    [onChange, precisionValue]
  );

  const handleChangePrecision = useCallback(
    (val) => {
      setPrecisionValue(val ?? 2);
      onChange?.({type: typeValue, precision: val ?? 2});
    },
    [onChange, typeValue]
  );

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        align-items: center;
        .ant-input-number {
          margin-left: 2px;
        }
        .unit {
          margin-left: 2px;
        }
      `}
    >
      <Select
        css={css`
          .ant-select-selector {
            background: ${delFlag === 1 ? 'none' : '#ffffff'} !important;
          }
        `}
        defaultValue={defaultTypeValue}
        disabled={delFlag === 1}
        options={options}
        size="small"
        style={{
          ...(delFlag === 1 ? styleProperties : {}),
          width: '100%',
        }}
        {...restProps}
        value={typeValue}
        onChange={handleChangeType}
      />
      {isDecimal && (
        <>
          <InputNumber
            defaultValue={defaultPrecisionValue}
            max={16}
            min={0}
            size="small"
            value={precisionValue}
            onChange={handleChangePrecision}
          />
          <span className="unit">位</span>
        </>
      )}
    </div>
  );
};

export default SelectFieldType;
