import {useAtom} from 'jotai';

import {permChangeAtom} from '../atoms/permChangeAtom';

export default function usePermChange(
  permChangeFetcher,
  permChangeValidator = (data) => data === null || data.length === 0
) {
  const [, setPermChange] = useAtom(permChangeAtom);

  return async (payload) => {
    const {data} = await permChangeFetcher(payload ?? {});
    if (permChangeValidator(data)) {
      setPermChange(true);
      return true;
    }
    return false;
  };
}
