import axios from 'axios';
import {useAtom} from 'jotai';
import {stringify} from 'query-string';
import React, {useEffect, useRef, useState} from 'react';

import {authMenusAtom} from '../../../atoms/authMenus';
import {selfInfoPath, USER_PERMS_MAP} from '../../../constants/system';
import {routeHistory} from '../../../helpers/history';
import {flattern} from '../../../helpers/utils';
import NoticeModal from '../components/NoticeModal';

/**
 * @typedef {{
 *  result?: import('react').ReactNode | Function;
 * } | null} returnType
 */

/**
 * @return {returnType}
 */
function openMessage(content) {
  /** @type {import("../components/MessageModal").dataSource} */
  const dataSource = {
    noticeId: content.id ?? content.noticeId,
    createBy: content.createBy,
    noticeTime: content.createTime,
    noticeContent: content.noticeContent,
  };

  return {
    result: (
      // eslint-disable-next-line react/jsx-filename-extension
      <NoticeModal.Modal closable dataSource={dataSource} />
    ),
  };
}

/**
 * @param {String} pathname
 * @param {Object} queryParams
 * @return {returnType}
 */
function turnToPage(pathname, content, queryParams) {
  const queryString = stringify(queryParams);

  return {
    result: () => {
      routeHistory.push(
        {
          pathname,
          search: `?${queryString}`,
        },
        {
          from: 'letter',
        }
      );
    },
  };
}

function downloadFile(content, queryParams) {
  if (!queryParams.url) return;
  axios
    .get(queryParams.url, {
      responseType: 'blob',
    })
    .then((response) => {
      const elink = document.createElement('a');
      elink.download = queryParams.fileName;
      elink.style.display = 'none';
      elink.href = URL.createObjectURL(response?.data ?? response);
      document.body.appendChild(elink);
      elink.click();
      URL.revokeObjectURL(elink.href);
      document.body.removeChild(elink);
    });
}

let strategy;

const getStrategy = (menus) => {
  if (strategy && menus.length) return strategy;
  const flattedMenus = flattern(menus);
  const collectTaskPageCode = USER_PERMS_MAP.get('采集任务');
  const collectTaskPageMenuItem = flattedMenus.find(
    (item) => item.code === collectTaskPageCode
  );

  const dbConfigPageCode = USER_PERMS_MAP.get('数据库表管理');
  const dbConfigPageMenuItem = flattedMenus.find(
    (item) => item.code === dbConfigPageCode
  );

  const divisionPageCode = USER_PERMS_MAP.get('区划代码管理-版本表单');
  const divisionPageMenuItem = flattedMenus.find(
    (item) => item.code === divisionPageCode
  );

  const microDataQueryPageCode = USER_PERMS_MAP.get('微观数据查询');
  const microDataQueryPageMenuItem = flattedMenus.find(
    (item) => item.code === microDataQueryPageCode
  );

  strategy = new Map([
    [`${USER_PERMS_MAP.get('系统设置')}1`, openMessage],
    [`${USER_PERMS_MAP.get('账号管理')}1`, turnToPage.bind(null, selfInfoPath)],
    [
      `${collectTaskPageCode}1`,
      turnToPage.bind(
        null,
        `/${collectTaskPageMenuItem?.code}${collectTaskPageMenuItem?.routeUrl}`
      ),
    ],
    [
      `${dbConfigPageCode}1`,
      turnToPage.bind(
        null,
        `/${dbConfigPageMenuItem?.code}${dbConfigPageMenuItem?.routeUrl}`
      ),
    ],
    [
      `${dbConfigPageCode}2`,
      turnToPage.bind(
        null,
        `/${dbConfigPageMenuItem?.code}${dbConfigPageMenuItem?.routeUrl}/DBFormListPage`
      ),
    ],
    [
      `${dbConfigPageCode}3`,
      turnToPage.bind(
        null,
        `/${dbConfigPageMenuItem?.code}${dbConfigPageMenuItem?.routeUrl}/DBFormListPage/DBTableListPage`
      ),
    ],
    [
      `${divisionPageCode}2`,
      turnToPage.bind(
        null,
        `/${divisionPageMenuItem?.code}${divisionPageMenuItem?.routeUrl}`
      ),
    ],
    [`${divisionPageCode}1`, downloadFile],
    [
      `${microDataQueryPageCode}1`,
      turnToPage.bind(
        null,
        `/${microDataQueryPageMenuItem?.code}${microDataQueryPageMenuItem?.routeUrl}`
      ),
    ],
    [`${USER_PERMS_MAP.get('组织管理')}1`, openMessage],
  ]);
  return strategy;
};

export function useReadLetter() {
  const [readResult, setReadResult] = useState(null);
  const [authMenus] = useAtom(authMenusAtom);
  const allStrategy = useRef();
  useEffect(() => {
    if (authMenus?.length) {
      allStrategy.current = getStrategy(authMenus);
    }
  }, [authMenus]);

  return {
    readResult,
    setReadResult: ({noticeType, pageCode, content, queryParams}) => {
      const reply = allStrategy.current?.get(pageCode + noticeType);
      if (reply) {
        setReadResult(reply(content, queryParams));
      }
      if (process.env.NODE_ENV === 'development' && !reply) {
        console.warn('未定义的站内信规则');
      }
    },
  };
}
