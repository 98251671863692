/* eslint-disable react/jsx-filename-extension */
import {css} from '@emotion/react';

import {getSheetsFields} from '../../../../../api/dataManagement/microIndex';
import {COLUMN_TYPE} from '../../../../../components/ComputedFieldEditor/constants';
import {
  delColor,
  grayBy,
  hidedTitleStyle,
  repeatTitleStyle,
  showTitleStyle,
} from '../../../../../components/TableSheet/components/styles.css';
import {SQLSyntaxKeywords} from '../../../../../constants/SQLSyntaxKey';
import {textOverFlow} from '../../../../../global.css';
import {deepClone, toLowerCase} from '../../../../../helpers/utils';
import {leftJoin, specialSplitSymbol} from './constants';

export const isEmptyValue = (val) => !val && val !== 0;
export const fillEmptyVal = (val) => (isEmptyValue(val) ? '(null)' : val);

export const tableCellRender = (value, showVal, row) => {
  const isEmptyVal = isEmptyValue(value);

  return (
    <div
      css={textOverFlow}
      style={{
        padding: '5px 10px',
        ...(row?.delFlag === 1
          ? {background: delColor}
          : showVal && !isEmptyVal
          ? {}
          : {
              background: grayBy,
            }),
      }}
    >
      {isEmptyVal ? '(null)' : value}
    </div>
  );
};

export const getTitleStyle = (showVal, hasRepeat, isNewCal) => {
  const hasRepeatStyle = hasRepeat ? repeatTitleStyle : '';
  const showOrHideStyle = showVal ? showTitleStyle : hidedTitleStyle;

  return css`
    ${hasRepeatStyle}
    ${showOrHideStyle}
    ${isNewCal
      ? css`
          background-color: lightblue;
        `
      : ''}
  `;
};

export const getExtraFilterItems = (allCols, col) => {
  // const {isShow = true, formula, title, _title, columnAlias, nameSource} = col;
  const {isShow = true, formula, columnAlias, delFlag} = col;

  const showText = isShow ? '隐藏' : '显示';
  const isCalcField = !!formula;

  const isQuoted2Calc = allCols.some((acol) =>
    acol?.uniqueAliasList?.includes(columnAlias)
  );

  // 1.被引用计算字段，不显示【删除】
  // 2.未被引用，计算字段，【删除】依然在
  // 3.计算字段，不显示【隐藏】
  // 4.普通字段，被引用，不显示【隐藏】
  return [
    {},
    ...(isCalcField || isQuoted2Calc || delFlag === 1
      ? []
      : [{key: 'toggleShow', label: showText}]),
    {},
    // {key: 'rename', label: '重命名'},
    // ...(titleChanged ? [{key: 'resetName', label: '重置名称'}] : []),
    {},
    // ...(isShow ? [{key: 'creatCalc', label: '创建计算字段'}] : []),
    ...(isCalcField
      ? [
          {key: 'editCalc', label: '编辑计算字段'},
          ...(isQuoted2Calc ? [] : [{key: 'delCalc', label: '删除计算字段'}]),
        ]
      : []),
  ];
};

// 每个字段的显、隐、重复状态和样式
export const calcColHideRepeatStyle = (arr) => {
  const newArr = deepClone(arr);
  return newArr.map((col, index) => {
    const {dataIndex, title, isShow, columnAlias} = col;

    const otherRepeat = arr.filter(
      (c) => c.title === title && c.dataIndex !== dataIndex
    );

    const columnAliasRepeat = arr.filter(
      (c) =>
        toLowerCase(c.columnAlias) === toLowerCase(columnAlias) &&
        c.dataIndex !== dataIndex
    );

    const repeatController = (repeat, key) => {
      if (repeat.length === 0) {
        col.hasRepeat = false;
        col._hasRepeat = false;
        col[key] = false;
        col.colCss = getTitleStyle(
          isShow,
          false,
          index === newArr.length - 1 && col.formula && !col.calId
        );
      } else {
        col.hasRepeat = true;
        col._hasRepeat = true;
        col[key] = true;
        col.colCss = getTitleStyle(
          isShow,
          true,
          index === newArr.length - 1 && col.formula && !col.calId
        );

        repeat.forEach((item) => {
          item.hasRepeat = true;
          item._hasRepeat = true;
          col[key] = true;
          item.colCss = getTitleStyle(item.isShow, true);
        });
      }
    };

    const validateColumnAlias = (fieldName) => {
      if (
        /^[a-zA-Z][a-zA-Z0-9_]*$/g.test(fieldName) &&
        !SQLSyntaxKeywords.some((key) =>
          new RegExp(`^${columnAlias}{1,${key.length}}$`, 'gi').test(key)
        )
      ) {
        col.columnAliasError = false;
      } else {
        col.columnAliasError = true;
      }
    };

    repeatController(otherRepeat, 'curShowNameRepeat');
    repeatController(columnAliasRepeat, 'columnAliasRepeat');
    validateColumnAlias(columnAlias);

    // 020_微观指标管理_交互原型_V1.0】【注释75/76】
    // (20230301新增)重复包括2种情况：
    // 1. 当前状态为【显示】的字段名称存在重复；
    // 2. 【字段名称】为【更新时间(系统)】
    if (['更新时间(系统)', '标签'].includes(title)) {
      col.hasRepeat = true;
      col._hasRepeat = true;
      col.curShowNameError = true;
      col.colCss = getTitleStyle(
        isShow,
        true,
        index === newArr.length - 1 && col.formula && !col.calId
      );
    } else {
      col.curShowNameError = false;
    }

    col.extraFilterItems = getExtraFilterItems(arr, col);

    return col;
  });
};

// previewTableX的显、隐、重复状态和样式
export const calcTableXColHideRepeatStyle = (arr) => {
  const newArr = deepClone(arr);
  return newArr.map((col, index) => {
    const {dataIndex, title, isShow, columnAlias, delFlag} = col;

    // 字段名重复 格式错误 显示名重复 都需要显示错误
    const otherRepeat = arr.filter(
      (c) =>
        (c.title === title ||
          toLowerCase(c.columnAlias) === toLowerCase(columnAlias) ||
          !/^[a-zA-Z][a-zA-Z0-9_]*$/g.test(columnAlias) ||
          delFlag) &&
        c.dataIndex !== dataIndex
    );

    if (otherRepeat.length === 0) {
      col.hasRepeat = false;
      col._hasRepeat = false;
      col.columnAliasRepeat = false;
      col.columnAliasError = false;
      col.className = 'noRepeat';
      col.colCss = getTitleStyle(
        isShow,
        false,
        index === newArr.length - 1 && col.formula && !col.calId
      );
    } else {
      col.hasRepeat = true;
      col._hasRepeat = true;
      col.className = 'repeat';
      col.colCss = getTitleStyle(
        isShow,
        true,
        index === newArr.length - 1 && col.formula && !col.calId
      );

      otherRepeat.forEach((item) => {
        item.hasRepeat = true;
        item._hasRepeat = true;
        col.className = 'repeat';
        item.colCss = getTitleStyle(item.isShow, true);
      });
    }

    // 020_微观指标管理_交互原型_V1.0】【注释75/76】
    // (20230301新增)重复包括2种情况：
    // 1. 当前状态为【显示】的字段名称存在重复；
    // 2. 【字段名称】为【更新时间(系统)】
    if (['更新时间(系统)', '标签'].includes(title)) {
      col.hasRepeat = true;
      col._hasRepeat = true;
      col.className = 'repeat';
      col.colCss = getTitleStyle(
        isShow,
        true,
        index === newArr.length - 1 && col.formula && !col.calId
      );
    }

    col.extraFilterItems = getExtraFilterItems(arr, col);

    return col;
  });
};

export const disposeToggle = (arr, curColumn) => {
  const newList = deepClone(arr);

  return newList.map((col) => {
    const {dataIndex, isShow} = col;
    if (curColumn.dataIndex === dataIndex) {
      const newShowVal = !isShow;
      const newColStyle = getTitleStyle(newShowVal, col.hasRepeat);

      col.isShow = newShowVal;
      col.colCss = newColStyle;
      col.extraFilterItems = getExtraFilterItems(arr, col);
      col.render = (val) => tableCellRender(val, newShowVal);
    }
    return col;
  });
};

export const disposeResetName = (arr, curColumn) => {
  let newList = deepClone(arr);

  newList = newList.map((col) => {
    if (col.dataIndex === curColumn.dataIndex) {
      col.title = col._title;
      col.curShowName = col._title;

      col.extraFilterItems = getExtraFilterItems(arr, col);
    }
    return col;
  });

  return calcColHideRepeatStyle(newList);
};

export const getNewJoinCondition = async (leftNode, rightNode) => {
  let joinCondition = leftJoin;
  const defaultJoinLogic = [];

  let fieldsListRes = await getSheetsFields({
    tableIds: [leftNode.tableId, rightNode.tableId],
  });

  fieldsListRes = fieldsListRes?.data ?? [];

  const [leftSheetFields, curSheetFields] = [
    // eslint-disable-next-line prettier/prettier
    fieldsListRes.find((t) => t.tableId === leftNode.tableId)?.columnList ?? [],
    // eslint-disable-next-line prettier/prettier
    fieldsListRes.find((t) => t.tableId === rightNode.tableId)?.columnList ?? [],
  ];

  const leftNodePK = leftSheetFields.filter(
    (field) => field.logicalPrimaryFlag === 1
  );

  const rightNodePK = curSheetFields.filter(
    (field) => field.logicalPrimaryFlag === 1
  );

  leftNodePK.forEach((lt) => {
    const obj = {};
    rightNodePK.forEach((rt) => {
      if (lt.name === rt.name) {
        obj.left = lt.id;
        obj.right = rt.id;
        defaultJoinLogic.push(obj);
      }
    });
  });

  const hasMutualPK = leftNodePK.some((lv0) =>
    rightNodePK.some((cur) => lv0.name === cur.name)
  );

  if (hasMutualPK) joinCondition = leftJoin;

  return {
    joinCondition,
    defaultJoinLogic,
  };
};

export const findNodeChilds = (dataList, curTableId, curLevel, result = []) => {
  const maxLevel = Math.max(...dataList.map((t) => t.level ?? 0));

  const curChilds = dataList.filter(
    (info) => info.level > curLevel && info.parentTableId === curTableId
  );

  result.push(...curChilds);

  if (curLevel <= maxLevel) {
    curChilds.forEach((child) => {
      findNodeChilds(dataList, child.tableId, child.level, result);
    });
  }

  return result;
};

export const treeFilter = (tree, func = (v) => v) => {
  if (!Array.isArray(tree)) {
    return [];
  }
  return tree
    .map((node) => ({...node}))
    .filter((node) => {
      node.children = node.children && treeFilter(node.children, func);
      return func(node) || (node.children && node.children.length);
    });
};

export const filterOnlyShowCol4Info = (allCols, curParams) => {
  const isShowCols = allCols; /* .filter((col) => col.isShow) */
  if (allCols.length === 0) return curParams;
  return curParams
    .filter((item) => {
      return isShowCols.some(
        (col) =>
          (col.aliasName ?? '').split(specialSplitSymbol)[0] ===
          (item.aliasName ?? '').split(specialSplitSymbol)[0]
      );
    })
    .map((item) => {
      return {
        ...item,
        aliasName: (item.aliasName ?? '').split(specialSplitSymbol)[0],
      };
    });
};

/**
 * @description: 新数组与旧数组对比，同字段的值替换为旧字段的值,计算字段没有id
 * @param {array} adoptedArr 旧数组，要被采纳旧值的数组
 * @param {array} newArr 新数组，接收旧值的数组,也是返回值
 * @param {string} uniqKey 两个对象数组遍历唯一key
 * @return {array}
 */
export function arrReplaceSameKeyVal(adoptedArr, newArr, deletedColumns) {
  const result = newArr
    .filter((i) => !deletedColumns.map((d) => d.id).includes(i.id))
    .map((newItem) => {
      let oldItem;
      if (newItem.formula) {
        // 是计算字段
        oldItem = adoptedArr.find(
          (aItem) => aItem.originColumnAlias === newItem.columnAlias
        );
      } else {
        // 普通字段则根据唯一id进行旧值替换
        oldItem = adoptedArr.find((aItem) => aItem.id === newItem.id);
      }

      // const oldItem = adoptedArr.find((aItem) => aItem.id === newItem.id);
      if (oldItem) {
        const keys = Object.keys(oldItem);
        keys.forEach((key) => {
          // 关键唯一字段采用新的, 重复字段aa会变成 aa_1, aa_2
          if (
            ![
              'aliasName',
              'dataIndex',
              'originColumnAlias',
              'sortKeys',
              'formulas',
              'formula',
              'uniqueAliasList',
              'calColumnIndexSource',
              'pointNum',
              'delFlag',
            ].includes(key)
          ) {
            newItem[key] = oldItem[key];
          }
        });
      }

      return newItem;
    });

  return result.sort((a, b) => a.indexRank - b.indexRank);
}

export function sortComputedColumns(columns) {
  const finalColumns = [];
  function traverse(_columns) {
    _columns.forEach((col) => {
      if (
        finalColumns.findIndex((col2) => col2.calAlias === col.calAlias) === -1
      ) {
        finalColumns.push(col);
      }
      const parsedFormulas = JSON.parse(col.formulas);
      const formulas = parsedFormulas.formulas;
      formulas.forEach((element) => {
        if (element.type === COLUMN_TYPE) {
          const content = JSON.parse(element.content);
          if (!content.id) {
            // 计算字段
            const index = finalColumns.findIndex(
              (col2) => col2.calAlias === content.calAlias
            );
            const elementCalCol = columns.find(
              (col3) => col3.calAlias === content.calAlias
            );
            if (elementCalCol) {
              finalColumns.unshift(elementCalCol);
            }
            if (index > -1) {
              finalColumns.splice(index + 1, 1);
            }
            if (elementCalCol) {
              traverse([elementCalCol]);
            }
          }
        }
      });
    });
  }
  traverse(columns);
  return finalColumns;
}
