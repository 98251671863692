import {Graph} from '@antv/x6';
import {useAtom} from 'jotai';
import {useCallback, useEffect} from 'react';
import '@antv/x6-react-shape';

import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import {flatDataToTree} from '../../../../../helpers/utils';
import {addNodeHeight} from '../../helpers';
import ToolTipNode from '../components/CanvasEdit/ToolTipNode';

const nodeWidth = 180 + 24 + 14;
const nodeHeight = 32;
const gapX = 36;
const gapY = 12;

Graph.registerEdge(
  'customedge',
  {
    zIndex: -1,
    attrs: {
      line: {
        fill: 'none',
        strokeLinejoin: 'round',
        strokeWidth: 2,
        stroke: '#A2B1C3',
        sourceMarker: null,
        targetMarker: null,
      },
    },
  },
  true
);

export default function useDrawCanvas() {
  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {graphData = {}, graphInstance} = totalStatus;
  const {tableStructureList = []} = graphData;

  const createNodes = useCallback(
    (arr, cleanBeforeRender = false) => {
      arr.forEach((nodeInfo, index) => {
        const {level, parentTableId, _gapY, children = []} = nodeInfo;
        const parentNodeInfo = tableStructureList.find(
          (item) => item.tableId === parentTableId
        );

        const diff = level === 0 ? 46 : 0;

        const nodeXPos = level * (nodeWidth + diff + gapX) - 33;
        const nodeYPos = _gapY * (nodeHeight + gapY) - 40;

        const curNode = graphInstance.addNode({
          x: nodeXPos,
          y: nodeYPos,
          width: nodeWidth - diff,
          height: nodeHeight,
          shape: 'react-shape',
          data: nodeInfo,
          component: (node) => {
            return (
              // eslint-disable-next-line react/jsx-filename-extension
              <ToolTipNode
                leftNodeInfo={parentNodeInfo}
                rightNodeInfo={nodeInfo}
              />
            );
          },
        });

        if (level) {
          const curNodeParentNode = graphInstance.getNodes().find((n) => {
            const data = n.getData();
            return data.tableId === parentTableId;
          });
          const pos = curNode.position();
          const parentNodePos = curNodeParentNode.position();

          graphInstance.addEdge({
            shape: 'customedge',

            source: {
              cell: curNodeParentNode,
              anchor: 'right',
            },
            target: {
              cell: curNode,
              anchor: 'left',
            },
            vertices:
              index > 0
                ? [
                    {x: pos.x - gapY, y: parentNodePos.y + 16},
                    {x: pos.x - gapY, y: pos.y + 16},
                  ]
                : [],
          });
        }
        if (children.length > 0) createNodes(children);
      });
    },
    [graphInstance, tableStructureList]
  );

  const initGraphInstance = useCallback(() => {
    const canvasBoxElement = document.getElementById('canvasBoxElement');
    const minimapContainer = document.getElementById('canvasMiniMap');

    const graph = new Graph({
      container: canvasBoxElement,
      autoResize: document.getElementById('centerResize'),
      scroller: {
        enabled: true,
        pannable: true,
        padding: 0,
      },

      panning: {
        enabled: true,
      },

      mousewheel: {
        enabled: false,
        modifiers: ['ctrl', 'meta'],
      },

      interacting: {
        nodeMovable: false,
        edgeMovable: false,
        edgeLabelMovable: false,
      },

      minimap: {
        enabled: true,
        scalable: false,
        width: 180,
        height: 110,
        container: minimapContainer,
      },
    });

    setTotalStatus((pre) => ({...pre, graphInstance: graph}));
  }, [setTotalStatus]);

  const toggleMiniMap = (arr = []) => {
    const arrLen = arr.length;
    const miniMapEl = document.getElementById('canvasMiniMap');

    const countInLevel = (levelArr) =>
      levelArr.map((n) => arr.filter((info) => info.level === n).length);

    const needShow =
      arr.some((info) => info.level >= 3) ||
      Math.max(...countInLevel([1, 2, 3])) >= 6;

    miniMapEl.style.display = needShow && arrLen !== 0 ? 'block' : 'none';
  };

  useEffect(() => {
    if (graphInstance) {
      // graphInstance.freeze();
      graphInstance.clearCells();

      if (tableStructureList.length > 0) {
        const treeNodesList = flatDataToTree(
          tableStructureList,
          'parentTableId',
          tableStructureList.find((n) => n.level === 0).tableId
        );

        addNodeHeight(treeNodesList);

        createNodes(treeNodesList);
      }

      toggleMiniMap(tableStructureList);

      // graphInstance.unfreeze();

      setTimeout(() => {
        setTotalStatus((pre) => ({...pre, canvasLoading: false}));
      }, 200);
    }
  }, [createNodes, graphInstance, setTotalStatus, tableStructureList]);

  return {initGraphInstance};
}
