import ArithmeticGuidance from './components/ArithmeticGuidance';
import ConstGuidance from './components/ConstGuidance';
import MergeGuidance from './components/MergeGuidance';

export const inputingFieldReg = /^@\S*$/gi;

export const inputingConstantReg = /^"[\s\S]*"$/gi;

export const inputingNumberReg =
  /(^(-?)0?(\.\d*)?$)|(^0$)|(^(-?)[1-9]?\d*(\.\d*)?$)/gi;

export const numberReg = /(^(-?)0(\.\d+)?$)|(^0$)|(^(-?)[1-9]\d*(\.\d+)?$)/gi;

export const NUMBER_TYPES = [
  'int',
  'tinyint',
  'smallint',
  'mediumint',
  'bigint',
  'decimal',
  'decimal(32,16)',
  'float',
  'double',
];

export const BRANKETS_TYPE = 1;
export const OPERATOR_TYPE = 2;
export const CONST_TYPE = 3;
export const COLUMN_TYPE = 4;
export const NUMBER_TYPE = 5;

export const ITEM_TYPE = {
  BRANKETS: {
    type: BRANKETS_TYPE,
    reg: /^(\(|\)|（|）)$/gi,
  },
  OPERATOR: {
    type: OPERATOR_TYPE,
    reg: /^(\+|-|\*|\/|\(|\)|（|）)$/gi,
  },
  CONST: {
    type: CONST_TYPE,
    reg: /^"[\s\S]+"$/gi,
  },
  NUMBER: {
    type: NUMBER_TYPE,
    reg: /(^(-?)0(\.\d+)?$)|(^0$)|(^(-?)[1-9]\d*(\.\d+)?$)/gi,
  },
  FIELD: {
    type: COLUMN_TYPE,
  },
};

export const ALL_TYPE = {
  NUMBER: '1',
  TEXT: '2',
};

export const ALL_OPTIONS = {
  NUMBER: {
    label: '数值',
    value: ALL_TYPE.NUMBER,
  },
  TEXT: {
    label: '文本',
    value: ALL_TYPE.TEXT,
  },
};

export const NUMBER_FORMULA_TYPE = {
  ARITHMETIC: '1',
};

export const TEXT_FORMULA_TYPE = {
  CONST: '2',
  MERGE: '3',
};

export const NUMBER_CONFIG = [
  {
    label: '四则运算',
    value: NUMBER_FORMULA_TYPE.ARITHMETIC,
    guidance: ArithmeticGuidance,
    // validator: arithmeticValidator,
  },
];

export const TEXT_CONFIG = [
  {
    label: '固定值',
    value: TEXT_FORMULA_TYPE.CONST,
    guidance: ConstGuidance,
  },
  {
    label: '文字合并',
    value: TEXT_FORMULA_TYPE.MERGE,
    guidance: MergeGuidance,
  },
];

export const FORMULA_CONFIG = [
  {
    type: ALL_TYPE.NUMBER,
    config: NUMBER_CONFIG,
  },
  {
    type: ALL_TYPE.TEXT,
    config: TEXT_CONFIG,
  },
];
