import {atom} from 'jotai';

import {GET_USER_INFO} from '../api/system/user';
import request from '../helpers/request';

const userInfoAtom = atom({});

const fetchUserInfoAtom = atom(
  (get) => get(userInfoAtom),
  (_get, set, url) => {
    request
      .get(GET_USER_INFO)
      .then((res) => {
        set(userInfoAtom, (val) => ({
          ..._get(userInfoAtom),
          ...res.data,
        }));
      })
      .catch((e) => e);
  }
);

export {userInfoAtom, fetchUserInfoAtom};
