import React from 'react';

import {AuthButton} from '../AuthButton';

/**
 * @typedef {import('antd').ButtonProps & {
 *  payload?: Object;
 *  twiceCheck: Boolean;
 *  permChangeFetcher: Function;
 *  permChangeMapping: Function;
 * }} TwiceCheckButton
 */

/**
 * @param {TwiceCheckButton} props
 */
const TwiceCheckButton = (props) => {
  const {
    twiceCheckClick,
    onClick: originClickHandle,
    // payload = {},
    twiceCheck = true,
    ...restProps
  } = props;
  // const {checkNeedTwiceCheck} = useTwiceCheckModal(payload);

  const curClickHandle = () => {
    if (twiceCheck) {
      // await checkNeedTwiceCheck(originClickHandle, twiceCheckClick);
      // } else {
      //   originClickHandle();
    }
    originClickHandle();
  };
  return <AuthButton onClick={curClickHandle} {...restProps} />;
};

export default TwiceCheckButton;
