export const SQLSyntaxKeywords = [
  'CREATE',
  'ALTER',
  'DROP',
  'RENAME',
  'TRUNCATE',
  'TABLE',
  'COLUMN',
  'CONSTRAINT',
  'INDEX',
  'PRIMARY',
  'FOREIGN',
  'UNIQUE',
  'CHECK',
  'DEFAULT',
  'VIEW',
  'SEQUENCE',
  'DATABASE',
  'SCHEMA',
  'OWNER',
  'GRANT',
  'REVOKE',
  'PRIVILEGES',
  'ROLE',
  'TRIGGER',
  'PROCEDURE',
  'FUNCTION',
  'TYPE',
  'ASSEMBLY',
  'PARTITION',
  'CLUSTER',
  'COMPOSITE',
  'MATERIALIZED',
  'REFRESH',
  'EDITIONING',
  'SELECT',
  'FROM',
  'WHERE',
  'GROUP',
  'HAVING',
  'ORDER',
  'LIMIT',
  'OFFSET',
  'FETCH',
  'DISTINCT',
  'ALL',
  'UNION',
  'INTERSECT',
  'EXCEPT',
  'JOIN',
  'INNER',
  'LEFT',
  'RIGHT',
  'FULL',
  'CROSS',
  'NATURAL',
  'LATERAL',
  'ON',
  'USING',
  'WITH',
  'CTE',
  'INSERT',
  'VALUES',
  'UPDATE',
  'SET',
  'DELETE',
  'MERGE',
  'WHEN',
  'OUTPUT',
  'RETURNING',
  'CASE',
  'THEN',
  'ELSE',
  'END',
  'IF',
  'NULLIF',
  'COALESCE',
  'GREATEST',
  'LEAST',
  'OVER',
  'RANGE',
  'ROWS',
  'GROUPS',
  'PRECEDING',
  'FOLLOWING',
  'CURRENT',
  'UNBOUNDED',
  'LAG',
  'LEAD',
  'FIRST_VALUE',
  'LAST_VALUE',
  'NTH_VALUE',
  'RANK',
  'DENSE_RANK',
  'ROW_NUMBER',
  'NTILE',
  'PERCENT_RANK',
  'CUME_DIST',
  'WINDOW',
  'TIES',
  'IGNORE',
  'RESPECT',
  'QUALIFY',
  'SESSION',
  'RESOURCE',
  'SYSTEM',
  'AUDIT',
  'NOAUDIT',
  'COMMENT',
  'LABEL',
  'PROTECTION',
  'POLICY',
  'MASKING',
  'INHERIT',
  'IDENTIFIED',
  'BY',
  'PASSWORD',
  'AS',
  'USER',
  'PROFILE',
  'ACCOUNT',
  'LOCK',
  'UNLOCK',
  'EXPIRE',
  'FORCE',
  'CASCADE',
  'RESTRICT',
  'IMMEDIATE',
  'DEFERRABLE',
  'INITIALLY',
  'KEY',
  'OUTER',
  'RECURSIVE',
  'MATCHED',
  'NOT',
  'ROW',
  'NULLS',
  'SOURCE',
  'TARGET',
  'RID',
  'UPDATE_TIME',
  'NULL',
];
