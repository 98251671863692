/* eslint-disable react/jsx-filename-extension */
import {useAtom} from 'jotai';
import {nanoid} from 'nanoid';
import {useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {
  getCodeTableData,
  parseCalIndex,
  WIDE_TABLE_DATA,
} from '../../../../../api/dataManagement/microIndex';
import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import {DataTypeMap} from '../../../../../components/ComputedFieldEditor/components/editorContentConfig';
import {COLUMN_TYPE} from '../../../../../components/ComputedFieldEditor/constants';
import {specifyFilterTypeByColumnType} from '../../../../../components/Filter/helpers';
import FormErrMsg from '../../../../../components/FormErrMsg';
import useResizeLineStyle from '../../../../../components/TableSheet/hooks/useResizeLineStyle';
import request from '../../../../../helpers/request';
import {deepClone} from '../../../../../helpers/utils';
import {DynamicTitle} from '../components/PreviewTable/components/DynamicTitle';
import {
  arrReplaceSameKeyVal,
  calcTableXColHideRepeatStyle,
  disposeResetName,
  disposeToggle,
  fillEmptyVal,
  getExtraFilterItems,
  sortComputedColumns,
  tableCellRender,
} from '../helpers';
import {specialSplitSymbol} from '../helpers/constants';
import useSymbolSerialFilter from './useSymbolSerialFilter';

export default function useFetchWideTable() {
  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);

  const {
    previewXOriginColumns,

    graphData,
    previewTableDirection,

    hasDelComputedColumns,
    modifiedComputedColumns,
    deletedColumns,
  } = totalStatus;

  const {tableStructureList} = graphData;

  const tableRef = document.getElementsByClassName(
    `previewTable${previewTableDirection}`
  )[0];
  const {freshTablCotainer, freshTablCotainerToLast} =
    useResizeLineStyle(tableRef);

  const {serialfilterConditions, updateFilter} = useSymbolSerialFilter();

  const requestConfig = useMemo(
    () => ({
      headers: {
        'filter-condition': JSON.stringify(
          // filterOnlyShowCol4Info(previewXOriginColumns, serialfilterConditions)
          serialfilterConditions
        ),
      },
    }),
    [serialfilterConditions]
  );

  const getFilterShowedData = async (data) => {
    try {
      const res = await getCodeTableData(data);
      if (res?.code === 200) {
        return res?.data;
      }
      return [];
    } catch (err) {
      throw new Error('getCodeTableData failed');
    }
  };

  const onExtraFilterClickHandle = ({key, column}) => {
    if (key === 'toggleShow') {
      setTotalStatus((pre) => {
        return {
          ...pre,
          previewYData: disposeToggle(pre.previewYData, column),
          previewXOriginColumns: disposeToggle(
            pre.previewXOriginColumns,
            column
          ),
        };
      });
    }

    if (key === 'resetName') {
      setTotalStatus((pre) => {
        return {
          ...pre,
          previewYData: disposeResetName(pre.previewYData, column),
          previewXOriginColumns: disposeResetName(
            pre.previewXOriginColumns,
            column
          ),
        };
      });
    }

    if (key === 'creatCalc') {
      setTotalStatus((pre) => {
        return {
          ...pre,
          curComputedColumn: {
            visible: true,
            isAfterEditComputedColumn: false,
            columnInfo: {
              curShowName: '',
              columnAlias: '',
              pointNum: '2',
              formulas: [
                {
                  type: COLUMN_TYPE,
                  content: JSON.stringify({
                    dataType: DataTypeMap.get(column.indexType),
                    dbId: column.dbId,
                    id: column.id,
                    instanceId: column.instanceId,
                    tableId: column.tableId,
                    tableName: column.tableName,
                    name: column.formula ?? column.columnAlias,
                    calAlias: column.columnAlias,
                  }),
                },
              ],
              oriFormula: ` ${column.curShowName} `,
              placeholder: ' {} ',
              mentionsData: [
                {
                  ...column,
                  start: 0,
                },
              ],
              uniqueAliasList: [column.columnAlias],
              indexType: '',
            },
          },
        };
      });
    }

    if (key === 'editCalc') {
      setTotalStatus((pre) => {
        const formulas = JSON.parse(column.formulas);
        return {
          ...pre,
          curComputedColumn: {
            visible: true,
            isAfterEditComputedColumn: false,
            columnInfo: {
              ...column,
              pointNum: column.pointNum ?? '',
              formulas: formulas.formulas,
              oriFormula: formulas.oriFormula,
              placeholder: formulas.placeholder,
              mentionsData: formulas.mentionsData,
              uniqueAliasList: column.uniqueAliasList ?? [],
              indexType: column.indexType,
            },
          },
        };
      });
    }

    if (key === 'delCalc') {
      setTotalStatus((pre) => {
        const matchPreviewXColumnIndex = pre.previewXOriginColumns.findIndex(
          (col) => {
            return col.columnAlias === column.columnAlias;
          }
        );
        const newPreviewXOriginColumns = [...pre.previewXOriginColumns];
        newPreviewXOriginColumns.splice(matchPreviewXColumnIndex, 1);

        const matchModifiedIndex = modifiedComputedColumns.findIndex((col) => {
          return col.calAlias === column.columnAlias;
        });
        const newModifiedComputedColumns = [...modifiedComputedColumns];
        if (matchModifiedIndex > -1) {
          newModifiedComputedColumns.splice(matchModifiedIndex, 1);
        }

        const tableYData = newPreviewXOriginColumns.map((xcol, index) => {
          const tempData = (pre.previewXData || [])
            .map((xdata) => fillEmptyVal(xdata[xcol.dataIndex]))
            .slice(0, 3)
            .join(';');

          return {
            ...xcol,
            tempData,
          };
        });

        let newHasDelComputedColumns = pre.hasDelComputedColumns;
        if (column.calId) {
          newHasDelComputedColumns = newHasDelComputedColumns.concat([column]);
        }

        return {
          ...pre,
          previewYData: tableYData,
          previewXOriginColumns: newPreviewXOriginColumns,
          modifiedComputedColumns: newModifiedComputedColumns,
          hasDelComputedColumns: newHasDelComputedColumns,
        };
      });
    }
  };
  const updatePreviewTable = async (reGetColumns = true) => {
    setTotalStatus((pre) => ({...pre, previewTableLoading: true}));

    let finalModifiedComputedColumns = modifiedComputedColumns;

    const sortsCols = serialfilterConditions.filter((c) => c.orderType);

    const sorts =
      previewXOriginColumns.length > 0
        ? sortsCols.map((scol) => {
            const indexType = previewXOriginColumns.find(
              (pcol) => pcol.dataIndex === scol.aliasName
            ).indexType;

            return {
              indexType,
              column: scol.aliasName,
              order: scol.orderType === 'asc' ? 1 : 2,
            };
          })
        : [];

    if (finalModifiedComputedColumns.length > 0 && reGetColumns) {
      finalModifiedComputedColumns = deepClone(modifiedComputedColumns);
      const dataRes = await request.post(
        WIDE_TABLE_DATA,
        {
          tableStructureList,
          pathId: wideId ? +wideId : null,
          calculates: [],
          sorts,
        },
        requestConfig
      );
      const {columnList = []} = dataRes.data || {};
      const inDbCalculates = [];
      for (let i = 0; i < columnList.length; i++) {
        const item = columnList[i];
        if (item.calId) {
          inDbCalculates.push(item);
        }
      }
      if (inDbCalculates.length > 0) {
        // 在第一次指标接口拿到最大的计算字段别名
        // 将所有没入库的计算字段别名再重新计算
        // 其他关联了这些计算字段的计算字段(含已入库字段且存在编辑)也需同步修改
        let maxId =
          parseInt(
            inDbCalculates
              .sort(
                (a, b) =>
                  a.columnAlias.split('_')[1] - b.columnAlias.split('_')[1]
              )
              [inDbCalculates.length - 1].columnAlias.split('_')[1],
            10
          ) + 1;
        const modifiedCalculates = [];
        let allCalculates = inDbCalculates;
        finalModifiedComputedColumns.forEach((c) => {
          if (c.calId) {
            const index = allCalculates.findIndex((c2) => {
              const calAlias = c2.calAlias ?? c2.columnAlias;
              return calAlias === c.calAlias;
            });
            allCalculates.splice(index, 1, c);
          } else {
            const newColumnAlias = `cal_${maxId++}`;
            c.newColumnAlias = newColumnAlias;
            allCalculates = allCalculates.concat([c]);
          }
        });
        finalModifiedComputedColumns.forEach((c) => {
          if (!c.calId) {
            allCalculates.forEach((c2) => {
              const calAlias =
                c2.newColumnAlias ?? c2.calAlias ?? c2.columnAlias;
              const index = c2.uniqueAliasList.findIndex(
                (alias) => alias === c.calAlias
              );
              // 某个计算字段引用了当前修改别名的计算字段
              if (
                index > -1 &&
                modifiedCalculates.findIndex(
                  (cal) => cal.calAlias === calAlias
                ) === -1
              ) {
                const formatFormulas = JSON.parse(c2.formulas);
                c2.uniqueAliasList.splice(index, 1, c.newColumnAlias);
                formatFormulas.mentionsData.forEach((c3) => {
                  if (c3.columnAlias === c.calAlias) {
                    c3.columnAlias = c.newColumnAlias;
                  }
                });
                formatFormulas.formulas.forEach((c3) => {
                  if (c3.type === COLUMN_TYPE) {
                    const content = JSON.parse(c3.content);
                    if (content.calAlias === c.calAlias) {
                      content.calAlias = c.newColumnAlias;
                      c3.content = JSON.stringify(content);
                    }
                  }
                });
                c2.formulas = JSON.stringify(formatFormulas);
                modifiedCalculates.push({
                  calAlias,
                  calId: c2.calId ?? null,
                  calOriName: c2.calOriName,
                  curShowName: c2.curShowName,
                  formula: c2.formula,
                  formulas: c2.formulas,
                  indexRank: c2.indexRank,
                  indexType: c2.indexType,
                  nameSource: c2.nameSource,
                  pointNum: c2.indexType.includes('decimal')
                    ? c2.pointNum
                    : null,
                  uniqueAliasList: c2.uniqueAliasList,
                });
              }
            });
          }
        });
        finalModifiedComputedColumns.forEach((c) => {
          if (c.newColumnAlias) {
            c.calAlias = c.newColumnAlias;
            delete c.newColumnAlias;
          }
        });
        for (let i = 0; i < modifiedCalculates.length; i++) {
          const cal = modifiedCalculates[i];
          const index = finalModifiedComputedColumns.findIndex(
            (c) => cal.calAlias === c.calAlias
          );
          if (index > -1) {
            finalModifiedComputedColumns.splice(index, 1, cal);
          } else {
            finalModifiedComputedColumns.push(cal);
          }
        }
      }
      finalModifiedComputedColumns = sortComputedColumns(
        finalModifiedComputedColumns
      );
      for (let i = 0; i < finalModifiedComputedColumns.length; i++) {
        const col = finalModifiedComputedColumns[i];
        const parsedFormulas = JSON.parse(col.formulas);
        const formulas = parsedFormulas.formulas;
        formulas.forEach((element) => {
          if (element.type === COLUMN_TYPE) {
            const content = JSON.parse(element.content);
            if (content.id) {
              // 非计算字段
              const targetCol = columnList.find(
                (col2) => col2.id === content.id
              );
              if (targetCol) {
                content.name = targetCol.columnAlias;
                content.calAlias = targetCol.columnAlias;
              }
            } else {
              // 计算字段
              const matchCol = finalModifiedComputedColumns.find(
                (col2) => col2.calAlias === content.calAlias
              );
              if (matchCol) {
                content.name = matchCol.formula;
              }
            }
            element.content = JSON.stringify(content);
          }
        });
        const mentionsData = parsedFormulas.mentionsData;
        mentionsData.forEach((element) => {
          if (element.id) {
            // 非计算字段
            const targetCol = columnList.find((col2) => col2.id === element.id);
            if (targetCol) {
              element.columnAlias = targetCol.columnAlias;
            }
          } else {
            // 计算字段
            const matchCol = finalModifiedComputedColumns.find(
              (col2) => col2.calAlias === element.columnAlias
            );
            if (matchCol) {
              element.formula = matchCol.formula;
            }
          }
        });
        col.formulas = JSON.stringify(parsedFormulas);
        col.uniqueAliasList = mentionsData.map((item) => item.columnAlias);
        const newFormula = await parseCalIndex({
          elements: formulas,
          pointNum: col.indexType.includes('decimal') ? col.pointNum : null,
        }).then((_res) => _res.data);
        // eslint-disable-next-line require-atomic-updates
        col.formula = newFormula;
      }
    }

    // 转换类型 [V2.3]
    // const formatFinalModifiedComputedColumns = finalModifiedComputedColumns.map(
    //   (col) => {
    //     if (Object.hasOwn(col, 'pointNum') && col.pointNum) {
    //       return {
    //         ...col,
    //         indexType: 'decimal',
    //       };
    //     }
    //     return col;
    //   }
    // );

    const params = {
      tableStructureList,
      pathId: wideId ? +wideId : null,
      calculates: finalModifiedComputedColumns,
      sorts,
    };

    try {
      const dataRes = await request.post(
        WIDE_TABLE_DATA,
        params,
        requestConfig
      );

      if (dataRes?.code === 200) {
        const {
          columnList = [],
          previewRecord = [],
          tableIndexs = [],
        } = dataRes.data || {};

        let newXColumns = columnList.map((col = {}, index) => {
          const showVal = col.isShow ?? true;
          const indexVal = col.indexRank ?? index;
          const originName = col.sysShowName || col.comment || col.indexOriName;
          const originColumnAlias = col.columnAlias;
          const sortKeys = nanoid(); // 排序 使用字段
          const filterDisable = col?.delFlag === 1;
          // 计算字段取小数位位数赋值编辑表格
          const decimalPlaces = col?.pointNum || col?.decimalPlaces;

          const recordFieldType =
            col.foreignKeyFlag === 1
              ? 'FatherSon'
              : col.foreignKeyFlag === 2
              ? 'Enum'
              : col.periodFlag
              ? 'Period'
              : 'Common';

          const recordEnumList = async () => {
            if (col.foreignKeyFlag === 2) {
              const data = await getFilterShowedData({
                tableId: col.tableId,
                colId: col.id,
                columnAlias: col.columnAlias,
                tableStructureList,
              });
              return data;
            }
            return [];
          };

          const recordFatherSonList = async () => {
            if (col.foreignKeyFlag === 1) {
              const data = await getFilterShowedData({
                tableId: col.tableId,
                colId: col.id,
                columnAlias: col.columnAlias,
                tableStructureList,
              });
              return data;
            }
            return [];
          };

          let ret = {
            ...col,
            isShow: showVal,
            indexRank: indexVal,

            //
            title: col.curShowName,
            filterTitle: col.curShowName,
            dataIndex: col.columnAlias,
            filterType: recordFieldType,
            filterDisable,

            decimalPlaces,
            fieldType: col.indexType,
            enumList: recordEnumList,
            fatherSonList: recordFatherSonList,

            //
            _show: showVal,
            _index: indexVal,
            _title: col.calOriName || originName,
            originColumnAlias, // 原始 字段名
            sortKeys, // 排序字段

            // 兼容多表中重复字段名分叉在筛选器中识别不到的情况(一个表时字段叫name,两个表都有时就会name消失，裂变成name_1,name_2)
            // 计算字段没有id，有columnAlias,calId在新建计算字段时没有，只有保存后才有
            aliasName: `${col.columnAlias}${specialSplitSymbol}${
              col.id ?? col.columnAlias
            }`,

            //
            onExtraFilterClick: (info) => onExtraFilterClickHandle(info),

            render: (val) => tableCellRender(val, showVal),
            toolTip: (column) => <DynamicTitle column={column} />,
            extraFilterItems: getExtraFilterItems(previewXOriginColumns, col),
          };

          // if (tableStructureList.some((table) => table.dbType === 3)) {
          /* if (TextTypeArr.some((colType) => ret.fieldType.includes(colType))) {
            ret.showCommonTabList = ['Text'];
          } else if (
            DateTypeArr.some((colType) => ret.fieldType.includes(colType))
          ) {
            ret.showCommonTabList = ['Date', 'Text'];
          } else {
            ret.showCommonTabList = ['Text', 'Number'];
          } */
          ret = specifyFilterTypeByColumnType(ret, 'fieldType');
          // }

          return ret;
        });

        // 重新组装筛选器的键
        updateFilter(newXColumns);

        // 新宽表与旧表对比，同字段采用旧表的编辑状态
        newXColumns = arrReplaceSameKeyVal(
          previewXOriginColumns,
          newXColumns,
          deletedColumns
        );

        // 重新计算表头各种状态样式
        newXColumns = calcTableXColHideRepeatStyle(newXColumns);

        // 过滤页面上删除的已入库的计算字段 &&
        // 过滤掉引用的表已被删除的计算字段
        newXColumns = newXColumns.filter((col) => {
          if (col.formula) {
            const parsedFormulas = JSON.parse(col.formulas);
            const tableIds =
              parsedFormulas.mentionsData.map((data) => data.tableId) ?? [];
            for (let i = 0; i < tableIds.length; i++) {
              const tableId = tableIds[i];
              if (Array.isArray(tableId)) {
                for (let j = 0; j < tableId.length; j++) {
                  const id = tableId[j];
                  if (
                    tableStructureList.every(
                      (listItem) => listItem.tableId !== id
                    )
                  ) {
                    return false;
                  }
                }
              } else if (
                tableStructureList.every(
                  (listItem) => listItem.tableId !== tableId
                )
              ) {
                return false;
              }
            }
          }
          if (
            col.calId &&
            hasDelComputedColumns.findIndex(
              (col2) => col2.calId === col.calId
            ) > -1
          ) {
            return false;
          }
          return true;
        });

        const tableYData = newXColumns.map((xcol, index) => {
          const tempData = (previewRecord || [])
            .map((xdata) => fillEmptyVal(xdata[xcol.dataIndex]))
            .slice(0, 3)
            .join(';');

          return {
            ...xcol,
            tempData,
            onExtraFilterClick: (info) => onExtraFilterClickHandle(info),
          };
        });

        setTotalStatus((pre) => ({
          ...pre,
          modifiedComputedColumns: finalModifiedComputedColumns,
          previewXData: previewRecord,
          previewXOriginColumns: newXColumns,
          previewYData: tableYData,
          tableIndexs,
        }));
      }
    } catch (err) {
      FormErrMsg.disposeErr(null, err);
    } finally {
      if (totalStatus.curComputedColumn.isAfterEditComputedColumn) {
        freshTablCotainerToLast();
      } else {
        freshTablCotainer();
      }
      setTotalStatus((pre) => ({
        ...pre,
        previewTableLoading: false,
      }));
    }
  };

  const checkJoinConditionValidate = () => {
    const notValide = tableStructureList
      .filter((t) => t.level !== 0)
      .some(
        (t) =>
          !t.joinCondition ||
          t.joinLogicalColumns.length === 0 ||
          t.joinLogicalColumns.some(
            (a, b) =>
              (a === undefined || b === undefined) &&
              !(a === undefined && b === undefined)
          )
      );

    if (notValide) {
      setTotalStatus((pre) => ({
        ...pre,
        previewXData: [],
        previewYData: pre.previewYData.map((ydata) => ({
          ...ydata,
          tempData: '',
        })),
      }));
    }
    return true;
  };

  return {checkJoinConditionValidate, updatePreviewTable};
}
