import {useAtom} from 'jotai';
import {useEffect, useState} from 'react';
import useSWR from 'swr';

import {QUERY_FORCE_CHECK_NOTICE, readNotice} from '../../../api/system/notice';
import {hasReadNoticeAtom} from '../../../atoms/hasReadNoticeAtom';
import {MessageModal} from './MessageModal';

/**
 * @description 强制查阅消息模态框
 */
function NoticeModal() {
  const [dataSource, setDataSource] = useState({});
  const [loading, setLoading] = useState(false);
  const [dataIndex, setDataIndex] = useState(0);
  const {data: noticeData} = useSWR(QUERY_FORCE_CHECK_NOTICE);
  const [, setHasReadNotice] = useAtom(hasReadNoticeAtom);

  useEffect(() => {
    if (noticeData?.data?.length) {
      setDataSource(noticeData.data[dataIndex]);
    }
  }, [noticeData, dataIndex]);

  function readNoticeHandler() {
    setLoading(true);
    readNotice(dataSource.id)
      .then((res) => {
        if (res.code === 200) {
          setDataIndex((i) => i + 1);
          setHasReadNotice(!noticeData.data[dataIndex]);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  }

  return (
    <MessageModal
      dataSource={dataSource}
      loading={loading}
      onOk={readNoticeHandler}
    />
  );
}

NoticeModal.Modal = MessageModal;

export default NoticeModal;
