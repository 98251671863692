import request from '../../helpers/request';

const prefix = '/user';

/** 菜单管理-查询应用列表 */
export const GET_APP_LIST = `${prefix}/app/getAppList`;

/** 菜单管理-菜单方案详情 */
export const GET_MENU_SCHEME_DETAIL = `${prefix}/menu/schemeDetail`;

/** 菜单管理-菜单方案列表 */
export const GET_MENU_SCHEME = `${prefix}/menu/menuScheme`;

/** 菜单管理-新建/编辑菜单 */
export function saveMenu(data) {
  return request({
    url: `${prefix}/menu/save`,
    method: 'post',
    data,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-删除菜单 */
export function removeMenu(data) {
  return request({
    url: `${prefix}/menu/removeMenu`,
    method: 'post',
    data,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-新增菜单方案 */
export function addMenuScheme(data) {
  return request({
    url: `${prefix}/menu/addMenuScheme`,
    method: 'post',
    data,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-编辑菜单方案 */
export function editMenuScheme(data) {
  return request({
    url: `${prefix}/menu/editMenuScheme`,
    method: 'post',
    data,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-删除菜单方案 */
export function removeMenuScheme(params) {
  return request({
    url: `${prefix}/menu/removeMenuScheme`,
    method: 'post',
    params,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-获取菜单详情 */
export function getMenuDetail(data) {
  return request({
    url: `${prefix}/menu/detail`,
    method: 'post',
    data,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-菜单方案关联组织 */
export function connectGroup(data) {
  return request({
    url: `${prefix}/menu/menuSchemeGroup`,
    method: 'post',
    data,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-新建目录/页面/功能获取上级目录或所属页面 */
export const GET_MENU_SELECT_LIST = `${prefix}/menu/selectList`;

/** 菜单管理-组织树 */
export const GET_GROUP_TREE = `${prefix}/group/tree`;

/** 菜单管理-调整菜单顺序 */
export function adjustMenuRank(data) {
  return request({
    url: `${prefix}/menu/adjustMenuRank`,
    method: 'post',
    data,
    // baseURL: '/test-api',
  });
}

/** 菜单管理-获取系统默认页面（过滤已选择的） */
export const GET_DEFAULT_MENU_PAGE = `${prefix}/menu/getDefaultMenuPage`;
