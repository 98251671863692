/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import {GuidanceCss} from './style.css';

export default function ConstGuidance() {
  return (
    <div css={GuidanceCss}>
      <div>在文字合并过程中增加固定值(固定字符)</div>
      <ul>
        <li>固定值通过两个"闭合输入</li>
      </ul>
      <div>配置示例</div>
      <p>
        "我的地址是"+<span>非数值字段A</span>
      </p>
      <div>效果示例</div>
      <p>我的地址是深圳市南山区粤海街道</p>
    </div>
  );
}
