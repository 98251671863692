import {useAtom} from 'jotai';
import {memo} from 'react';
import {useMount, useUnmount} from 'react-use';
import {useSWRConfig} from 'swr';

import {defaultLogicEditStatus, logicEditAtom} from '../../atoms/logicEditAtom';
import {PUBLIC_PATH} from '../../constants/system';
import {routeHistory} from '../../helpers/history';
import {deepClone} from '../../helpers/utils';

const RouteGuard = memo(function RouteGuard() {
  const {cache} = useSWRConfig();

  const [, setTotalStatus] = useAtom(logicEditAtom);

  // eslint-disable-next-line no-empty-function
  let unlisten = () => {};

  useMount(() => {
    unlisten = routeHistory.listen(({location, action}) => {
      if (
        location.pathname === `${PUBLIC_PATH}/login` &&
        action === 'REPLACE'
      ) {
        cache.clear();
        setTotalStatus({...deepClone(defaultLogicEditStatus)});
      }
      if (window.luckysheet) {
        window.luckysheet.destroy();
      }
    });
  });

  useUnmount(() => {
    unlisten();
  });

  return null;
});

export default RouteGuard;
