import {useAtom} from 'jotai';
import {useMemo, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '..';
import {
  getFilterGroupDetail,
  indexFilterGroupList,
} from '../../../../api/dataQuery/microDataQuery';
import {filterAtom} from '../../../../components/Filter/atoms/filterAtom';
import {deepClone} from '../../../../helpers/utils';

export const useFetchFilterGroup = () => {
  const {
    columnList,
    storeColumnWidthKey,
    updateTable,
    defaultId,
    setDefaultId,
    curFilterGroup,
    filterGroupRef,
    // setDefaultFilterConditions,
  } = useContext(MicroDataQueryContext);

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  const [searchParams] = useSearchParams();

  const wideId = useMemo(() => {
    return searchParams.get('wideId');
  }, [searchParams]);

  const generateFilterConditions = (condition) => {
    condition = condition ?? [];
    const obj = {};
    condition.forEach((i) => {
      if (i.aliasName) {
        obj[i.aliasName] = {
          ...i,
          valueList: i?.valueList?.map((v) => decodeURIComponent(v)),
        };
      }
    });
    const conditionsCopy = deepClone(conditions);
    conditionsCopy[storeColumnWidthKey] = obj;
    setFilterParams({
      ...filterParams,
      conditions: conditionsCopy,
    });
  };

  const changeFilterGroup = async (isCurFilterDelete, _default) => {
    if (!defaultId && Object.keys(curFilterGroup).length === 0) {
      return;
    }
    if (isCurFilterDelete && !_default?.id) {
      const conditionsCopy = deepClone(conditions);
      conditionsCopy[storeColumnWidthKey] = {};
      setFilterParams({
        ...filterParams,
        conditions: conditionsCopy,
      });
      updateTable(conditionsCopy[storeColumnWidthKey]);
    } else {
      const _conditons = [];
      const conditionsCopy = deepClone(conditions);
      conditionsCopy[storeColumnWidthKey] = {};
      const {data} = await getFilterGroupDetail({
        id: isCurFilterDelete ? _default?.id : curFilterGroup.id,
        queryList: columnList.map((i) => ({
          aliasName: i.indexAlias,
          curShowName: i.curShowName,
        })),
      });
      data.forEach((d) => {
        const filterJson = d.filterJson && JSON.parse(d.filterJson);
        if (filterJson) {
          _conditons.push(filterJson);

          conditionsCopy[storeColumnWidthKey][d.aliasName] = filterJson;
        }
      });
      filterGroupRef.current.setFilterGroupName(
        isCurFilterDelete ? _default?.name : curFilterGroup.name
      );
      updateTable(conditionsCopy[storeColumnWidthKey]);
      generateFilterConditions(_conditons);
    }
  };

  const reqFilterGroupList = async () => {
    try {
      const res = await indexFilterGroupList({tableId: wideId});
      if (res?.code === 200) {
        const list = res?.data;
        const defaultGroup = list.find((item) => item.defaultFlag === 1);
        const isDelete = list.some((item) => item.id === curFilterGroup?.id);
        if (!isDelete) {
          filterGroupRef.current.setFilterGroupName(
            defaultGroup?.name ?? '未选中筛选器'
          );
        }
        changeFilterGroup(!isDelete, defaultGroup ?? {});
      }
    } catch (err) {
      setDefaultId(undefined);
      throw new Error('request filterGroup list failed');
    }
  };

  return [reqFilterGroupList];
};
