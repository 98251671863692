import {atom} from 'jotai';

const defaultLogicEditStatus = {
  // 左侧数据表列表
  sheetList: [],

  // 逻辑编辑预览表横向X，竖向Y
  previewTableHeight: 220,
  previewTableDirection: 'X',

  // 显示隐藏字段
  showHideFields: false,

  // 预览自动更新
  previewAutoUpdate: true,
  previewTableLoading: false,
  deleteFilterFlag: false, // 是否是删除筛选器

  // 横向表列和数据
  previewBling: false,
  previewXData: [],
  previewXOriginColumns: [],
  deletedColumns: [], // 前端已经删除过的字段

  // 纵向表列和数据
  previewYData: [],
  previewYOriginColumns: [],

  // 保存指标的文件夹和表名

  // 文件夹id
  pathId: null,
  _pathId: null,
  placeHolderBling: false,
  // 宽表名
  tableName: '',
  _tableName: '',
  tableNameErrMsg: '',

  // 数据逻辑筛选总览表格弹窗
  filterPreviewModalVisible: false,
  // 用id 与筛选器中的字段名进行对应，因为多表会有重名字段，会变成字段名_自增1
  filterColKeyIds: [],

  // 表逻辑配置区，画布相关数据和状态
  // 数据表状态，1：暂存，2：初始化，3：正常，4：暂停，5：错误
  graphData: {status: null, tableStructureList: []},
  graphOriginData: {status: null, tableStructureList: []},
  canvasLoading: false,
  graphInstance: null,
  logicSetModalVisible: false,
  showNoTableStructure: false,

  // 当前点击的交叉圆左右节点的业务数据
  // 每次操作完要清空
  leftNodeInfo: {},
  rightNodeInfo: {},

  // 当前显示计算字段
  curComputedColumn: {
    visible: false,
    columnInfo: {
      curShowName: '',
      pointNum: 2,
      oriFormula: '',
      placeholder: '',
      mentionsData: [],
      formulas: [],
      uniqueAliasList: [],
      indexType: '',
    },
    isAfterEditComputedColumn: false,
  },

  // 编辑或者新增过的计算字段
  modifiedComputedColumns: [],

  // 存储在页面上删除的已入库的计算字段
  // 在制作宽表接口结果进行过滤
  hasDelComputedColumns: [],

  // 导入数据表字段匹配 数据
  matchFieldColumns: [],

  // 点击创建宽表 保存的初始化宽表数据
  createWideTableInfo: null,
};

// 微观指标状态store
const logicEditAtom = atom(defaultLogicEditStatus);

export {defaultLogicEditStatus, logicEditAtom};
