import {useAtom} from 'jotai';
import {useRef} from 'react';
import useSWR from 'swr';

import {GET_PERM_STATUS} from '../api/system/user';
import {permChangeAtom} from '../atoms/permChangeAtom';

/**
 * @param {object} props
 * @param {1 | 2 | 3} props.type - 类型，菜单：1，表单：2，按钮：3
 */
export function useValidatePerm({type, code}) {
  const permRef = useRef(false);

  const {mutate} = useSWR(`${GET_PERM_STATUS}?type=${type}&code=${code}`, {
    revalidateOnMount: false,
  });

  const [, setPermChange] = useAtom(permChangeAtom);

  async function validatePermChange() {
    const res = await mutate();

    // 有权 => 无权
    if (res?.data?.hasPerm === 2) {
      setPermChange(true);
      permRef.current = true;
    } else {
      setPermChange(false);
      permRef.current = false;
    }
  }

  return [permRef, validatePermChange];
}
