import {useState} from 'react';
import {useInterval, useMount} from 'react-use';

import {getCodeImg} from '../../../../api/login';
import {base64ImgPrefix} from '../../../../constants/base';

export function useCaptcha() {
  const [imgSrc, setImgSrc] = useState('');
  const [uuid, setUuid] = useState();

  function fetchData() {
    getCodeImg().then((data) => {
      if (data) {
        setImgSrc(base64ImgPrefix + data.img);
        setUuid(data.uuid);
      }
    });
  }

  useMount(fetchData);

  useInterval(fetchData, 110 * 1000);

  return {imgSrc, uuid, refresh: fetchData};
}
