import {Form, Input} from 'antd';
import React, {useEffect, useMemo} from 'react';

import GlobalModal from '.';
import FormErrMsg from '../FormErrMsg';

export function RenameModal(props) {
  const {
    modalControl,
    folderInfo = {},
    confirm,
    isAdd = false,
    keyName = 'name',
  } = props;

  const [form] = Form.useForm();

  const isFolderOrDB = useMemo(() => {
    const {pathId, pathType} = folderInfo;
    const isFold = pathId === null || pathType === 1 || pathType === 2;

    return isFold;
  }, [folderInfo]);

  const typeStr = useMemo(() => {
    return isAdd ? '文件夹' : isFolderOrDB ? '文件夹' : '数据表';
  }, [isAdd, isFolderOrDB]);

  const title = useMemo(() => {
    return isAdd
      ? '新建文件夹'
      : isFolderOrDB
      ? '重命名文件夹'
      : '重命名数据表';
  }, [isAdd, isFolderOrDB]);

  const onValuesChange = () => {
    FormErrMsg.clearFormErrMsg(form);
  };

  useEffect(() => {
    if (!isAdd) form.setFieldsValue({[keyName]: folderInfo.name});
  }, [folderInfo, form, isAdd, keyName]);

  useEffect(() => {
    if (!modalControl.visible) {
      FormErrMsg.clearFormErrMsg(form);
      FormErrMsg.resetFormErrMsg(form);
      form.resetFields();
    }
  }, [form, modalControl.visible]);

  const onModalOkHandle = () => {
    const val = form.getFieldValue(keyName);
    if (!val?.trim()) {
      FormErrMsg.setFormErrMsg(form, undefined, `请输入${typeStr}名称`);
      return;
    }
    confirm(
      form.getFieldValue(keyName),
      isAdd
        ? isFolderOrDB
          ? folderInfo.id
          : folderInfo.parentId ?? -1
        : folderInfo.id,
      (err) => {
        FormErrMsg.disposeErr(form, err);
      }
    );
  };

  return (
    <GlobalModal
      centered
      cancelButtonProps={{className: 'cancel-btn-text-color'}}
      okButtonProps={{className: 'ensure-btn-text-color'}}
      title={title}
      width={447}
      zIndex={9999}
      {...modalControl}
      onOk={onModalOkHandle}
    >
      <Form form={form} onValuesChange={onValuesChange}>
        <Form.Item required name={keyName}>
          <Input maxLength={50} placeholder={`请输入${typeStr}名称`} />
        </Form.Item>
        <FormErrMsg />
      </Form>
    </GlobalModal>
  );
}
