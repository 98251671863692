/* eslint-disable react/no-unescaped-entities */
import React from 'react';

import {GuidanceCss} from './style.css';

export default function MergeGuidance() {
  return (
    <div css={GuidanceCss}>
      <div>文本合并，由字段与固定值之间通过+合并</div>
      <ul>
        <li>支持数值类字段+固定值</li>
        <li>支持非数值类字段+固定值</li>
        <li>固定值通过两个"闭合输入</li>
      </ul>
      <div>配置示例</div>
      <p>
        <span>字段A</span>+<span>字段B</span>+"我的"
      </p>
      <div>效果示例</div>
      <p>你的+1000+我的=你的1000我的</p>
    </div>
  );
}
