import {InfoCircleOutlined} from '@ant-design/icons';
import {Button, Checkbox, Form, Radio, Tooltip} from 'antd';
import React, {useEffect, useImperativeHandle, useCallback} from 'react';

import {getUserMailList} from '../../../../../api/dataManagement/wideTable';
import FormErrMsg from '../../../../../components/FormErrMsg';
import FormRequireLabel from '../../../../../components/FormRequireLabel';
import GlobalModal from '../../../../../components/GlobalModal';
import {defaultDateFormat} from '../../../../../constants/base';
import dayjs from '../../../../../helpers/extendedDayjs';
import {deepClone} from '../../../../../helpers/utils';
import useSafeState from '../../../../../hooks/useSafeState';
import {MicroDataQueryContext} from '../../../DataTableManagement';
import useTableFields, {
  WIDE_TABLE_TYPE,
} from '../../components/ConfigIndex/useTableFields';
import {validateWideTableColumns} from '../../helpers';
import CoverSaveWayFormItem from './CoverSaveWayFormItem';
import EmailFormItem from './EmailFormItem';
import NewSaveWayFormItem from './NewSaveWayFormItem';
import SyncFormItems from './SyncFormItems';

const NEW_SAVE_WAY = 1;
const COVER_SAVE_WAY = 2;

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 4},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 20},
  },
};

const getCurStartTime = () => dayjs().add(2, 'm').format(defaultDateFormat);
export const defaultFrequency = {
  frequency: 1,
  startTime: getCurStartTime(),
  stepHours: null,
  stepDays: null,
  months: [],
  monthDays: [],
};
const initialFormValues = {
  saveType: NEW_SAVE_WAY,
  tableName: '',
  tableNameEn: 'ud_',
  pathId: '',
  collectFlag: false,
  collectPathId: '',
  syncJobType: 0,
  jobCronJson: defaultFrequency,
  // sendMessageFlag: false,
  writeType: 1,
  mail: '',
};

const SaveWideTableModal = (
  {
    visible,
    onOk,
    onCancel,
    title,
    tableData,
    type = 'add',
    isSaveAs,
    syncFlag,
    isWide = true,
    onlyFolder,
    isWideList,
    requestTablePathId,
    isSaving = false,
    isSqlWide = false,
  },
  ref
) => {
  // const [importPermission, setImportPermission] = useSafeState(true);
  const [syncFormValues, setSyncFormValues] = useSafeState(initialFormValues);
  const [userMailList, setUserMailList] = useSafeState([]);

  const {wideTableColumns} = useTableFields(
    isSqlWide ? WIDE_TABLE_TYPE.SQLWideTable : WIDE_TABLE_TYPE.wideTable
  );

  const [form] = Form.useForm();

  const fetchUserMailList = useCallback(async () => {
    try {
      const {data} = await getUserMailList();
      setUserMailList(data);
    } catch (err) {
      throw new Error('Get user mail list failed');
    }
  }, [setUserMailList]);

  useEffect(() => {
    if (tableData && visible) {
      const next = deepClone(tableData);
      if (next.jobCronJson) {
        next.jobCronJson = JSON.parse(next.jobCronJson);
      }
      if (next.collectPathId) {
        next.collectFlag = true;
      }
      if (!next.tableNameEn) {
        next.tableNameEn = 'ud_';
      }
      if (next.tableNameEn && !next.tableNameEn.startsWith('ud_')) {
        next.tableNameEn = `ud_${next.tableNameEn}`;
      }
      form.setFieldsValue({...next, saveType: NEW_SAVE_WAY});
      setSyncFormValues({...next, saveType: NEW_SAVE_WAY});
    }
  }, [form, setSyncFormValues, tableData, visible]);

  useEffect(() => {
    if (syncFormValues.syncResultRemind?.includes(2)) fetchUserMailList();
  }, [fetchUserMailList, syncFormValues]);

  function handleValuesChange(changedValues, allValues) {
    if (changedValues.syncJobType === 1) {
      setSyncFormValues({
        ...allValues,
        jobCronJson: defaultFrequency,
      });
      form.setFieldsValue({
        jobCronJson: defaultFrequency,
      });
    } else {
      setSyncFormValues({
        ...allValues,
      });
    }

    // 切换保存方式 清空数据
    if (changedValues.saveType) {
      form.setFieldsValue({
        targetTableId: null,
        pathId: null,
      });
    }

    if (changedValues.mails) {
      setSyncFormValues((pre) => ({
        ...pre,
        mail: userMailList
          .filter((item) => changedValues.mails.includes(item.userId))
          .map((d) => d.email)
          .filter(Boolean)
          .join(','),
      }));
    }

    /* if (changedValues.jobCronJson) {
      // /^\+?[1-9][0-9]*$/  正整数
      const checkRule = new Map([
        [
          2,
          {
            field: 'stepHours',
            reg: /^(([1-9])|(1\d)|(2[0-3]))$/,
            msg: '请填写间隔小时数(1 ~ 23范围整数)',
          },
        ],
        [
          3,
          {
            field: 'stepDays',
            reg: /^(([1-9])|([1-2]\d)|(3[0-1]))$/,
            msg: '请填写间隔天数(1 ~ 31范围整数)',
          },
        ],
        [5, {field: ['months', 'monthDays'], msg: '请选择月份和日期'}],
      ]);
      const {frequency, startTime} = changedValues.jobCronJson;
      const okDateTimeVal = dayjs().isBefore(dayjs(startTime));
      FormErrMsg.resetFormErrMsg(form, 'jobCronJson');

      [...checkRule.keys()].forEach((key) => {
        if (key === frequency) {
          let isValid = false;
          const {field, reg, msg} = checkRule.get(key);
          if (Array.isArray(field)) {
            isValid = field.every(
              (item) => changedValues.jobCronJson[item].length > 0
            );
          } else {
            const curFieldVal = changedValues.jobCronJson[field];
            isValid = reg.test(curFieldVal);
          }

          if (!isValid) {
            FormErrMsg.setFormErrMsg(form, 'jobCronJson', msg);
          }
        }
      });

      if (frequency === 1 && !okDateTimeVal) {
        FormErrMsg.setFormErrMsg(
          form,
          'jobCronJson',
          '请选择当前系统时间之后的时间'
        );
      }
    } */
  }

  // [V2.3] 接口待联调
  // 宽表新增 - 保存 & 保存并同步 wide/indexMaking/save
  // SQL 宽表新增 - 保存 & 保存并同步 wide/sqlMaking/save
  function handleSubmit(flag) {
    if (validateWideTableColumns(wideTableColumns)) {
      form.validateFields().then(() => {
        onOk(syncFormValues, flag, (err) => {
          FormErrMsg.disposeErr(form, err);
        });
      });
    }
  }

  /* async function validateImportPermission(value) {
    const res = await checkWrite({
      pathId: value,
    });
    setImportPermission(!!res.data);
  } */

  useImperativeHandle(ref, () => ({
    setFormErrorMsg: (name, msg) => {
      FormErrMsg.setFormErrMsg(form, name, msg);
    },
  }));

  return (
    <MicroDataQueryContext.Provider
      value={{
        loading: false,
      }}
    >
      <GlobalModal
        footer={[
          <Button
            className="cancel-btn-text-color"
            key={1}
            loading={isSaving}
            size="small"
            type="default"
            onClick={onCancel}
          >
            取消
          </Button>,
          <Button
            className="ensure-btn-text-color"
            key={2}
            loading={isSaving}
            size="small"
            type="primary"
            onClick={() => handleSubmit(0)}
          >
            保存
          </Button>,
          syncFlag ? (
            false
          ) : (
            <Button
              className="ensure-btn-text-color"
              key={3}
              loading={isSaving}
              size="small"
              type="primary"
              onClick={() => {
                handleSubmit(1);
              }}
            >
              保存并同步
            </Button>
          ),
        ].filter(Boolean)}
        title={title}
        visible={visible}
        width={650}
        onCancel={onCancel}
      >
        <Form
          {...formItemLayout}
          form={form}
          initialValues={initialFormValues}
          name="saveTable"
          requiredMark={false}
          validateTrigger={['onBlur', 'onChange']}
          onValuesChange={handleValuesChange}
        >
          <Form.Item
            required
            label={<FormRequireLabel title="保存方式" />}
            name="saveType"
          >
            <Radio.Group>
              <Radio value={NEW_SAVE_WAY}>创建为新数据表</Radio>
              <Radio value={COVER_SAVE_WAY}>导入到已有数据表</Radio>
            </Radio.Group>
          </Form.Item>
          {syncFormValues.saveType === NEW_SAVE_WAY ? (
            <NewSaveWayFormItem
              form={form}
              isSaveAs={isSaveAs}
              isSqlWide={isSqlWide}
              isWideList={isWideList}
              onlyFolder={onlyFolder}
              requestTablePathId={requestTablePathId}
              setSyncFormValues={setSyncFormValues}
              syncFormValues={syncFormValues}
              tableData={tableData}
              type={type}
              visible={visible}
            />
          ) : (
            <CoverSaveWayFormItem
              form={form}
              isSqlWide={isSqlWide}
              syncFormValues={syncFormValues}
              visible={visible}
              wideIdProp="tableId"
              onSelect={(info) => {
                form.setFieldsValue({
                  targetTableId: info.node.tableId,
                });
                setSyncFormValues((prev) => {
                  return {
                    ...prev,
                    targetTableId: info.node.tableId,
                  };
                });
              }}
            />
          )}

          <SyncFormItems formValues={syncFormValues} hasEmail={false} />
          <Form.Item
            label={<FormRequireLabel title="写入方式" />}
            name="writeType"
          >
            <Radio.Group>
              <Radio value={1}>覆盖写入</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item label="同步结果提醒" name="syncResultRemind">
            <Checkbox.Group>
              {/* <Checkbox value={1}>站内消息</Checkbox> */}
              <Checkbox value={2}>
                邮箱
                <Tooltip title="读取用户中心的邮箱">
                  <InfoCircleOutlined />
                </Tooltip>
              </Checkbox>
            </Checkbox.Group>
          </Form.Item>
          {syncFormValues.syncResultRemind?.includes(2) && (
            <EmailFormItem userMailList={userMailList} />
          )}
          <FormErrMsg />
        </Form>
      </GlobalModal>
    </MicroDataQueryContext.Provider>
  );
};

export default React.forwardRef(SaveWideTableModal);
