import {DatePicker} from 'antd';
import React, {forwardRef, useImperativeHandle, useState} from 'react';

import dayjs from '../../helpers/extendedDayjs';

/**
 * @param {{
 *  limit: Number;
 *  handleChange: Function;
 * } & import('antd/lib/date-picker').RangePickerProps} props
 */
function LimitRangePicker(props, ref) {
  const {
    limit,
    format = 'YYYY-MM-DD',
    handleChange = (_) => _,
    ...rest
  } = props;

  function initDate() {
    return [dayjs().subtract(limit, 'day'), dayjs()];
  }

  const [selectedDateRange, setSelectedDateRange] = useState(initDate);
  const [selectingDateRange, setSelectingDateRange] = useState([null, null]);

  const disabledDate = (current) => {
    const afterToday = current > dayjs().endOf('day');
    const largeThanlimit =
      selectingDateRange[0] &&
      current > selectingDateRange[0].add(limit, 'day');
    return afterToday || largeThanlimit;
  };

  function onChange(dates) {
    let ret;
    if (dates) {
      if (dates[1].diff(dates[0], 'day') > limit) {
        ret = [dates[0], dates[0].add(limit, 'day')];
        setSelectedDateRange(ret);
      } else {
        ret = dates;
        setSelectedDateRange(ret);
      }
    } else {
      ret = [dayjs().subtract(limit, 'day'), dayjs()];
      setSelectedDateRange(ret);
    }
    handleChange(ret);
  }

  useImperativeHandle(ref, () => ({
    reset: (cb) => {
      onChange(initDate());
      setSelectingDateRange([null, null]);
      if (typeof cb === 'function') {
        cb();
      }
    },
  }));

  return (
    <DatePicker.RangePicker
      disabledDate={disabledDate}
      format={format}
      value={selectedDateRange}
      onCalendarChange={(dates) => {
        if (dates) {
          setSelectingDateRange(dates);
        } else {
          setSelectingDateRange([null, null]);
        }
      }}
      onChange={onChange}
      onOpenChange={(open) => {
        setSelectingDateRange([null, null]);
      }}
      {...rest}
    />
  );
}

export default forwardRef(LimitRangePicker);
