import {css} from '@emotion/react';
import {Form, Input} from 'antd';
import React, {useEffect} from 'react';

import FormRequireLabel from '../../../../../components/FormRequireLabel';
import GlobalModal from '../../../../../components/GlobalModal';
import useSafeState from '../../../../../hooks/useSafeState';

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 18},
  },
};

export default function TempModal({
  visible,
  onOk,
  onCancel,
  tableData,
  isSaving = false,
}) {
  const [tableName, setTableName] = useSafeState('');

  const [form] = Form.useForm();

  useEffect(() => {
    if (tableData) {
      form.setFieldsValue({
        tableName: tableData.tableName,
      });
      setTableName(tableData.tableName);
    }
  }, [form, setTableName, tableData]);

  return (
    <GlobalModal
      destroyOnClose
      cancelButtonProps={{
        loading: isSaving,
      }}
      okButtonProps={{
        disabled: tableName?.trim().length === 0,
        loading: isSaving,
      }}
      title="暂存为草稿"
      visible={visible}
      width={450}
      onCancel={onCancel}
      onOk={() => {
        onOk(form.getFieldValue('tableName'));
      }}
    >
      <Form
        form={form}
        name="tempTable"
        requiredMark={false}
        validateTrigger="onBlur"
        {...formItemLayout}
      >
        <Form.Item
          required
          label={<FormRequireLabel title="显示名称" />}
          name="tableName"
          rules={[
            {
              required: true,
              message: '请输入显示名称',
            },
          ]}
        >
          <Input
            maxLength={50}
            placeholder="请输入显示名称"
            onChange={(e) => {
              setTableName(e.target.value);
            }}
          />
        </Form.Item>
      </Form>
      <p
        css={css`
          text-align: right;
          margin-top: 10px;
        `}
      >
        您可以在【我的收藏-草稿文件夹】中找到您保存的草稿
      </p>
    </GlobalModal>
  );
}
