import {css} from '@emotion/react';
import {EditorState, Modifier} from 'draft-js';
import React from 'react';

import {deepClone} from '../../../helpers/utils';
import {
  BRANKETS_TYPE,
  OPERATOR_TYPE,
  CONST_TYPE,
  COLUMN_TYPE,
} from '../constants';

export const Parser = require('expr-eval').Parser;
export const parser = new Parser();

export const HAS_COLUMN_PERM = 1;

export const INTEGER_DATATYPE = 1;
export const DECIMAL_DATATYPE = 2;
export const STRING_DATATYPE = 3;
export const TIME_DATATYPE = 4;

export const TypeMap = new Map([
  ['(', BRANKETS_TYPE],
  [')', BRANKETS_TYPE],
  ['+', OPERATOR_TYPE],
  ['-', OPERATOR_TYPE],
  ['*', OPERATOR_TYPE],
  ['/', OPERATOR_TYPE],
]);

export const DataTypeMap = new Map([
  ['int', INTEGER_DATATYPE],
  ['tinyint', INTEGER_DATATYPE],
  ['smallint', INTEGER_DATATYPE],
  ['mediumint', INTEGER_DATATYPE],
  ['bigint', INTEGER_DATATYPE],
  ['decimal', DECIMAL_DATATYPE],
  ['decimal(32,16)', DECIMAL_DATATYPE],
  ['float', DECIMAL_DATATYPE],
  ['double', DECIMAL_DATATYPE],
  ['char', STRING_DATATYPE],
  ['varchar', STRING_DATATYPE],
  ['tinytext', STRING_DATATYPE],
  ['text', STRING_DATATYPE],
  ['mediumtext', STRING_DATATYPE],
  ['longtext', STRING_DATATYPE],
  ['datetime', TIME_DATATYPE],
  ['date', TIME_DATATYPE],
  ['year', TIME_DATATYPE],
]);

// 排序
export function sortMentionsData(mentionsData) {
  // 生成以 lineIndex 为 key 的对象
  const obj = {};
  for (let i = 0; i < mentionsData.length; i++) {
    const mention = mentionsData[i];
    if (obj[mention.lineIndex]) {
      obj[mention.lineIndex].push(mention);
    } else {
      obj[mention.lineIndex] = [mention];
    }
  }
  // 得到 key 从小到大排序好的数组
  const sortmentionsData = Object.keys(obj).map((key) => {
    return obj[key].sort((a, b) => a.start - b.start);
  });
  return sortmentionsData.flat();
}

// 编辑回显添加
export function _addMention(newEditorState, mention, begin) {
  const contentStateWithEntity = newEditorState
    .getCurrentContent()
    .createEntity('mention', 'SEGMENTED', {
      mention: {
        ...mention,
        name: mention.curShowName,
      },
    });
  const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
  const currentSelectionState = newEditorState.getSelection();
  const mentionTextSelection = currentSelectionState.merge({
    anchorOffset: begin,
    focusOffset: begin,
  });

  const mentionReplacedContent = Modifier.replaceText(
    newEditorState.getCurrentContent(),
    mentionTextSelection,
    mention.curShowName,
    newEditorState.getCurrentInlineStyle(),
    entityKey
  );
  const _newEditorState = EditorState.push(
    newEditorState,
    mentionReplacedContent,
    'insert-fragment'
  );
  return EditorState.forceSelection(
    _newEditorState,
    mentionReplacedContent.getSelectionAfter()
  );
}

export function filterName(value, mentions) {
  let res = [];
  for (let i = 0; i < mentions.length; i++) {
    const children = [];
    const item = mentions[i];
    for (let j = 0; j < item.children.length; j++) {
      const child = item.children[j];
      for (let k = 0; k < child.keyWords.length; k++) {
        const keyword = child.keyWords[k];
        if (keyword?.includes(value) || !value) {
          children.push(child);
          break;
        }
      }
    }
    if (children.length > 0) {
      children.unshift({
        name: item.name,
        type: item.type,
        delFlag: item.delFlag,
      });
    }
    res = res.concat(children);
  }
  return res;
}

/**
 * @param {*} input B0504 + B0500 + B0341
 * @param {*} mentionsData
 * @returns "*-b0504_1" + "*-b0500" + "*-b0341"
 */
export function replaceName(input, mentionsData) {
  input = input.split('');
  let _mentionsData = deepClone(mentionsData);
  _mentionsData = sortMentionsData(_mentionsData);
  for (let i = 0; i < _mentionsData.length; i++) {
    const col = _mentionsData[i];
    const oriLength = col.curShowName.length;
    const newName = `"*-${col.originColumnAlias}"`;
    const restLength = oriLength - 1;
    for (let j = i + 1; j < _mentionsData.length; j++) {
      const nextCol = _mentionsData[j];
      nextCol.start -= restLength;
    }
    input.splice(col.start, oriLength, newName);
  }
  return input.join('');
}

/**
 * @param {*} input B0504 + B0500 + B0341
 * @param {*} mentionsData {
    "indexId": 8973,
    "id": 10212,
    "dbId": 37,
    "tableId": 449,
    "curShowName": "B0504",
    "tableSequence": 2,
    "nameSource": 2,
    "sysShowName": "B0504",
    "sysNameType": null,
    "dbType": "Mysql",
    "dbIp": "10.10.20.68",
    "dbPort": 54321,
    "dbName": "test_wide",
    "tableName": "ud_hotel_info",
    "tableComment": "hotel_info",
    "indexOriName": "b0504",
    "indexRank": 2,
    "indexType": "varchar",
    "columnAlias": "b0504_1",
    "isShow": true,
    "calId": null,
    "formulas": null,
    "formula": null,
    "comment": "B0504",
    "pointNum": null,
    "indexIds": null,
    "uniqueAliasList": null,
    "calOriName": null,
    "calColumnIndexSource": null,
    "targetColumnInfo": null,
    "delFlag": null,
    "sourceColumnId": null,
    "title": "B0504",
    "filterTitle": "B0504",
    "dataIndex": "b0504_1",
    "filterType": "Common",
    "filterDisable": false,
    "fieldType": "varchar",
    "_show": true,
    "_index": 2,
    "_title": "B0504",
    "originColumnAlias": "b0504_1",
    "sortKeys": "hkQ8WvcGXQB3-xKXIkr3M",
    "aliasName": "b0504_1__specialSplitSymbol__10212",
    ...
  }
 * @param {*} editorState
 * @returns (("*-b0500" + "*-a0521") + "*-b0341")
 */
export function validate(input, mentionsData, editorState) {
  try {
    if (mentionsData.some((item) => item.delFlag === 1 || item._del)) {
      return false;
    }
    input = replaceName(input, mentionsData, editorState);
    input = input.replaceAll('（', '(').replaceAll('）', ')');
    Parser.evaluate(input);
    return parser.parse(input).toString();
  } catch (err) {
    return false;
  }
}

/**
 * @param {*} input B0504 + B0500 + B0341
 * @param {*} mentionsData
 * @param {*} editorState
 * @returns [
    {
        "type": 1,
        "content": "("
    },
    {
        "type": 4,
        "content": "{\"indexType\":\"varchar\",\"dataType\":3,\"dbId\":37,\"id\":10212,\"tableId\":449,\"tableName\":\"ud_hotel_info\",\"name\":\"b0504_1\",\"calAlias\":\"b0504_1\"}"
    },
    {
        "type": 2,
        "content": "+"
    },
    {
        "type": 4,
        "content": "{\"indexType\":\"varchar\",\"dataType\":3,\"dbId\":37,\"id\":10213,\"tableId\":449,\"tableName\":\"ud_hotel_info\",\"name\":\"b0500\",\"calAlias\":\"b0500\"}"
    },
    {
        "type": 1,
        "content": ")"
    },
    {
        "type": 2,
        "content": "+"
    },
    {
        "type": 4,
        "content": "{\"indexType\":\"text\",\"dataType\":3,\"dbId\":37,\"id\":10216,\"tableId\":449,\"tableName\":\"ud_hotel_info\",\"name\":\"b0341\",\"calAlias\":\"b0341\"}"
    }
  ]
 */
export function formatFomulas(input, mentionsData, editorState) {
  input = replaceName(input, mentionsData, editorState);
  input = input.replaceAll('（', '(').replaceAll('）', ')');
  const expr = parser.parse(input).toString();
  const oriExpr = /^\((\s|\S)+\)$/.test(expr)
    ? expr.slice(1, expr.length - 1)
    : expr;
  const splitExpr = [];
  const oriExprArr = oriExpr.split('');
  // hack: 双引号之间空格导致校验报错
  for (let i = 0; i < oriExprArr.length; i++) {
    const item = oriExprArr[i];
    if (/\s/.test(item)) {
      const prev = oriExprArr[i - 1];
      const next = oriExprArr[i + 1];
      if (/\+|-|\*|\//.test(prev) || /\+|-|\*|\//.test(next)) {
        const macthCalcItem = oriExprArr
          .slice(0, i + 1)
          .join('')
          .trim();
        splitExpr.push(macthCalcItem);
        oriExprArr.splice(0, i);
        i = 0;
      }
    }
  }
  if (oriExprArr.length > 0) {
    splitExpr.push(oriExprArr.join('').trim());
  }
  const res = [];
  for (let i = 0; i < splitExpr.length; i++) {
    const words = splitExpr[i];
    const splitWords = words.split('');
    if (/\(/.test(words)) {
      for (let j = 0; j < splitWords.length; j++) {
        const curStr = splitWords[j];
        if (curStr === '(') {
          splitExpr.splice(i, 0, '(');
          i++;
          splitWords.splice(j, 1);
          j--;
        }
      }
      splitExpr.splice(i, 1, splitWords.join(''));
    }
    if (/\)/.test(words)) {
      let strIndex = 0;
      for (let j = 0; j < splitWords.length; j++) {
        const curStr = splitWords[j];
        if (curStr === ')') {
          if (strIndex !== -1) {
            splitExpr.splice(i, 1, splitWords.slice(0, strIndex).join(''));
            strIndex = -1;
          }
          splitExpr.splice(i + 1, 0, ')');
          i++;
          splitWords.splice(j, 1);
          j--;
        } else {
          strIndex++;
        }
      }
    }
  }
  try {
    for (let i = 0; i < splitExpr.length; i++) {
      let str = splitExpr[i];
      const type = TypeMap.get(str);
      if (type) {
        res.push({
          type,
          content: str,
        });
      } else if (/^"/.test(str) && !/^"\*-/.test(str)) {
        res.push({
          type: CONST_TYPE,
          content: JSON.stringify({
            content: str.slice(1, str.length - 1),
            dataType: STRING_DATATYPE,
          }),
        });
      } else if (!isNaN(Number(str))) {
        res.push({
          type: CONST_TYPE,
          content: JSON.stringify({
            content: Number(str),
            dataType: /\./.test(str) ? DECIMAL_DATATYPE : INTEGER_DATATYPE,
          }),
        });
      } else if (/^"\*-/.test(str)) {
        str = str.slice(1, str.length - 1).split('-')[1];
        const matchField = mentionsData.find(
          (item) => item.originColumnAlias === str
        );
        res.push({
          type: COLUMN_TYPE,
          content: JSON.stringify({
            indexType: matchField.indexType,
            dataType: DataTypeMap.get(matchField.indexType.toLowerCase()),
            dbId: matchField.dbId,
            id: matchField.id,
            instanceId: matchField.instanceId,
            tableId: matchField.tableId,
            tableName: matchField.tableName,
            name: matchField.formula ?? matchField.originColumnAlias,
            calAlias: matchField.originColumnAlias,
          }),
        });
      }
    }
  } catch (err) {
    return false;
  }
  return res;
}

export function insertPlaceholder(input, mentionsData) {
  input = input.split('');
  let _mentionsData = deepClone(mentionsData);
  _mentionsData = sortMentionsData(_mentionsData);
  for (let i = 0; i < _mentionsData.length; i++) {
    const col = _mentionsData[i];
    // 当前遍历到的引用字段名长度
    const oriLength = col.curShowName.length;
    // col.curShowName.split('')多个位置被替换成'{}'
    const restLength = oriLength - 1;
    for (let j = i + 1; j < _mentionsData.length; j++) {
      const nextCol = _mentionsData[j];
      // 当前字段名被替换成{}，所以其后的所有字段都减去当前字段名长度
      nextCol.start -= restLength;
    }
    input.splice(col.start, oriLength, '{}');
  }
  return input.join('') /* .trim() */;
}

export function formatMentionsData(state, _mentionsData) {
  let preBlockLength = 0;
  let index = 0;
  let newMentionsData = deepClone(_mentionsData);
  newMentionsData = sortMentionsData(newMentionsData);
  if (newMentionsData.length > 0) {
    state
      .getCurrentContent()
      .getBlocksAsArray()
      .forEach((block) => {
        block.findEntityRanges(
          (character) => character.getEntity(),
          (entityStart, entityEnd) => {
            if (newMentionsData[index]) {
              newMentionsData[index].start = preBlockLength + entityStart;
              index++;
            }
          }
        );
        preBlockLength += block.getLength() + 1;
      });
  }
  return newMentionsData;
}

export function findIsColumnRelied(columns, column, targetColumnAlias) {
  let res;
  function traverse(_column) {
    const parsedFormulas = JSON.parse(_column.formulas);
    const _formulas = parsedFormulas.formulas;
    // 遍历所依赖的元素
    for (let j = 0; j < _formulas.length; j++) {
      const _formulasItem = _formulas[j];
      if (_formulasItem.type === COLUMN_TYPE) {
        const _content = JSON.parse(_formulasItem.content);
        // 在某个计算字段中依赖了本计算字段
        if (targetColumnAlias === _content.calAlias) {
          res = true;
        } else if (!_content.id) {
          const foundCol = columns.find(
            (_col) => _col.originColumnAlias === _content.calAlias
          );
          traverse(foundCol);
        }
      }
    }
  }
  traverse(column);
  return res;
}

export function Entry(props) {
  const {mention, theme, searchValue, isFocused, ...parentProps} = props;

  if (mention.type === 'container') {
    return mention.delFlag ? null : (
      <h2
        style={{
          fontSize: '14px',
          padding: '5px',
          whiteSpace: 'nowrap',
          letterSpacing: 0,
          background: 'yellow',
        }}
      >
        {mention.name}
      </h2>
    );
  } else if (mention.type === 'column') {
    return (
      <div
        {...parentProps}
        css={css`
          display: ${mention.delFlag === 1 ||
          (mention.canSelect !== undefined &&
            mention.canSelect !== HAS_COLUMN_PERM) ||
          (mention.isEncrypt !== undefined && mention.isEncrypt) ||
          (mention.isVisibleInCal !== undefined && !mention.isVisibleInCal)
            ? 'none'
            : 'block'};
        `}
      >
        <div className={theme?.mentionSuggestionsEntryContainer}>
          <div className={theme?.mentionSuggestionsEntryContainerRight}>
            <div
              className={theme?.mentionSuggestionsEntryText}
              css={css`
                color: rgba(37, 49, 69, 0.7);
                letter-spacing: 0;
              `}
            >
              {mention.mentionName}
            </div>
          </div>
        </div>
      </div>
    );
  }
  return <div style={{textAlign: 'center'}}>{mention.name}</div>;
}
