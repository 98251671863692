import {atom} from 'jotai';

const pagePermsAtom = atom({
  type: null,
  /* tip: 在新版用户中心，去除了pageCode，
    为避免其它页面太多修改调整，暂时在赋值时做兼容，使pageCode = menuCode
   */
  pageCode: '',
  menuCode: '',
  menuName: '',
  permBtns: [],
});

export {pagePermsAtom};
