import {css} from '@emotion/react';
import {Form, Input} from 'antd';
import React, {useContext, useEffect} from 'react';

import {EditContext, useLogicAtom} from '..';
import {SQLSyntaxKeywords} from '../../../constants/SQLSyntaxKey';
import FormRequireLabel from '../../FormRequireLabel';

const AliasNameInput = ({value, onChange, ...rest}) => {
  return (
    <Input
      allowClear
      css={css`
        width: 180px;
      `}
      maxLength={50}
      placeholder="请输入字段名称(英文)"
      value={value}
      onChange={(e) => {
        const v = e.target.value;
        if (!v || /^[_a-zA-Z0-9]+$/gi.test(v)) {
          onChange(e);
        } else {
          e.preventDefault();
        }
      }}
      {...rest}
    />
  );
};

export default function EditorHeader() {
  const {
    visible,
    indexType,
    setConfig,
    headerForm,
    // calAlias,
    setCanFetchPreviewData,
    isInMicroIndexPage,
    totalStatusProps,
    // editorState,
  } = useContext(EditContext);

  const {totalStatus, setTotalStatus, columnInfo} = useLogicAtom(
    isInMicroIndexPage,
    totalStatusProps
  );
  const {previewXOriginColumns} = totalStatus;

  function validateDuplicateShowName() {
    for (let i = 0; i < previewXOriginColumns.length; i++) {
      const col = previewXOriginColumns[i];
      // 先判断是同一字段则不比较
      if (
        col.curShowName === columnInfo.curShowName &&
        col.columnAlias === columnInfo.columnAlias
      ) {
        continue;
      }
      // 名称相同
      if (col.curShowName === headerForm.getFieldValue('curShowName')) {
        return Promise.reject(new Error('名称已存在，请修改！'));
      }
      if (!headerForm.getFieldValue('curShowName').trim()) {
        return Promise.reject(new Error('名称不能为空！'));
      }
    }
    return Promise.resolve();
  }

  function validateDuplicateAliasName() {
    for (let i = 0; i < previewXOriginColumns.length; i++) {
      const col = previewXOriginColumns[i];
      // 先判断是同一字段则不比较
      if (
        col.curShowName === columnInfo.curShowName &&
        col.columnAlias === columnInfo.columnAlias
      ) {
        continue;
      }
      const columnAlias = headerForm.getFieldValue('columnAlias');
      // 别名相同
      if (col.columnAlias === columnAlias) {
        return Promise.reject(new Error('名称已存在，请修改！'));
      }
      if (!columnAlias.trim()) {
        return Promise.reject(new Error('名称不能为空！'));
      }

      // 不可使用数据库语法作为字段名
      if (
        SQLSyntaxKeywords.some((key) =>
          new RegExp(`^${columnAlias}{1,${key.length}}$`, 'gi').test(key)
        )
      ) {
        return Promise.reject(new Error('不可使用数据库语法作为字段名'));
      }

      // /^[A-Za-z][A-Za-z0-9_]*$/
      if (!/^[_a-zA-Z0-9]+$/gi.test(columnAlias)) {
        return Promise.reject(new Error('仅支持英文大小写、数值、_'));
      }
      if (/^[0-9]+/gi.test(columnAlias)) {
        return Promise.reject(new Error('不能为数字开头'));
      }
      if (/^_/gi.test(columnAlias)) {
        return Promise.reject(new Error('不能为下划线开头'));
      }
    }
    return Promise.resolve();
  }

  useEffect(() => {
    if (visible && ['bigint', 'decimal'].includes(indexType)) {
      const allValues = headerForm.getFieldsValue(true);

      const calcList = previewXOriginColumns.filter((c) => c.formula);

      const findCol = calcList.find(
        (col) =>
          col.columnAlias === allValues.columnAlias &&
          col.curShowName === allValues.curShowName
      );

      if (findCol) {
        const vals = {
          columnAlias: findCol?.columnAlias,
          curShowName: findCol?.curShowName,
          pointNum: findCol?.decimalPlaces,
        };

        setTotalStatus((prev) => {
          const total = {...prev};
          total.curComputedColumn.columnInfo = {
            ...prev.curComputedColumn.columnInfo,
            ...vals,
          };
          return total;
        });
      }
    }
  }, [visible, indexType, headerForm, previewXOriginColumns, setTotalStatus]);

  return (
    <div
      css={css`
        height: 54px;
        /* border-bottom: 1px solid #d8d8d8; */
        padding-top: 10px;
        /* margin-bottom: 10px; */
        .ant-form-item-label > label {
          height: 28px;
        }
        .ant-form-item-control-input {
          min-height: 28px;
        }
      `}
    >
      <Form
        css={css`
          .ant-form-item-explain,
          .ant-form-item-extra {
            font-size: 12px;
            line-height: 1;
          }
        `}
        form={headerForm}
        layout="inline"
        validateTrigger="onBlur"
        onValuesChange={(changedValue, allValues) => {
          setConfig((prev) => ({
            ...prev,
            ...allValues,
          }));
          setCanFetchPreviewData(true);
        }}
      >
        <Form.Item
          label={<FormRequireLabel title="字段显示名称" />}
          name="curShowName"
          rules={[{validator: validateDuplicateShowName}]}
        >
          <Input
            allowClear
            css={css`
              width: 180px;
            `}
            maxLength={50}
            placeholder="请输入字段显示名称"
          />
        </Form.Item>
        <Form.Item
          label={<FormRequireLabel title="字段名称(英文)" />}
          name="columnAlias"
          rules={[{validator: validateDuplicateAliasName}]}
        >
          <AliasNameInput
          /* disabled={
              !!columnInfo.calId ||
              previewXOriginColumns.some((acol) =>
                acol?.uniqueAliasList?.includes(columnInfo.originColumnAlias)
              )
            } */
          />
        </Form.Item>
        {(indexType.includes('bigint') || indexType.includes('decimal')) && (
          <Form.Item
            label={<FormRequireLabel title="小数位数" />}
            name="pointNum"
            rules={[
              {
                required: true,
                message: '小数位数不能为空',
              },
              {
                pattern: /^([0-9]|1[0-6])$/,
                message: '请输入 0 - 16 的整数',
              },
            ]}
          >
            <Input
              css={css`
                width: 90px;
              `}
            />
          </Form.Item>
        )}
      </Form>
    </div>
  );
}
