import {css} from '@emotion/react';
import {Dropdown, Input, Menu, Tabs, notification} from 'antd';
// import {useAtom} from 'jotai';
import React, {
  useContext,
  useRef,
  useMemo,
  useEffect,
  useCallback,
} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {
  scAddFolder,
  scFolderList,
} from '../../../../../api/dataManagement/wideTable';
// import {folderTabAtom} from '../../../../../atoms/folderTab';
import {AuthButton} from '../../../../../components/AuthButton';
import IconFont from '../../../../../components/IconFont';
import {triggerType} from '../../../../../constants/layout';
import {overflowScrollBar} from '../../../../../global.css';
import {routeHistory} from '../../../../../helpers/history';
import {filterTree} from '../../../../../helpers/utils';
import useModalControl from '../../../../../hooks/useModalControl';
import useSafeState from '../../../../../hooks/useSafeState';
import {useApi} from '../../hooks/useApi';
import {
  addFolderSpace,
  addFolderStyle,
  indexRightHeader,
  indexRightTree,
  searchInput,
} from '../../style.css';
import ChooseTargetFolder from '../ChooseTargetFolder';
import {iconMap} from '../MicroDataQueryInfo/WideTableInfo';
import MenuTree from './MenuTree';

const tabsOpt = [
  {
    name: '我的收藏',
    tabVal: '1',
    showAddTableBtn: true,
  },
  {
    name: '数据库',
    tabVal: '2',
    showAddTableBtn: true,
  },
];

const MicroDataQueryMenu = ({
  showAddBtns = true,
  onlyFolder,
  generateExtraActions,
  isWideList,
  menuTreeHeight,
  selectable,
}) => {
  const {/* wideOverview, */ queryMenuRef, activeKey, setActiveKey} =
    useContext(MicroDataQueryContext);

  // const [, setFolderTabStatus] = useAtom(folderTabAtom);

  const apis = useApi();

  // 鼠标点击选中的，在展示的节点
  const [curNodeInfo, setCurNodeInfo] = useSafeState({});
  const [searchWord, setSearchWord] = useSafeState('');
  // dropdown下拉操作时的节点
  const [curOperateNodeInfo, setOperateNodeInfo] = useSafeState({});

  const moveModalRef = useRef();

  const moveFolderModalControl = useModalControl();
  const followWideTableModalControl = useModalControl();

  const locationHook = useLocation();

  const [searchParams, setSearchParams] = useSearchParams();

  const isDraft = useMemo(() => {
    const {draftFlag} = curNodeInfo;
    const flag = draftFlag === 0;
    return flag;
  }, [curNodeInfo]);

  const disableAddFolder = useMemo(() => {
    const wideId = searchParams.get('wideId');
    const {pathType, managePerm} = curNodeInfo;

    // 管理权限 managePerm
    const hasWideOrFolder =
      managePerm &&
      ((wideId && wideId !== 'undefined' && wideId !== 'null') || pathType);

    return activeKey === '1' ? isDraft : !hasWideOrFolder;
  }, [activeKey, curNodeInfo, isDraft, searchParams]);

  const handleMoveFolderConfirm = async (info) => {
    try {
      const res = await apis.moveTo({
        id: curOperateNodeInfo.id,
        targetId: info?.id,
      });
      if (res?.code === 200) {
        await queryMenuRef.current.refresh();
        moveFolderModalControl._toggle(false);
      }
    } catch (error) {
      const {data} = error;
      if (data?.code === 512) {
        moveModalRef.current.showSameError(data.msg);
      }
      throw new Error('follow wideTable failed');
    }
  };

  const handleFollowWideTableConfirm = async (info) => {
    try {
      const res = await apis.collectDo({
        pathId: curOperateNodeInfo.id,
        targetId: info.id,
      });
      if (res?.code === 200) {
        await queryMenuRef.current.refresh();
        followWideTableModalControl._toggle(false);
      }
    } catch (error) {
      if (error.data.code === 512) {
        notification.error({description: error.data.msg});
      }
      throw new Error('follow wideTable failed');
    }
  };

  const onSelectCallBack = (keys, info) => {
    setCurNodeInfo(info?.node || {});
  };

  const onSearch = useCallback(() => {
    const originTreeData = queryMenuRef.current?.getOriginList() ?? [];

    if (searchWord) {
      const searchList = filterTree(originTreeData, (item) => {
        return (
          item?.name?.includes(searchWord) ||
          item?.showName?.includes(searchWord) ||
          item?.tableName?.includes(searchWord)
        );
      });
      queryMenuRef.current?.handleTreeData(searchList);
      queryMenuRef.current?.handleExpand(true, originTreeData);
    } else {
      queryMenuRef.current?.handleTreeData(originTreeData);
      queryMenuRef.current?.handleExpand(false, originTreeData);
    }
  }, [queryMenuRef, searchWord]);

  const createNewSheetLogic = () => {
    // setTotalStatus({...deepClone(defaultLogicEditStatus)});
    routeHistory.push(
      `${locationHook.pathname}/LogicEdit?tabIndex=${
        searchParams.get('tabIndex') ?? 1
      }`
    );
  };

  const createSQLWide = () => {
    routeHistory.push(
      `${locationHook.pathname}/SqlWideEdit?tabIndex=${
        searchParams.get('tabIndex') ?? 1
      }`
    );
  };

  const turnToExcelPage = () => {
    const wideId = searchParams.get('wideId');
    routeHistory.push(
      `${locationHook.pathname}/ExcelPage${wideId ? `?wideId=${wideId}` : ''}`
    );
  };

  const onChangeTabHandle = (newVal) => {
    searchParams.set('tabIndex', newVal);

    onSelectCallBack();
    // setActiveKey(newVal);
    setSearchParams(searchParams);
    // setFolderTabStatus({active: newVal});
  };

  useEffect(() => {
    onSearch();
  }, [onSearch, searchWord]);

  useEffect(() => {
    const tabKey = searchParams.get('tabIndex');
    if (tabKey) setActiveKey(tabKey);
  }, [searchParams, setActiveKey]);

  useEffect(() => {
    if (activeKey) {
      setSearchWord('');
      setCurNodeInfo({});
      setOperateNodeInfo({});
    }
  }, [activeKey, setOperateNodeInfo, setSearchWord, setCurNodeInfo]);

  return (
    <div style={{width: '100%'}}>
      <div css={indexRightHeader}>
        <Tabs
          destroyInactiveTabPane
          activeKey={activeKey}
          css={css`
            & > .ant-tabs-nav {
              margin-bottom: 6px;
            }
            .ant-tabs-tab + .ant-tabs-tab {
              margin-left: 12px;
            }
          `}
          size="small"
          type="line"
          onChange={onChangeTabHandle}
        >
          {tabsOpt.map((tabOpt) => {
            const menu = (
              <Menu>
                <Menu.Item key="0" onClick={createSQLWide}>
                  <IconFont
                    css={css`
                      font-size: 16px;
                      margin-right: 4px;
                      color: ${iconMap.wideIcon1.color};
                    `}
                    type={iconMap.wideIcon1.icon}
                  />
                  创建SQL宽表
                </Menu.Item>
                <Menu.Item key="1" onClick={createNewSheetLogic}>
                  <IconFont
                    css={css`
                      font-size: 16px;
                      margin-right: 4px;
                      color: ${iconMap.wideIcon1.color};
                    `}
                    type={iconMap.wideIcon1.icon}
                  />
                  创建宽表
                </Menu.Item>
                <Menu.Item key="2" onClick={turnToExcelPage}>
                  <IconFont
                    css={css`
                      font-size: 16px;
                      margin-right: 4px;
                      color: ${iconMap.wideIcon4.color};
                    `}
                    type={iconMap.wideIcon4.icon}
                  />
                  上传文件
                </Menu.Item>
              </Menu>
            );
            return (
              <Tabs.TabPane key={tabOpt.tabVal} tab={tabOpt.name}>
                <>
                  {showAddBtns && (
                    <div css={addFolderSpace} size={5}>
                      {tabOpt.showAddTableBtn && (
                        // 触发“新建文件夹”弹窗，文件夹添加至用户选中的文件夹下级，没选中就是根目录
                        <AuthButton
                          css={addFolderStyle}
                          disabled={disableAddFolder}
                          size="small"
                          onClick={() => {
                            queryMenuRef.current.newFolder();
                          }}
                        >
                          添加文件夹
                        </AuthButton>
                      )}
                      <Dropdown overlay={menu} trigger={triggerType}>
                        <AuthButton block size="small" type="primary">
                          添加数据表
                        </AuthButton>
                      </Dropdown>
                    </div>
                  )}
                  <Input
                    allowClear
                    css={searchInput}
                    maxLength={50}
                    placeholder="检索文件夹/表中英文名"
                    value={searchWord}
                    onChange={(e) => {
                      setSearchWord(e.target.value);
                    }}
                  />

                  <div
                    css={css`
                      ${indexRightTree}
                      ${overflowScrollBar}
                    `}
                    id="treeContainer"
                    style={{position: 'relative', maxHeight: '80vh'}}
                  >
                    <MenuTree
                      draggable
                      defaultExpandAllKeys={false}
                      followWideTableModalControl={followWideTableModalControl}
                      generateExtraActions={generateExtraActions}
                      height={menuTreeHeight}
                      isWideList={isWideList}
                      key={tabOpt.tabVal}
                      moveFolderModalControl={moveFolderModalControl}
                      onlyFolder={onlyFolder}
                      ref={queryMenuRef}
                      selectable={selectable}
                      setOperateNodeInfo={setOperateNodeInfo}
                      onSearch={onSearch}
                      onSelectCallBack={onSelectCallBack}
                    />
                  </div>
                </>
              </Tabs.TabPane>
            );
          })}
        </Tabs>
      </div>

      {/* 移动 */}
      <ChooseTargetFolder
        confirm={handleMoveFolderConfirm}
        modalControl={moveFolderModalControl}
        ref={moveModalRef}
        title={curOperateNodeInfo.name || curOperateNodeInfo.tableName || '--'}
        treeApi={(p) =>
          apis.folderList({
            [activeKey === '1' ? 'collectPathId' : 'tablePathId']:
              curOperateNodeInfo.id,
          })
        }
      />

      {/* 收藏 */}
      <ChooseTargetFolder
        addFolderApi={scAddFolder}
        confirm={handleFollowWideTableConfirm}
        modalControl={followWideTableModalControl}
        modalType={2}
        title={curOperateNodeInfo.name || curOperateNodeInfo.tableName}
        treeApi={scFolderList}
      />
    </div>
  );
};

export default MicroDataQueryMenu;
