import request from '../../helpers/request';

const serviceName = '/wide';

// 解析excel数据表
export const PARSING_EXCEL_TABLE = `${serviceName}/sourcetable/parsingExcelTable`;
export function parsingExcelTable(data) {
  return request({
    url: `${serviceName}/sourcetable/parsingExcelTable`,
    method: 'POST',
    data,
  });
}

// 保存excel数据表
export const SAVE_EXCEL_TABLE = `${serviceName}/sourcetable/saveExcelTable`;
export function saveExcelTable(data) {
  return request({
    url: SAVE_EXCEL_TABLE,
    method: 'POST',
    data,
  });
}

// 更新excel数据表（追加 或 替换）
export function updateExcelTable(data) {
  return request({
    url: `${serviceName}/sourcetable/updateExcelTable`,
    method: 'POST',
    data,
  });
}

// 查原始表字段
export function getSourcecolumnList(data) {
  return request({
    url: `${serviceName}/sourcecolumn/list`,
    method: 'POST',
    data,
  });
}

// 查数据库全部列表
export const GET_All_DB_PATH_LIST = `/wide/widepath/allFolderList`;
export function getAllFolderList(params) {
  return request({
    url: GET_All_DB_PATH_LIST,
    method: 'get',
    params,
  });
}

// 查数据库列表
export const GET_DB_PATH_LIST = `/wide/widepath/dbPathList`;

// 查我的收藏列表
export const GET_COLLECT_LIST = `/wide/widecollect/collectList`;

// 表名查重
export function checkTableName(params) {
  return request({
    url: `${serviceName}/sourcetable/checkTableName`,
    method: 'get',
    params,
  });
}

// 表显示名查重
export function checkTableShowName(params) {
  return request({
    url: `${serviceName}/sourcetable/checkTableShowName`,
    method: 'get',
    params,
  });
}

// 查组织树
export const GET_ALL_GROUP = `${serviceName}/userPerm/getAllGroup`;
export function getAllGroup(params) {
  return request({
    url: GET_ALL_GROUP,
    method: 'get',
    params,
  });
}

// 查角色列表
export const GET_ALL_ROLE = `${serviceName}/userPerm/getAllRole`;
export function getAllRole(params) {
  return request({
    url: GET_ALL_ROLE,
    method: 'get',
    params,
  });
}

// 查组织用户树
export const GET_ALL_GROUP_USER = `${serviceName}/userPerm/getAllGroupUser`;
export function getAllGroupUser(params) {
  return request({
    url: GET_ALL_GROUP_USER,
    method: 'get',
    params,
  });
}

// 查权限列表
export const GET_PATH_PERM_LIST = `${serviceName}/userPerm/pathPermList`;
export function getPathPermList(params) {
  return request({
    url: GET_PATH_PERM_LIST,
    method: 'get',
    params,
  });
}

// 修改权限
export const CHANGE_PERM = `${serviceName}/userPerm/changePerm`;
export function changePerm(data) {
  return request({
    url: CHANGE_PERM,
    method: 'post',
    data,
  });
}

// 校验数据库写入权限
export const CHECK_WRITE = `${serviceName}/perm/checkWrite`;
export function checkWrite(params) {
  return request({
    url: CHECK_WRITE,
    method: 'get',
    params,
  });
}

// 解析excel数据表(第二行识别为字段名)
export const PARSING_EXCEL_URL = `${serviceName}/sourcetable/parsingExcelUrl`;
export function parsingExcelUrl(data) {
  return request({
    url: PARSING_EXCEL_URL,
    method: 'POST',
    data,
  });
}

// 编辑Excel数据表字段
export const EDIT_EXCEL_TABLE_COLUMN = `${serviceName}/sourcecolumn/editExcelTableColumn`;
export function editExcelTableColumn(data) {
  return request({
    url: EDIT_EXCEL_TABLE_COLUMN,
    method: 'POST',
    data,
  });
}

// 更新日志
export const GET_TABLE_LOG = `${serviceName}/tableLog/list`;
export function getTableLog(params) {
  return request({
    url: GET_TABLE_LOG,
    method: 'GET',
    params,
  });
}

// 导出日志
export const EXPORT_TABLE_LOG = `${serviceName}/tableLog/exportWideTableLog`;

// 是否可刷新数据库元数据
// export const GET_DB_REFRESH_FLAG = `${serviceName}/sourcedb/dbRefreshFlag`;
// 临时换成mcs 服务 => 改回wide
export const GET_DB_REFRESH_FLAG = `/wide/sourcedb/dbRefreshFlag`;
export function getDbRefreshFlag(dbId) {
  return request({
    url: `${GET_DB_REFRESH_FLAG}/${dbId}`,
    method: 'GET',
  });
}

// 刷新数据库元数据
// export const REFRESH_DB_META = `${serviceName}/sourcedb/refreshDbMeta`;
// 临时换成mcs 服务 => 改回wide
export const REFRESH_DB_META = `/wide/sourcedb/refreshDbMeta`;
export function refreshDbMeta(dbId) {
  return request({
    url: `${REFRESH_DB_META}/${dbId}`,
    method: 'GET',
  });
}

// 通过cronJson获取cron表达式
export const GET_CRON = `${serviceName}/sourcedb/getCron`;
export function getCron(data) {
  return request({
    url: GET_CRON,
    method: 'POST',
    data,
  });
}

//
export const UPDATE_CRON = `${serviceName}/sourcedb/updateCron`;
export function updateCron(params) {
  return request({
    url: UPDATE_CRON,
    method: 'POST',
    params,
  });
}

// Sql宽表预览数据
export const SQL_PREVIEW = `${serviceName}/sqlMaking/sqlPreview`;
export function sqlPreview(data) {
  return request({
    url: SQL_PREVIEW,
    method: 'POST',
    data,
  });
}

// 保存sql宽表
export const SAVE_SQL_WIDE_TABLE = `${serviceName}/sqlMaking/save`;
export function saveSqlWideTable(data) {
  return request({
    url: SAVE_SQL_WIDE_TABLE,
    method: 'POST',
    data,
  });
}

// 另存为sql宽表
export const SAVE_AS_SQL_WIDE_TABLE = `${serviceName}/sqlMaking/saveAs`;
export function saveAsSqlWideTable(data) {
  return request({
    url: SAVE_AS_SQL_WIDE_TABLE,
    method: 'POST',
    data,
  });
}

// 更新宽表
export const UPDATE_SQL_WIDE_TABLE = `${serviceName}/sqlMaking/update`;
export function updateSqlWideTable(data) {
  return request({
    url: UPDATE_SQL_WIDE_TABLE,
    method: 'POST',
    data,
  });
}

// 获取已存宽表数据
export const GET_SAVED_SQL_WIDE_DATA = `${serviceName}/sqlMaking/getSqlWideData`;
export function getSavedSqlWideData(params) {
  return request({
    url: GET_SAVED_SQL_WIDE_DATA,
    method: 'GET',
    params,
  });
}

// 暂存
export const TEMP_SAVE_SQL_WIDE_TABLE = `${serviceName}/sqlMaking/tempSave`;
export function tempSaveSqlWideTable(data) {
  return request({
    url: TEMP_SAVE_SQL_WIDE_TABLE,
    method: 'POST',
    data,
  });
}
