import {css} from '@emotion/react';
import React from 'react';

import {GuidanceCss} from './style.css';

export default function ArithmeticGuidance() {
  return (
    <div css={GuidanceCss}>
      <div>四则运算，字段或数值之间输入运算符或数值构成</div>
      <ul>
        <li>相加运算通过+连接数值类字段或数值</li>
        <li>相减运算通过-连接数值类字段或数值</li>
        <li>相乘运算通过*连接数值类字段或数值</li>
        <li>相除运算通过/连接数值类字段或数值</li>
        <li>优先运算通过(和)进行闭合</li>
        <li>在数值类字段或数值左侧输入-将得到负数</li>
        <li>相邻数值类字段或数值之间必须存在+-*/运算符号</li>
      </ul>
      <div>配置示例</div>
      <p>
        ( <span>数值字段A</span>-<span>数值字段B</span>)÷<span>数值字段C</span>
        -100.00
      </p>
      <div>效果示例</div>
      <p
        css={css`
          margin-bottom: 0 !important;
        `}
      >
        (1000-500)/20/-100.00=-0.25
      </p>
    </div>
  );
}
