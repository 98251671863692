/* eslint-disable react-hooks/exhaustive-deps */
import {css} from '@emotion/react';
import {Row, Col, Radio, InputNumber, Select, Card} from 'antd';
import {useCallback, useState, useMemo, useEffect} from 'react';

import {monthList, monthDayList, defaultDateFormat} from '../../constants/base';
import dayjs from '../../helpers/extendedDayjs';
import TaskDatePicker from './TaskDatePicker';

const marginStyle = css`
  margin-top: 10px;
`;

const widthStyle = {width: '206px'};

export const frequencys = [
  {label: '立即执行', value: 0},
  {label: '一次', value: 1},
  {label: '每小时', value: 2},
  {label: '每天', value: 3},
  {label: '每月', value: 5},
];

const frequencyDefaultValue = new Map([
  [0, {}],
  [1, {}],
  [2, {stepHours: 1}],
  [3, {stepDays: 1}],
  [5, {months: [1], monthDays: [1]}],
]);

export default function Frequency({
  format = defaultDateFormat,
  value,
  onChange,
  disabled,
  disablePassedDate,
}) {
  const getCurStartTime = () => dayjs().add(2, 'm').format(format);
  const defaultValue = {
    frequency: 0,
    startTime: getCurStartTime(),
    stepHours: null,
    stepDays: null,
    months: [],
    monthDays: [],
  };
  const [frequencySetting, setFrequencySetting] = useState(defaultValue);

  // 检查是否有全部月/天，和其它月/天互斥
  const allChecked = (valArr = []) => {
    let result = [];
    const allValIndex = valArr.findIndex((v) => v === -1);

    if (allValIndex < 0) {
      result = valArr;
    }
    if (allValIndex === 0) {
      result = valArr.length === 1 ? valArr : valArr.filter((v) => v !== -1);
    }
    if (allValIndex > 0) {
      result = [-1];
    }
    return result;
  };

  const onDatesChangeHandle = (val) => {
    const newVal = val;

    if (val.months) newVal.months = allChecked(val.months);
    if (val.monthDays) newVal.monthDays = allChecked(val.monthDays);

    const newFrequencySetting = {
      ...frequencySetting,
      ...newVal,
    };

    setFrequencySetting(newFrequencySetting);
    onChange(newFrequencySetting);
  };

  const onFrequencyChangeHandle = (val) => {
    const newFrequencySetting = {
      ...defaultValue,
      ...frequencyDefaultValue.get(val.frequency),
      ...val,
      ...{startTime: getCurStartTime()},
    };

    setFrequencySetting(newFrequencySetting);
    onChange(newFrequencySetting);
  };

  useEffect(() => {
    if (value) {
      setFrequencySetting(value);
      onChange(value);
    } else {
      onChange(defaultValue);
    }
  }, [value]);

  useEffect(() => {
    if (value) {
      const {frequency, startTime} = value;

      // 立即执行任务再次开启时重置开始执行时间
      if (frequency === 0) {
        value.startTime = getCurStartTime();
      }

      // 一次性执行任务再次开启时重置开始执行时间
      if (frequency === 1) {
        value.startTime = dayjs().isBefore(dayjs(startTime))
          ? getCurStartTime()
          : startTime;
      }
    }
  }, []);

  const OnceNode = useCallback(() => {
    return (
      <TaskDatePicker
        disablePassedDate={disablePassedDate}
        disabled={disabled}
        format={format}
        value={dayjs(frequencySetting.startTime)}
        onChange={(val, valStr) => onDatesChangeHandle({startTime: valStr})}
      />
    );
  }, [frequencySetting, disabled, disablePassedDate]);

  const Hourly = useCallback(() => {
    return (
      <div>
        <OnceNode />
        <div css={marginStyle}>
          每隔&ensp;
          <InputNumber
            disabled={disabled}
            max={23}
            min={1}
            precision={0}
            style={widthStyle}
            value={frequencySetting.stepHours}
            onBlur={(val) => onDatesChangeHandle({stepHours: val.target.value})}
            onStep={(val) => onDatesChangeHandle({stepHours: val})}
          />
          &ensp;小时
        </div>
      </div>
    );
  }, [frequencySetting, disabled]);

  const Dayly = useCallback(() => {
    return (
      <div>
        <OnceNode />
        <div css={marginStyle}>
          每隔&ensp;
          <InputNumber
            disabled={disabled}
            max={31}
            min={1}
            precision={0}
            style={widthStyle}
            value={frequencySetting.stepDays}
            onBlur={(val) => onDatesChangeHandle({stepDays: val.target.value})}
            onStep={(val) => onDatesChangeHandle({stepDays: val})}
          />
          &ensp;天
        </div>
      </div>
    );
  }, [frequencySetting, disabled]);

  const Monthly = useCallback(() => {
    return (
      <div>
        <OnceNode />
        <div css={marginStyle}>
          &emsp;月&ensp;
          <Select
            disabled={disabled}
            mode="multiple"
            options={monthList}
            style={widthStyle}
            value={frequencySetting.months}
            onChange={(val) => onDatesChangeHandle({months: val})}
          />
        </div>
        <div css={marginStyle}>
          &emsp;天&ensp;
          <Select
            disabled={disabled}
            mode="multiple"
            options={monthDayList}
            style={widthStyle}
            value={frequencySetting.monthDays}
            onChange={(val) => onDatesChangeHandle({monthDays: val})}
          />
        </div>
      </div>
    );
  }, [frequencySetting, disabled]);

  const frequencyComMap = useMemo(
    () =>
      new Map([
        [0, <span key={0} />],
        [1, <OnceNode key={1} />],
        [2, <Hourly key={2} />],
        [3, <Dayly key={3} />],
        [5, <Monthly key={5} />],
      ]),
    [frequencySetting, disabled]
  );

  const FrequencyCom = useMemo(
    () => frequencyComMap.get(frequencySetting.frequency),
    [frequencySetting, disabled]
  );

  return (
    <Card hoverable>
      <Row>
        <Col span={6}>
          <Radio.Group
            disabled={disabled}
            value={frequencySetting.frequency}
            onChange={(e) =>
              onFrequencyChangeHandle({frequency: e.target.value})
            }
          >
            {frequencys.map(({label, value: val}) => {
              return (
                <Radio key={val} value={val}>
                  {label}
                </Radio>
              );
            })}
          </Radio.Group>
        </Col>
        <Col span={18}>{FrequencyCom}</Col>
      </Row>
    </Card>
  );
}
