import request from '../../helpers/request';

/** 站内信-已读 */
export function hasReadByIds(data) {
  return request({
    url: `/user/notice/hasReadByIds`,
    method: 'put',
    data,
  });
}

/** 站内信-删除 */
export function deleteNotice(params) {
  return request({
    url: `/user/notice/deleteNotice`,
    method: 'delete',
    params,
  });
}

/** 站内信-列表 */
export function getMessage(data) {
  return request({
    url: `/user/notice/message`,
    method: 'post',
    data,
  });
}

/** 站内信-类别 */
export const GET_NOTICE_TYPE = '/user/notice/getNoticeType';

/** 个人中心-站内信 */
export const GET_PERSON_MESSAGE = '/user/notice/personMessage';
export function getPersonMessage(params) {
  return request({
    url: GET_PERSON_MESSAGE,
    method: 'get',
    params,
  });
}
