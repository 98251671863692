import {Col, Divider, Form, Input, notification, Row, Select} from 'antd';
import {useAtom} from 'jotai';
import React, {useEffect, useContext, useRef, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {
  addFilterGroup,
  editFilterGroup,
  getFilterRecord,
} from '../../../../../api/dataQuery/microDataQuery';
import {filterAtom} from '../../../../../components/Filter/atoms/filterAtom';
import FormErrMsg from '../../../../../components/FormErrMsg';
import FormRequireLabel from '../../../../../components/FormRequireLabel';
import GlobalModal from '../../../../../components/GlobalModal';
import WarningModal from '../../../../../components/GlobalModal/WarningModal';
import {makeFilterCondition} from '../../../../../components/TableSheetCopy/helper';
import {deepClone} from '../../../../../helpers/utils';
import useModalControl from '../../../../../hooks/useModalControl';
import useSafeState from '../../../../../hooks/useSafeState';
import useSerialFilter from '../../../../../hooks/useSerialFilter';
import {encodeValueList} from '../../helpers';
import {infoItem, infoItemLabel, infoItemText} from '../../style.css';
import FilterTableEditor from '../FilterTableEditor';

export default function EditFilterModal({
  title = '保存筛选组',
  modalControl,
  storeColumnWidthKey,
}) {
  const [filterGroupInfo, setFilterGroupInfo] = useSafeState([]);
  const [cacheFilterData, setCacheFilterData] = useSafeState([]);
  const [update, setUpdate] = useSafeState(1);
  const [updateTipReson, setUpdateTipReson] = useSafeState('');

  const [form] = Form.useForm();

  const {formatColumns, wideOverview, queryMenuRef, columnList} = useContext(
    MicroDataQueryContext
  );

  const [searchParams] = useSearchParams();

  const cacheFilterRef = useRef();
  const recordIdRef = useRef();

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  const updateTipModalControl = useModalControl();
  const cancelModalControl = useModalControl();

  const originFilterConditions = useSerialFilter(
    `dataQueryTable${searchParams.get('wideId')}`
  );

  const filterConditions = useSerialFilter(storeColumnWidthKey);

  const hasCalcFilter = useMemo(() => {
    const filterCalc = formatColumns
      .filter((fc) => !!fc.queryCalId)
      .map((fc) => fc.indexAlias);

    return filterConditions.some((fc) => filterCalc.includes(fc.aliasName));
  }, [formatColumns, filterConditions]);

  // 获取标签列筛选
  const tagList = useMemo(() => {
    return (
      filterConditions.filter((ofc) => ofc.aliasName === 'zhtj_tag')[0]
        ?.valueList ?? []
    ).map((item) => {
      return Number(decodeURIComponent(item).split('#z_h_t_j#')[0]);
    });
  }, [filterConditions]);

  const isCanSubmit = useMemo(() => {
    return filterConditions.some((i) => !!i.valueList);
  }, [filterConditions]);

  const extraFilterCondition = useMemo(() => {
    const conditionsCopy = deepClone(conditions);
    return makeFilterCondition(
      columnList,
      conditionsCopy[storeColumnWidthKey] ?? {}
    );
  }, [columnList, conditions, storeColumnWidthKey]);

  const staticFilters = useMemo(() => {
    return extraFilterCondition
      .map((item) => {
        const column = columnList.find(
          (col) => `zhtj_coding_${col.indexAlias}` === item.aliasName
        );
        return column?.mosaicType
          ? {
              column: column.indexAlias,
              mosaicType: column.mosaicType,
            }
          : false;
      })
      .filter(Boolean);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnList, conditions, extraFilterCondition]);

  const sectionFilters = useMemo(() => {
    const _sectionFilters = [];
    const conditionsCopy = deepClone(conditions);
    const queryFilterConditions = conditionsCopy[storeColumnWidthKey] ?? {};
    Object.keys(queryFilterConditions).forEach((key) => {
      const column = columnList.find((col) => col.indexAlias === key);
      if (column?.sectionId) {
        const valueList = queryFilterConditions[key].valueList;
        if (valueList) {
          valueList.forEach((item) => {
            let splitItem = item;
            if (typeof splitItem === 'string') {
              splitItem = decodeURIComponent(item).split('#z_h_t_j#');
              if (splitItem.length < 2) return;
              splitItem = splitItem.slice(0, splitItem.length - 1);
              splitItem = JSON.parse(splitItem.join('#z_h_t_j#'));
            }
            _sectionFilters.push({
              column: key,
              left: splitItem.left,
              right: splitItem.right,
              symbol: splitItem.symbol,
              label: splitItem.label,
            });
          });
        }
      }
    });
    return _sectionFilters;
  }, [columnList, conditions, storeColumnWidthKey]);

  const requestData = useMemo(() => {
    return {
      pathId: +searchParams.get('wideId'),
      filterGroupDetail: filterGroupInfo.map((i) => ({
        curShowName: i.curShowName,
        indexId: i.indexId ?? null,
        calId: i.queryCalId ?? null,
        filterContent: i.record,
        filterCondition: filterConditions.filter(
          (con) => con.aliasName === i.aliasName
        )[0]?.valueList
          ? JSON.stringify(
              filterConditions.filter((con) => con.aliasName === i.aliasName)[0]
            )
          : '',
      })),
      tagIds: tagList,
      staticFilters,
      sectionFilters,
    };
  }, [
    filterConditions,
    filterGroupInfo,
    searchParams,
    sectionFilters,
    staticFilters,
    tagList,
  ]);

  const updateFilterGroup = async () => {
    const {name, type} = form.getFieldsValue();
    try {
      const res = await editFilterGroup({
        name,
        type,
        id: recordIdRef.current,
        ...requestData,
      });
      if (res?.code === 200) {
        notification.success({description: `${name}筛选组保存成功`});
        updateTipModalControl._toggle(false);
        modalControl._toggle(false);
      }
    } catch (error) {
      notification.error({description: `${name}筛选组保存失败`});
      throw new Error('edit filter group failed');
    }
  };

  const onSubmitHandle = async () => {
    try {
      await form.validateFields();
      const {name, type} = form.getFieldsValue();
      if (name.trim()) {
        const res = await addFilterGroup({name, type, ...requestData});
        if (res?.code === 200) {
          if (res?.data) {
            const {type: resType, id: resId} = res?.data || {};
            if (type === resType) {
              setUpdateTipReson('');
            } else {
              setUpdateTipReson(
                `请确认是否修改为"${type ? '私有' : '公开'}"类型`
              );
            }
            recordIdRef.current = resId;
            updateTipModalControl._toggle(true);
          } else {
            queryMenuRef.current.refresh();
            notification.success({description: `${name}筛选组保存成功`});
            modalControl._toggle(false);
          }
        }
      } else {
        FormErrMsg.setFormErrMsg(form, 'name', '请输入名称！');
      }
    } catch (error) {
      FormErrMsg.disposeErr(form, error);
      notification.error({description: `筛选组保存失败`});
      throw new Error('submit error');
    }
  };

  const requestFilter = async (con = null) => {
    try {
      const res = await getFilterRecord(
        {
          'filter-condition': JSON.stringify(
            encodeValueList(con) ??
              originFilterConditions
                .filter((ofg) => ofg.aliasName !== 'zhtj_tag')
                .filter((ofg) => Object.keys(ofg).length > 0)
          ),
        },
        {
          queryList: formatColumns.map((i) => ({
            ...i,
            aliasName: i.indexOriName,
            curShowName: i.curShowName || i.indexOriName,
          })),
        }
      );
      if (res?.code === 200) {
        const resData = res?.data ?? [];
        const cloneColumnList = deepClone(formatColumns);
        const combination = [];
        resData.forEach((i) => {
          cloneColumnList.forEach((k) => {
            if (i.aliasName === k.indexOriName) {
              combination.push({...k, ...i});
            }
          });
        });
        setFilterGroupInfo(combination);
      }
    } catch (error) {
      throw new Error('failed');
    }
  };

  useEffect(() => {
    if (hasCalcFilter) {
      form.setFieldsValue({type: 1});
    }
  }, [form, hasCalcFilter]);

  useEffect(() => {
    if (modalControl.visible) {
      requestFilter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalControl.visible]);

  const updateCacheFilterHandle = (con) => {
    const record = Object.values(con[storeColumnWidthKey]);

    const keys = Object.keys(con[storeColumnWidthKey]);
    const format = record.map((i, index) => ({
      ...i,
      aliasName: keys[index],
    }));
    setCacheFilterData(format);
    requestFilter(format);
  };

  useEffect(() => {
    if (modalControl.visible) {
      cacheFilterRef.current = originFilterConditions;
    }
  }, [originFilterConditions, modalControl.visible]);

  useEffect(() => {
    if (!modalControl.visible) {
      form.resetFields();
      setUpdate(Date.now());
      setCacheFilterData({});
      setFilterGroupInfo([]);
      setCacheFilterData([]);
      const conditionsCopy = deepClone(conditions);
      conditionsCopy[storeColumnWidthKey] = {};
      setFilterParams({
        ...filterParams,
        conditions: conditionsCopy,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalControl.visible]);

  return (
    <>
      <GlobalModal
        {...modalControl}
        centered
        key={update}
        title={title}
        width={668}
        onCancel={() => {
          cancelModalControl._toggle(true);
        }}
        onOk={() => {
          onSubmitHandle();
        }}
      >
        <div>
          <p css={infoItem}>
            <span css={infoItemLabel}>表名：</span>
            <span css={infoItemText}>{wideOverview.name ?? '--'}</span>
          </p>

          <Divider style={{margin: '10px 0'}} />

          <Form form={form} initialValues={{type: 1, name: ''}} size="small">
            <Row>
              <Col span={10}>
                <Form.Item
                  label={<FormRequireLabel title="名称" />}
                  name="name"
                  rules={[{required: true, message: '请输入名称！'}]}
                >
                  <Input placeholder="请输入筛选组名称" />
                </Form.Item>
              </Col>
              <Col offset={2} span={12}>
                <Form.Item
                  label={<FormRequireLabel title="公开类型" />}
                  name="type"
                >
                  <Select>
                    {hasCalcFilter ? (
                      <Select.Option value={1}>私有</Select.Option>
                    ) : (
                      <>
                        <Select.Option value={1}>私有</Select.Option>
                        <Select.Option value={0}>公开</Select.Option>
                      </>
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
            <FormErrMsg />
          </Form>

          <Divider style={{margin: '10px 0'}} />

          <p style={{color: '#1A2230', marginBottom: 8}}>
            筛选器详情
            {isCanSubmit ? null : (
              <span style={{color: '#E34D59', marginLeft: 8}}>
                请至少对1个字段进行筛选
              </span>
            )}
          </p>

          {modalControl.visible && filterGroupInfo.length > 0 ? (
            <FilterTableEditor
              // headerDraggable
              dataSource={filterGroupInfo}
              filterConditions={
                cacheFilterData.length > 0
                  ? cacheFilterData
                  : cacheFilterRef.current
              }
              scroll={{y: '40vh'}}
              storeColumnWidthKey={storeColumnWidthKey}
              updateCacheFilterHandle={updateCacheFilterHandle}
            />
          ) : null}
        </div>
      </GlobalModal>

      <WarningModal
        confirm={updateFilterGroup}
        modalControl={updateTipModalControl}
        reason={
          updateTipReson
            ? ['请确认是否覆盖更新', updateTipReson]
            : ['请确认是否覆盖更新']
        }
        title="我们发现您在此前已保存了相同名称的筛选器"
        width={450}
        zIndex={1001}
      />

      <WarningModal
        confirm={() => {
          modalControl._toggle(false);
          cancelModalControl._toggle(false);
        }}
        description="当前操作内容未保存，请确认是否离开"
        modalControl={cancelModalControl}
        title="确认是否离开"
        width={450}
        zIndex={1001}
      />
    </>
  );
}
