import {useContext, useMemo} from 'react';

import {MicroDataQueryContext} from '..';
import {
  dbAddFolder,
  dbBatchMoveTo,
  dbFolderList,
  dbFolderTableList,
  dbMoveTo,
  dbPathList,
  dbRemoveFolder,
  dbRemoveTable,
  dbRename,
  scAddFolder,
  scCancelCollect,
  scCollectDo,
  scCollectList,
  scFolderList,
  scMoveTo,
  scRemoveFolder,
  scRenameFolder,
} from '../../../../api/dataManagement/wideTable';

export const useApi = () => {
  const {activeKey} = useContext(MicroDataQueryContext);

  const apis = useMemo(
    () => ({
      moveTo: activeKey === '1' ? scMoveTo : dbMoveTo,
      treeList: activeKey === '1' ? scCollectList : dbPathList,
      addFolder: activeKey === '1' ? scAddFolder : dbAddFolder,
      folderList: activeKey === '1' ? scFolderList : dbFolderList,
      renameFolder: activeKey === '1' ? scRenameFolder : dbRename,
      removeFolder: activeKey === '1' ? scRemoveFolder : dbRemoveFolder,

      collectDo: activeKey === '1' ? scCollectDo : scCollectDo,
      cancelCollect: activeKey === '1' ? scCancelCollect : scCancelCollect,

      //
      removeTable: activeKey === '1' ? dbRemoveTable : dbRemoveTable,
      batchMoveTo: activeKey === '1' ? dbBatchMoveTo : dbBatchMoveTo,
      dbFolderTableTree:
        activeKey === '1' ? dbFolderTableList : dbFolderTableList,
    }),
    [activeKey]
  );

  return apis;
};
