import {atom, useAtom} from 'jotai';
import {useMount} from 'react-use';

export const tooltipAtom = atom({});

export function updateTooltip(data) {
  return data.reduce((prev, current) => {
    return {
      ...prev,
      [current.pageCode]: current.tipMessageVOList,
    };
  }, {});
}

export function useInitTooltipAtom() {
  // const {token} = useToken();
  const [, setAtom] = useAtom(tooltipAtom);
  useMount(() => {
    /* if (!token) return;
    queryTipList({
      menuCode: '',
    }).then((res) => {
      const formatData = updateTooltip(res.data);
      setAtom((val) => ({...val, ...formatData}));
    }); */
    setAtom({});
  });
}
