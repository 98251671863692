import {useState} from 'react';

import {isObjectValueEqual} from '../helpers/utils';

export default function useCheckValueChanged() {
  const [changed, setChanged] = useState(false);

  /**
   * @description: 判断表单或者对象数据跟原数据对比是否有变化
   * @param {object} originValue 原数据
   * @param {object} curValue 新数据
   * @param {boolean} checkAllProperty 是否检测原数据所有属性是否变化,因为curValue属性可能少于原数据
   * @return {boolean}
   */
  const checkChanged = (originValue, curValue, checkAllProperty = false) => {
    let changedValues = {};
    const {formErrMsg, ...restCurValue} = curValue;

    if (checkAllProperty) {
      setChanged(!isObjectValueEqual(originValue, restCurValue));
    } else {
      changedValues = Object.keys(restCurValue).reduce((pre, key) => {
        if (restCurValue?.[key] !== originValue?.[key]) {
          pre[key] = restCurValue?.[key];
        }
        return pre;
      }, {});

      setChanged(Object.keys(changedValues).length > 0);
    }

    return changedValues;
  };

  return [changed, checkChanged];
}
