export default {
  /** 首页 */
  Home: {},
  /** 元数据字典管理 */
  MetaDataPage: {},
  /** 采集任务 */
  CollectTaskPage: {
    SWITCH_MISSION: 'T000018',
    DEL_MISSION: 'T000019',
    COLLECT_SCRIPT: 'T000020',
    ACCOUNT_PERMISSION: 'T000021',
    ALL_MISSION: 'T000022',
    PERIOD: 'T000023',
    COLLECT_STATUS: 'T000024',
    PROCESS_STATUS: 'T000025',
  },
  /** 数据处理逻辑配置 */
  LogicConfigPage: {},
  /** 系统设置 */
  SysSetting: {},
  /** 菜单管理 */
  MenusManage: {
    MENU_TYPE: 'T000012',
    BTN_TYPE: 'T000013',
    ROUTE: 'T000014',
    LINK: 'T000015',
    MENU_STATUS: 'T000017',
  },
  /** 账号管理 */
  AccountManage_Accounts: {
    ACCOUNT_TYPE_NORMAL: 'T000001',
    ACCOUNT_TYPE_TEMP: 'T000002',
    TEMP_ACCOUNT_EXPIRE: 'T000003',
    SEND_EMAIL: 'T000004',
    SEND_MESSAGE: 'T000005',
  },
  /** 分组管理 */
  AccountManage_Groups: {
    DISABLE_GROUP_BTN: 'T000006',
    ENABLE_GROUP_BTN: 'T000007',
    DATA_PERMISSION: 'T000008',
    DEL_GROUP_BTN: 'T000016',
  },
  /** 提示管理 */
  TipsManage: {},
  /** 个人中心 */
  PersonalCenter: {
    GROUP_STATUS: 'T000009',
    ROLE_STATUS: 'T000010',
    DATA_PERMISSION_STATUS: 'T000011',
  },
  /** 数据库表管理
   * DBINSTANCE 数据库实例
   * DBFORM 数据库
   * DBTABLE 数据表
   * DBCODETABLE 代码表
   */
  DBConfig: {
    // 设置数据库实例
    SET_DBINSTANCE: 'T000026',
    // 设置实例中的数据库
    SET_DBFORM_IN_DBINSTANCE: 'T000027',
    // 删除数据库实例
    DELETE_DBINSTANCE: 'T000028',
    // 数据库连接状态
    DBFORM_CONNECT_STATUS: 'T000029',
    // 选择数据表
    SELECT_DBTABLE: 'T000030',
    // 设置数据表
    SET_DBTABLE: 'T000031',
    // 删除数据表
    DELETE_DBTABLE: 'T000032',
    // 数据表字段设置
    DBTABLE_SET_FIELD: 'T000033',
    // 数据表逻辑外键设置
    DBTABLE_SET_LOGIC_OUT_KEY: 'T000034',
    // 数据表字段名
    DBTABLE_FIELD_NAME: 'T000035',
    // 数据表字段注释
    DBTABLE_FIELD_COMMENT: 'T000036',
    // 数据表字段显示名
    DBTABLE_FIELD_SHOW_NAME: 'T000037',
    // 数据表字段提示
    DBTABLE_FIELD_TIP: 'T000038',
    // 数据表字段类型
    DBTABLE_FIELD_TYPE: 'T000039',
    // 数据表系统主键
    DBTABLE_SYS_MAIN_KEY: 'T000040',
    // 数据表是否可空
    DBTABLE_CAN_EMPTY: 'T000041',
    // 数据表索引
    DBTABLE_INDEX: 'T000042',
    // 数据表逻辑主键
    DBTABLE_LOGIC_MAIN_KEY: 'T000043',
    // 数据表类型
    DBTABLE_TYPE: 'T000044',
    // 代码表父子级
    DBCODETABLE_FATHER_SON_LEVEL: 'T000045',
    // 代码表代码键
    DBCODETABLE_CODE_KEY: 'T000046',
    // 代码表名称键
    DBCODETABLE_NAME_KEY: 'T000047',
    // 代码表注解
    DBCODETABLE_COMMENT: 'T000048',
    // 选择数据库
    SELECT_DBBASE: 'T000049',
  },
  /** 计量单位管理
   */
  MeasureUnitConfig: {
    // 基本单位
    BASE_UNIT: 'T000052',
    // 等数换算量
    CONVERSION_NUMBER: 'T000053',
  },
  /** 计算特征管理
   */
  CalcFeatureConfig: {
    // 删除计算特征
    DELETE_CALC_FEATURE: 'T000054',
  },
  /** 宏观分组管理
   */
  MacroGroup: {
    // 删除宏观分组
    DELETE_MACRO_GROUP: 'T000055',
  },

  /** 宏观指标管理
   */
  MacroIndex: {
    // 删除宏观指标
    DELETE_INDEX: 'T000056',
  },
  /** 微观指标查询
   */
  MicroDataQuery: {
    // 筛选组
    FILTER_GROUP: 'T000057',
    // 标签
    TAG: 'T000058',
    // 显示字段
    SHOW_COLUMN: 'T000059',
    // 数据导出
    EXPORT: 'T000060',
    // 筛选组公开类型
    FILTER_GROUP_PUBLIC: 'T000061',
    // 动态标签公开类型
    DYNAMIC_TAG_PUBLIC: 'T000062',
  },
};
