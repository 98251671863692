import {useAtom} from 'jotai';
import {useMemo, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '..';
import {filterAtom} from '../../../../components/Filter/atoms/filterAtom';
import {makeFilterCondition} from '../../../../components/TableSheetCopy/helper';

export default function useRequestFilters() {
  const {columnList} = useContext(MicroDataQueryContext);

  const [searchParams] = useSearchParams();

  const [filterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  const storeColumnWidthKey = useMemo(() => {
    return `dataQueryTable${searchParams.get('wideId')}`;
  }, [searchParams]);

  const getRequestFilters = () => {
    const queryFilterConditions = conditions[storeColumnWidthKey] || {};

    const sectionFilters = [];
    Object.keys(queryFilterConditions).forEach((key) => {
      const column = columnList.find((col) => col.indexAlias === key);
      if (column?.sectionId) {
        const valueList = queryFilterConditions[key].valueList;
        if (valueList) {
          valueList.forEach((item) => {
            let splitItem = item;
            if (typeof splitItem === 'string') {
              splitItem = decodeURIComponent(item).split('_');
              if (splitItem.length < 2) return;
              splitItem = splitItem.slice(0, splitItem.length - 1);
              splitItem = JSON.parse(splitItem.join('_'));
            }
            sectionFilters.push({
              column: key,
              left: splitItem.left,
              right: splitItem.right,
              symbol: splitItem.symbol,
              label: splitItem.label,
            });
          });
        }
      }
    });

    const filterCondition = makeFilterCondition(
      columnList,
      queryFilterConditions
    );

    const queryFilterCals = filterCondition
      .map((item) => {
        const column = columnList.find(
          (col) => col.indexAlias === item.aliasName
        );
        if (column?.queryCalId) {
          return {
            queryCalId: column.queryCalId,
          };
        }
        return false;
      })
      .filter(Boolean);

    const staticFilters = filterCondition
      .map((item) => {
        const column = columnList.find(
          (col) => `zhtj_coding_${col.indexAlias}` === item.aliasName
        );
        return column?.mosaicType
          ? {
              column: column.indexAlias,
              mosaicType: column.mosaicType,
            }
          : false;
      })
      .filter(Boolean);

    return {queryFilterCals, staticFilters, sectionFilters, filterCondition};
  };

  return [getRequestFilters];
}
