import {useAtom} from 'jotai';
import {useCallback, useMemo} from 'react';

import {filterAtom} from '../../../../../components/Filter/atoms/filterAtom';
import {specialSplitSymbol} from '../helpers/constants';

// 此序列化钩子适配宽表中重复字段名的变异行为
// 如name字段在多表都包含此字段时变异为name_1和name_2，原name消失，造成筛选器查找不到
export default function useSymbolSerialFilter() {
  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {tableKey, conditions} = filterParams;

  const filterConditions = useMemo(() => {
    return conditions[tableKey?.split('__')[0] ?? ''] || {};
  }, [conditions, tableKey]);

  const serialfilterConditions = useMemo(() => {
    return Object.keys(filterConditions)
      .map((key) => {
        const realAliasName = key.split(specialSplitSymbol)[0];
        // eslint-disable-next-line prettier/prettier
        const curCondition = Object.keys(filterConditions[key]).length > 0
            ? {aliasName: realAliasName, ...filterConditions[key]}
            : {};

        if (curCondition?.valueList) {
          curCondition.valueList = curCondition.valueList.map((v) =>
            encodeURIComponent(v)
          );
        }

        const {aliasName, ...restCondition} = curCondition;

        return Object.keys(restCondition).length > 0
          ? {aliasName, ...restCondition}
          : false;
      })
      .filter(Boolean);
  }, [filterConditions]);

  /**
   * 兼容多表中重复字段名分叉在筛选器中识别不到的情况
   *
   * (一个表时字段叫name,两个表都有时就会name消失，裂变成name_1,name_2)
   *
   * @param {Array} newColumns - 新的宽表列数组
   * @returns {void}
   */
  const updateFilter = useCallback(
    (newColumns) => {
      const curFilterColKeys = Object.keys(filterConditions);

      // 根据唯一字段名组合，右侧为（普通字段是id,计算字段是columnAlias，新计算字段没有calId）
      // 找出右侧相同而左侧不相同的筛选器字段，将左侧替换成新宽表中的字段
      const needUpdateFilterKeys = curFilterColKeys.filter((colKey) => {
        const [_columnAlias, rightSymbol] = colKey.split(specialSplitSymbol);

        const sameRightSymbolInCols = newColumns.find((col) => {
          const {columnAlias, id} = col;
          return rightSymbol === String(id) || rightSymbol === columnAlias;
        });

        return sameRightSymbolInCols?.columnAlias !== _columnAlias;
      });

      if (needUpdateFilterKeys.length > 0) {
        const newFilterCondition = needUpdateFilterKeys.reduce((pre, key) => {
          const [, rightSymbol] = key.split(specialSplitSymbol);
          const curCol = newColumns.find(
            (col) =>
              String(col.id) === rightSymbol || col.columnAlias === rightSymbol
          );
          if (curCol) {
            const colFStatus = filterConditions[key];
            const newKey = `${curCol.columnAlias}${specialSplitSymbol}${
              curCol.id ?? curCol.columnAlias
            }`;
            pre[newKey] = colFStatus;
          }
          return pre;
        }, {});

        const otherOldFilterConditon = Object.keys(filterConditions).reduce(
          (pre, key) => {
            if (!needUpdateFilterKeys.includes(key)) {
              pre[key] = filterConditions[key];
            }
            return pre;
          },
          {}
        );

        setFilterParams((pre) => ({
          ...pre,
          conditions: {
            [tableKey]: {...newFilterCondition, ...otherOldFilterConditon},
          },
        }));
      }
    },
    [filterConditions, setFilterParams, tableKey]
  );

  return {serialfilterConditions, updateFilter};
}
