import {css} from '@emotion/react';
import React from 'react';

export default function TooltipTitle(props) {
  return (
    <div
      css={css`
        line-height: 22px;
        white-space: nowrap;
      `}
    >
      {Object.keys(props).map((key) => {
        return <p key={key}>{props[key]}</p>;
      })}
    </div>
  );
}
