import {MenuOutlined} from '@ant-design/icons';
import {
  restrictToVerticalAxis,
  restrictToHorizontalAxis,
} from '@dnd-kit/modifiers';
import {
  arrayMove,
  useSortable,
  verticalListSortingStrategy,
  horizontalListSortingStrategy,
} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import React from 'react';

import {deepClone} from '../../helpers/utils';

const onDragEndForData = ({active, over, data, setData}) => {
  if (active.id !== over?.id) {
    const activeIndex = data?.findIndex((i) => i.sortKeys === active.id);
    const overIndex = data?.findIndex((i) => i.sortKeys === over?.id);
    const newData = arrayMove([...data], activeIndex, overIndex).map(
      (p, idx) => ({...p, indexRank: idx})
    );
    setData?.(newData);
    return data;
  }
  return data;
};

export const getDragSortConfigY = (data, setData, sortKey = 'sortKeys') => {
  return {
    items: data.map((record) => record[sortKey]),
    modifiers: [restrictToVerticalAxis],
    strategy: verticalListSortingStrategy,
    onDragEnd: (props) => onDragEndForData({...props, data, setData}),
  };
};

export const onHeaderDragEndForColumns = (
  event,
  {columns = [], setColumns, sortKey = 'sortKeys'} = {}
) => {
  const {newColumns = []} = event;
  const offsetLeft =
    event.collisions[0]?.data?.droppableContainer?.node?.current?.offsetLeft;

  const cloneColumns = deepClone(columns);

  cloneColumns.forEach((p, pIdx) => {
    const index = newColumns.findIndex((n) => n.sortKeys === p.sortKeys);
    if (index >= 0) {
      newColumns.splice(index, 1, p);
    } else {
      newColumns.splice(pIdx, 0, p);
    }
  });
  const formatNewColumns = newColumns.map((n, nIdx) => ({
    ...n,
    indexRank: nIdx,
  }));
  setColumns?.(formatNewColumns);
  return {offsetLeft, columns: formatNewColumns};
};

export const getDragSortConfigX = () => {
  return {
    modifiers: [restrictToHorizontalAxis],
    strategy: horizontalListSortingStrategy,
  };
};

export const getDragSortConfig = () => {
  return {
    headerDraggable: true,
    dragSortConfig: getDragSortConfigX(),
  };
};

export const TableComponentsBodyRow = ({children, ...props}) => {
  const {
    attributes,
    listeners,
    setNodeRef,
    setActivatorNodeRef,
    transform,
    transition,
    isDragging,
  } = useSortable({
    id: props['data-row-key'],
  });
  const style = {
    ...props.style,
    transform: CSS.Transform.toString(
      transform && {
        ...transform,
        scaleY: 1,
      }
    ),
    transition,
    ...(isDragging
      ? {
          position: 'relative',
          zIndex: 9999,
        }
      : {}),
  };
  return (
    <tr {...props} ref={setNodeRef} style={style} {...attributes}>
      {React.Children.map(children, (child) => {
        if (child.key === 'sort') {
          return React.cloneElement(child, {
            children: (
              <MenuOutlined
                ref={setActivatorNodeRef}
                style={{
                  touchAction: 'none',
                  cursor: 'move',
                }}
                {...listeners}
              />
            ),
          });
        }
        return child;
      })}
    </tr>
  );
};
