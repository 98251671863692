import {useAtom} from 'jotai';
import {useMemo, useCallback} from 'react';

import {getFilterRecord} from '../../../../../api/dataManagement/microIndex';
import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import {filterAtom} from '../../../../../components/Filter/atoms/filterAtom';
import useSafeState from '../../../../../hooks/useSafeState';
import {specialSplitSymbol} from '../helpers/constants';
import useSymbolSerialFilter from './useSymbolSerialFilter';

export default function useFilterDescription(otherTableColumns) {
  const [filterParams] = useAtom(filterAtom);
  const {tableKey, conditions} = filterParams;

  const [totalStatus] = useAtom(logicEditAtom);
  const {previewXOriginColumns} = totalStatus;

  const [filterRecord, setFilterRecord] = useSafeState([]);

  const {serialfilterConditions} = useSymbolSerialFilter();

  const filterCols = useMemo(() => {
    const filterObject = conditions[tableKey] ?? {};
    const hasConditionFilter = Object.keys(filterObject).filter((colKey) => {
      const filterContent = filterObject[colKey];
      const {orderType, aliasName, ...realFilters} = filterContent;
      return Object.keys(realFilters).length > 0;
    });

    const resultData = hasConditionFilter.map((key) => {
      const cols =
        Array.isArray(otherTableColumns) && otherTableColumns.length > 0
          ? otherTableColumns
          : previewXOriginColumns;

      const colInfo = cols
        .filter((col) => col.delFlag !== 1)
        .find(
          (col) =>
            col.aliasName === key ||
            (col.aliasName || col.dataIndex).toUpperCase() === key
        );
      return colInfo;
    });

    return resultData;
  }, [conditions, otherTableColumns, previewXOriginColumns, tableKey]);

  const reqCondition = useMemo(() => {
    return filterCols.map((item) => {
      const ret = {
        colId: item?.id ?? item?.colId,
        aliasName: item?.originColumnAlias ?? item?.indexOriName,
        curShowName: item?.curShowName,
        indexId: item?.indexId ?? null,
        extendId: String(
          item?.id ?? item?.originColumnAlias ?? item?.indexOriName
        ),
      };
      if (item?.formulas) {
        ret.formula = JSON.parse(item?.formulas).oriFormula;
      }
      return ret;
    });
  }, [filterCols]);

  const getFilterRecordDes = useCallback(async () => {
    const res = await getFilterRecord(
      {'filter-condition': JSON.stringify(serialfilterConditions)},
      {queryList: reqCondition}
    );

    if (res?.code === 200) {
      const recordData = res.data ?? [];

      const result = recordData.map((rec) => {
        const {aliasName, extendId} = rec;
        const filterItem = filterCols.find(
          (item) =>
            item?.aliasName === aliasName.toUpperCase() ||
            item?.aliasName === `${aliasName}${specialSplitSymbol}${extendId}`
        );
        return {...rec, ...filterItem};
      });

      setFilterRecord(result);
      return result;
    }
    return [];
  }, [filterCols, reqCondition, serialfilterConditions, setFilterRecord]);

  return {filterRecord, getFilterRecordDes};
}
