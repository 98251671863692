/* eslint-disable no-unused-vars */
import {SearchOutlined} from '@ant-design/icons';
import {css} from '@emotion/react';
import {Checkbox, Input, Select, Divider, Button, Space} from 'antd';
import React, {useContext, useEffect} from 'react';

import {MicroDataQueryContext} from '../..';
import {configShow} from '../../../../../api/dataQuery/microDataQuery';
import IconFont from '../../../../../components/IconFont';
import {overflowScrollBar} from '../../../../../global.css';
import useSafeState from '../../../../../hooks/useSafeState';
import {HIDDEN_FLAG, SHOW_FLAG} from '../../helpers/constant';

const DisplayFieldBtn = () => {
  const {columnList, updateTable, columns, setColumns} = useContext(
    MicroDataQueryContext
  );

  const defaultOptions = [...columnList];
  const defaultCheckedList = columnList.map((col) => {
    return col.indexOriName;
  });

  const [options, setOptions] = useSafeState(defaultOptions);
  const [checkedList, setCheckedList] = useSafeState(defaultCheckedList);
  const [displayFieldOpen, setDisplayFieldOpen] = useSafeState(false);
  const [checkAll, setCheckAll] = useSafeState(false);
  const [indeterminate, setIndeterminate] = useSafeState(
    !!defaultCheckedList.length &&
      defaultCheckedList.length < defaultOptions.length
  );
  const [showError, setShowError] = useSafeState(false);
  const [searchWords, setSearchWords] = useSafeState('');

  useEffect(() => {
    const _options = [...columnList].map((col) => {
      if (!col.comment) col.comment = col.indexOriName;
      if (!col.curShowName) col.curShowName = col.indexOriName;

      return col;
    });
    const _checkedList = columns
      .filter((col) => col.showFlag !== false)
      .map((col) => {
        return col.indexOriName;
      });
    setOptions(_options);
    setCheckedList(_checkedList);
    setCheckAll(_options.length === _checkedList.length);
  }, [columns, columnList, setOptions, setCheckedList, setCheckAll]);

  const onChange = (checkedValues) => {
    setIndeterminate(
      !!checkedValues.length && checkedValues.length < options.length
    );
    setCheckedList(checkedValues);
    setCheckAll(checkedValues.length === options.length);
    setShowError(false);
  };

  const onCheckAllChange = (e) => {
    setCheckedList(e.target.checked ? options.map((i) => i.indexOriName) : []);
    setCheckAll(e.target.checked);
    setIndeterminate(false);
  };

  // eslint-disable-next-line no-unused-vars
  const configShowHandler = async () => {
    const payload = options.map((col) => {
      const base = {
        indexClassify: col.indexClassify,
        indexId: col.indexId,
        pathId: col.pathId,
        queryCalId: col.queryCalId,
        wideId: col.wideId,
        tecAlias: col.tecAlias,
      };
      if (checkedList.includes(col.indexOriName)) {
        base.showFlag = SHOW_FLAG;
      } else {
        base.showFlag = HIDDEN_FLAG;
      }
      return base;
    });
    const res = await configShow(payload);
    return res;
  };

  const onSubmitHandle = () => {
    if (checkedList.length === 0) {
      setShowError(true);
    } else {
      const showCols = columns.map((col) => {
        col.showFlag = checkedList.includes(col.indexOriName);

        return col;
      });

      setColumns(showCols);
      setSearchWords('');
      setDisplayFieldOpen(false);

      // await configShowHandler();
      // queryColumns();
    }
  };

  const onCancelHandle = () => {
    setShowError(false);
    setDisplayFieldOpen(false);
    setSearchWords('');
    setIndeterminate(
      !!defaultCheckedList.length && defaultCheckedList.length < options.length
    );
    setCheckedList(defaultCheckedList);
    setCheckAll(defaultCheckedList.length === options.length);
  };

  return (
    <Select
      bordered={false}
      className="ant-select-suffix"
      css={css`
        .ant-select-arrow .anticon:not(.ant-select-suffix) {
          pointer-events: none;
        }
      `}
      dropdownMatchSelectWidth={false}
      dropdownRender={(menu) => {
        return (
          <div
            css={css`
              padding: 2px 8px;
            `}
            onMouseDown={(e) => {
              e.preventDefault();
              e.stopPropagation();
            }}
          >
            <Input
              allowClear
              placeholder="检索字段"
              size="small"
              style={{minWidth: 185}}
              suffix={
                <SearchOutlined style={{color: 'rgba(26, 34, 48, 0.25)'}} />
              }
              value={searchWords}
              onChange={(e) => setSearchWords(e.target.value)}
            />
            <div
              css={css`
                max-height: 400px;
                overflow-y: auto;
                ${overflowScrollBar};
              `}
            >
              <div style={{marginTop: 6}}>
                <Checkbox
                  checked={checkAll}
                  indeterminate={indeterminate}
                  onChange={onCheckAllChange}
                >
                  显示全部字段
                </Checkbox>
              </div>
              <Checkbox.Group value={checkedList} onChange={onChange}>
                {options.map((i) => {
                  const curShowName = i.curShowName ?? i.indexOriName;
                  return (
                    <div
                      css={css`
                        padding-right: 8px;
                        .ant-checkbox + span {
                          padding-right: 0;
                        }
                        display: ${curShowName?.includes(searchWords)
                          ? 'block'
                          : 'none'};
                      `}
                      key={i.indexOriName}
                    >
                      <Checkbox value={i.indexOriName}>{curShowName}</Checkbox>
                    </div>
                  );
                })}
              </Checkbox.Group>
            </div>
            {showError ? (
              <p style={{color: '#E34D59', fontSize: 12}}>请至少选择1个字段</p>
            ) : null}
            <Divider style={{margin: '7px 0'}} />
            <div
              css={css`
                display: flex;
                justify-content: end;
              `}
            >
              <Space>
                <Button size="small" type="default" onClick={onCancelHandle}>
                  取消
                </Button>
                <Button size="small" type="primary" onClick={onSubmitHandle}>
                  确定
                </Button>
              </Space>
            </div>
          </div>
        );
      }}
      open={displayFieldOpen}
      placement="bottomRight"
      size="small"
      suffixIcon={
        <IconFont
          style={{fontSize: 14, color: 'rgba(37, 49, 69, 0.8)'}}
          type="icon-arrow-down"
        />
      }
      value={`显示字段(${checkedList.length}/${options.length})`}
      onDropdownVisibleChange={(visible) => {
        setDisplayFieldOpen(visible);
        if (!visible) {
          onCancelHandle();
        }
      }}
    />
  );
};

export default DisplayFieldBtn;
