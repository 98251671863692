import {css} from '@emotion/react';
import React from 'react';

import {STATUS_MAP} from '.';

/**
 * @param {object} props
 * @param {import('.').Status} props.status
 * @param {number=} props.size
 * @param {{[key: string]: any;}=} props.data
 * @param {function=} props.onClick
 * @param {import('@emotion/react').SerializedStyles=} props.css
 */
const StatusIcon = ({
  status,
  size = 26,
  data,
  onClick,
  css: Styles,
  ...rest
}) => {
  const mappedStatus = STATUS_MAP[status];

  const Icon = mappedStatus?.icon;

  function handleClick() {
    if (onClick) onClick(data);
  }

  return Icon ? (
    <Icon
      css={css`
        cursor: ${onClick ? 'pointer' : 'default'};
        color: ${mappedStatus?.iconColor};
        ${Styles}
      `}
      style={{fontSize: '16px'}}
      type={mappedStatus?.iconType}
      onClick={handleClick}
      {...rest}
    />
  ) : null;
};

export default StatusIcon;
