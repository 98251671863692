import {Graph} from '@antv/x6';
import {css} from '@emotion/react';
import {Spin} from 'antd';
import React, {useCallback, useContext, useEffect, useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';
import useSWR from 'swr';

import {MicroDataQueryContext} from '../..';
import {
  QUERY_DOWN_BLOOD_STREAM,
  QUERY_UP_BLOOD_STREAM,
} from '../../../../../api/dataManagement/wideTable';
import {overflowScrollBar} from '../../../../../global.css';
import useSafeState from '../../../../../hooks/useSafeState';
import {generateIcon} from '../MicroDataQueryMenu/MenuTree';

const totalHeight = document.documentElement.clientHeight;
const otherElement = document.getElementsByClassName('pageActionBar')?.[0];
const otherHeight = (otherElement?.clientHeight || 106) * 2.6;

const canvasWidth = otherElement?.clientWidth || 1440;
const canvasHeight = totalHeight - otherHeight;

const nodeWidth = 200;
const nodeHeight = 60;
const nodeGapX = 20;
const nodeGapY = 10;
const centerNodeX = (canvasWidth - nodeWidth) / 2;

const canvasMiniMap = css`
  width: 180px;
  height: 110px;
  background: #ffffff;
  border: 1px solid rgba(223, 223, 223, 0.5);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

const BloodNode = ({info, style}) => {
  const fixedInfo = {...info, tableType: info.type ?? info.tableType};
  return (
    <div
      style={{
        maxWidth: nodeWidth,
        height: nodeHeight,
        border: '1px solid #ccc',
        padding: '2px 6px',
        display: 'flex',
        ...style,
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '22px',
          marginRight: '4px',
        }}
      >
        {generateIcon(fixedInfo)}
      </div>
      <div
        css={overflowScrollBar}
        style={{
          display: 'flex',
          flex: 1,
          flexDirection: 'column',
          overflowY: 'auto',
          justifyContent: 'space-between',
        }}
      >
        <div
          css={overflowScrollBar}
          style={{
            fontSize: 14,
            wordBreak: 'break-word',
          }}
        >
          {info?.tableName || info?.tableShowName || info?.tableNameEn || '--'}
        </div>
        <div style={{fontSize: 12}}>
          创建人：{info?.realName || info?.tableCreator || '-'}
        </div>
      </div>
    </div>
  );
};

const addNodes = ({
  instance,
  dataList,
  centerNode,
  direction,
  firstNodeStartY,
}) => {
  const isUpStream = direction === 'left';

  dataList.forEach((infoItem, index) => {
    const curNode = instance.addNode({
      x: isUpStream
        ? centerNodeX - nodeWidth * 1.5 - nodeGapX
        : centerNodeX + nodeWidth * 1.5 + nodeGapX,
      y: (firstNodeStartY + nodeGapY) * (1 + index),
      width: nodeWidth,
      height: nodeHeight,
      shape: 'react-shape',
      data: infoItem,
      component: (node) => <BloodNode info={infoItem} />,
    });

    const sourceNode = isUpStream ? curNode : centerNode;
    const targetNode = isUpStream ? centerNode : curNode;

    instance.addEdge({
      source: {
        cell: sourceNode,
        anchor: 'right',
      },
      target: {
        cell: targetNode,
        anchor: 'left',
      },

      router: {
        name: 'manhattan',
        args: {
          padding: nodeGapX,
          startDirections: ['right', 'center'],
          endDirections: ['left', 'center'],
        },
      },
      connector: {name: 'rounded'},
      attrs: {
        line: {
          fill: 'none',
          strokeLinejoin: 'round',
          strokeWidth: 2,
          // stroke: '#A2B1C3',
          targetMarker: 'classic',
          stroke: '#1890ff',
          strokeDasharray: 5,
          style: {
            animation: 'ant-line 30s infinite linear',
          },
        },
      },
    });
  });
};

const BloodRelationship = () => {
  const [searchParams] = useSearchParams();
  const [canvasLoading, setcanvasLoading] = useSafeState(false);
  const [graphInstance, setGraphInstance] = useSafeState(null);

  const {wideOverview} = useContext(MicroDataQueryContext);

  const wideId = searchParams.get('wideId');

  const {data: upBloodRes, isValidating: upBloodLoading} = useSWR(
    wideId ? `${QUERY_UP_BLOOD_STREAM}?pathId=${wideId}` : null
  );
  const {data: downBloodRes, isValidating: downBloodLoading} = useSWR(
    wideId ? `${QUERY_DOWN_BLOOD_STREAM}?pathId=${wideId}` : null
  );

  const canvasData = useMemo(() => {
    return {
      upStream: upBloodRes?.data?.sourceTables ?? [],
      centerNode: wideOverview,
      downStream: downBloodRes?.data?.sourceTables ?? [],
    };
  }, [
    downBloodRes?.data?.sourceTables,
    upBloodRes?.data?.sourceTables,
    wideOverview,
  ]);

  const initGraphInstance = useCallback(() => {
    const canvasBoxElement = document.getElementById('bloodCanvasBox');
    const minimapContainer = document.getElementById('canvasMiniMap');

    const graph = new Graph({
      container: canvasBoxElement,
      height: canvasHeight,
      grid: false,
      autoResize: true,
      minimap: {
        enabled: true,
        scalable: false,
        width: 180,
        height: 110,
        container: minimapContainer,
      },
      scroller: {enabled: true, pannable: false, padding: 8},
      mousewheel: {enabled: false, modifiers: ['ctrl', 'meta']},
      // eslint-disable-next-line prettier/prettier
      interacting: { nodeMovable: false, edgeMovable: false, edgeLabelMovable: false },
    });
    setGraphInstance(graph);
  }, [setGraphInstance]);

  useEffect(() => {
    // 创建一个画布实例
    initGraphInstance();
  }, [initGraphInstance]);

  useEffect(() => {
    if (wideId && graphInstance && canvasData.centerNode) {
      const cells = graphInstance.getCells();
      graphInstance.removeCells(cells);
      graphInstance.clearCells();

      setcanvasLoading(true);

      const maxLen = Math.max(
        canvasData.upStream.length,
        canvasData.downStream.length
      );

      const centerNodeY = Math.ceil(maxLen / 2) * (nodeHeight + nodeGapY);

      const diffLen = canvasData.upStream.length - canvasData.downStream.length;

      const firstNodeStartY =
        Math.floor(Math.abs(diffLen) / 2) * (nodeHeight + nodeGapY) +
        nodeHeight;

      const centerNode = graphInstance.addNode({
        x: centerNodeX,
        y: centerNodeY,
        width: nodeWidth,
        height: nodeHeight,
        shape: 'react-shape',
        data: canvasData.centerNode,
        component: (node) => (
          <BloodNode
            info={canvasData.centerNode}
            style={{
              height: nodeHeight,
              padding: '8px 6px',
              border: '1px solid #1890ff',
            }}
          />
        ),
      });

      if (canvasData.upStream.length > 0) {
        addNodes({
          instance: graphInstance,
          dataList: canvasData.upStream,
          centerNode,
          direction: 'left',
          firstNodeStartY:
            canvasData.upStream.length > canvasData.downStream.length
              ? nodeHeight
              : firstNodeStartY,
        });
      }

      if (canvasData.downStream.length > 0) {
        addNodes({
          instance: graphInstance,
          dataList: canvasData.downStream,
          centerNode,
          direction: 'right',
          firstNodeStartY:
            canvasData.upStream.length > canvasData.downStream.length
              ? firstNodeStartY
              : nodeHeight,
        });
      }

      graphInstance.centerContent();
      setcanvasLoading(false);
    }
  }, [
    canvasData.centerNode,
    canvasData.downStream,
    canvasData.upStream,
    graphInstance,
    setcanvasLoading,
    wideId,
  ]);

  return (
    <Spin spinning={upBloodLoading || downBloodLoading || canvasLoading}>
      <div id="bloodCanvasBox" />
      {wideId ? <div css={canvasMiniMap} id="canvasMiniMap" /> : null}
    </Spin>
  );
};

export default BloodRelationship;
