import {useAtom} from 'jotai';

import {pagePermsAtom} from '../atoms/pagePermsAtom';

export default function useHasFormPerm(permCode) {
  const [pagesPermAtom] = useAtom(pagePermsAtom);
  const {takePerm, permForms = []} = pagesPermAtom;

  let hasAuth = false;

  // 是否获取按钮/表单权限 1：获取，2：不获取
  if (takePerm === 1) {
    if (Array.isArray(permCode)) {
      hasAuth = permForms.some((perm) => permCode.includes(perm.code));
    } else if (typeof permCode === 'string') {
      hasAuth = permForms.some((perm) => perm.code === permCode);
    } else if (!permCode) {
      hasAuth = true;
    }
  } else if (takePerm === undefined) {
    hasAuth = false;
  } else {
    hasAuth = true;
  }

  return hasAuth;
}
