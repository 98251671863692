/* eslint-disable react/jsx-filename-extension */
import {BsArrowDown, BsArrowUp} from 'react-icons/bs';

import {REG_CN_WORD} from '../../constants/regExp';
import {ADMINCODE, BUTTON_PERMS_MAP} from '../../constants/system';
import {deepGet} from '../../helpers/utils';
import {AuthButton} from '../AuthButton';
import FilterColumn from './components/FilterColumn';

/**  @param  {import('./type').CustomColumnType[]} colArr */
export const makeColumnTitle = (colArr, mutate, storeColumnWidthKey) => {
  const hasFilter = colArr.some((c) => c.filterType);
  return colArr.map((col) => {
    const {filterType = '', dataIndex, key} = col;

    return {
      ...col,
      title: filterType ? (
        <FilterColumn
          column={{onFilterSearch: mutate, ...col}}
          key={dataIndex ?? key}
          tableKey={storeColumnWidthKey}
        />
      ) : hasFilter ? (
        () => <span style={{display: 'block', marginTop: 14}}>{col.title}</span>
      ) : (
        col.title
      ),
    };
  });
};

/**  @param  {import('./type').CustomColumnType[]} colArr */
export const calcColumns = (colArr) => {
  return colArr.map((col) => {
    const {align = 'left'} = col;

    return {
      ellipsis: true,
      sorter: false,
      sortOrder: false,
      showSorterTooltip: false,
      ...col,
      align,
    };
  });
};

const computeSortIcon = ({
  reorderVal,
  dataSource,
  reorderKey,
  reorderBtnPermText,
  mutate,
  reorderCallBack,
  row,
}) => {
  let SortIcon = null;
  const colStyle = {
    display: 'flex',
    justifyContent: 'space-around',
  };
  const placeholderBox = {
    flex: 1,
    display: 'flex',
    justifyContent: 'center',
  };

  // 适配(微观指标管理-数据表逻辑编辑-纵向预览表)
  const isShowList = dataSource.filter((d) => d?.isShow);
  const isShowListLen = isShowList.length;
  const curRowIsShowIndex = isShowList.findIndex((d) => d.id === row.id);

  const findPosition = (list = [], value) => {
    /**
     * reorderCallBack({
     *  direction; 方向，1：向上，2：向下
     *  item; 当前行数据
     * })
     **/

    for (let index = 0; index < list.length; index++) {
      const item = list[index];
      const children = item.children ?? [];
      const reorderKeyVal = item[reorderKey];
      const curLen = list.length;
      const hasCurValInList = list.some((v) => v[reorderKey] === value);

      if (hasCurValInList) {
        if (reorderKeyVal === value) {
          SortIcon =
            curLen > 1 ? (
              index === 0 || curRowIsShowIndex === 0 ? (
                <div style={colStyle}>
                  <div style={placeholderBox}>
                    <AuthButton
                      icon={<BsArrowDown />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size="small"
                      onClick={() => reorderCallBack({direction: 2, item})}
                    />
                  </div>
                  <div style={placeholderBox} />
                </div>
              ) : index === curLen - 1 ||
                (isShowListLen > 0 &&
                  curRowIsShowIndex === isShowListLen - 1) ? (
                <div style={colStyle}>
                  <div style={placeholderBox} />
                  <div style={placeholderBox}>
                    <AuthButton
                      ghost
                      icon={<BsArrowUp />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size="small"
                      type="primary"
                      onClick={() => reorderCallBack({direction: 1, item})}
                    />
                  </div>
                </div>
              ) : (
                <div style={colStyle}>
                  <div style={placeholderBox}>
                    <AuthButton
                      icon={<BsArrowDown />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size="small"
                      onClick={() => reorderCallBack({direction: 2, item})}
                    />
                  </div>
                  <div style={placeholderBox}>
                    <AuthButton
                      ghost
                      icon={<BsArrowUp />}
                      permCode={BUTTON_PERMS_MAP.get(reorderBtnPermText)}
                      size="small"
                      type="primary"
                      onClick={() => reorderCallBack({direction: 1, item})}
                    />
                  </div>
                </div>
              )
            ) : null;

          break;
        }
      } else if (children.length > 0) {
        findPosition(children, value);
      }
    }
  };
  if (reorderVal === ADMINCODE) {
    SortIcon = null;
  } else {
    findPosition(dataSource, reorderVal);
  }

  return SortIcon;
};

export const reorderColumns = ({
  columns,
  dataSource,
  reorderKey,
  reorderBtnPermText,
  reorderIndex,
  mutate,
  reorderCallBack,
  storeColumnWidthKey,
}) => {
  const calcedColumns = calcColumns(columns, mutate, storeColumnWidthKey);
  const reorderCol = {
    title: '顺序',
    align: 'left',
    dataIndex: reorderKey,
    width: 100,
    render: (reorderVal, row) =>
      computeSortIcon({
        reorderVal,
        dataSource,
        reorderKey,
        reorderBtnPermText,
        mutate,
        reorderCallBack,
        row,
      }),
  };

  return [
    ...calcedColumns.slice(0, reorderIndex),
    reorderCol,
    ...calcedColumns.slice(reorderIndex),
  ];
};

export const handleResize = (callback) => {
  const paddingValue = 16 * 2;

  const headRect = document.querySelector('.ant-layout-header');
  const pageTop = document.querySelector('.tablePageTopSection');
  const barRect = document.querySelector('.pageActionBar');
  const theadRect = document.querySelector('.ant-table-thead');
  const pagiRect = document.querySelector('.ant-pagination');
  const footerRect = document.querySelector('.system-footer');

  const {height: pageHeaderHeight} = headRect?.getBoundingClientRect() ?? {};
  const {height: pageTopHeight} = pageTop?.getBoundingClientRect() ?? {};
  const {height: actionBarHeight} = barRect?.getBoundingClientRect() ?? {};
  const {height: theadHeight} = theadRect?.getBoundingClientRect() ?? {};
  const {height: paginationHeight} = pagiRect?.getBoundingClientRect() ?? {};
  const {height: footerHeight} = footerRect?.getBoundingClientRect() ?? {};

  const otherHeight =
    (pageHeaderHeight ?? 0) +
    (pageTopHeight ?? 0) +
    (actionBarHeight ? actionBarHeight + 20 : 0) +
    (theadHeight ?? 0) +
    (paginationHeight ? paginationHeight + 32 : 0) +
    (footerHeight ?? 0) +
    paddingValue;

  callback(`calc(100vh - ${otherHeight}px)`);
  // callback((pre) => ({...pre, y: `calc(100vh - ${otherHeight}px)`}));
};

// 列宽度自动补齐计算
export const colWidthFix = ({
  totalCols,
  columnMinWidth,
  dataSource,
  storeColumnWidthKey,
}) => {
  const tableEl = document.querySelector(`.${storeColumnWidthKey}-tablesheet`);

  const tableWidth = tableEl?.clientWidth ?? 0;

  const fixWidthCols = totalCols.filter((col) => col.fixWidth || col.width);

  const fixWidthColsTotalWidth = fixWidthCols.reduce(
    (pre, cur) => pre + (cur.width ?? 0),
    0
  );

  const dynamicTotalWidth =
    tableWidth - fixWidthColsTotalWidth; /*  - columnMinWidth */

  let notFixWidthCols = totalCols.filter((col) => !col.fixWidth && !col.width);

  notFixWidthCols = notFixWidthCols.map((col, colIndex) => {
    const curColValArr = dataSource
      .map((dataRow) => deepGet(dataRow, col.dataIndex ?? '', ''))
      .sort((a, b) => String(a).length - String(b).length);

    const maxLengthVal = String(curColValArr.at(curColValArr.length - 1));
    const maxLenColValLen = maxLengthVal.length;

    const curColMaxStrWidth = REG_CN_WORD.test(maxLengthVal)
      ? maxLenColValLen > 15
        ? maxLenColValLen * 14 > 800
          ? 800
          : maxLenColValLen * 14
        : maxLenColValLen * 28
      : maxLenColValLen > 15
      ? maxLenColValLen * 6 > 600
        ? 600
        : maxLenColValLen * 6
      : maxLenColValLen * 14;

    return {
      ...col,
      width: curColMaxStrWidth,
    };
  });

  const totalMaxStrWidth = notFixWidthCols.reduce(
    (pre, cur) => pre + (cur.width ?? 0),
    0
  );

  notFixWidthCols = notFixWidthCols.map((col) => {
    const widthRatio = col.width / totalMaxStrWidth;
    const width = widthRatio * dynamicTotalWidth;
    const missingWidth =
      columnMinWidth - width > 0 ? columnMinWidth - width : 0;

    return {
      ...col,
      width,
      missingWidth,
      widthRatio,
    };
  });

  let smallThanLimitCols = notFixWidthCols.filter(
    (col) => col.width < columnMinWidth
  );
  let bigThanLimitCols = notFixWidthCols.filter(
    (col) => col.width > columnMinWidth
  );

  const bigColsTotalWidth = bigThanLimitCols.reduce(
    (pre, cur) => pre + (cur.width ?? 0),
    0
  );

  const missingTotalWidth = smallThanLimitCols.reduce(
    (pre, cur) => pre + (cur.missingWidth ?? 0),
    0
  );

  smallThanLimitCols = smallThanLimitCols.map((col) => ({
    ...col,
    width: columnMinWidth,
  }));

  bigThanLimitCols = bigThanLimitCols.map((col) => {
    const curWidth = col.width;
    const newWidth =
      curWidth - (curWidth / bigColsTotalWidth) * missingTotalWidth;

    return {
      ...col,
      width: Math.floor(newWidth),
    };
  });

  const changedCols = [...smallThanLimitCols, ...bigThanLimitCols];

  totalCols = totalCols.map((tcol) => {
    const changedCol = changedCols.find(
      (ccol) => ccol.dataIndex === tcol.dataIndex
    );
    if (changedCol) {
      tcol.width = changedCol.width;
    }
    if (
      typeof tcol.title === 'string' &&
      tcol.width < tcol.title.length * 14 + 20
    ) {
      tcol.width = tcol.title.length * 14 + 20;
    }

    return tcol;
  });

  const totalWidth = totalCols.reduce(
    (prev, cur) => {
      return {width: prev.width + cur.width};
    },
    {width: 0}
  );

  if (totalWidth.width < tableWidth) {
    const perWidth = Math.ceil(dynamicTotalWidth / notFixWidthCols.length);
    let widths = 0;
    totalCols = totalCols.map((tcol) => {
      if (!tcol.fixWidth) {
        let w =
          dynamicTotalWidth - widths < perWidth
            ? dynamicTotalWidth - widths
            : perWidth;
        w = w < 52 ? 52 : w;
        tcol.width = w;
        widths += tcol.width;
      }
      return tcol;
    });
  }

  return totalCols;
};

export function makeAverageWidth(columns, storeColumnWidthKey) {
  const tableEl = document.querySelector(`.${storeColumnWidthKey}-tablesheet`);

  const tableWidth = tableEl?.clientWidth ?? 0;

  const fixWidthCols = columns.filter((col) => col.fixWidth || col.width);

  const fixWidthColsTotalWidth = fixWidthCols.reduce(
    (pre, cur) => pre + (cur.width ?? 0),
    0
  );

  const notFixWidthCols = columns.filter((col) => !col.fixWidth && !col.width);

  const dynamicTotalWidth = tableWidth - fixWidthColsTotalWidth;

  const perWidth = Math.ceil(dynamicTotalWidth / notFixWidthCols.length);

  let widths = 0;

  const finalCols = columns.map((tcol) => {
    if (!tcol.fixWidth) {
      let w =
        dynamicTotalWidth - widths < perWidth
          ? dynamicTotalWidth - widths
          : perWidth;

      if (typeof tcol.title === 'string') {
        w = tcol.title.length * 14 + 20;
      }

      w = w < 52 ? 52 : w;

      widths += perWidth;
      return {
        ...tcol,
        width: w,
      };
    }
    return tcol;
  });

  return finalCols;
}

export const onResizeEnd =
  (tableName, resizableHeader) => (curCol, onResize) => {
    const cols = curCol.resizableColumns;
    const tableNode = document.querySelector(`.${tableName}-tablesheet`);
    const tableClientWidth = tableNode.clientWidth;
    const curWidth = cols.reduce((prev, current) => {
      return prev + current.width;
    }, 0);
    resizableHeader.tableWidth = curWidth;
    tableNode.querySelector(`.${tableName}-tablesheet-content`).style.width =
      resizableHeader.tableWidth < tableClientWidth
        ? `${resizableHeader.tableWidth}px`
        : '100%';
  };
