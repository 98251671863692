import {useMemo, useRef, useEffect} from 'react';
import {useMount} from 'react-use';

import {columnMinWidth} from '..';
import useARH from '../../../helpers/resizableHeaderCopy/index.es';
import useSafeState from '../../../hooks/useSafeState';
import {
  calcColumns,
  colWidthFix,
  makeAverageWidth,
  makeColumnTitle,
  reorderColumns,
  setColumnsOnResizeEnd,
} from '../helper';

export default function useColumns({
  isValidating,
  dataSource,
  columns,
  storeColumnWidthKey,
  reorderKey,
  hasReorderAuth,
  reorderBtnPermText,
  reorderIndex,
  mutate,
  reorderCallBack,
  update,
  fixedTableWidth,
  reorderTitle,
}) {
  const [isMount, setIsMount] = useSafeState(false);

  const [dynamicColumns, setDynamicColumns] = useSafeState(columns);

  // 是否经过resize操作
  // 是则不使用从接口获取的表格宽度样式
  const hasResizeRef = useRef(false);

  const columnRef = useRef();

  useMount(() => {
    setIsMount(true);
  });

  useEffect(() => {
    setDynamicColumns(() => {
      if (reorderKey && hasReorderAuth) {
        return reorderColumns({
          columns,
          dataSource,
          reorderKey,
          reorderBtnPermText,
          reorderIndex,
          mutate,
          reorderCallBack,
          storeColumnWidthKey,
          reorderTitle,
        });
      }
      return columns;
    });
  }, [
    reorderKey,
    hasReorderAuth,
    reorderBtnPermText,
    reorderCallBack,
    reorderIndex,
    mutate,
    storeColumnWidthKey,
    update,
    columns,
    dataSource,
    setDynamicColumns,
    reorderTitle,
  ]);

  // 原型要求：https://axhub.im/ax9/5f662a6ea08fdb13/#id=lhr4w2&p=%E8%A1%A8%E5%8D%95%E9%BB%98%E8%AE%A4%E5%BC%80%E5%8F%91%E5%8E%9F%E5%88%99_v1_0&g=1
  // 所有按钮类的操作column，需要添加fixWidth: true，来单独处理成固定不可拖拽宽度
  const clacColumns = useMemo(() => {
    let widthFixedCols = dynamicColumns;
    if (!isValidating && isMount && dataSource) {
      const mergedColumns = [];
      if (!hasResizeRef.current) {
        dynamicColumns.forEach((item) => {
          mergedColumns.push({
            ...item,
            width: item.width
              ? item.width
              : typeof item.title === 'string'
              ? item.title.length * 18 + 50
              : item.titleWidth,
          });
        });
      }
      const newColumns =
        mergedColumns.length > 0 ? mergedColumns : dynamicColumns;
      if (dataSource.length > 0) {
        widthFixedCols = colWidthFix({
          totalCols: newColumns,
          dataSource,
          storeColumnWidthKey,
          fixedTableWidth,
        });
      } else {
        widthFixedCols = makeAverageWidth(
          newColumns,
          storeColumnWidthKey,
          fixedTableWidth
        );
      }
    }
    return makeColumnTitle(widthFixedCols, mutate, storeColumnWidthKey);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    mutate,
    dynamicColumns,
    dataSource,
    storeColumnWidthKey,
    isValidating,
    isMount,
  ]);

  const resizableHeader = useARH({
    columns: useMemo(() => {
      return calcColumns(clacColumns);
    }, [clacColumns]),

    // 某一列不能拖动，设置该列的最小展示宽度，默认 120
    defaultWidth: 80,

    // 拖动最小宽度 默认 52
    // minConstraints: columnMinWidth,

    // 保存拖拽宽度至本地localStorage
    // columnsState: {
    //   persistenceKey: `_resizeColumn_${storeColumnWidthKey}`,
    //   persistenceType: 'localStorage',
    // },

    setColumnsOnResizeEnd: setColumnsOnResizeEnd(
      storeColumnWidthKey,
      fixedTableWidth,
      columnMinWidth
    ),

    onResizeStart: () => {
      hasResizeRef.current = true;
    },

    onResizeEnd: (prevCol, curCol, onResize, setResizeWidth) => {
      setResizeWidth(
        curCol.resizableColumns.find(
          (col) => col.dataIndex === curCol.dataIndex
        ).width
      );
    },
  });

  const {components, resizableColumns, setResizableColumns} = resizableHeader;

  useEffect(() => {
    if (resizableColumns.length !== 0 && resizableColumns) {
      columnRef.current = resizableColumns;
    }
  }, [resizableColumns]);

  return {
    components,
    finalColumns: resizableColumns,
    resizableColumns,
    columnRef,
    resizableHeader,
    setResizableColumns,
  };
}
