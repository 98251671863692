import {css} from '@emotion/react';

const statusText = (color, text) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        span {
          color: rgba(26, 34, 48, 0.7);
        }
      `}
    >
      <div
        css={css`
          background-color: ${color};
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 7px;
        `}
      />
      <span>{text}</span>
    </div>
  );
};

/** @type {import('../TableSheet/type').CustomColumnType[]} */
export default [
  {
    align: 'left',
    dataIndex: 'id',
    title: '日志id',
    fieldType: 'varchar',
    filterType: 'Common',
    // renderWidth: 'AUTO',
  },
  {
    align: 'left',
    dataIndex: 'createTime',
    title: '操作时间',
    filterType: 'Sort',
  },
  {
    align: 'left',
    dataIndex: 'businessType',
    title: '类型',
    // width: 100,
    filterType: 'Enum',
    enumList: [
      {label: '全部', value: ''},
      {label: '登录', value: 0},
      {label: '退出', value: 3},
      {label: '访问页面', value: 1},
      {label: '使用功能', value: 2},
    ],
    render: (text, row, index) => {
      switch (text) {
        case 0:
          return '登录';
        case 1:
          return '访问页面';
        case 2:
          return '使用功能';
        case 3:
          return '退出';
        default:
          return '';
      }
    },
  },
  {
    align: 'left',
    dataIndex: 'operIp',
    title: '操作IP',
    fieldType: 'varchar',
    filterType: 'Common',
  },
  {
    align: 'left',
    dataIndex: 'operContent',
    title: '操作内容',
    fieldType: 'varchar',
    filterType: 'Common',
  },
  {
    align: 'left',
    dataIndex: 'status',
    title: '结果',
    // width: 100,
    filterType: 'Enum',
    enumList: [
      {label: '成功', value: 0},
      {label: '失败', value: 1},
    ],
    render: (text, row, index) => {
      switch (text) {
        case 0:
          return statusText('#00A870', '成功');
        case 1:
          return statusText('#E34D59', '失败');
        default:
          return '';
      }
    },
  },
  {
    align: 'left',
    dataIndex: 'errorMsg',
    title: '详情',
    ellipsis: false,
    fieldType: 'varchar',
    filterType: 'Common',
  },
];
