import {css} from '@emotion/react';
import styled from '@emotion/styled';

import {theme} from '../constants/theme';

const common = css`
  display: flex;
  gap: 12px;
`;

export const PageActionBar = styled.section`
  ${common}
  margin-bottom: 10px;
  align-items: baseline;
  &:empty {
    margin: 0;
  }
`;

export const PageActionBarLeft = styled.div`
  ${common}
  margin-right: auto;
`;

export const PageActionBarRight = styled.div`
  ${common}
  margin-left: auto;
`;

export const PageActionBarCenter = styled.div`
  ${common}
  margin: 0 auto;
`;

export const Page = styled.div((props) => [
  css`
    /* flex: 1; */
    border-radius: 2px;
    box-shadow: 0 0px 10px ${theme.colors.SHADOW};
    background-color: ${theme.colors.BLOCK};
    padding: 16px;
    max-height: 100%;
    overflow: ${props.autoHeight ? 'auto' : 'hidden'};
    overscroll-behavior: contain;

    ::-webkit-scrollbar {
      width: ${theme.scrollbar.WIDTH}px;
      height: ${theme.scrollbar.WIDTH}px;
    }

    ::-webkit-scrollbar-track {
      margin: 2px 0;
    }

    ::-webkit-scrollbar-thumb {
      background-color: ${theme.scrollbar.COLOR};
      border-radius: 10px;
    }

    ::-webkit-scrollbar-thumb:hover {
      background-color: ${theme.scrollbar.HOVER_COLOR};
    }
  `,
  props.fullHeight &&
    css`
      height: 100%;
    `,
]);
