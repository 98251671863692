import {notification} from 'antd';
import axios from 'axios';
import {useAtom} from 'jotai';
import React, {useCallback, useEffect, useMemo, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';
import {useMount, useUnmount} from 'react-use';
import useSWR from 'swr';

import {
  Wide_Table_Fields,
  wideDataList,
} from '../../../api/dataManagement/wideTable';
import {GET_PERM_STATUS} from '../../../api/system/user';
import {pagePermsAtom} from '../../../atoms/pagePermsAtom';
import {permChangeAtom} from '../../../atoms/permChangeAtom';
import {filterAtom} from '../../../components/Filter/atoms/filterAtom';
import ResizebleFrame from '../../../components/ResizebleFrame';
import {makeFilterCondition} from '../../../components/TableSheetCopy/helper';
import request from '../../../helpers/request';
import {deepClone} from '../../../helpers/utils';
import {usePaginationUrlParams} from '../../../hooks/usePaginationUrlParams';
import useSafeState from '../../../hooks/useSafeState';
import MicroDataQueryInfo from './components/MicroDataQueryInfo';
import MicroDataQueryMenu from './components/MicroDataQueryMenu';

export const MicroDataQueryContext = React.createContext({});

let cancelToken = axios.CancelToken;
let source = cancelToken.source();
let isValidating = false;
let globalFilterConditions = {};

let requestInterceptId;

const validatePerm = () => {
  let timer;
  let hasChange;
  return (code) => {
    if (timer) clearTimeout(timer);
    if (hasChange) return Promise.resolve();
    return new Promise((resolve) => {
      timer = setTimeout(async () => {
        const res = await request.get(`${GET_PERM_STATUS}?type=1&code=${code}`);
        hasChange = res?.data?.hasPerm === 2;
        resolve(res);
      }, 1000);
    });
  };
};

export const secFilterValueMap = new Map([
  ['小于等于', 1],
  ['小于', 2],
  ['大于等于且小于等于', 3],
  ['大于等于且小于', 4],
  ['大于且小于等于', 5],
  ['大于且小于', 6],
  ['大于等于', 7],
  ['大于', 8],
]);

const MicroDataQuery = () => {
  const [dataSource, setDataSource] = useSafeState([]);
  const [total, setTotal] = useSafeState([]);
  const [columns, setColumns] = useSafeState([]);
  const [formatColumns, setFormatColumns] = useSafeState([]);
  const [chooseBatchData, setChooseBatchData] = useSafeState({});
  const [wideOverview, setWideOverview] = useSafeState({});
  const [loading, setLoading] = useSafeState(false);
  const [filterInfo, setFilterInfo] = useSafeState({});
  const [defaultFilterConditions, setDefaultFilterConditions] =
    useSafeState(null);
  const [activeKey, setActiveKey] = useSafeState('1');
  const [defaultId, setDefaultId] = useSafeState(null);
  const [tableName, setTableName] = useSafeState('');
  const [curFilterGroup, setCurFilterGroup] = useSafeState({});

  const isInit = useRef(true);
  const queryMenuRef = useRef();
  const operateDropDownRef = useRef();
  const filterGroupRef = useRef();

  const [pageInfo] = useAtom(pagePermsAtom);
  const {menuCode, pageCode} = pageInfo;

  const [, setIsPermChange] = useAtom(permChangeAtom);

  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');
  const tabIndex = searchParams.get('tabIndex');
  const isDraft = searchParams.get('isDraftFile');

  const isDraftFile = useMemo(() => {
    return isDraft && isDraft === 'true';
  }, [isDraft]);

  const {
    data: columnData,
    error: columnError,
    mutate: queryColumns,
  } = useSWR(
    // [V2.2.2]
    wideId && wideId !== 'null' && wideId !== 'undefined' && !isDraftFile
      ? `${Wide_Table_Fields}/${wideId}/0`
      : null
  );

  const columnList = useMemo(() => {
    return columnData?.data ?? [];
  }, [columnData]);

  const storeColumnWidthKey = useMemo(() => {
    return `dataQueryTable${searchParams.get('wideId')}`;
  }, [searchParams]);

  const [filterParams, setFilterAtom] = useAtom(filterAtom);
  const {conditions} = filterParams;
  globalFilterConditions = conditions[storeColumnWidthKey] || {};

  useEffect(() => {
    if (columnError) {
      setLoading(false);
      setDataSource([]);
      setColumns([]);
      if (columnError?.code === 512 || columnError?.data?.code === 512) {
        notification.error({
          description: columnError?.data?.msg || columnError?.msg || '未知错误',
        });
      }
    }
  }, [columnError, setLoading, setDataSource, setColumns]);

  useEffect(() => {
    setActiveKey(tabIndex || '1');
  }, [setActiveKey, tabIndex]);

  const {pageNum, pageSize} = usePaginationUrlParams();

  useMount(() => {
    cancelToken = axios.CancelToken;
    source = cancelToken.source;
    const validatePermHandler = validatePerm();
    requestInterceptId = request.interceptors.request.use((config) => {
      if (config.url !== `${GET_PERM_STATUS}?type=1&code=${menuCode}`) {
        validatePermHandler(menuCode).then((res) => {
          if (res?.data?.hasPerm === 2) {
            setIsPermChange(true);
          }
        });
      }
      return config;
    });
  });
  useUnmount(() => {
    isValidating = false;
    globalFilterConditions = {};
    const conditionsCopy = deepClone(conditions);
    delete conditionsCopy[storeColumnWidthKey];
    setFilterAtom({
      ...filterParams,
      conditions: conditionsCopy,
    });
    request.interceptors.request.eject(requestInterceptId);
  });

  const updateTable = useCallback(
    (_filterConditions, pagination) => {
      if (
        columnList.length === 0 ||
        (pagination && pagination[0] !== pageNum) ||
        isDraftFile
      ) {
        return;
      }
      if (isValidating && source.cancel) {
        source.cancel();
        cancelToken = axios.CancelToken;
        source = cancelToken.source;
      }
      const queryFilterConditions = _filterConditions || globalFilterConditions;

      const sorts = Object.keys(queryFilterConditions)
        .filter((key) => queryFilterConditions[key].orderType)
        .map((key) => {
          const targetCol = columnList.find((col) => col.indexAlias === key);
          if (targetCol) {
            return {
              column: key,
              indexType: targetCol.indexType,
              order: queryFilterConditions[key].orderType === 'asc' ? 1 : 2,
            };
          }
          return false;
        })
        .filter(Boolean);

      const sectionFilters = [];
      Object.keys(queryFilterConditions).forEach((key) => {
        const column = columnList.find((col) => col.indexAlias === key);
        if (column?.sectionId) {
          const valueList = queryFilterConditions[key].valueList;
          if (valueList) {
            valueList.forEach((item) => {
              let splitItem = item;
              if (typeof splitItem === 'string') {
                splitItem = decodeURIComponent(item).split('#z_h_t_j#');
                if (splitItem.length < 2) return;
                splitItem = splitItem.slice(0, splitItem.length - 1);
                splitItem = JSON.parse(splitItem.join('#z_h_t_j#'));
              }
              sectionFilters.push({
                column: key,
                left: splitItem.left,
                right: splitItem.right,
                symbol: splitItem.symbol,
                label: splitItem.label,
              });
            });
          }
        }
      });

      const filterCondition = makeFilterCondition(
        columnList,
        queryFilterConditions
      );

      const queryFilterCals = filterCondition
        .map((item) => {
          const column = columnList.find(
            (col) => col.indexAlias === item.aliasName
          );
          if (column?.queryCalId) {
            return {
              queryCalId: column.queryCalId,
            };
          }
          return false;
        })
        .filter(Boolean);

      const staticFilters = filterCondition
        .map((item) => {
          const column = columnList.find(
            (col) => `zhtj_coding_${col.indexAlias}` === item.aliasName
          );
          return column?.mosaicType
            ? {
                column: column.indexAlias,
                mosaicType: column.mosaicType,
              }
            : false;
        })
        .filter(Boolean);

      isValidating = true;
      setLoading(true);
      wideDataList({
        data: {
          sorts,
          staticFilters,
          sectionFilters,
          queryFilterCals,
          pathId: wideId,
          pageNum,
          pageSize,
        },
        headers: {
          'filter-condition': JSON.stringify(filterCondition),
          filterIdentity: JSON.stringify({
            pageCode,
            tableKey: storeColumnWidthKey,
          }),
        },
        cancelToken: source.token,
      })
        .then((data) => {
          setTotal(data.total);
          setDataSource(data.data);
        })
        .catch((err) => {
          setDataSource([]);
          setColumns([]);
        })
        .finally(() => {
          isValidating = false;
          cancelToken = axios.CancelToken;
          source = cancelToken.source();
          setLoading(false);
        });
    },
    [
      columnList,
      pageNum,
      isDraftFile,
      setLoading,
      wideId,
      pageSize,
      pageCode,
      storeColumnWidthKey,
      setTotal,
      setDataSource,
      setColumns,
    ]
  );

  useEffect(() => {
    if (
      defaultFilterConditions !== null &&
      (filterInfo === null || filterInfo.length > 0) &&
      isInit.current
    ) {
      const curTableCondition =
        filterParams.conditions[storeColumnWidthKey] ?? {};
      if (defaultFilterConditions) {
        const finalCoditions = defaultFilterConditions[storeColumnWidthKey];
        const filterConditions = defaultFilterConditions[storeColumnWidthKey];
        for (const key in curTableCondition) {
          if (Object.hasOwnProperty.call(curTableCondition, key)) {
            const con = curTableCondition[key];
            finalCoditions[key] = con;
            if (key.indexOf('zhtj_coding_') === 0) {
              filterConditions[key.slice(12)] = con;
            } else {
              filterConditions[key] = con;
            }
          }
        }
        updateTable(finalCoditions);
        setFilterAtom({
          ...filterParams,
          conditions: {
            [storeColumnWidthKey]: filterConditions,
          },
        });
      } else {
        const tempConditions = {};
        const filterConditions = {};

        for (const key in curTableCondition) {
          if (Object.hasOwnProperty.call(curTableCondition, key)) {
            const con = curTableCondition[key];
            tempConditions[key] = con;
            if (key.indexOf('zhtj_coding_') === 0) {
              filterConditions[key.slice(12)] = con;
            } else {
              filterConditions[key] = con;
            }
          }
        }
        if (filterInfo) {
          deepClone(filterInfo).forEach((i) => {
            const aliasName = i.aliasName;
            if (i.valueList) {
              i.valueList = i?.valueList?.map((v) => decodeURIComponent(v));
            }
            delete i.aliasName;
            tempConditions[aliasName] = i;
            if (aliasName.indexOf('zhtj_coding_') === 0) {
              filterConditions[aliasName.slice(12)] = i;
            } else {
              filterConditions[aliasName] = i;
            }
          });
        }
        updateTable(tempConditions);
        setFilterAtom({
          ...filterParams,
          conditions: {
            [storeColumnWidthKey]: filterConditions,
          },
        });
      }
      isInit.current = false;
    }
  }, [
    filterInfo,
    defaultFilterConditions,
    filterParams,
    setFilterAtom,
    storeColumnWidthKey,
    updateTable,
  ]);

  const clearPrevTableInfo = () => {
    setDefaultFilterConditions(null);
    setDefaultId(null);
    setFilterInfo({});
    globalFilterConditions = {};
    setFilterAtom({
      ...filterParams,
      conditions: {},
    });
    isInit.current = true;
  };

  useEffect(() => {
    // if (!isInit.current) {
    //   updateTable();
    // }

    updateTable();
  }, [updateTable]);

  return (
    <MicroDataQueryContext.Provider
      value={{
        columnList,
        dataSource,
        updateTable,
        queryColumns,
        total,
        formatColumns,
        setFormatColumns,
        chooseBatchData,
        setChooseBatchData,
        storeColumnWidthKey,
        wideOverview,
        setWideOverview,
        loading,
        setFilterInfo,
        setDefaultFilterConditions,
        queryMenuRef,
        clearPrevTableInfo,
        defaultId,
        setDefaultId,
        operateDropDownRef,
        tableName,
        setTableName,
        curFilterGroup,
        setCurFilterGroup,
        filterGroupRef,
        columns,
        setColumns,
        activeKey,
        setActiveKey,
      }}
    >
      <ResizebleFrame
        noCenterTopCache
        cacheStorageKey="microDataQueryFrame"
        centerTopElment={<MicroDataQueryInfo />}
        centerTopMinHeight="100%"
        enableCenterTopResize={false}
        leftElement={<MicroDataQueryMenu />}
      />
    </MicroDataQueryContext.Provider>
  );
};

export default MicroDataQuery;
