import request from '../../helpers/request';

/** 账号管理-获取分组列表 */
export const GET_GROUP_LIST = '/user/group/getSysGroupTree';

/** 账号管理-新增或编辑分组时获取分组列表 */
export const EDIT_GROUP_LIST = '/user/group/getPureGroupTree';

/** 账号管理-获取账号列表 */
export const GET_USER_LIST = '/user/user/list';
export function getUserList(params) {
  return request({
    url: `/user/user/list`,
    method: 'get',
    params,
  });
}

/** 账号管理-检查短信、邮件 */
export const GET_NOTICE_CONFIG = '/user/user/getNoticeConfig';

/** 账号管理-日志 */
export const OPERATION_LOG = '/user/operlog/list';
/** 账号管理-日志2 */
export const OPERATION_LOG_USER = '/user/operlog/userList';

/** 账号管理-导出日志 */
export const OPERLOG_EXPORT = '/user/operlog/export';
/** 账号管理-导出日志2 */
export const OPERLOG_EXPORT_USER = '/user/operlog/userExport';

/** 账号管理-账号权限下分组列表 */
export const GET_ACCOUNT_GROUP_LIST = '/user/group/list';

/** 账号管理-账号权限 */
export const GET_USER_PERM = '/user/user/getUserPerm';
export function getUserPermission(data) {
  return request({
    url: GET_USER_PERM,
    method: 'post',
    data,
  });
}

/** 账号管理-账号角色列表 */
export const GET_ACCOUNT_ROLE = '/user/grole/getUserRole';

/** 账号管理-账号详情 */
export const GET_USER_INFO = '/user/user/userInfo';

/** 账号管理-停用/启用 */
export function changeStatus(data) {
  return request({
    url: `/user/user/changeStatus`,
    method: 'post',
    data,
  });
}

/** 账号管理-重置密码 */
export function resetPwd(params) {
  return request({
    url: `/user/user/resetPwd`,
    method: 'get',
    params,
  });
}

/** 账号管理-新建账号 */
export function createAccount(data) {
  return request({
    url: `/user/user`,
    method: 'post',
    data,
  });
}

/** 账号管理-关联分组 */
export function relateGroup(data) {
  return request({
    url: `/user/user/updateUserGroup`,
    method: 'post',
    data,
  });
}

/** 账号管理-关联角色 */
export function relateRole(data) {
  return request({
    url: `/user/user/updateUserRole`,
    method: 'post',
    data,
  });
}

/** 账号管理-修改账号 */
export function editAccount(data) {
  return request({
    url: `/user/user`,
    method: 'put',
    data,
  });
}

/** 账号管理-账号权限是否变化 */
export function getIsPermChange() {
  return request({
    url: `/user/grole/isPermChange`,
    method: 'get',
  });
}

/** 账号管理-获取所有分组 */
export const GET_ALL_GROUP = '/user/group/getAllGroup';

/** ***********************************分割线********************************** */
/** 分组管理-查询分组列表 */
export const GROUP_LIST_ALL = '/user/group/getSysGroupManageTree';

/** 分组管理-查询分组详情 */
export const GROUP_DETAIL = '/user/group/getDetail';

export function getGroupDetail(params) {
  return request({
    url: `/user/group/getDetail`,
    method: 'get',
    params,
  });
}

/** 分组管理-新增分组 */
export function runCreateGroup(data) {
  return request({
    url: `/user/group/add`,
    method: 'post',
    data,
  });
}
/** 分组管理-编辑分组 */
export function runEditGroup(data) {
  return request({
    url: `/user/group/edit`,
    method: 'post',
    data,
  });
}
/** 分组管理-启用分组 */
export function runStartGroup(data) {
  return request({
    url: `/user/group/enable`,
    method: 'post',
    data,
  });
}
/** 分组管理-停用分组 */
export function runStopGroup(data) {
  return request({
    url: `/user/group/disable`,
    method: 'post',
    data,
  });
}
/** 分组管理-停用分组 */
export function runDeleteGroup(code) {
  return request({
    url: `/user/group/${code}`,
    method: 'delete',
  });
}
/** 分组管理-调整分组顺序 */
export function runReorderGroup(data) {
  return request({
    url: `/user/group/adjustRank`,
    method: 'post',
    data,
  });
}

/** 分组管理-获取编辑权限时可关联账号列表 */
export function getGroupCanRelateUsersList(params) {
  return request({
    url: `/user/groupUser/getUsersByGroup`,
    method: 'get',
    params,
  });
}
/** 分组管理-获取新增或编辑分组时可选负责人账号列表 */
export function getGroupPrincipleUsersList(params) {
  return request({
    url: `/user/user/getPrincipal`,
    method: 'get',
    params,
  });
}

/** ***********************************分割线********************************** */
/** 角色-新增角色 */
export function runCreateRole(data) {
  return request({
    url: `/user/grole/add`,
    method: 'post',
    data,
  });
}
/** 角色-停用角色 */
export function runStopRole(data) {
  return request({
    url: `/user/grole/disable`,
    method: 'post',
    data,
  });
}
/** 角色-启用角色 */
export function runStartRole(data) {
  return request({
    url: `/user/grole/enable`,
    method: 'post',
    data,
  });
}
/** 角色-修改角色 */
export function runUpdateRole(data) {
  return request({
    url: `/user/grole/edit`,
    method: 'post',
    data,
  });
}
/** 角色-授权操作，含授权页面、表单、数据、功能权限，关联角色账号信息操作 */
export function runSaveUserRoleConfig(data) {
  return request({
    url: `/user/grole/grantPrivileges`,
    method: 'post',
    data,
  });
}
/** 角色-获取用户的角色 */
export function getUserRole(params) {
  return request({
    url: `/user/grole/getUserRole`,
    method: 'get',
    params,
  });
}
/** 角色-根据分组code查询角色列表 */
export function getRolesByGroupCode(params) {
  return request({
    url: `/user/grole/listByGroup`,
    method: 'get',
    params,
  });
}
/** 角色-根据角色code查询角色列表 */
export const GET_ROLES_BY_ROLECODE = `/user/grole/queryByRole`;
export function getRolesByRoleCode(params) {
  return request({
    url: `/user/grole/queryByRole`,
    method: 'get',
    params,
  });
}
/** 角色-根据当前负责人角色查询上级负责人权限列表 */
export const GET_PARENTAUTH_BY_CURPRINCIPAL = `/user/grole/queryRolePerm`;
export function getParentAuthByCurPrincipal(params) {
  return request({
    url: `/user/grole/queryRolePerm`,
    method: 'get',
    params,
  });
}

/** 分组管理-日志 */
export const OPERATION_LOG_GROUP = '/user/operlog/groupList';
/** 分组管理-导出日志 */
export const OPERLOG_EXPORT_GROUP = '/user/operlog/groupExport';
