import {css} from '@emotion/react';
import {Menu} from 'antd';

import SvgIcon from '../../../components/SvgIcon';
import {needTwiceCheckUrl} from '../../../constants/system';
import {jump} from '../../../helpers/utils';
import useSafeState from '../../../hooks/useSafeState';
import {useValidatePerm} from '../../../hooks/useValidatePerm';

function MenuItem({
  onClick,
  menuCode,
  setPagesPermAtom,
  // pageCode,
  linkUrl,
  ...rest
}) {
  const [canDo, setCanDo] = useSafeState(true);

  const [permRef, validatePermChange] = useValidatePerm({
    type: 1,
    code: menuCode,
  });

  return (
    <Menu.Item {...rest}>
      <a
        onClick={async () => {
          if (!canDo) return;

          setCanDo(false);

          window.setTimeout(() => {
            setCanDo(true);
          }, 1000);

          if (!linkUrl) {
            await setPagesPermAtom((pre) => ({
              ...pre,
              menuCode,
            }));
          }
          await validatePermChange();
          if (!permRef.current) {
            onClick();
          }
        }}
      >
        {rest.title}
      </a>
    </Menu.Item>
  );
}

export function renderMenus(
  menus,
  checkNeedTwiceCheck,
  setShowPage,
  setPagesPermAtom,
  blockRouter
) {
  const linkToClick = async (curMenuItem) => {
    const {code, nodeInfo = {}, name} = curMenuItem;
    const routePath = nodeInfo.linkUrl || `/${code}${nodeInfo.routeUrl}`;
    // const jumpUrl = nodeInfo.linkUrl
    //   ? (_url) => window.open(_url.includes('http') ? _url : `https://${_url}`)
    //   : jump;
    const jumpUrl = nodeInfo.linkUrl
      ? () =>
          jump(
            `/system/iFrameWindow?frameUrl=${encodeURIComponent(
              nodeInfo.linkUrl
            )}&code=${code}`
          )
      : jump;

    if (blockRouter) {
      jumpUrl(routePath);
    } else {
      setShowPage(false);

      if (needTwiceCheckUrl.some((n) => routePath.includes(n))) {
        await checkNeedTwiceCheck(
          // 二次身份验证
          () => {
            jumpUrl(routePath);
            setShowPage(true);
          },
          undefined,
          {functionName: name}
        );
      } else {
        jumpUrl(routePath);
        setShowPage(true);
      }
    }
  };

  function recursive(arr) {
    return Array.isArray(arr)
      ? arr.reduce((pre, cur) => {
          const {name, children, code, nodeInfo = {}} = cur;

          if (nodeInfo.type === 1) {
            pre = [
              ...pre,
              <Menu.SubMenu
                children={recursive(children)}
                css={css`
                  .ant-menu-submenu-title .ant-menu-title-content {
                    color: #171717;
                    font-weight: 500;
                  }
                `}
                icon={
                  nodeInfo.icon ? <SvgIcon iconClass={nodeInfo.icon} /> : null
                }
                key={code}
                title={name}
              />,
            ];
          } else if (nodeInfo.type === 2) {
            pre = [
              ...pre,
              ...(nodeInfo.status === 1
                ? [
                    <MenuItem
                      icon={
                        nodeInfo.icon ? (
                          <SvgIcon iconClass={nodeInfo.icon} />
                        ) : null
                      }
                      key={`/${code}`}
                      linkUrl={nodeInfo.linkUrl}
                      menuCode={code}
                      setPagesPermAtom={setPagesPermAtom}
                      title={name}
                      onClick={() => linkToClick(cur)}
                    />,
                  ]
                : []),
            ];
          }

          return pre;
        }, [])
      : [];
  }

  const finalMenus = recursive(menus);

  return finalMenus;
}
