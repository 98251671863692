import {Form} from 'antd';
import React, {useCallback, useEffect} from 'react';
import {useMount} from 'react-use';

import {getUserMailList} from '../../../../../api/dataManagement/wideTable';
import FormErrMsg from '../../../../../components/FormErrMsg';
import GlobalModal from '../../../../../components/GlobalModal';
import useSafeState from '../../../../../hooks/useSafeState';
import {defaultFrequency} from './SaveWideTableModal';
import SyncFormItems from './SyncFormItems';

const formItemLayout = {
  labelCol: {
    xs: {span: 24},
    sm: {span: 6},
  },
  wrapperCol: {
    xs: {span: 24},
    sm: {span: 18},
  },
};

const initialFormValues = {
  syncJobType: 0,
  jobCronJson: defaultFrequency,
  mails: [],
  syncResultRemind: [],
  // sendMessageFlag: false,
  mail: '',
};

export default function SyncConfigModal({visible, onOk, onCancel, values}) {
  const [syncFormValues, setSyncFormValues] = useSafeState(
    values ? {...values} : {...initialFormValues}
  );
  const [userMailList, setUserMailList] = useSafeState([]);

  const fetchUserMailList = useCallback(async () => {
    try {
      const {data} = await getUserMailList();
      setUserMailList(data);
    } catch (err) {
      throw new Error('Get user mail list failed');
    }
  }, [setUserMailList]);

  const [form] = Form.useForm();

  useMount(() => {
    fetchUserMailList();
  });

  function handleValuesChange(changedValues, allValues) {
    if (changedValues.syncJobType === 1) {
      setSyncFormValues((prev) => ({
        ...prev,
        ...allValues,
        jobCronJson: defaultFrequency,
      }));
      form.setFieldsValue({
        jobCronJson: defaultFrequency,
      });
    } else if (changedValues.mails) {
      setSyncFormValues((prev) => ({
        ...prev,
        mail: userMailList
          .filter((item) => changedValues.mails.includes(item.userId))
          .map((d) => d.email)
          .filter(Boolean)
          .join(','),
      }));
    } /* else if (changedValues.jobCronJson) {
      // /^\+?[1-9][0-9]*$/  正整数
      const checkRule = new Map([
        [
          2,
          {
            field: 'stepHours',
            reg: /^(([1-9])|(1\d)|(2[0-3]))$/,
            msg: '请填写间隔小时数(1 ~ 23范围整数)',
          },
        ],
        [
          3,
          {
            field: 'stepDays',
            reg: /^(([1-9])|([1-2]\d)|(3[0-1]))$/,
            msg: '请填写间隔天数(1 ~ 31范围整数)',
          },
        ],
        [5, {field: ['months', 'monthDays'], msg: '请选择月份和日期'}],
      ]);
      const {frequency, startTime} = changedValues.jobCronJson;
      const okDateTimeVal = dayjs().isBefore(dayjs(startTime));
      FormErrMsg.resetFormErrMsg(form, 'jobCronJson');

      [...checkRule.keys()].forEach((key) => {
        if (key === frequency) {
          let isValid = false;
          const {field, reg, msg} = checkRule.get(key);
          if (Array.isArray(field)) {
            isValid = field.every(
              (item) => changedValues.jobCronJson[item].length > 0
            );
          } else {
            const curFieldVal = changedValues.jobCronJson[field];
            isValid = reg.test(curFieldVal);
          }

          if (!isValid) {
            FormErrMsg.setFormErrMsg(form, 'jobCronJson', msg);
          }
        }
      });

      // 一次性执行任务
      if (frequency === 1 && !okDateTimeVal) {
        FormErrMsg.setFormErrMsg(
          form,
          'jobCronJson',
          '请选择当前系统时间之后的时间'
        );
      }
      setSyncFormValues((prev) => ({
        ...prev,
        ...allValues,
      }));
    } */ else {
      setSyncFormValues((prev) => ({
        ...prev,
        ...allValues,
      }));
    }
  }

  const onOkHandle = () => {
    form.validateFields().then(() => {
      onOk(syncFormValues);
    });
  };

  const onCancelHandle = () => {
    onCancel();
    setSyncFormValues({...initialFormValues});
  };

  useEffect(() => {
    if (visible) {
      const data = {...values};
      if (data.mail) {
        const mails = [];
        data.mail.split(',').forEach((mail) => {
          mails.push(userMailList.find((user) => user.email === mail)?.userId);
        });
        data.mails = mails;
      } else {
        data.mails = [];
      }
      setSyncFormValues({...data});
      form.setFieldsValue({...data});
    } else {
      setSyncFormValues({...initialFormValues});
      form.setFieldsValue({...initialFormValues});
    }
  }, [form, setSyncFormValues, userMailList, values, visible]);

  return (
    <GlobalModal
      title="配置同步"
      visible={visible}
      width={750}
      onCancel={onCancelHandle}
      onOk={onOkHandle}
    >
      <Form
        {...formItemLayout}
        form={form}
        initialValues={{...initialFormValues}}
        name="syncConfig"
        requiredMark={false}
        validateTrigger="onChange"
        onValuesChange={handleValuesChange}
      >
        <SyncFormItems
          formValues={syncFormValues}
          userMailList={userMailList}
        />
        <FormErrMsg />
      </Form>
    </GlobalModal>
  );
}
