// 微观指标
import request from '../../helpers/request';

/* 查询表的字段列表 */
export function getSheetsFields(data) {
  return request({
    url: '/wide/indexMaking/sourceColumnList',
    method: 'post',
    data,
  });
}

/** 新增宽表查询获取索关联索引 */
export function getRelationTableIdx(params) {
  return request({
    url: '/wide/indexMaking/queryTableIdx',
    method: 'get',
    params,
  });
}

/* 获取当前数据表配置 */
export function getCanvasCfg(params) {
  return request({
    url: '/wide/indexMaking/selectWildStructure',
    method: 'get',
    params,
  });
}

/* 获取当前数据表配置的预览宽表 */
export const WIDE_TABLE_DATA = '/wide/indexMaking/makeWideStructure';
export function getPreviewData(data) {
  return request({
    url: '/wide/indexMaking/makeWideStructure',
    method: 'post',
    data,
  });
}

/* 新增数据表保存 */
export function createSheetCfg(data) {
  return request({
    url: '/wide/indexMaking/save',
    method: 'post',
    data,
  });
}

/* 修改数据表保存 */
export function updateSheetCfg(data) {
  return request({
    url: '/wide/indexMaking/update',
    method: 'post',
    data,
  });
}

/* 暂存数据表 */
export function tempSaveSheetCfg(data) {
  return request({
    url: '/wide/indexMaking/tempSave',
    method: 'post',
    data,
  });
}

/* 数据表另存为 */
export function saveAsSheetCfg(data) {
  return request({
    url: '/wide/indexMaking/saveAs',
    method: 'post',
    data,
  });
}

/** 查询入库的字段 */
export function getIndexColWideId(params) {
  return request({
    url: '/mis/column/getIndexColWideId',
    method: 'get',
    params,
  });
}

/** 查询字段打码加密后内容配置 */
export function getCodeEncryptCfg(params) {
  return request({
    url: '/mis/encrypt/getMosaicConfig',
    method: 'get',
    params,
  });
}

/** 查询字段角色权限树 */
export function getIndexRoleTree(data) {
  return request({
    url: '/mis/indexColPerm/getIndexRoleTree',
    method: 'post',
    data,
  });
}

/** 查询当前角色的账号 */
export const GET_USER_BY_ROLE = `/mis/indexColPerm/getUserByRole`;

/** 保存字段角色权限树 */
export function getPathRoleTree(data) {
  return request({
    url: '/mis/indexPath/getPathRoleTree',
    method: 'post',
    data,
  });
}

/** 查询权限的表结构树 */
export function saveIndexRoleTree(data) {
  return request({
    url: '/mis/indexColPerm/saveIndexRoleTree',
    method: 'post',
    data,
  });
}

export function getMicroInstanceList(menuCode) {
  const id = request.interceptors.request.use((config) => {
    const {headers} = config;
    headers.mc = menuCode;
    config.headers = headers;
    return config;
  });
  const res = request({
    url: '/mis/instance/list',
    method: 'get',
  });
  request.interceptors.request.eject(id);
  return res;
}

export function getMicroDbList(params, menuCode) {
  const id = request.interceptors.request.use((config) => {
    const {headers} = config;
    headers.mc = menuCode;
    config.headers = headers;
    return config;
  });
  const res = request({
    url: '/mis/db/list',
    method: 'get',
    params,
  });
  request.interceptors.request.eject(id);
  return res;
}

export function getMicroTableList(params, menuCode) {
  const id = request.interceptors.request.use((config) => {
    const {headers} = config;
    headers.mc = menuCode;
    config.headers = headers;
    return config;
  });
  const res = request({
    url: '/mis/table/list',
    method: 'get',
    params,
  });
  request.interceptors.request.eject(id);
  return res;
}

// 校验指标字段名称是否重复
export function checkTableNameUnique(params) {
  return request({
    url: '/mis/indexPath/checkUnique',
    method: 'get',
    params,
  });
}

export function getFilterRecord(header, data) {
  return request({
    url: '/wide/widetablefilter/getFilterRecord',
    method: 'post',
    data,
    headers: header,
  });
}

export function parseCalIndex(data, config = {}) {
  return request({
    url: '/wide/indexCal/parseCalIndex',
    method: 'post',
    data,
    ...config,
  });
}

// 获取计算字段类型
export function getCalIndexType(data, config) {
  return request({
    url: '/wide/indexCal/getCalIndexType ',
    method: 'post',
    data,
  });
}

// 预览
export function getCalIndexData(data) {
  return request({
    url: '/wide/indexCal/getCalIndexData ',
    method: 'post',
    data,
  });
}

export function getSectionConfigList(params) {
  return request({
    url: '/mis/sectionConfig/getSectionConfigList',
    method: 'get',
    params,
  });
}

export function getAutoSection(params) {
  return request({
    url: '/mis/sectionConfig/getAutoSection',
    method: 'get',
    params,
  });
}

export function getMeasureUnitTree(params) {
  return request({
    url: 'dms/measureUnit/getMeasureUnitTree',
    method: 'get',
    params,
  });
}

export function addSectionConfig(data) {
  return request({
    url: 'mis/sectionConfig/add',
    method: 'post',
    data,
  });
}

export function getSectionConfigDetail(params) {
  return request({
    url: 'mis/sectionConfig/getSectionConfig',
    method: 'get',
    params,
  });
}

export function getValueRange(params) {
  return request({
    url: 'mis/sectionConfig/getValueRange',
    method: 'get',
    params,
  });
}

export function getCodeTableData(data) {
  return request({
    url: 'mis/indexMaking/getCodeTableData ',
    method: 'post',
    data,
  });
}
