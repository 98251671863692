import {css} from '@emotion/react';
import {Menu} from 'antd';
import {useAtom} from 'jotai';
import React, {useEffect, useMemo, useState} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useLocalStorage} from 'react-use';
import useSWR from 'swr';

import {GET_USER_PAGE_PERM} from '../../../api/system/user';
import {authMenusAtom} from '../../../atoms/authMenus';
import {blockRouterAtom} from '../../../atoms/commonAtom';
import {pagePermsAtom} from '../../../atoms/pagePermsAtom';
import IconFont from '../../../components/IconFont';
import {storageKeys} from '../../../constants/storageKeys';
import {
  needTwiceCheckUrl,
  notNeedGetPermByMenu,
} from '../../../constants/system';
import {menuCSS} from '../../../global.css';
import {jump} from '../../../helpers/utils';
import useTwiceCheckModal from '../../../hooks/useTwiceCheckModal';
import {
  findOpenKeys,
  flattenMenus,
  formatBreadcrumb,
} from '../helpers/disposeMenu';
import {renderMenus} from '../helpers/renderMenus';

const Menus = (props) => {
  const {collapse, setShowPage, setBreadcrumbsMenu} = props;
  const locationHook = useLocation();
  const [searchParams] = useSearchParams();
  const frameMenuCode = searchParams.get('code');

  const [authMenus] = useAtom(authMenusAtom);
  const [menuCode, setMenuCode] = useState('');
  const [, setPagesPermAtom] = useAtom(pagePermsAtom);
  const {checkNeedTwiceCheck} = useTwiceCheckModal();

  const [blockRouter] = useAtom(blockRouterAtom);

  const flattedMenu = useMemo(() => {
    return flattenMenus(authMenus);
  }, [authMenus]);

  const breadPathTitleObject = useMemo(() => {
    if (authMenus.length > 0) {
      return formatBreadcrumb(authMenus);
    }
    return {};
  }, [authMenus]);

  const rootSubmenuKeys = useMemo(
    () => authMenus.map((level1) => level1.code),
    [authMenus]
  );

  const [selectedKeys = [], setSelectedKeys] = useLocalStorage(
    storageKeys.SELECTED_MENU_KEYS
  );

  const [openKeys = [rootSubmenuKeys[0]], setOpenKeys] = useLocalStorage(
    storageKeys.OPEN_MENU_KEYS
  );

  // 展开submenu时保证同时只展开一个submenu
  const onOpenChangeHandle = (keys) => {
    const latestOpenKey = keys.find((key) => !openKeys.includes(key));
    if (rootSubmenuKeys.includes(latestOpenKey)) {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    } else {
      setOpenKeys(keys);
    }
  };

  const {data: pagePermsRes} = useSWR(
    menuCode ? `${GET_USER_PAGE_PERM}?menuCode=${menuCode}` : null
  );

  useEffect(() => {
    const pagePermsData = pagePermsRes?.data || {};
    const {buttonList} = pagePermsData;

    setPagesPermAtom((pre) => ({
      ...pre,
      permBtns: (buttonList ?? []).filter((btn) => btn.perm),
    }));
  }, [pagePermsRes, setPagesPermAtom]);

  useEffect(() => {
    const curPath = locationHook.pathname;

    if (authMenus.length > 0) {
      const matchedMenuItem =
        flattedMenu.find((menuItem) => {
          const {
            parentPageCode,
            code,
            nodeInfo: {routeUrl},
          } = menuItem;

          if (locationHook.pathname === '/') return routeUrl === '/';

          // eslint-disable-next-line prettier/prettier
          if (parentPageCode) return `/${parentPageCode}${routeUrl}` === curPath;

          if (frameMenuCode) return code === frameMenuCode;

          return `/${code}${routeUrl}` === curPath;
        }) ?? {};

      const {code, name, nodeInfo = {}, parentPageCode} = matchedMenuItem;

      const breadKeyCode = code;

      const _selectedKeys = [`/${parentPageCode ?? code}`];

      const _openKeys = findOpenKeys(flattedMenu, code);

      const bread = breadPathTitleObject[breadKeyCode];

      const needGetPermByMenu = notNeedGetPermByMenu.every(
        (p) => !curPath.includes(p)
      );

      const _menuCode = needGetPermByMenu ? code : '';

      console.log('code', code, frameMenuCode);
      // 如果匹配不到，则可能是直接输入路由进入页面，而实际没有页面权限
      if (!code) {
        // jump('/system/user');
        const firstAuthMenu = flattedMenu.filter(
          (m) => m.nodeInfo.type === 2
        )[0];

        const toPath = firstAuthMenu?.nodeInfo?.linkUrl
          ? `/system/iFrameWindow?frameUrl=${encodeURIComponent(
              firstAuthMenu?.nodeInfo?.linkUrl
            )}&code=${firstAuthMenu.code}`
          : `/${firstAuthMenu.code}${firstAuthMenu?.nodeInfo?.routeUrl}`;

        setSelectedKeys((pre) => pre ?? []);
        jump(toPath);
        return;
      }

      setOpenKeys(collapse ? [] : _openKeys);
      setSelectedKeys(_selectedKeys);
      setBreadcrumbsMenu(bread);

      setPagesPermAtom((pre) => ({
        ...pre,
        type: nodeInfo.type,
        pageCode: _menuCode,
        menuCode: _menuCode,
        menuName: name,
      }));
      setMenuCode(_menuCode);
    }
  }, [
    authMenus,
    locationHook.pathname,
    collapse,
    flattedMenu,
    breadPathTitleObject,
    frameMenuCode,
    setOpenKeys,
    setSelectedKeys,
    setBreadcrumbsMenu,
    setPagesPermAtom,
  ]);

  useEffect(() => {
    // 浏览器原生前进后退按钮在路由变化时
    // 上次退出时页面是需要二次身份验证的页面，刚登录时若进入此类页面，则进行二次身份验证
    // 若需要二次验证，在验证前隐藏页面

    if (!authMenus.length) return;

    const curPath = locationHook.pathname;
    const curItem = flattedMenu.find(({code}) => code === frameMenuCode) ?? {};
    const {name} = curItem;

    setShowPage(false);

    if (needTwiceCheckUrl.some((n) => curPath.includes(n))) {
      checkNeedTwiceCheck(
        () => {
          setShowPage(true);
        },
        undefined,
        {functionName: name}
      );
    } else {
      setShowPage(true);
    }
  }, [
    authMenus,
    checkNeedTwiceCheck,
    flattedMenu,
    frameMenuCode,
    locationHook.pathname,
    setShowPage,
  ]);

  return (
    <Menu
      css={menuCSS}
      expandIcon={(SubMenuProps) => {
        return SubMenuProps.isOpen ? (
          <IconFont
            css={css`
              position: relative;
              left: 25px;
            `}
            type="icon-arrow-down"
          />
        ) : (
          <IconFont
            css={css`
              position: relative;
              left: 25px;
            `}
            type="icon-arrow-right"
          />
        );
      }}
      mode="inline"
      openKeys={openKeys}
      selectedKeys={selectedKeys}
      theme="light"
      onOpenChange={onOpenChangeHandle}
    >
      {renderMenus(
        authMenus,
        checkNeedTwiceCheck,
        setShowPage,
        setPagesPermAtom,
        blockRouter
      )}
    </Menu>
  );
};

export default Menus;
