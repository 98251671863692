import {css} from '@emotion/react';
import {Button, Form, Input, Modal} from 'antd';
import React, {useEffect, useState} from 'react';

import {uploadPassword} from '../../../../api/system/user';
import FormErrMsg from '../../../../components/FormErrMsg';
import FormRequireLabel from '../../../../components/FormRequireLabel';
import GlobalModal from '../../../../components/GlobalModal';
import {REG_PASSWORD} from '../../../../constants/regExp';
import {logoutHandle} from '../../../../helpers/history';
import {encrypt} from '../../../../helpers/jsencrypt';

const SetNewPwdModal = (props) => {
  /** @type {{modalControl: import('../../../../hooks/useModalControl').modalControlObject}} */
  const {modalControl} = props;
  const [passwordForm] = Form.useForm();
  const [isLoging, setIsLoging] = useState(false);

  const onFinishHandle = async () => {
    const formValues = await passwordForm.validateFields();
    const oldPassword = encrypt(formValues.oldPassword);
    const newPassword = encrypt(formValues.newPassword);

    setIsLoging(true);
    FormErrMsg.clearFormErrMsg(passwordForm);

    try {
      await uploadPassword({newPassword, oldPassword});
      Modal.confirm({
        title: '提示',
        content: '密码修改成功，请重新登录系统。',
        centered: true,
        closable: false,
        keyboard: false,
        maskClosable: false,
        cancelButtonProps: {
          style: {display: 'none'},
        },
        onOk: (close) => {
          close();
          logoutHandle(false);
        },
      });
    } catch (err) {
      FormErrMsg.disposeErr(passwordForm, err, '密码修改失败');
    } finally {
      setIsLoging(false);
    }
  };

  const onCancelHandle = () => {
    passwordForm.resetFields();
    modalControl._toggle(false);
  };

  const equalToPassword = (rule, value) => {
    if (value && passwordForm.getFieldValue('oldPassword') === value) {
      return Promise.reject(new Error('新密码与现有密码一致，请修改'));
    }
    return Promise.resolve();
  };
  const equalToPassword1 = (rule, value) => {
    if (value && passwordForm.getFieldValue('newPassword') !== value) {
      return Promise.reject(new Error('两次输入的新密码不一致，请重新输入'));
    }
    return Promise.resolve();
  };

  useEffect(() => {
    if (modalControl.visible) {
      FormErrMsg.clearFormErrMsg(passwordForm);
    }
  }, [modalControl.visible, passwordForm]);

  return (
    <GlobalModal
      {...modalControl}
      footer={false}
      okText="保存"
      title="修改密码"
      width={600}
      onCancel={onCancelHandle}
    >
      <Form form={passwordForm} requiredMark={false} onFinish={onFinishHandle}>
        <Form.Item
          label={<FormRequireLabel title="现密码" />}
          labelCol={{span: 4}}
          name="oldPassword"
          rules={[
            {
              required: true,
              message: '请输入现在的密码',
            },
            {
              pattern: REG_PASSWORD,
              message: '请按大小写英文字母+数字+字符格式重新输入密码',
            },
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password autoComplete="off" placeholder="请输入现在的密码" />
        </Form.Item>
        <Form.Item
          label={<FormRequireLabel title="新密码" />}
          labelCol={{span: 4}}
          name="newPassword"
          rules={[
            {
              required: true,
              message: '密码不能为空',
            },
            {
              pattern: REG_PASSWORD,
              message: '请按大小写英文字母+数字+字符格式重新输入密码',
            },
            {validator: equalToPassword},
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password
            autoComplete="off"
            placeholder="请输入新的密码(必须是包含大写英文、小写英文、数字、符号的组合)"
          />
        </Form.Item>
        <Form.Item
          label={<FormRequireLabel title="确认新密码" />}
          name="newPassword2"
          rules={[
            {
              required: true,
              message: '请再次输入新的密码',
            },
            {
              pattern: REG_PASSWORD,
              message: '请按大小写英文字母+数字+字符格式重新输入密码',
            },
            {validator: equalToPassword1},
          ]}
          validateTrigger="onBlur"
        >
          <Input.Password autoComplete="off" placeholder="请再次输入新的密码" />
        </Form.Item>

        <FormErrMsg />

        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            .ant-form-item {
              margin-bottom: 0;
            }
          `}
        >
          <Form.Item>
            <Button
              block
              className="cancel-btn-text-color"
              type="default"
              onClick={() => {
                onCancelHandle();
              }}
            >
              取消
            </Button>
          </Form.Item>
          <div style={{width: 8}} />
          <Form.Item>
            <Button
              block
              disabled={isLoging}
              htmlType="submit"
              loading={isLoging}
              type="primary"
            >
              {isLoging ? '保 存 中...' : '保 存'}
            </Button>
          </Form.Item>
        </div>
      </Form>
    </GlobalModal>
  );
};

export default SetNewPwdModal;
