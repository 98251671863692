import { ColumnType } from "antd/lib/table";

export const TextTypeArr = ["char", "text"] as const;

export const DateTypeArr = ["date", "time", "year"] as const;

export const NumberTypeArr = [
  "int",
  "decimal",
  "float",
  "double",
  "numeric",
] as const;

export const PeriodTypeMap = {
  YEAR: "0",
  HALF_YEAR: "1",
  SEASON: "2",
  MONTH: "3",
  TEN_DAYS: "4",
  WEEK: "5",
  DAY: "6",
} as const;

export type TPeriodType = typeof PeriodTypeMap;

export type PeriodType = TPeriodType[keyof TPeriodType];

export type TextType = typeof TextTypeArr[number];

export type DateType = typeof DateTypeArr[number];

export type NumberType = typeof NumberTypeArr[number];

export interface CustomColumnType extends ColumnType<unknown> {
  /** 字段名称 */
  aliasName?: string;
  /** 字段类型 */
  fieldType?: TextType | DateType | NumberType;
  /** 筛选器类型 */
  filterType?: "Common" | "Period" | "FatherSon" | "Enum" | "Sort";
  /** 数据期时间特征类型 */
  periodType?: PeriodType;
  /** 自定义筛选回调函数 */
  onFilterSearch?: (...args: unknown[]) => void;
  /** 筛选器标题 */
  filterTitle?: string;
  /** 当title不为字符串时指定title宽度 */
  titleWidth?: number;
  /** 列是否固定宽度  */
  fixWidth?: boolean;
  /** 是否允许拖拽列 */
  draggable?: boolean;
}
