export const DATA_STATUS_TEXT_MAP = new Map([
  [5, {text: '错误', color: '#E34D59'}],
  [3, {text: '正常', color: '#00A870'}],
  [4, {text: '暂停', color: '#ED7B2F'}],
  [2, {text: '初始化', color: '#4D5EFF'}],
  [1, {text: '暂存', color: 'rgba(26, 34, 48, 0.7)'}],
]);

// 1:宽表 2：同步的数据表 同步状态
export const JOB_STATUS_TEXT_MAP = new Map([
  [3, {text: '失败', color: '#E34D59'}],
  [2, {text: '同步成功', color: '#00A870'}],
  [1, {text: '执行中', color: '#4D5EFF'}],
  [0, {text: '队列中', color: '#4D5EFF'}],
  [-1, {text: '暂停', color: '#4D5EFF'}],
]);

// 上传excel表更新状态
export const JOB_STATUS_TEXT_UPLOAD_MAP = new Map([
  [1, {text: '更新成功', color: '#00A870'}],
  [2, {text: '更新失败', color: '#E34D59'}],
  [3, {text: '正在更新', color: '#4D5EFF'}],
]);

export const SYNC_TYPE_MAP = new Map([
  [1, {text: '手动同步', color: '#4D5EFF'}],
  [0, {text: '定时同步', color: '#4D5EFF'}],
]);

export const EXPORT_STATUS_MAP = new Map([
  [0, {text: '失败', color: '#E34D59'}],
  [1, {text: '成功', color: '#00A870'}],
  [2, {text: '执行中', color: '#4D5EFF'}],
]);

export const EXPORT_DATATYPE_MAP = new Map([
  [1, '当前页面'],
  [2, '当前筛选'],
  [3, '所有数据'],
]);

/** 计算字段标识 */
export const CAL_INDEX_CLASSIFY = 1;

/** 字段有权 */
export const HAS_COLUMN_PERM = 1;

/** 计算字段初始化 */
export const TASK_STATUS_INIT_FLAG = 0;

/** 计算字段异常 */
export const TASK_STATUS_INVALID_FLAG = 2;

/** 显示标识 */
export const SHOW_FLAG = 1;

/** 不显示标识 */
export const HIDDEN_FLAG = 0;
