/* eslint-disable react-hooks/exhaustive-deps */
import {css} from '@emotion/react';
import {Button, Card, Col, Form, Row} from 'antd';
import {useAtom} from 'jotai';
import React, {useContext, useEffect, useMemo} from 'react';
import useSWR from 'swr';

import {GET_USER_GROUP_ROLE} from '../../../../api/system/user';
import {pagePermsAtom} from '../../../../atoms/pagePermsAtom';
import {GlobalTooltip} from '../../../../components/GlobalTooltip';
import IconFont from '../../../../components/IconFont';
import TextView from '../../../../components/TextView';
import TwiceCheckButton from '../../../../components/TwiceCheckButton';
import tipsCode from '../../../../constants/tipsCode';
import {overflowScrollBar} from '../../../../global.css';
import useModalControl from '../../../../hooks/useModalControl';
import MyContext from '../helpers/MyContext';
import {
  AccountInfoTitle,
  cardBodyStyle,
  cardHeadStyle,
  RelateGroupTitle,
  RelateRoleTitle,
} from './CardTitles';
import EditAccountModal from './EditAccountModal';
import EditPwdModal from './EditPwdModal';

const formItemStyle = css`
  .ant-form-item-label {
    width: 98px;
    text-align: left;
    label {
      color: rgba(26, 34, 48, 0.7);
    }
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .ant-btn-link {
    padding: 0;
  }
  .ant-typography {
    color: #1a2230;
  }
`;

const AccountInfoCard = (props) => {
  const {userInfo} = props;

  const [pageInfo] = useAtom(pagePermsAtom);

  const [form] = Form.useForm();

  const accountModalControl = useModalControl();
  const passwordModalControl = useModalControl();

  const {data: userGroupRole} = useSWR(GET_USER_GROUP_ROLE);

  const groupRole = useMemo(() => {
    const groups = userGroupRole?.data?.groups ?? [];
    const roles = userGroupRole?.data?.roles ?? [];

    const startedGroups = groups.filter((v) => v.status === 1);
    const stoppedGroups = groups.filter((v) => v.status !== 1);

    const startedRoles = roles
      .filter((v) => v.status === 1)
      .map((r) => {
        const roleName = r?.roleName;
        return {...r, roleName};
      });

    const stoppedRoles = roles
      .filter((v) => v.status !== 1)
      .map((r) => {
        const roleName = r?.roleName;
        return {...r, roleName};
      });

    return {
      startedGroups,
      stoppedGroups,
      startedRoles,
      stoppedRoles,
    };
  }, [userGroupRole]);

  const {userInfo: userInfoContext = {}} = useContext(MyContext);

  const editPersonalPwd = () => {
    passwordModalControl._toggle(true);
  };

  const editAccountInfo = () => {
    accountModalControl._toggle(true);
  };

  useEffect(() => {
    form.setFieldsValue(userInfo?.data ?? {});
  }, [userInfo]);

  return (
    <>
      <Row gutter={12}>
        <Col span={8}>
          <Card
            css={css`
              ${cardHeadStyle}
              ${cardBodyStyle}
              .ant-card-body {
                height: 280px;
              }
            `}
            extra={
              <TwiceCheckButton
                disabled
                icon={<IconFont size={16} type="icon-edit" />}
                payload={{
                  functionName: `【${pageInfo.menuName}】-【编辑账号信息】`,
                }}
                size="small"
                type="text"
                onClick={editAccountInfo}
              />
            }
            title={<AccountInfoTitle />}
          >
            <Form css={formItemStyle} form={form} labelAlign="right">
              <Form.Item label="姓名" name="realName">
                <TextView />
              </Form.Item>
              <Form.Item label="账号" name="userName">
                <TextView />
              </Form.Item>
              <Form.Item label="密码" name="password">
                <Button type="link" onClick={editPersonalPwd}>
                  修改密码
                </Button>
              </Form.Item>
              <Form.Item label="身份证号" name="idCard">
                <TextView />
              </Form.Item>
              <Form.Item label="手机号码" name="phonenumber">
                <TextView />
              </Form.Item>
              <Form.Item label="电子邮箱" name="email">
                <TextView />
              </Form.Item>
              <Form.Item label="创建日期" name="createTime">
                <TextView />
              </Form.Item>
              <Form.Item label="上次登录日期" name="loginDate">
                <TextView />
              </Form.Item>
            </Form>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            css={css`
              ${cardHeadStyle}
              ${cardBodyStyle}
              .ant-card-body {
                height: 280px;
              }
            `}
            title={<RelateGroupTitle />}
          >
            <Row
              css={css`
                height: 100%;
              `}
            >
              <Col
                css={css`
                  ${overflowScrollBar}
                  overflow: auto;
                  height: 100%;
                `}
                span={12}
              >
                {groupRole.startedGroups.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        height: 32,
                        alignItems: 'center',
                        paddingLeft: 8,
                      }}
                      title="已启用"
                    >
                      <GlobalTooltip
                        code={tipsCode.PersonalCenter.GROUP_STATUS}
                      >
                        <IconFont
                          css={css`
                            color: #00a870;
                            font-size: 16px;
                            margin-right: 8px;
                          `}
                          type="icon-grouping"
                        />
                      </GlobalTooltip>
                      {item.groupName}
                    </div>
                  );
                })}
              </Col>
              <div
                css={css`
                  height: 100%;
                  width: 1px;
                  background-color: #eeeeee;
                `}
              />
              <Col
                css={css`
                  ${overflowScrollBar}
                  overflow: auto;
                  height: 100%;
                `}
                span={11}
              >
                {groupRole.stoppedGroups.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        height: 32,
                        alignItems: 'center',
                        paddingLeft: 8,
                      }}
                      title="已停用"
                    >
                      <GlobalTooltip
                        code={tipsCode.PersonalCenter.GROUP_STATUS}
                      >
                        <IconFont
                          css={css`
                            color: #e34d59;
                            font-size: 16px;
                            margin-right: 8px;
                          `}
                          type="icon-grouping"
                        />
                      </GlobalTooltip>
                      {item.groupName}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Card>
        </Col>
        <Col span={8}>
          <Card
            css={css`
              ${cardBodyStyle}
              ${cardHeadStyle}
              .ant-card-body {
                height: 280px;
              }
            `}
            title={<RelateRoleTitle />}
          >
            <Row
              css={css`
                height: 100%;
              `}
            >
              <Col
                css={css`
                  ${overflowScrollBar}
                  overflow: auto;
                  height: 100%;
                `}
                span={12}
              >
                {groupRole.startedRoles.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        height: 32,
                        alignItems: 'center',
                        paddingLeft: 8,
                      }}
                      title="已启用"
                    >
                      <GlobalTooltip code={tipsCode.PersonalCenter.ROLE_STATUS}>
                        <IconFont
                          css={css`
                            color: #00a870;
                            font-size: 16px;
                            margin-right: 8px;
                          `}
                          type="icon-user"
                        />
                      </GlobalTooltip>
                      {item.roleName}
                    </div>
                  );
                })}
              </Col>
              <div
                css={css`
                  height: 100%;
                  width: 1px;
                  background-color: #eeeeee;
                `}
              />
              <Col
                css={css`
                  ${overflowScrollBar}
                  overflow: auto;
                  height: 100%;
                `}
                span={11}
              >
                {groupRole.stoppedRoles.map((item, index) => {
                  return (
                    <div
                      key={index}
                      style={{
                        display: 'flex',
                        height: 32,
                        alignItems: 'center',
                        paddingLeft: 8,
                      }}
                      title="已停用"
                    >
                      <GlobalTooltip code={tipsCode.PersonalCenter.ROLE_STATUS}>
                        <IconFont
                          css={css`
                            color: #e34d59;
                            font-size: 16px;
                            margin-right: 8px;
                          `}
                          type="icon-user"
                        />
                      </GlobalTooltip>
                      {item.roleName}
                    </div>
                  );
                })}
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>

      <EditPwdModal modalControl={{...passwordModalControl}} />

      <EditAccountModal
        detail={
          userInfo?.data
            ? {...userInfo.data, idCard: userInfoContext.idCard}
            : {}
        }
        modalControl={{...accountModalControl}}
      />
    </>
  );
};

export default AccountInfoCard;
