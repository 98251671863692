import CryptoJS from 'crypto-js';

const secretKey = 'EpO3vEn7j7p37eBW5KxJAg==';

export const aesEncrypt = (content) => {
  if (!content) return '';
  const encrypted = CryptoJS.AES.encrypt(
    CryptoJS.enc.Utf8.parse(content),
    CryptoJS.enc.Base64.parse(secretKey),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return encrypted.toString();
};

export const aesDecrypt = (content) => {
  if (!content) return '';
  const decrypts = CryptoJS.AES.decrypt(
    content,
    CryptoJS.enc.Base64.parse(secretKey),
    {
      mode: CryptoJS.mode.ECB,
      padding: CryptoJS.pad.Pkcs7,
    }
  );
  return decrypts.toString(CryptoJS.enc.Utf8);
};
