import {Button, Modal, Space} from 'antd';
import {useAtom} from 'jotai';
import React, {useEffect} from 'react';

import {logicEditAtom} from '../../../../../../../atoms/logicEditAtom';
import {TableSheet} from '../../../../../../../components';
import {filterAtom} from '../../../../../../../components/Filter/atoms/filterAtom';
import GlobalModal from '../../../../../../../components/GlobalModal';
import {deepClone} from '../../../../../../../helpers/utils';
import useFilterDescription from '../../../hooks/useFilterDescription';

const FilterPreviewModal = () => {
  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);
  const {filterPreviewModalVisible} = totalStatus;

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {tableKey, conditions} = filterParams;

  const {filterRecord, getFilterRecordDes} = useFilterDescription();

  const onEditFilterHandle = (row) => {
    setTotalStatus((pre) => ({
      ...pre,
      filterPreviewModalVisible: false,
    }));
    setFilterParams((pre) => ({
      ...pre,
      visible: true,
      column: row,
    }));
  };

  const onDelFilterHandle = (row) => {
    Modal.confirm({
      title: '确定要删除筛选器？',
      content: '筛选器删除为不可逆操作，请确认',
      centered: true,
      onOk: (close) => {
        const delKey = row.aliasName;
        const conditionsCopy = deepClone(conditions);
        // const curTableFilters = conditionsCopy?.[tableKey]?.[delKey] || {};
        // const {orderType} = curTableFilters;
        // conditionsCopy[tableKey][delKey] = orderType ? {orderType} : {};

        conditionsCopy[tableKey][delKey] = {};

        setFilterParams({
          ...filterParams,
          conditions: conditionsCopy,
        });

        close();
      },
    });
  };

  useEffect(() => {
    if (filterPreviewModalVisible) {
      getFilterRecordDes();
    }
  }, [filterPreviewModalVisible, getFilterRecordDes]);

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 80,
      render: (val, row, index) => index + 1,
    },
    {
      title: '字段',
      dataIndex: 'indexInfo',
      width: 480,
      render: (val, row) => {
        return val;
      },
    },
    {
      title: '详细内容',
      dataIndex: 'record',
      width: 480,
      render: (val, row) => {
        return val;
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      width: 120,
      fixWidth: true,
      render: (val, row) => {
        return (
          <Space>
            <Button
              size="small"
              type="link"
              onClick={() => onEditFilterHandle(row)}
            >
              编辑
            </Button>

            <Button
              size="small"
              type="link"
              onClick={() => onDelFilterHandle(row)}
            >
              删除
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <GlobalModal
      destroyOnClose
      bodyStyle={{
        padding: '0 16px 10px',
        maxHeight: '800px',
        overflow: 'auto',
      }}
      footer={false}
      maskClosable={false}
      style={{maxWidth: '80vw'}}
      title="筛选器总览"
      visible={filterPreviewModalVisible}
      width={1200}
      wrapClassName="filterPreviewModal"
      onCancel={() =>
        setTotalStatus((pre) => ({
          ...pre,
          filterPreviewModalVisible: false,
        }))
      }
    >
      <TableSheet
        clearFilter={false}
        columns={columns}
        dataSource={filterRecord}
        pagination={false}
        refresh={false}
        rowKey="columnAlias"
        storeColumnWidthKey="filterPreviewTable"
      />
    </GlobalModal>
  );
};

export default FilterPreviewModal;
