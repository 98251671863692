import {css} from '@emotion/react';

export const indexRightHeader = css`
  /* display: flex; */
  padding-right: 12px;
  font-size: 12px;
`;

export const addFolder = css`
  display: flex;
  width: 68px;
  height: 26px;
  color: #4d5eff;
  font-size: 12px;
  padding-left: 6px;
  padding-right: 6px;
  border-color: #4d5eff;
  margin-right: 5px;
`;

export const addFolderStyle = css`
  color: #4d5eff;
  font-size: 12px;
  border-color: #4d5eff;
  margin-right: 5px;
  width: 100%;
`;

export const addDataBase = css`
  display: flex;
  width: 80px;
  height: 28px;
  background-color: #4d5eff;
  color: #ffffff;
  padding-left: 10px;
  padding-right: 10px;
  border: none;
  font-size: 14px;
`;

export const addFolderSpace = css`
  display: flex;
  align-items: center;
`;

export const addFolderText = css`
  position: relative;
  top: -1px;
`;

export const searchInput = css`
  height: 26px;
  font-size: 12px;
  flex: 1;
  margin-top: 6px;
  input::placeholder {
    font-size: 12px;
  }
`;

export const flexEndBox = css`
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
`;

export const infoBox = css`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #dfdfdf;
  border-radius: 2px;
  padding: 10px;
`;

export const infoItem = css`
  display: flex;
  align-items: center;
  margin-right: 20px;
`;

export const infoItemLabel = css`
  color: rgba(26, 34, 48, 0.7);
  line-height: 20px;
`;

export const infoItemText = css`
  color: #1a2230;
`;

export const fieldInfoCon = css`
  flex: 1;
  border-radius: 2px;
  height: 100%;
  margin-top: 10px;
`;

export const indexRightTree = css`
  width: 100%;
  padding-top: 8px;
  padding-right: 8px;
  height: 100%;
  overflow: auto;
  padding-bottom: 40px;
`;
