import {useAtom} from 'jotai';
import {useCallback} from 'react';

import {getAuthMenuTree} from '../api/system/menu';
import {authMenusAtom} from '../atoms/authMenus';
import {flattedMenusAtom} from '../atoms/flattedMenu';
import {whiteRouters} from '../constants/system';
import {flattern} from '../helpers/utils';

export default function useAuthMenuTree() {
  const [, setFlattedMenus] = useAtom(flattedMenusAtom);
  const [authMenus, setAuthMenusAtom] = useAtom(authMenusAtom);

  const getAuthMenuTreeHandle = useCallback(() => {
    // setAuthMenusAtom([]);
    // 获取菜单路由
    getAuthMenuTree().then((res) => {
      const menusData = res?.data || [];
      const finalMenus = [...menusData, ...whiteRouters];
      setAuthMenusAtom(finalMenus);
      setFlattedMenus(flattern(finalMenus));
    });
  }, [setAuthMenusAtom, setFlattedMenus]);

  return [authMenus, getAuthMenuTreeHandle];
}
