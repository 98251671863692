import {css} from '@emotion/react';
import React from 'react';

export const iconStyle = {
  size: 22,
  style: {marginRight: 6},
};

export const colorIconStyle = {
  size: 22,
  style: {marginRight: 6},
  color: '#1890ff',
};

export const cardHeadStyle = css`
  .ant-card-head {
    background: #fff;
    border: none;
    padding: 0 12px;
    min-height: auto;
    .ant-card-head-title {
      padding: 12px 0;
      padding-bottom: 0px;
      flex: none;
    }
    .ant-card-extra {
      padding: 12px 0;
      padding-bottom: 0px;
      margin-left: 0;
    }
  }
`;

export const cardBodyStyle = css`
  .ant-card-body {
    padding: 12px;
    height: auto;
    overflow: hidden;
  }
`;

const cardHeadTitle = css`
  font-size: 16px;
  color: #1a2230;
`;

export const AccountInfoTitle = () => {
  return <p css={cardHeadTitle}>账号信息</p>;
};

export const RelateGroupTitle = () => {
  return <p css={cardHeadTitle}>关联组织</p>;
};

export const RelateRoleTitle = () => {
  return <p css={cardHeadTitle}>关联角色</p>;
};

export const PermListTitle = () => {
  return <p css={cardHeadTitle}>权限列表</p>;
};

export const StationLetterTitle = () => {
  return <p css={cardHeadTitle}>站内信</p>;
};

export const ActionLogsTitle = () => {
  return <p css={cardHeadTitle}>操作日志</p>;
};
