import {css} from '@emotion/react';
import {Button, Divider} from 'antd';
import React, {useMemo, useRef, useEffect, useContext} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {
  downloadDetail,
  EXPORT_HISTORY,
  GET_EXPORT_HISTORY_LIST,
} from '../../../../../api/dataQuery/microDataQuery';
import GlobalModal from '../../../../../components/GlobalModal';
import TableSheetCopy from '../../../../../components/TableSheetCopy';
import {downloadBackEndFilename} from '../../../../../helpers/request/download';
import useSafeState from '../../../../../hooks/useSafeState';
import {EXPORT_DATATYPE_MAP, EXPORT_STATUS_MAP} from '../../helpers/constant';
import {infoItem, infoItemLabel, infoItemText} from '../../style.css';

function StatusText({color, status}) {
  return (
    <span
      css={css`
        display: flex;
        align-items: center;
        span {
          color: rgba(26, 34, 48, 0.7);
        }
      `}
    >
      <span
        css={css`
          background-color: ${color};
          width: 8px;
          height: 8px;
          border-radius: 50%;
          margin-right: 7px;
          display: block;
        `}
      />
      <span style={{color: '#1A2230'}}>{status}</span>
    </span>
  );
}

export default function ExportHistoryRecord({modalControl}) {
  const {wideOverview} = useContext(MicroDataQueryContext);

  const [exportDetailSource, setExportDetailSource] = useSafeState([]);
  const [detailLoading, setDetailLoading] = useSafeState(false);
  const [curDetailType, setCurDetailType] = useSafeState('--');
  const [curExport, setCurExport] = useSafeState({});

  const [searchParams, setSearchParams] = useSearchParams();

  const leftTableRef = useRef();

  const wideId = useMemo(() => {
    return searchParams.get('wideId');
  }, [searchParams]);

  const _downloadId = useMemo(() => {
    return searchParams.get('downloadId');
  }, [searchParams]);

  const onExportHandle = (downloadId, type) => {
    downloadBackEndFilename(EXPORT_HISTORY, {downloadId, type});
  };

  /** @type {import('../../../components/TableSheet/type').CustomColumnType[]} */
  const left_columns = [
    {
      title: '任务ID',
      dataIndex: 'id',
      fieldType: 'varchar',
      filterType: 'Common',
    },
    {
      title: '任务状态',
      dataIndex: 'status',
      enumList: [
        {label: '成功', value: 1},
        {label: '失败', value: 0},
        {label: '执行中', value: 2},
      ],
      filterType: 'Enum',
      render: (val) => {
        return (
          <StatusText
            color={EXPORT_STATUS_MAP.get(val).color}
            status={EXPORT_STATUS_MAP.get(val).text}
          />
        );
      },
    },
    {
      title: '数据量(条)',
      dataIndex: 'count',
      fieldType: 'int',
      filterType: 'Common',
    },
    {
      title: '任务提交时间',
      dataIndex: 'createTime',
      filterType: 'Common',
      fieldType: 'datetime',
    },
    {
      title: '任务结束时间',
      dataIndex: 'updateTime',
      filterType: 'Common',
      fieldType: 'datetime',
    },
    {
      title: '操作',
      align: 'right',
      dataIndex: 'operate',
      render: (val, record) => {
        return record.status === 2 ? null : (
          <Button
            disabled={record?.isExpire}
            size="small"
            style={{padding: 0, fontSize: 12}}
            type="link"
            onClick={() => {
              onExportHandle(record.id, record.status === 1 ? 1 : 2);
            }}
          >
            {record.status === 1 ? '导出' : '再次导出'}
          </Button>
        );
      },
    },
  ];

  const right_columns = [
    {
      title: '导出字段',
      dataIndex: 'exportCol',
    },
    {
      title: '筛选字段及内容',
      dataIndex: 'filterCol',
    },
  ];

  const getExportDetail = async (downloadId) => {
    try {
      setDetailLoading(true);
      const res = await downloadDetail({downloadId});
      const {
        exportColList = [],
        filterColList = [],
        dataType,
      } = res?.data || {};
      if (res?.code === 200) {
        const combin = exportColList.map((i, index) => ({
          exportCol: i,
          filterCol: filterColList[index] ?? '',
        }));
        setCurDetailType(EXPORT_DATATYPE_MAP.get(dataType));
        setExportDetailSource(combin);
        setDetailLoading(false);
      }
    } catch (error) {
      setExportDetailSource([]);
      setDetailLoading(false);
      throw new Error('get export detail failed');
    }
  };

  useEffect(() => {
    if (!modalControl.visible) {
      setExportDetailSource([]);
      setCurExport({});
    }
  }, [modalControl.visible, setCurExport, setExportDetailSource]);

  useEffect(() => {
    if (_downloadId && _downloadId !== 'null' && _downloadId !== 'undefined') {
      modalControl._toggle(true);
      getExportDetail(_downloadId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [_downloadId, modalControl]);

  return (
    <GlobalModal
      centered
      title="历史导出记录"
      width={972}
      {...modalControl}
      footer={null}
      onCancel={() => {
        searchParams.delete('downloadId');
        setSearchParams(searchParams);
        modalControl._toggle(false);
      }}
    >
      <p css={infoItem}>
        <span css={infoItemLabel}>表名：</span>
        <span css={infoItemText}>{wideOverview.name ?? '--'}</span>
      </p>

      <Divider style={{margin: '10px 0', marginBottom: 0}} />

      <div
        css={css`
          display: flex;
          padding-top: 10px;
        `}
      >
        <div
          css={css`
            width: 65%;
            border-right: 1px solid #f0f0f0;
            padding-right: 10px;
          `}
        >
          <TableSheetCopy
            clearFilter={false}
            columns={left_columns}
            dataApi={modalControl.visible ? GET_EXPORT_HISTORY_LIST : null}
            extraParams={{pathId: wideId}}
            recordRecurrent={false}
            ref={leftTableRef}
            refresh={false}
            rowKey="id"
            scroll={{y: '40vh'}}
            searchParamsUsable={false}
            storeColumnWidthKey="exportHistoryLeftTable"
            onRow={(record) => {
              return {
                onClick: (event) => {
                  if (record.id === curExport.id) {
                    return;
                  }
                  setCurExport(record);
                  getExportDetail(record?.id);
                },
              };
            }}
          />
        </div>
        <div
          css={css`
            width: 35%;
            padding-left: 10px;
          `}
        >
          <p
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 8px;
            `}
          >
            <span style={{fontSize: 14}}>导出任务详情</span>
            <p css={infoItem} style={{marginRight: 0}}>
              <span css={infoItemLabel}>导出范围：</span>
              <span css={infoItemText}>{curDetailType}</span>
            </p>
          </p>
          <TableSheetCopy
            fixedTableWidth
            clearFilter={false}
            columns={right_columns}
            dataSource={exportDetailSource}
            loading={detailLoading}
            pagination={false}
            recordRecurrent={false}
            refresh={false}
            rowKey="id"
            scroll={{y: '40vh'}}
            storeColumnWidthKey="exportHistoryRightTable"
          />
        </div>
      </div>
    </GlobalModal>
  );
}
