import {css} from '@emotion/react';

export const grayBy = '#F1F2F5';
export const whiteBg = '#FFFFFF';
export const repeatBg = 'rgba(227, 77, 89, 0.2)';
export const showColBorder = '#00a870';
export const hideColBorder = '#e34d59';

export const thCommonStyle = css`
  padding: 2px 10px;
`;

export const repeatTitleStyle = css`
  ${thCommonStyle}
  background: ${repeatBg};
`;

export const showTitleStyle = css`
  ${thCommonStyle}
  border-top: 2px solid ${showColBorder};
`;

export const hidedTitleStyle = css`
  ${thCommonStyle}
  border-top: 2px solid ${hideColBorder};
`;

export const showRowTitleStyle = css`
  .showRowTitleStyle {
    ${thCommonStyle}
    background: ${whiteBg};
    td.ant-table-cell:nth-of-type(1) {
      padding: 0 !important;
    }
  }
  .showCol {
    border-left: 2px solid ${showColBorder};
  }
  .hasRepeat {
    background: ${repeatBg};
  }
`;

export const hidedRowTitleStyle = css`
  .hidedRowTitleStyle {
    ${thCommonStyle}
    background: ${grayBy};
    td.ant-table-cell:nth-of-type(1) {
      padding: 0 !important;
    }
  }
  .hideCol {
    border-left: 2px solid ${hideColBorder};
  }
  .hasRepeat {
    background: ${repeatBg};
  }
`;
