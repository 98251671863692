import {css} from '@emotion/react';
import {Form, Table} from 'antd';
import React, {forwardRef, useImperativeHandle, useMemo} from 'react';

import {isObj} from '../../helpers/utils';
import Cell from './components/Cell';
import Row from './components/Row';
import {TableContext} from './helpers/context';

const components = {
  body: {
    row: Row,
    cell: Cell,
  },
};

function generateItem(source) {
  return Object.keys(source)?.reduce((prev, current) => {
    return {
      ...prev,
      [current]: Array.isArray(source[current])
        ? []
        : isObj(source[current])
        ? {}
        : '',
    };
  }, {});
}

/**
 * @param {import('./type').EditableTableProps} props
 */
function EditableTable(
  {
    dataSource,
    rowModel,
    onDataChange,
    shouldFieldValidate,
    onBlurHandler,
    onChangeHandler,
    columns: defaultColumns,
    dataFieldName = 'data',
    ...rest
  },
  ref
) {
  const model = useMemo(() => {
    return rowModel ? rowModel : generateItem(dataSource[0]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [tableForm] = Form.useForm();

  const initialValues = {
    [dataFieldName]: dataSource,
  };

  const columns = defaultColumns.map((col) => {
    return {
      ...col,
      onCell: (record, index) => ({
        onBlurHandler,
        onChangeHandler,
        shouldFieldValidate,
        ...col,
        record,
        index,
      }),
    };
  });

  function add(index) {
    const newItem = {...model};
    let temp;
    if (index >= 0) {
      temp = [...dataSource];
      temp.splice(index, 0, newItem);
    } else {
      temp = [...dataSource, newItem];
    }
    tableForm.setFieldsValue({
      [dataFieldName]: temp,
    });
    onDataChange(temp);
  }

  function remove(index) {
    let temp = [];
    if (index >= 0) {
      temp = [...dataSource];
      temp.splice(index, 1);
    }
    tableForm.setFieldsValue({
      [dataFieldName]: temp,
    });
    onDataChange(temp);
  }

  function onValuesChange(changedValues, allValues) {
    const temp = [...dataSource];
    let rowIndex = 0;
    let changedKey = '';
    for (let i = 0; i < changedValues[dataFieldName].length; i++) {
      if (changedValues[dataFieldName][i]) {
        temp.splice(i, 1, {
          ...temp[i],
          ...changedValues[dataFieldName][i],
        });
        rowIndex = i;
        changedKey = Object.keys(changedValues[dataFieldName][i])[0];
      }
    }
    onDataChange(temp, rowIndex, changedKey);
  }

  useImperativeHandle(ref, () => ({
    getFormInstance: () => tableForm,
    add,
    remove,
  }));

  return (
    <Form
      form={tableForm}
      initialValues={initialValues}
      size="small"
      onValuesChange={onValuesChange}
    >
      <Form.List name={dataFieldName}>
        {() => {
          return (
            <TableContext.Provider value={{tableForm}}>
              <Table
                columns={columns}
                components={components}
                css={css`
                  .ant-table-thead {
                    height: 34px;
                  }
                  th.ant-table-cell {
                    padding: 3px 10px !important;
                    /* vertical-align: bottom; */
                    min-height: 34px;
                  }
                  .ant-table-row {
                    height: 24px;
                  }
                  td.ant-table-cell {
                    padding: 0px 10px !important;
                    line-height: 14px;
                    font-size: 12px;
                    max-height: 24px;
                  }
                `}
                dataSource={dataSource}
                pagination={false}
                {...rest}
              />
            </TableContext.Provider>
          );
        }}
      </Form.List>
    </Form>
  );
}

export default forwardRef(EditableTable);
