import React from 'react';

import WideTableContainer from './WideTableContainer';

export default function MicroDataQueryInfo() {
  return (
    <div style={{height: '100%'}}>
      <WideTableContainer />
    </div>
  );
}
