import {useMemo} from 'react';
import useSWR from 'swr';

import {DICT_TYPE_LIST} from '../api/system/sysSetting';
import {getObjectFromDictArray} from '../helpers/utils';

/**
 * 字典处理逻辑
 * @param {String} dictType 字典类型
 */
export default function useSysSettingData(dictType) {
  const {data: dataRes, ...rest} = useSWR(`${DICT_TYPE_LIST}/${dictType}`);
  const data = dataRes?.data || [];

  const objectData = useMemo(() => {
    return getObjectFromDictArray(dataRes?.data || []);
  }, [dataRes]);

  return {
    data,
    objectData,
    ...rest,
  };
}
