import {css} from '@emotion/react';
import React from 'react';

import EditorContent from './EditorContent';
import EditorHeader from './EditorHeader';

export default function Editor() {
  return (
    <div
      css={css`
        margin: 0 9px 0 13px;
        flex: 1;
      `}
    >
      <EditorHeader />
      <EditorContent />
    </div>
  );
}
