import React from 'react';

export default function FormRequireLabel(props) {
  return (
    <span style={{color: 'rgba(26, 34, 48, 0.7)'}}>
      {props.title ?? props.children}
      <span style={{color: '#E34D59'}}>*</span>
    </span>
  );
}
