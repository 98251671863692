import request from '../../helpers/request';

/** 系统通知-提醒所有 */
export const QUERY_FORCE_CHECK_NOTICE = '/user/notice/queryForceCheckNotice';

/** 系统通知-提醒所有 */
export function readNotice(id) {
  return request({
    url: `/user/notice/hasRead/${id}`,
    method: 'put',
  });
}
