import {useEffect, useRef} from 'react';
import {useLocalStorage} from 'react-use';

import {throttle} from '../../../helpers/utils';
import useSafeState from '../../../hooks/useSafeState';
import {handleResize} from '../helper';

export function useScroll(scroll, isValidating, tableWidth, tableName) {
  const [_scroll, setScroll] = useSafeState({});
  const isMount = useRef();
  const [widthStorage] = useLocalStorage(`_resizeColumn_${tableName}`);

  useEffect(() => {
    const updateScroll = (newScroll) => {
      setScroll((pre) => ({
        ...pre,
        ...(scroll ?? {x: tableWidth, y: newScroll}),
      }));
    };

    const handleResizeThrottle = throttle(() => handleResize(updateScroll));

    setTimeout(() => {
      handleResize(updateScroll);
    }, 0);

    // 仅初始化执行
    if (!isMount.current && tableWidth && widthStorage) {
      isMount.current = true;
      const tableNode = document.querySelector(`.${tableName}-tablesheet`);
      const tableClientWidth = tableNode.clientWidth;
      tableNode.querySelector(`.${tableName}-tablesheet-content`).style.width =
        tableWidth < tableClientWidth ? `${tableWidth}px` : '100%';
    }

    window.removeEventListener('resize', handleResizeThrottle);
    window.addEventListener('resize', handleResizeThrottle);

    return () => {
      window.removeEventListener('resize', handleResizeThrottle);
    };
  }, [isValidating, scroll, tableWidth, setScroll, tableName, widthStorage]);

  return {_scroll};
}
