import {atom} from 'jotai';

const defaultSqlWideEditAtom = {
  // 选中的数据库id
  sourceDbId: null,

  // 预览数据接口key值
  previewKey: null,

  // 预览表 高度
  previewTableHeight: 180,

  // 预览表 loading
  previewTableLoading: false,

  // 预览失败
  previewError: '',

  // 横向表数据
  previewXColumn: [],
  previewXData: [],

  // 纵向表数据
  previewYData: [],

  preSourceDbId: null,
};

const sqlWideEditAtom = atom(defaultSqlWideEditAtom);

export {sqlWideEditAtom, defaultSqlWideEditAtom};
