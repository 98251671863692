import React from 'react';

import IconFont from '../IconFont';
import StatusIcon from './StatusIcon';
import StatusTab from './StatusTab';
import StatusTag from './StatusTag';
import StatusTags, {StatusTagsWrapper} from './StatusTags';

/**
 * @typedef {'success' | 'error'} Status
 */

export const STATUS_MAP = {
  success: {
    bgColor: '#00A870',
    iconColor: '#1b8b32',
    fontColor: 'rgba(26, 34, 48, 0.7000)',
    icon: IconFont,
    iconType: 'icon-check-circle-filled',
  },
  error: {
    bgColor: 'rgba(26, 34, 48, 0.4000)',
    iconColor: '#d04437',
    fontColor: 'rgba(26, 34, 48, 0.2500)',
    icon: IconFont,
    iconType: 'icon-close-circle-filled',
  },
};

export const TAB_STATUS_MAP = {
  success: {
    bgColor: '#19be6b',
    iconColor: '#1b8b32',
    fontColor: '#000',
    iconType: 'icon-check-circle-filled',
  },
  error: {
    bgColor: '#ed4014',
    iconColor: '#d04437',
    fontColor: '#fff',
    icon: IconFont,
    iconType: 'icon-close-circle-filled',
  },
};

const StatusItem = ({children}) => {
  return <div>{children}</div>;
};

StatusItem.Icon = StatusIcon;
StatusItem.TagsWrapper = StatusTagsWrapper;
StatusItem.Tags = StatusTags;
StatusItem.Tag = StatusTag;
StatusItem.Tab = StatusTab;

export default StatusItem;
