import {css} from '@emotion/react';
import React, {
  useContext,
  useEffect,
  useCallback,
  useRef,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {specifyFilterTypeByColumnType} from '../../../../../components/Filter/helpers';
import WarningModal from '../../../../../components/GlobalModal/WarningModal';
import TableSheetCopy from '../../../../../components/TableSheetCopy';
import {NumberTypeArr} from '../../../../../components/TableSheetCopy/type';
import useModalControl from '../../../../../hooks/useModalControl';
import {usePaginationUrlParams} from '../../../../../hooks/usePaginationUrlParams';

const WideTable = (props, ref) => {
  const {
    columnList,
    dataSource,
    updateTable,
    total,
    setFormatColumns,
    storeColumnWidthKey,
    loading,
    setFilterInfo,
    columns,
    setColumns,
    // queryMenuRef,
  } = useContext(MicroDataQueryContext);

  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');

  const tableRef = useRef();

  const nextStepRef = useRef();

  const cancelWarningModalControl = useModalControl();

  const {pageSize} = usePaginationUrlParams();

  // const dataSourceShow = useMemo(() => {
  //   return (dataSource ?? []).map((dataItem) => {
  //     const keys = Object.keys(dataItem);
  //     const newDataItem = {};
  //     keys.forEach((key) => {
  //       const upperKey = key.toUpperCase();
  //       newDataItem[upperKey] = dataItem[key];
  //     });
  //     return newDataItem;
  //   });
  // }, [dataSource]);

  const makeColumns = useCallback(
    (list) => {
      return list
        .map((col, i) => {
          const finalCol = {
            ...col,
            showSort: false,
            dataIndex: col.indexOriName.toUpperCase(),
            align: NumberTypeArr.some((colType) =>
              col.indexType.includes(colType)
            )
              ? 'right'
              : 'left',
            draggable: true,
            render(value, row, index) {
              return value;
            },
          };

          finalCol.fieldType = col.indexType;
          finalCol.title = col.curShowName || col.indexOriName;
          finalCol.titleWidth = (col.curShowName?.length ?? 0) * 18 + 20;

          finalCol.filterType = col.periodFlag
            ? 'Period'
            : col.foreignKeyFlag === 1
            ? 'FatherSon'
            : col.foreignKeyFlag === 2 || col.sectionId
            ? 'Enum'
            : 'Common';
          finalCol.onFilterSearch = (conditions) => {
            tableRef.current?.setPage(1, pageSize);
            updateTable(conditions[storeColumnWidthKey], [1]);
          };

          return finalCol;
        })
        .filter(Boolean);
    },
    [pageSize, storeColumnWidthKey, updateTable]
  );

  const refreshColumns = useCallback(() => {
    const res = makeColumns(columnList);

    setColumns(res);
    setFormatColumns(
      makeColumns(columnList).map((item) => {
        if (item.queryCalId) {
          return {
            ...item,
            filterType: 'Common',
            fieldType: item.indexType,
          };
        }
        return item;
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [columnList]);

  useEffect(() => {
    refreshColumns();
  }, [refreshColumns]);

  useImperativeHandle(ref, () => ({
    refreshColumns,
  }));

  return (
    <>
      <TableSheetCopy
        clearFilter={false}
        columns={columns
          .filter((col) => col.showFlag !== false)
          .map((col) => {
            return specifyFilterTypeByColumnType(col);
          })
          .map((col) => {
            // columnExt: 1 有索引
            return {...col, directFilter: col?.columnExt === 1};
          })}
        css={css`
          .ant-table-tbody {
            position: relative;
          }
        `}
        dataSource={(dataSource ?? []).map((dataItem) => {
          const keys = Object.keys(dataItem);
          const newDataItem = {};
          keys.forEach((key) => {
            const upperKey = key.toUpperCase();
            newDataItem[upperKey] = dataItem[key];
          });
          return newDataItem;
        })}
        enableGetFilterInfo={false}
        getFilterInfo={setFilterInfo}
        key={wideId}
        loading={loading}
        pagination={{
          total,
          pageSizeOptions: [10, 20, 50, 100, 1000],
          pageSize: 20,
        }}
        // permCode={FORM_PERMS_MAP.get('微观数据查询-数据宽表详情表单')}
        ref={tableRef}
        refresh={false}
        restHeight={166}
        rowKey="id"
        storeColumnWidthKey={storeColumnWidthKey}
      />

      <WarningModal
        confirm={() => {
          cancelWarningModalControl._toggle(false);
          nextStepRef.current?.();
        }}
        description="当前操作内容未保存，请确认是否放弃"
        modalControl={cancelWarningModalControl}
        title="确认是否放弃保存？"
        zIndex={99999}
      />
    </>
  );
};

export default forwardRef(WideTable);
