import {Button, notification, Space} from 'antd';
import {useAtom} from 'jotai';
import React, {useEffect} from 'react';

import {secFilterValueMap} from '..';
import {getSectionConfigDetail} from '../../../../api/dataManagement/microIndex';
import {
  getCodeTableData,
  getDmsMeasureUnit,
  getIndexSectionId,
} from '../../../../api/dataQuery/microDataQuery';
import {filterAtom} from '../../../../components/Filter/atoms/filterAtom';
import TableSheetCopy from '../../../../components/TableSheetCopy';
import {bigNumberTransform, deepClone} from '../../../../helpers/utils';

export default function FilterTableEditor({
  dataSource,
  filterConditions,
  storeColumnWidthKey,
  updateCacheFilterHandle = (v) => v,
  cacheFilterConditions,
  exceptDynamicTag = false,
  curGroup = {},
  modalType = '',
  ...rest
}) {
  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  const generateFilterConditions = (condition) => {
    condition = condition ?? [];
    const obj = {};
    condition.forEach((i) => {
      if (i.aliasName) {
        obj[i.aliasName] = {
          ...i,
          valueList: i?.valueList?.map((v) => decodeURIComponent(v)),
        };
      }
    });
    const conditionsCopy = deepClone(conditions);
    conditionsCopy[storeColumnWidthKey] = obj;
    setFilterParams({
      ...filterParams,
      conditions: conditionsCopy,
    });
  };

  async function getSectionEnum(record) {
    const sectionId = await getIndexSectionId({
      indexId: record.indexId,
    });
    const config = await getSectionConfigDetail({
      id: sectionId.data,
    }).catch((err) => {
      if (err.data.code === 512) {
        notification.error({description: err.data.msg});
      }
    });
    const unit = await getDmsMeasureUnit({
      id: config.data.unitId,
    }).catch((err) => {
      if (err.data.code === 512) {
        notification.error({description: err.data.msg});
      }
    });
    const configJson = JSON.parse(config.data.configJson);
    const unitName = unit.data.name;
    const res = configJson.map((item, index) => {
      if (item.isSectionNameEdited) {
        return {
          label: item.customStratified,
          value: JSON.stringify({
            left: item.leftInput,
            right: item.rightInput,
            symbol: secFilterValueMap.get(item.connectValue.split('_')[1]),
            label: item.customStratified,
          }),
        };
      }
      let combConnect;
      let defaultCustomValue;
      const leftInput = item.leftInput;
      const rightInputValue = item.rightInput;
      const connect = item.connectValue.split('_')[1];
      if (index === 0 || index === configJson.length - 1) {
        combConnect = [connect];
      } else {
        const _combConnect = connect?.split('且') ?? [];
        combConnect = _combConnect;
      }
      if (!rightInputValue) {
        defaultCustomValue = '';
      } else if (index === 0) {
        defaultCustomValue = `${combConnect[0]}${bigNumberTransform(
          rightInputValue
        )}${unitName}`;
      } else if (index === configJson.length - 1) {
        defaultCustomValue = `${combConnect[0]}${bigNumberTransform(
          leftInput
        )}${unitName}`;
      } else {
        defaultCustomValue = `${combConnect[0]}${bigNumberTransform(
          leftInput
        )}${unitName}，${combConnect[1]}${bigNumberTransform(
          rightInputValue
        )}${unitName}`;
      }
      return {
        label: defaultCustomValue,
        value: JSON.stringify({
          left: item.leftInput,
          right: item.rightInput,
          symbol: secFilterValueMap.get(item.connectValue.split('_')[1]),
          label: defaultCustomValue,
        }),
      };
    });
    return res;
  }
  async function getForeignEnum(record) {
    const data = await getCodeTableData({
      tableId: +record.tableId,
      colId: record.colId,
      wideId: record.wideId,
    });
    return data;
  }

  async function getForeignFatherSon(record) {
    const data = await getCodeTableData({
      tableId: +record.tableId,
      colId: record.colId,
      wideId: record.wideId,
    });
    return data;
  }

  const editFilterHandle = (record) => {
    setFilterParams({
      ...filterParams,
      tableKey: storeColumnWidthKey,
      visible: true,
      column: {
        ...record,
        title: record.curShowName,
        filterType: record.filterType,
        fieldType: record.fieldType,
        aliasName: record.aliasName,
        enumList: async () => {
          if (
            record.foreignKeyFlag === 0 &&
            !record.periodFlag &&
            record.sectionId
          ) {
            const res = await getSectionEnum(record);
            return res;
          } else if (
            !record.periodFlag &&
            record.foreignKeyFlag === 2 &&
            !record.sectionId
          ) {
            const {data} = await getForeignEnum(record);
            return data;
          }
          return [];
        },
        fatherSonList: async () => {
          if (!record.periodFlag && record.foreignKeyFlag === 1) {
            const {data} = await getForeignFatherSon(record);
            return data;
          }
          return [];
        },
        onFilterSearch: (con) => {
          updateCacheFilterHandle(con);
        },
      },
    });
  };

  useEffect(() => {
    const clone = deepClone(filterConditions);
    generateFilterConditions(clone);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (cacheFilterConditions) {
      const clone = deepClone(cacheFilterConditions);
      generateFilterConditions(clone);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cacheFilterConditions]);

  const columns = [
    {
      title: '字段',
      dataIndex: 'curShowName',
      render: (val, record) => {
        const condition = record?.canSelect ?? 1;
        return condition === 1 ? (
          val
        ) : (
          <span>
            <span style={{color: 'rgba(26, 34, 48, 0.4)'}}>{val}</span>
            <span style={{color: '#E34D59'}}>{`(权限不足)`}</span>
          </span>
        );
      },
    },
    {
      title: '筛选内容',
      dataIndex: 'record',
      width: 340,
      render: (val, record) => {
        return val;
      },
    },
    {
      title: '操作',
      dataIndex: 'operate',
      width: 100,
      fixWidth: true,
      align: 'right',
      render: (val, record) => {
        if (record?.canSelect === 0) {
          return null;
        } else if (record.record) {
          return (
            <Space>
              <Button
                disabled={modalType === 'tagManage' && curGroup?.type === 0}
                size="small"
                style={{padding: 0, fontSize: 12}}
                type="link"
                onClick={() => {
                  editFilterHandle(record);
                }}
              >
                编辑
              </Button>
              <Button
                disabled={modalType === 'tagManage' && curGroup?.type === 0}
                size="small"
                style={{padding: 0, fontSize: 12}}
                type="link"
                onClick={() => {
                  const conditionsCopy = deepClone(conditions);
                  conditionsCopy[storeColumnWidthKey][record.aliasName] = {};
                  setFilterParams({
                    ...filterParams,
                    conditions: conditionsCopy,
                  });
                  updateCacheFilterHandle(conditionsCopy);
                }}
              >
                清除
              </Button>
            </Space>
          );
        }
        return (
          <Button
            disabled={
              modalType === 'tagManage'
                ? curGroup?.type === 0
                : curGroup?.type === 0 && !!record?.queryCalId
            }
            size="small"
            style={{padding: 0, fontSize: 12}}
            type="link"
            onClick={() => {
              editFilterHandle(record);
            }}
          >
            添加
          </Button>
        );
      },
    },
  ];

  return (
    <TableSheetCopy
      // headerDraggable
      fixedTableWidth
      clearFilter={false}
      columns={columns}
      dataSource={dataSource}
      pagination={false}
      recordRecurrent={false}
      refresh={false}
      rowKey="a"
      storeColumnWidthKey={storeColumnWidthKey}
      {...rest}
    />
  );
}
