import {useAtom} from 'jotai';
import {useCallback} from 'react';

import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import {filterAtom} from '../../../../../components/Filter/atoms/filterAtom';
import useSafeState from '../../../../../hooks/useSafeState';

/**
 * @description: 检查页面是否发生过编辑变化
 * @return {[
 *  result: Boolean
 *  checkChanged: Function
 * ]}
 */
export default function useDataChangeCheck() {
  const [changed, setChanged] = useSafeState(false);

  const [totalStatus] = useAtom(logicEditAtom);

  const [filterParams] = useAtom(filterAtom);
  const {tableKey, conditions} = filterParams;

  const {
    pathId,
    _pathId,

    tableName,
    _tableName,

    graphData,
    graphOriginData,

    previewXOriginColumns,

    modifiedComputedColumns,
  } = totalStatus;

  const {tableStructureList: newCanvasTable = []} = graphData;
  const {frontParam, tableStructureList: oldCanvasTable = []} = graphOriginData;

  const checkChanged = useCallback(() => {
    // 修改了关联文件夹
    const pathIdChanged = pathId !== _pathId;

    // 修改了表名
    const tableNameChanged = tableName !== _tableName;

    //
    const sameLen = newCanvasTable.length === oldCanvasTable.length;
    const sameIds = oldCanvasTable.every((ot) =>
      newCanvasTable.some((nt) => nt.tableId === ot.tableId)
    );
    const sameFields = oldCanvasTable.every((ot) => {
      const {
        //
        joinCondition,
        joinLogicalColumns,
        level,
        parentTableId,
        tableId,
      } = ot;

      const newTable = newCanvasTable.find((nt) => nt.tableId === tableId);

      const result =
        newTable &&
        joinCondition === newTable.joinCondition &&
        level === newTable.level &&
        parentTableId === newTable.parentTableId &&
        JSON.stringify(joinLogicalColumns) ===
          JSON.stringify(newTable.joinLogicalColumns);

      return result;
    });

    // 修改了接入的数据表
    const canvasTalbleChanged = !sameLen || !sameIds;

    // 修改了关联关系逻辑
    const canvasTalbleRelationChanged = !sameLen || !sameFields;

    // 修改了字段名称/显示/隐藏状态
    const columnFieldsChanged = previewXOriginColumns
      .filter((col) => !col.formula)
      .some((col) => {
        const {isShow, _show} = col;
        const {title, _title} = col;

        return isShow !== _show || title !== _title;
      });

    // 修改了筛选器
    const filterChanged =
      JSON.stringify(conditions[tableKey]) !==
      JSON.stringify(JSON.parse(frontParam ?? '{}'));

    // 修改了计算字段名称/内容
    const hasCalcInfoChanged = previewXOriginColumns
      .filter((col) => col.formula)
      .some((col) => {
        const {isShow, _show} = col;
        const {title, _title} = col;

        return isShow !== _show || title !== _title;
      });

    const hasNewCalc =
      modifiedComputedColumns.filter((col) => !col.calId).length > 0;
    const hasClacEdited =
      modifiedComputedColumns.filter((col) => col.calId).length > 0;
    const calcFieldChanged = hasCalcInfoChanged || hasNewCalc || hasClacEdited;

    const result =
      pathIdChanged ||
      tableNameChanged ||
      canvasTalbleChanged ||
      canvasTalbleRelationChanged ||
      columnFieldsChanged ||
      filterChanged ||
      calcFieldChanged;

    const resultDetail = {
      pathIdChanged,
      tableNameChanged,
      canvasTalbleChanged,
      canvasTalbleRelationChanged,
      columnFieldsChanged,
      filterChanged,
      calcFieldChanged,
    };

    setChanged({result, resultDetail});

    return {result, resultDetail};
  }, [
    _pathId,
    _tableName,
    modifiedComputedColumns,
    conditions,
    frontParam,
    newCanvasTable,
    oldCanvasTable,
    pathId,
    previewXOriginColumns,
    setChanged,
    tableKey,
    tableName,
  ]);

  return [changed, checkChanged];
}
