import {FormOutlined, PlusOutlined, DeleteOutlined} from '@ant-design/icons';
import {css} from '@emotion/react';
import {Button, Select, Tag} from 'antd';

import useTableFields from './useTableFields';

export const FORM_ITEM_FIELD = 'form_item_field';

export const formItemCss = css`
  margin-bottom: 12px;
`;

const antTagCloseIconStyle = css`
  .ant-tag-close-icon {
    color: var(--ant-error-color);
  }
`;

export const getArrRepeatValid = (valueList, curVal, carKey) => {
  let [hasRepeatValue, rejectMsg] = [false, ''];
  for (let i = 0; i < valueList.length; i++) {
    if (
      i !== carKey &&
      valueList[i] &&
      JSON.stringify(valueList[i]) === JSON.stringify(curVal)
    ) {
      hasRepeatValue = true;
      rejectMsg = `当前字段选项与【索引${i + 1}】重复，请重新设置`;
      break;
    }
  }
  return {hasRepeatValue, rejectMsg};
};

export const scrollIntoView = (targetCalssName) => {
  if (targetCalssName) {
    const target = document.getElementsByClassName(`${targetCalssName}`)[0];
    if (target) {
      target.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }
  }
};

export const ScrollWraper = ({children}) => (
  <div
    css={css`
      padding: 12px 24px 0 24px;
      max-height: 528px;
      min-height: 104px;
      overflow-y: overlay;
      &::-webkit-scrollbar {
        width: 8px;
        height: 8px;
      }
      &::-webkit-scrollbar-thumb {
        background-color: rgba(0, 13, 26, 0.2);
        border-radius: 10px;
      }
    `}
  >
    {children}
  </div>
);

export const ConfigIndexBtn = ({className, ...rest}) => {
  return (
    <div
      className={className}
      css={css`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
      `}
    >
      <Button icon={<FormOutlined />} size="small" {...rest}>
        配置索引
      </Button>
    </div>
  );
};

export const AddIndexBtn = (props) => {
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: 12px 24px 24px;
      `}
    >
      <section
        css={css`
          margin-right: 32px;
        `}
      >
        ！适当使用索引可以提高筛选速度，盲目使用索引会导致存储资源浪费，建议只为常用字段创建索引
      </section>
      <Button icon={<PlusOutlined />} size="small" {...props}>
        新增索引
      </Button>
    </div>
  );
};

export const ReSelect = ({
  titleIdx,
  createWideTableType,
  onDelete,
  ...rest
}) => {
  const {fieldOptions, Field_SHOW_STATE} = useTableFields(createWideTableType);

  const tagRender = (props) => {
    const {label, value, ...restProps} = props;
    const onPreventMouseDown = (event) => {
      event.preventDefault();
      event.stopPropagation();
    };

    return (
      <Tag
        {...restProps}
        color={Field_SHOW_STATE.get(value) ? null : 'error'}
        css={css`
          ${!Field_SHOW_STATE.get(value) && antTagCloseIconStyle}
        `}
        style={{marginRight: 3}}
        onMouseDown={onPreventMouseDown}
      >
        {label.split('(')[0]}
      </Tag>
    );
  };

  return (
    <div
      css={css`
        padding: 8px 12px 12px 12px;
        border-radius: 2px;
        box-shadow: 0 1px 2px -2px #00000029, 0 3px 6px #0000001f,
          0 5px 12px 4px #00000017;
        user-select: none;
      `}
    >
      <section>
        <span
          css={css`
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}
        >
          {titleIdx}
          <DeleteOutlined
            style={{cursor: 'pointer', color: 'var(--ant-error-color)'}}
            onClick={onDelete}
          />
        </span>
      </section>
      <section
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
        `}
      >
        <span
          css={css`
            width: 68px;
            margin-right: 4px;
            display: flex;
            align-items: flex-start;
            &::before {
              content: '*';
              display: flex;
              align-items: flex-start;
              width: 8px;
              height: 100%;
              color: var(--ant-error-color);
            }
          `}
        >
          索引字段
        </span>
        <Select
          allowClear
          css={css`
            width: calc(100% - 72px) !important;
          `}
          mode="multiple"
          options={fieldOptions}
          placeholder="请选择索引项"
          tagRender={tagRender}
          {...rest}
        />
      </section>
    </div>
  );
};
