import request from '../helpers/request';

/** 通用上传API */
export function runUploadFile(data) {
  return request({
    url: '/user/file/upload',
    method: 'post',
    data,
  });
}

/**
 * @description: 检查功能点是否被引用
 * @param {object} params
 * @param {number} params.id   行数据id
 * @param {number} params.delType   待删除的类型，1：实例，2：库，3：表，4：字段 6：计量管理
 */
export function hasQuoteFlag(params) {
  return request({
    url: 'dms/quote/quoteFlag',
    method: 'get',
    params,
  });
}

export const UPLOAD_FILE = '/user/file/upload';

export const DOWNLOAD_HELP_DOC = '/user/setting/downloadHelpDoc';
