import CryptoJS from 'crypto-js';
import JSEncrypt from 'jsencrypt/bin/jsencrypt.min';
import {parseBigInt} from 'jsencrypt/lib/lib/jsbn/jsbn';

import {getWideSecurityAesKey} from '../api/system/sysSetting';

// 密钥对生成 http://web.chacuo.net/netrsakeypair

// 公钥
const publicKey =
  'MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQC7cBNJGYG/ZrTOixWR8cTDqE3zC3QrR3Tw4RhUC6ao8BgPXY0/RbL6F/OxfZKfPm1Ue/TYIi9SrhSvGQK9Qlv45c1/k/bqNV7f87AS6rQYrklvnGz6JvSMEla7iTt/cMbwVpELwY444Yn/9gVrLr4b0TA/MgPhE4Ku9xHWocTVXwIDAQAB';
// 私钥
// const privateKey = '';

function pkcs1unpad2(d, n) {
  const b = d.toByteArray();
  let i = 0;
  while (i < b.length && b[i] === 0) {
    ++i;
  }
  ++i;
  while (b[i] !== 0) {
    if (++i >= b.length) {
      return null;
    }
  }
  let ret = '';
  while (++i < b.length) {
    const c = b[i] & 255;
    if (c < 128) {
      // utf-8 decode
      ret += String.fromCharCode(c);
    } else if (c > 191 && c < 224) {
      ret += String.fromCharCode(((c & 31) << 6) | (b[i + 1] & 63));
      ++i;
    } else {
      ret += String.fromCharCode(
        ((c & 15) << 12) | ((b[i + 1] & 63) << 6) | (b[i + 2] & 63)
      );
      i += 2;
    }
  }
  return ret;
}

// 加密
export function encrypt(txt) {
  const encryptor = new JSEncrypt();
  // 设置公钥
  encryptor.setPublicKey(publicKey);
  // 对数据进行加密
  return encryptor.encrypt(txt);
}

// 公钥解密
export function decrypt(txt) {
  const encryptor = new JSEncrypt();
  encryptor.setPublicKey(publicKey);
  const rsaKey = encryptor.getKey();
  // eslint-disable-next-line func-names
  rsaKey.decrypt = function (ctext) {
    const c = parseBigInt(ctext, 16);
    const m = this.doPublic(c);
    if (m === null) {
      return null;
    }
    return pkcs1unpad2(m, (this.n.bitLength() + 7) >> 3);
  };
  return encryptor.decrypt(txt);
}

// 加密 AES - ECB 模式
export async function ecbEncrypt(txt) {
  try {
    const result = await getWideSecurityAesKey();
    if (result?.data) {
      const key = CryptoJS.enc.Utf8.parse(decrypt(result.data));
      const encrypter = CryptoJS.AES.encrypt(txt, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return encrypter.toString();
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
}

// 解密 AES - ECB 模式
export async function ecbDecrypt(txt) {
  try {
    const result = await getWideSecurityAesKey();
    if (result?.data) {
      const key = CryptoJS.enc.Utf8.parse(decrypt(result.data));
      const decrypter = CryptoJS.AES.decrypt(txt, key, {
        mode: CryptoJS.mode.ECB,
        padding: CryptoJS.pad.Pkcs7,
      });
      return CryptoJS.enc.Utf8.stringify(decrypter).toString();
    }
    return '';
  } catch (e) {
    console.error(e);
    return '';
  }
}
