import {css} from '@emotion/react';
import React from 'react';

const MousePositionModal = (props) => {
  const {visible, content, left, top} = props;

  return (
    <div
      className="mouse-position-modal"
      css={css`
        background: #16234e;
        box-shadow: 0 2px 4px 0 rgba(38, 45, 115, 0.1);
        border-radius: 2px;
        position: fixed;
        z-index: 2000;
        padding: 4px 10px;
        opacity: 0.8;
        .mouse-position-modal-content {
          font-size: 14px;
          color: #ffffff;
        }
      `}
      id="mouse-position-modal"
      style={{
        left: `${left}px`,
        top: `${top}px`,
        display: `${visible ? 'block' : 'none'}`,
      }}
    >
      <div className="mouse-position-modal-content">{content}</div>
    </div>
  );
};

export default MousePositionModal;
