import {css} from '@emotion/react';
import {Tag} from 'antd';
import React from 'react';

import {TAB_STATUS_MAP} from '.';

/**
 * @param {object} props
 * @param {string} props.label
 * @param {import('.').Status} props.status
 * @param {{[key: string]: any;}=} props.data
 * @param {function=} props.onClick
 * @param {import('@emotion/react').SerializedStyles=} props.css
 */
const StatusTab = ({
  children,
  label,
  status,
  data,
  onClick,
  css: Styles,
  ...rest
}) => {
  const mappedStatus = TAB_STATUS_MAP[status];

  function handleClick() {
    if (onClick) onClick(data);
  }

  return (
    <Tag
      color={mappedStatus?.bgColor}
      css={css`
        cursor: ${onClick ? 'pointer' : 'default'};
        color: ${mappedStatus?.fontColor};
        ${Styles}
      `}
      onClick={handleClick}
      {...rest}
    >
      {children ? children : label}
    </Tag>
  );
};

export default StatusTab;
