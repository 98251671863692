import {useState} from 'react';

export function usePaginationOriginal({
  currentPageName = 'pageNum',
  pageSizeName = 'pageSize',
  defaultPageSize = 20,
} = {}) {
  const [pagenationParams, setPagenationParams] = useState({
    [currentPageName]: 1,
    [pageSizeName]: defaultPageSize,
  });

  const setPage = (newPageNum, newPageSize) => {
    setPagenationParams((pre) => ({
      ...pre,
      [currentPageName]: newPageNum,
      [pageSizeName]: newPageSize,
    }));
  };

  return {
    pageNum: pagenationParams[currentPageName],
    pageSize: pagenationParams[pageSizeName],
    setPage,
  };
}
