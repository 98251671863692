import dayjs from 'dayjs';
import 'dayjs/locale/zh-cn';
import * as duration from 'dayjs/plugin/duration';
import * as localeData from 'dayjs/plugin/localeData';
import * as quarterOfYear from 'dayjs/plugin/quarterOfYear';
import * as updateLocale from 'dayjs/plugin/updateLocale';
import * as weekday from 'dayjs/plugin/weekday';

dayjs.extend(updateLocale);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(quarterOfYear);
dayjs.extend(duration);

dayjs.locale('zh-cn');

dayjs.updateLocale('zh-cn', {
  weekStart: 1,
});

export default dayjs;
