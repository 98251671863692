import {css} from '@emotion/react';
import {Button} from 'antd';
import React, {useEffect, useState} from 'react';

import {GlobalModal} from '../../../components/GlobalModal';

/**
 * @typedef {{
 *  noticeId: number;
 *  noticeTime: string;
 *  createBy: string;
 *  noticeContent: string;
 * }} dataSource
 */

/**
 * @param {import('antd').ModalProps & {dataSource: dataSource; closable: Boolean;}} props
 */
export function MessageModal(props) {
  const [visible, setVisible] = useState(true);
  const {loading, closable, onOk, dataSource} = props;

  useEffect(() => {
    if (closable && dataSource) {
      setVisible(true);
    }
  }, [dataSource, closable]);

  return (
    <GlobalModal
      closable={false}
      css={css`
        .ant-modal-footer {
          text-align: center;
        }
      `}
      footer={[
        <Button
          key="1"
          loading={loading}
          type="primary"
          onClick={() => {
            if (onOk) {
              onOk();
            }
            if (closable) {
              setVisible(false);
            }
          }}
        >
          已读
        </Button>,
      ]}
      keyboard={false}
      maskClosable={false}
      title="通知"
      visible={closable ? visible : dataSource?.noticeId}
    >
      <p
        css={css`
          display: flex;
          justify-content: space-between;
          margin-bottom: 8px;
          font-size: 16px;
          span {
            color: rgba(26, 34, 48, 0.4);
          }
        `}
      >
        <span>通知时间：{dataSource?.noticeTime}</span>
        <span>通知人：{dataSource?.createBy}</span>
      </p>
      <div
        css={css`
          font-size: 16px;
          color: #1a2230;
        `}
      >
        {dataSource?.noticeContent}
      </div>
    </GlobalModal>
  );
}
