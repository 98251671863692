import {keyframes} from '@emotion/css';
import {css} from '@emotion/react';

export const grayBy = '#F1F2F5';
export const whiteBg = '#FFFFFF';
export const errColor = '#E34D59';
export const delColor = '#f9b1b4';
export const repeatBg = 'rgba(227, 77, 89, 0.2)';
export const showColBorder = '#00a870';
export const hideColBorder = '#e34d59';

export const thCommonStyle = css`
  padding: 2px 10px;
`;

export const repeatTitleStyle = css`
  ${thCommonStyle}
  background: ${repeatBg};
  color: ${errColor};
`;

export const showTitleStyle = css`
  ${thCommonStyle}
  border-top: 2px solid ${showColBorder};
`;

export const hidedTitleStyle = css`
  ${thCommonStyle}
  border-top: 2px solid ${hideColBorder};
`;

export const showRowTitleStyle = css`
  tr.showRowTitleStyle {
    ${thCommonStyle}
    background: ${whiteBg};
    td.ant-table-cell {
      padding: 0 !important;
    }
  }

  .showCol {
    border-left: 2px solid ${showColBorder};
  }
  .hasRepeat {
    background: ${repeatBg};
  }
`;

export const delRowStyle = css`
  .delRowStyle {
    ${thCommonStyle}
    td.ant-table-cell-row-hover {
      background: ${delColor}!important;
    }
    td.ant-table-cell {
      background: ${delColor};
      padding: 0 !important;
    }
  }
`;

export const hidedRowTitleStyle = css`
  .hidedRowTitleStyle {
    ${thCommonStyle}
    td.ant-table-cell {
      background: ${grayBy};
      padding: 0 !important;
    }
    /* 隐藏字段不展示顺序按钮 */
    /* td.ant-table-cell:nth-of-type(2) > div {
      display: none !important;
      width: 0;
      height: 0;
      overflow: hidden;
    } */
  }
  .hideCol {
    border-left: 2px solid ${hideColBorder};
  }
  .hasRepeat {
    background: ${repeatBg};
  }
`;

const animationTime = 1;

const bling = keyframes({
  '0%': {
    color: 'rgba(227, 77, 89, 1)',
  },
  '50%': {
    color: 'rgba(227, 77, 89, 0.4)',
  },
  '100%': {
    color: 'rgba(227, 77, 89, 1)',
  },
});

export const previewBlingStyle = css`
  animation: ${bling} ${animationTime}s ease infinite;
`;
