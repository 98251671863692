import {css} from '@emotion/react';
import {Form, Modal} from 'antd';
import {useRef} from 'react';

import {runTwiceCheckPwd} from '../api/system/sysSetting';
import IconFont from '../components/IconFont';
import SecretInput from '../components/SecretInput';
import {FULLSCREEN_POINT} from '../constants/system';

/**
 * @description: 全局Modal,二次密码验证
 * @param {Object=} payload
 */
const useTwiceCheckModal = (payload) => {
  const {
    title = '你当前进入的是系统全局设置，请输入密码验证身份，并谨慎操作！',
    description = '',
  } = payload || {};
  const countRef = useRef(0);
  const [form] = Form.useForm();
  /* const {mutate: runCheckNeedTwiceCheck} = useSWR(CHECKNEEDTWICECHECK, {
    revalidateOnMount: false,
  }); */

  const runCheckNeedTwiceCheck = () => {
    return Promise.resolve();
  };

  const showModal = (
    afterCheckOkCallBack = (v) => v,
    cancelCheckCallBack = (v) => v,
    extraParams
  ) => {
    setTimeout(() => {
      form.resetFields();
    }, 0);

    const modalInstance = Modal.confirm({
      title: null,
      centered: true,
      keyboard: false,
      maskClosable: false,
      icon: null,
      className: 'twiceCheckModal',
      okButtonProps: {className: 'ensure-btn-text-color'},
      cancelButtonProps: {className: 'cancel-btn-text-color'},
      getContainer: () => document.getElementById(FULLSCREEN_POINT),
      content: (
        // eslint-disable-next-line react/jsx-filename-extension
        <div>
          <div
            css={css`
              display: flex;
              margin-bottom: 24px;
              align-items: flex-start;
            `}
          >
            <IconFont
              style={{
                color: '#FAAD14',
                fontSize: 21,
                marginRight: 10,
                marginTop: 3,
              }}
              type="icon-error-circle-filled"
            />
            <span style={{fontSize: 16}}>{title}</span>
          </div>
          {description ? (
            <p
              css={css`
                margin-top: -18px;
                margin-bottom: 14px;
                color: #1a2230;
              `}
            >
              {description}
            </p>
          ) : null}
          <Form form={form} initialValues={{password: ''}}>
            <Form.Item
              name="password"
              rules={[{required: true, message: '请输入密码'}]}
              validateTrigger="onBlur"
            >
              <SecretInput placeholder="请输入密码" />
            </Form.Item>
          </Form>
        </div>
      ),
      async onOk(close) {
        const fieldsValue = await form.validateFields();

        modalInstance.update({
          okButtonProps: {loading: !!fieldsValue?.password},
        });

        countRef.current = 0;

        return runTwiceCheckPwd({...fieldsValue, ...payload, ...extraParams})
          .then((res) => {
            form.resetFields();
            afterCheckOkCallBack();
            close();
          })
          .catch((err) => {
            form.setFields([{name: 'password', errors: [err.data.msg]}]);
            return Promise.reject(err.data);
          })
          .finally(() => {
            modalInstance.update({
              okButtonProps: {loading: false},
            });
          });
      },
      onCancel(close) {
        form.resetFields();
        countRef.current = 0;
        cancelCheckCallBack();

        close();
      },
    });
  };

  const checkNeedTwiceCheck = (
    afterCheckOkCallBack = (v) => v,
    cancelCheckCallBack = (v) => v,
    extraParams = {}
  ) => {
    /* const needCheckTwice = await runCheckNeedTwiceCheck();
    const {data} = needCheckTwice || {};

    if (countRef.current === 0) {
      if (data === true) {
        countRef.current = 1;
        showModal(
          () => afterCheckOkCallBack(data),
          cancelCheckCallBack,
          extraParams
        );
      } else if (data === false) {
        afterCheckOkCallBack(data);
      }
    } */
    afterCheckOkCallBack(false);
  };

  return {runCheckNeedTwiceCheck, showModal, checkNeedTwiceCheck};
};

export default useTwiceCheckModal;
