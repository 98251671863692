import {useAtom} from 'jotai';

import {pagePermsAtom} from '../atoms/pagePermsAtom';

export default function useHasBtnPerm(permCode, permBtnsParams = null) {
  const [pagesPermAtom] = useAtom(pagePermsAtom);
  const {permBtns = []} = pagesPermAtom;

  let hasAuth = false;

  if (Array.isArray(permCode)) {
    hasAuth = permBtns.some((perm) => permCode.includes(perm.buttonCode));
  } else if (typeof permCode === 'string') {
    hasAuth = permBtns.some((perm) => permCode === perm.buttonCode);
  } else if (!permCode) {
    hasAuth = true;
  }

  return hasAuth;
}
