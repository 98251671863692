/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';

import useSafeState from '../../../hooks/useSafeState';

export default function useFixWidthColumn(
  tableRefEl,
  dataSource,
  resizableColumns,
  fixedTableWidth
) {
  const [fixWidthColumnIndexs, setfixWidthColumnIndexs] = useSafeState([]);

  const tableHeader = tableRefEl?.getElementsByClassName('ant-table-thead')[0];
  const tableColTHs = tableHeader?.getElementsByClassName(
    'ant-table-cell resizable-container'
  );

  useEffect(() => {
    const fixWidthIndexs = resizableColumns.reduce((pre, cur, index) => {
      if (cur?.fixWidth || index === resizableColumns.length - 1) {
        pre.push(index);
      }
      return pre;
    }, []);

    setfixWidthColumnIndexs(fixWidthIndexs);
  }, [tableRefEl, dataSource, resizableColumns]);

  useEffect(() => {
    if (fixWidthColumnIndexs.length > 0 && tableColTHs?.length > 0) {
      [...tableColTHs].forEach((th) => {
        const resizeChildNode = th?.getElementsByClassName(
          'resizable-box react-resizable'
        )[0];
        if (resizeChildNode) {
          resizeChildNode.style.display = 'block';
        }
      });
      fixWidthColumnIndexs.forEach((fixWidthColIndex) => {
        const curFixWidthColTH = tableColTHs?.[fixWidthColIndex];

        const resizeChildNode = curFixWidthColTH?.getElementsByClassName(
          'resizable-box react-resizable'
        )[0];

        const prevResizeChildNode =
          fixWidthColIndex - 1 >= 0
            ? tableColTHs?.[fixWidthColIndex - 1]?.getElementsByClassName(
                'resizable-box react-resizable'
              )[0]
            : null;
        const isFirstColumn = fixWidthColIndex === 0;

        if (resizeChildNode) {
          resizeChildNode.style.display = 'none';
          // curFixWidthColTH.removeChild(resizeChildNode);
        }
        if (
          prevResizeChildNode &&
          (isFirstColumn || (!isFirstColumn && fixedTableWidth))
        ) {
          prevResizeChildNode.style.display = 'none';
        }
      });
    }
  }, [fixWidthColumnIndexs, tableColTHs]);
}
