import {Button} from 'antd';
import {useAtom} from 'jotai';
import React, {useRef, useCallback, useEffect} from 'react';
import {useLocation, useSearchParams} from 'react-router-dom';
import {useMount, useUnmount} from 'react-use';

import {
  getCanvasCfg,
  getSheetsFields,
  getRelationTableIdx,
} from '../../../../api/dataManagement/microIndex';
import {
  defaultLogicEditStatus,
  logicEditAtom,
} from '../../../../atoms/logicEditAtom';
import {filterAtom} from '../../../../components/Filter/atoms/filterAtom';
import WarningModal from '../../../../components/GlobalModal/WarningModal';
import IconFont from '../../../../components/IconFont';
import ResizebleFrame from '../../../../components/ResizebleFrame';
import useResizeLineStyle from '../../../../components/TableSheet/hooks/useResizeLineStyle';
import {routeHistory} from '../../../../helpers/history';
import {deepClone} from '../../../../helpers/utils';
import useModalControl from '../../../../hooks/useModalControl';
import useSafeState from '../../../../hooks/useSafeState';
import {MicroDataQueryContext} from '../../DataTableManagement';
import MicroDataQueryMenu from '../../DataTableManagement/components/MicroDataQueryMenu';
import {WIDE_TABLE_TYPE} from '../components/ConfigIndex';
import CanvasEdit from './components/CanvasEdit';
import PreviewTable from './components/PreviewTable';
import {getNewJoinCondition} from './helpers';
import {leftJoin} from './helpers/constants';
import useDataChangeCheck from './hooks/useDataChangeCheck';

const noop = () => {};

const LogicEditPage = () => {
  const [activeKey, setActiveKey] = useSafeState('1');
  const queryMenuRef = useRef();

  const [lastSaveConfig, setLastSaveConfig] = useSafeState(null);

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {tableKey} = filterParams;

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);

  const {previewTableDirection, sheetList, graphData} = totalStatus;

  const {tableStructureList} = graphData;
  const level0ItemInCanvas = tableStructureList.find((t) => t.level === 0);

  const addSheet2Canvas = useCallback(
    async (curSheet) => {
      const maxTableSequence =
        tableStructureList.length >= 1
          ? Math.max(...tableStructureList.map((t) => t.tableSequence ?? 0))
          : 0;

      const sheetIntoCanvas = {
        ...curSheet,
        level: level0ItemInCanvas ? 1 : 0,
        parentTableId: level0ItemInCanvas?.tableId ?? '',
        parentTableName: level0ItemInCanvas?.tableName ?? '',
        joinCondition: leftJoin,
        joinLogicalColumns: [],
        tableSequence: maxTableSequence + 1,
      };

      const newSheetList = sheetList.map((sheet) => {
        if (sheet.tableId === curSheet.tableId) sheet._show = false;
        return sheet;
      });

      setTotalStatus((pre) => ({...pre, canvasLoading: true}));

      let resRelationTableIdx = [];
      if (level0ItemInCanvas) {
        const {joinCondition: newJoinCondition, defaultJoinLogic} =
          await getNewJoinCondition(level0ItemInCanvas, curSheet);

        resRelationTableIdx = await getRelationTableIdx({
          tableIds: [level0ItemInCanvas.tableId, curSheet.tableId].join(),
        });

        sheetIntoCanvas.joinCondition = newJoinCondition;
        sheetIntoCanvas.joinLogicalColumns =
          defaultJoinLogic.length > 0
            ? defaultJoinLogic.map(({left, right}) => [left, right])
            : [];
      }

      setTotalStatus((pre) => ({
        ...pre,
        tableIndexs: resRelationTableIdx?.data,
        sheetList: newSheetList,
        showNoTableStructure: false,
        graphData: {
          ...pre.graphData,
          tableStructureList: [...tableStructureList, sheetIntoCanvas],
        },
      }));
    },
    [level0ItemInCanvas, setTotalStatus, sheetList, tableStructureList]
  );

  const tableRef = document.getElementsByClassName(
    `previewTable${previewTableDirection}`
  )[0];

  const cancelModalControl = useModalControl();

  const [, checkChanged] = useDataChangeCheck();
  const {freshTablCotainer} = useResizeLineStyle(tableRef);

  const locationHook = useLocation();
  const [searchParams] = useSearchParams();
  const wideId = searchParams.get('wideId');
  const actionType = searchParams.get('actionType');
  const isDraftFile = searchParams.get('isDraftFile');
  // const createWideId = searchParams.get('createWideId');

  // 重置筛选
  const clearFilterHandler = () => {
    setFilterParams({
      ...filterParams,
      conditions: {[tableKey]: {}},
    });
  };

  const jump2MiroIndex = () => {
    const wideIdStr = wideId ? `?wideId=${wideId}&` : '?';
    const draftStr = isDraftFile ? `isDraftFile=${isDraftFile}` : '';

    const newRoutePath = `${locationHook.pathname
      .split('/')
      .slice(0, -1)
      .join('/')}${wideIdStr}${draftStr}`;

    clearFilterHandler();
    setTotalStatus({...deepClone(defaultLogicEditStatus)});
    routeHistory.push(
      `${newRoutePath}&tabIndex=${searchParams.get('tabIndex') ?? 1}`
    );
  };

  const goBack = () => {
    const {result} = checkChanged();

    if (result) {
      cancelModalControl._toggle(true);
    } else {
      jump2MiroIndex();
    }
  };

  const ensureCancelHandle = () => {
    cancelModalControl._toggle(false);

    jump2MiroIndex();
  };

  const fixPreviewTableHeight = () => {
    const topEl = document.getElementById('canvasEditWraper');
    const wraper = document.getElementsByClassName('logicEditPage')[0];

    const previewTableHeight = wraper?.offsetHeight - topEl?.offsetHeight - 220;

    setTotalStatus((pre) => ({
      ...pre,
      previewTableHeight,
    }));

    // 更新拖拽辅助线高度
    freshTablCotainer();
  };

  const updateCanvasTableKeys = async (_tableStructureList) => {
    const cfgTableIds = _tableStructureList.map((t) => t.tableId);

    const columnsInfoRes = await getSheetsFields({tableIds: cfgTableIds});

    const treeData = queryMenuRef.current.getTreeData();

    const {data: columnsInfo = []} = columnsInfoRes;

    const newTableStructureList = _tableStructureList.map((t) => {
      const otherInfo =
        columnsInfo.find((c) => c.tableId === t.tableId)?.columnList?.[0] ?? {};
      const {schemaName, dbType} =
        treeData?.find((dbConfig) => dbConfig.dbId === t.dbId) ?? {};

      const {
        // 1
        instanceUrl,
        instanceType,
        instancePort,
        instanceName,
        // 2
        dbName,
        dbRemark,
        tableComment,
        tableRemark,
      } = otherInfo;

      return {
        ...t,
        instanceUrl,
        instanceType,
        instancePort,
        instanceName,
        dbName,
        dbRemark,
        tableComment,
        tableRemark,
        schemaName,
        dbType,
      };
    });

    return newTableStructureList;
  };

  const getCanvasConfig = async (id) => {
    const res = await getCanvasCfg({pathId: id});
    const {code, data} = res;

    if (code === 200) {
      // 从宽表中查找数据表配置的表对应的库表信息进行回填
      const {
        status,
        pathId,
        tableName,
        tableStructureList: _tableStructureList,
        frontParam,
        collectPathId,
        tableNameEn,
        syncJobType,
        mail,
        jobCronJson,
        saveType,
        writeType,
        targetTableId,
      } = data;

      setLastSaveConfig({
        tableName,
        pathId,
        collectPathId,
        tableNameEn,
        syncJobType,
        mail,
        jobCronJson,
        saveType,
        writeType,
        targetTableId,
      });

      const newTableStructureList = await updateCanvasTableKeys(
        _tableStructureList ?? []
      );

      const canvasCfgData = {
        status,
        frontParam,
        tableStructureList: newTableStructureList,
      };

      setFilterParams((pre) => ({
        ...pre,
        conditions: {
          microIndexPreviewTable: JSON.parse(frontParam ?? '{}'),
        },
      }));

      setTotalStatus((pre) => ({
        ...pre,
        pathId: pathId === 0 ? undefined : pathId,
        _pathId: pathId === 0 ? undefined : pathId,
        tableName: tableName?.trim(),
        _tableName: tableName?.trim(),
        graphData: canvasCfgData,
        graphOriginData: deepClone(canvasCfgData),
      }));
    }
  };

  const generateExtraActions = useCallback(
    (org, createSyncTask) => {
      if (
        (activeKey === '1' && (org.pathId === null || org.pathType === 1)) ||
        (activeKey === '2' && org.pathType !== 3) ||
        totalStatus.graphData.tableStructureList.some(
          (table) =>
            table.dbIp === org.dbIp &&
            +table.dbPort === +org.dbPort &&
            // table.dbUserName === org.dbUserName &&
            table.tableName === org.tableName
        ) ||
        org?.delFlag === 1
      ) {
        return false;
      }
      if (
        totalStatus.graphData.tableStructureList.length === 0 ||
        totalStatus.graphData.tableStructureList.every(
          (table) =>
            table.dbIp === org.dbIp &&
            +table.dbPort === +org.dbPort &&
            table.dbUserName === org.dbUserName
        )
      ) {
        return {
          name: '添加',
          key: 'insert',
          calback: (p) => {
            addSheet2Canvas(p);
          },
        };
      }
      return {
        name: '创建同步任务',
        key: 'sync',
        calback: (p) => createSyncTask(p),
      };
    },
    [activeKey, addSheet2Canvas, totalStatus.graphData.tableStructureList]
  );

  useMount(() => {
    if (wideId && actionType !== 'creatWide') getCanvasConfig(wideId);

    setTimeout(() => {
      fixPreviewTableHeight();
    }, 0);

    setFilterParams((pre) => ({
      ...pre,
      tableKey: 'microIndexPreviewTable',
    }));

    window.localStorage.removeItem('_resizeColumn_microIndexPreviewTable__X');
    window.localStorage.removeItem('_resizeColumn_microIndexPreviewTable__Y');
  });

  useUnmount(() => {
    setTotalStatus({...deepClone(defaultLogicEditStatus)});
  });

  // 从创建宽表进入
  useEffect(() => {
    if (actionType === 'creatWide' && totalStatus.createWideTableInfo) {
      addSheet2Canvas(totalStatus.createWideTableInfo);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [actionType, totalStatus.createWideTableInfo]);

  return (
    <MicroDataQueryContext.Provider
      value={{
        loading: false,
        activeKey,
        setActiveKey,
        queryMenuRef,
        clearPrevTableInfo: noop,
      }}
    >
      <ResizebleFrame
        cacheStorageKey="LogicEditPage"
        centerBottomElement={
          <PreviewTable
            createWideTableType={WIDE_TABLE_TYPE.wideTable}
            lastSaveConfig={lastSaveConfig}
          />
        }
        centerTopElment={<CanvasEdit />}
        centerTopMinHeight={191}
        className="logicEditPage"
        headerElement={
          <Button
            icon={<IconFont style={{fontSize: 16}} type="icon-rollback" />}
            size="small"
            style={{margin: '0 0 16px 16px'}}
            type="primary"
            onClick={goBack}
          >
            返回数据表管理
          </Button>
        }
        leftElement={
          <MicroDataQueryMenu
            isWideList
            generateExtraActions={generateExtraActions}
            menuTreeHeight="calc(100vh - 240px)"
            onlyFolder={(node) => {
              // 我的收藏文件夹不置灰
              if (
                tableStructureList.length > 0 &&
                tableStructureList.some(
                  (table) =>
                    table.dbIp !== node.dbIp || +table.dbPort !== +node.dbPort
                ) &&
                ((activeKey === '1' && node.pathId) || activeKey === '2')
              ) {
                node.disabled = true;
              }
              return node.draftFlag !== 0;
            }}
            selectable={false}
            showAddBtns={false}
          />
        }
        resizeStopCallback={{
          centerResizeStopCallBack: ({ref, diff}) => {
            fixPreviewTableHeight({ref, diff});
          },
        }}
      />

      {/* 未保存取消 */}
      <WarningModal
        confirm={ensureCancelHandle}
        description="当前操作内容未保存，请确认是否离开"
        modalControl={cancelModalControl}
        title="确认是否离开"
      />
    </MicroDataQueryContext.Provider>
  );
};

export default LogicEditPage;
