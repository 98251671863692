import {css} from '@emotion/react';
import {Input} from 'antd';
import React, {useContext, useEffect, useMemo, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import TableSheetCopy from '../../../../../components/TableSheetCopy';
import useSafeState from '../../../../../hooks/useSafeState';
import {searchInput} from '../../style.css';

const rowKey = 'indexOriName';

export default function MicroFieldInfoTable(props) {
  const [searchWord, setSearchWord] = useSafeState('');

  const [searchParams] = useSearchParams();

  const tableRef = useRef();

  const {dataSource, columnList} = useContext(MicroDataQueryContext);

  const columns = [
    {
      title: '显示名(当前)',
      dataIndex: 'curShowName',
      render: (val, row) => {
        return val ? val : ' ';
      },
    },
    {
      title: '英文名',
      dataIndex: 'indexOriName',
      render: (val, row) => {
        return val ? val : ' ';
      },
    },

    {
      title: '字段类型',
      dataIndex: 'indexTypeShowName',
      render: (val, row) => {
        return val ? val : ' ';
      },
    },
    {
      title: '样例数据',
      dataIndex: 'dataTemp',
      render: (val, row) => {
        return val ? val : ' ';
      },
    },
  ];

  const showDataSource = useMemo(() => {
    return columnList
      .filter(
        (col) =>
          col?.name?.includes(searchWord) ||
          col?.curShowName?.includes(searchWord) ||
          col?.indexOriName?.includes(searchWord)
      )
      .map((col) => {
        const {indexOriName} = col;
        const dataTemp = dataSource
          .slice(0, 10)
          .map((item) => item[indexOriName])
          .join(',');

        col.dataTemp = dataTemp;
        return col;
      });
  }, [columnList, dataSource, searchWord]);

  useEffect(() => {
    if (!searchParams.get('wideId')) {
      tableRef.current?.setDataSource([]);
    }
  }, [searchParams]);

  return (
    <>
      <div
        className="tablePageTopSection"
        style={{position: 'absolute', right: 10, top: -1}}
      >
        <Input
          allowClear
          css={searchInput}
          maxLength={50}
          placeholder="搜索字段中/英文名"
          value={searchWord}
          onChange={(e) => {
            setSearchWord(e.target.value);
          }}
        />
      </div>
      <TableSheetCopy
        clearFilter={false}
        columns={columns}
        css={css`
          th.ant-table-cell {
            padding: 3px 0 0 10px !important;
          }
          td.ant-table-cell {
            padding: 4.5px 0 4.5px 10px !important;
          }
          td.gray {
            background-color: #f1f2f5;
          }
        `}
        dataSource={showDataSource}
        pagination={false}
        recordRecurrent={false}
        ref={tableRef}
        refresh={false}
        rowKey={rowKey}
        searchParamsUsable={false}
        storeColumnWidthKey="FieldInfoTableKey"
      />
    </>
  );
}
