import {Checkbox, Form, Input} from 'antd';
import React, {useMemo} from 'react';
import {useSearchParams} from 'react-router-dom';

import {
  dbAddFolder,
  dbPathList,
  scAddFolder,
  scFolderList,
} from '../../../../../api/dataManagement/wideTable';
import FormRequireLabel from '../../../../../components/FormRequireLabel';
import ModalConfigIndex, {WIDE_TABLE_TYPE} from '../ConfigIndex';
import FolderTree from './FolderTree';

const formTailLayout = {
  labelCol: {
    span: 4,
  },
  wrapperCol: {
    span: 20,
    offset: 4,
  },
};

const UdInput = ({onChange, value, ...rest}) => {
  return (
    <Input
      defaultValue="ud_"
      maxLength={50}
      value={value}
      onChange={(e) => {
        if (e.target.value.startsWith('ud_')) {
          onChange(e);
        } else {
          e.preventDefault();
        }
      }}
      {...rest}
    />
  );
};

export default function NewSaveWayFormItem({
  tableData,
  isSaveAs,
  type,
  isWideList,
  isSqlWide,
  onlyFolder,
  requestTablePathId,
  visible,
  form,
  syncFormValues,
  setSyncFormValues,
}) {
  const [searchParams] = useSearchParams();

  const createWideTableType = useMemo(
    () =>
      isSqlWide ? WIDE_TABLE_TYPE.SQLWideTable : WIDE_TABLE_TYPE.wideTable,
    [isSqlWide]
  );

  return (
    <>
      <Form.Item
        required
        label={<FormRequireLabel title="显示名称" />}
        name="tableName"
        rules={[
          {
            required: true,
            message: '请输入显示名称',
          },
        ]}
      >
        <Input placeholder="请输入显示名称" />
      </Form.Item>
      <Form.Item
        required
        label={<FormRequireLabel title="英文名称" />}
        name="tableNameEn"
        rules={[
          {
            required: true,
            message: '请输入英文名称',
          },
        ]}
      >
        <UdInput
          disabled={tableData?.tableNameEn && !isSaveAs}
          placeholder="请输入英文名称"
        />
      </Form.Item>
      <Form.Item
        label={
          <FormRequireLabel
            title={`${type === 'add' ? '保存' : '同步'}至数据库`}
          />
        }
        name="pathId"
        rules={[
          {
            required: true,
            message: '请选择数据库',
          },
        ]}
      >
        <FolderTree
          addFolderApi={dbAddFolder}
          isWideList={isWideList}
          needPermission={searchParams.get('tabIndex') === '2'}
          onlyFolder={(data) => {
            return [1, 2].includes(data.pathType);
          }}
          treeApi={(props) => {
            return dbPathList({
              ...props,
              ...(requestTablePathId ? {tablePathId: requestTablePathId} : {}),
            });
          }}
          visible={visible}
          wideIdProp={`${tableData?.pathId ?? ''}`}
          onSelect={(info) => {
            form.setFieldsValue({
              pathId: info.node.id,
            });
            setSyncFormValues((prev) => {
              return {
                ...prev,
                pathId: info.node.id,
              };
            });
          }}
        />
      </Form.Item>
      <Form.Item noStyle name="tableIndexs">
        <ModalConfigIndex createWideTableType={createWideTableType} />
      </Form.Item>
      <Form.Item label="收藏数据表" name="collectFlag" valuePropName="checked">
        <Checkbox />
      </Form.Item>
      {syncFormValues.collectFlag ? (
        <Form.Item name="collectPathId" {...formTailLayout}>
          <FolderTree
            onlyFolder
            addFolderApi={scAddFolder}
            treeApi={scFolderList}
            visible={syncFormValues.collectFlag}
            wideIdProp={`${tableData?.collectPathId ?? ''}`}
            onSelect={(info) => {
              form.setFieldsValue({
                collectPathId: info.node.id,
              });
              setSyncFormValues((prev) => {
                return {
                  ...prev,
                  collectPathId: info.node.id,
                };
              });
            }}
          />
        </Form.Item>
      ) : null}
    </>
  );
}
