import {
  BRANKETS_TYPE,
  COLUMN_TYPE,
  CONST_TYPE,
  ITEM_TYPE,
  NUMBER_TYPE,
  NUMBER_TYPES,
  OPERATOR_TYPE,
} from './constants';

const getOperatorStrategy = () => {
  const parentheses = [];
  const leftParenthesis = (i, inputArr) => {
    parentheses.push(i);
    return true;
  };
  const rightParenthesis = (i, inputArr) => {
    const startIndex = parentheses.pop();
    // 右括号先于左括号或者不存在左括号
    if (!startIndex && startIndex !== 0) {
      return false;
      // 闭合左右括号内无内容
    } else if (startIndex + 1 === i) {
      return false;
    }
    return true;
  };
  const addition = (i, inputArr) => {
    const prev = inputArr[i - 1];
    const next = inputArr[i + 1];
    const flag =
      (prev?.value === ')' ||
        prev?.value === '）' ||
        [
          ITEM_TYPE.CONST.type,
          ITEM_TYPE.FIELD.type,
          ITEM_TYPE.NUMBER.type,
        ].includes(prev?.type)) &&
      (next?.value === '(' ||
        next?.value === '（' ||
        [
          ITEM_TYPE.CONST.type,
          ITEM_TYPE.FIELD.type,
          ITEM_TYPE.NUMBER.type,
        ].includes(next?.type));
    if (!flag) {
      return false;
    }
    return true;
  };
  const subtraction = (i, inputArr) => {
    const prev = inputArr[i - 1];
    const next = inputArr[i + 1];
    const flag =
      (!prev ||
        prev?.value === ')' ||
        prev?.value === '）' ||
        prev?.type === ITEM_TYPE.NUMBER.type ||
        (prev?.type === ITEM_TYPE.FIELD.type &&
          NUMBER_TYPES.includes(prev?.indexType?.toLowerCase()))) &&
      (next?.value === '(' ||
        next?.value === '（' ||
        next?.type === ITEM_TYPE.NUMBER.type ||
        (next?.type === ITEM_TYPE.FIELD.type &&
          NUMBER_TYPES.includes(next?.indexType?.toLowerCase())));
    if (!flag) {
      return false;
    }
    return true;
  };
  const multiplicationOrDivision = (i, inputArr) => {
    const prev = inputArr[i - 1];
    const next = inputArr[i + 1];
    const flag =
      (prev?.value === ')' ||
        prev?.value === '）' ||
        prev?.type === ITEM_TYPE.NUMBER.type ||
        (prev?.type === ITEM_TYPE.FIELD.type &&
          NUMBER_TYPES.includes(prev?.indexType?.toLowerCase()))) &&
      (next?.value === '(' ||
        next?.value === '（' ||
        next?.type === ITEM_TYPE.NUMBER.type ||
        (next?.type === ITEM_TYPE.FIELD.type &&
          NUMBER_TYPES.includes(next?.indexType?.toLowerCase())));
    if (!flag || (inputArr[i].value === '/' && next?.value === 0)) {
      return false;
    }
    return true;
  };
  const strategy = new Map([
    ['(', leftParenthesis],
    ['（', leftParenthesis],
    [')', rightParenthesis],
    ['）', rightParenthesis],
    ['+', addition],
    ['-', subtraction],
    ['*', multiplicationOrDivision],
    ['/', multiplicationOrDivision],
  ]);

  return {
    strategy,
    parentheses,
  };
};

const constValidator = (i, inputArr) => {
  const prev = inputArr[i - 1];
  const next = inputArr[i + 1];
  const flag =
    (!prev ||
      prev?.value === '+' ||
      prev?.value === '(' ||
      prev?.value === '（') &&
    (!next ||
      next?.value === '+' ||
      next?.value === ')' ||
      next?.value === '）');
  if (!flag) {
    return false;
  }
  return true;
};

const fieldValidator = (i, inputArr) => {
  const prev = inputArr[i - 1];
  const next = inputArr[i + 1];
  const cur = inputArr[i];
  const flag =
    (!prev ||
      prev?.value === '+' ||
      prev?.value === '(' ||
      prev?.value === '（' ||
      (NUMBER_TYPES.includes(cur.indexType?.toLowerCase()) &&
        (prev?.value === '-' || prev?.value === '*' || prev?.value === '/'))) &&
    (!next ||
      next?.value === '+' ||
      next?.value === ')' ||
      next?.value === '）' ||
      (NUMBER_TYPES.includes(cur.indexType?.toLowerCase()) &&
        (next?.value === '-' || next?.value === '*' || next?.value === '/')));
  if (!flag) {
    return false;
  }
  return true;
};

const numberValidator = (i, inputArr) => {
  const prev = inputArr[i - 1];
  const next = inputArr[i + 1];
  const flag =
    (!prev ||
      prev?.type === OPERATOR_TYPE ||
      ((prev?.value === '(' || prev?.value === '（') &&
        (next?.value === ')' ||
          next?.value === '）' ||
          next?.type === OPERATOR_TYPE))) &&
    ((!next && prev?.value !== '(' && prev?.value !== '（') ||
      next?.value === ')' ||
      next?.value === '）' ||
      next?.type === OPERATOR_TYPE);
  if (!flag) {
    return false;
  }
  return true;
};

const valueStrategy = new Map([
  [ITEM_TYPE.CONST.type, constValidator],
  [ITEM_TYPE.FIELD.type, fieldValidator],
  [ITEM_TYPE.NUMBER.type, numberValidator],
]);

export function arithmeticValidator(inputArr) {
  const {strategy, parentheses} = getOperatorStrategy();
  const newInputArr = inputArr.map((item) => {
    let content = item.content;
    if (item.type === CONST_TYPE || item.type === COLUMN_TYPE) {
      content = JSON.parse(item.content);
    }
    switch (item.type) {
      case BRANKETS_TYPE:
        return {
          type: BRANKETS_TYPE,
          value: content,
        };
      case OPERATOR_TYPE:
        return {
          type: OPERATOR_TYPE,
          value: content,
        };
      case CONST_TYPE:
        if (typeof content.content === 'string') {
          return {
            type: CONST_TYPE,
            value: content.content,
          };
        }
        return {
          type: NUMBER_TYPE,
          value: content.content,
        };
      case COLUMN_TYPE:
        return {
          type: COLUMN_TYPE,
          value: content.content,
          indexType: content.indexType,
        };
      default:
        return {};
    }
  });
  for (let i = 0; i < newInputArr.length; i++) {
    const item = newInputArr[i];
    if (item.type === null) {
      return false;
    } else if (
      item.type === ITEM_TYPE.BRANKETS.type ||
      item.type === ITEM_TYPE.OPERATOR.type
    ) {
      const func = strategy.get(item.value);
      const res = func(i, newInputArr);
      if (!res) {
        return false;
      }
      // TODO: 跳过字符串定量类型校验以避免双引号之间空格导致校验报错
    } else if (item.type !== CONST_TYPE) {
      const func = valueStrategy.get(item.type);
      const res = func(i, newInputArr);
      if (!res) {
        return false;
      }
    }
  }
  if (parentheses.length !== 0) {
    return false;
  }
  return true;
}
