import {useCallback, useEffect, useRef} from 'react';

// import {columnMinWidth} from '..';
import {FULLSCREEN_POINT} from '../../../constants/system';
import useSafeState from '../../../hooks/useSafeState';

// '宽度： xxx像素'字数之和的宽度
const showSizeBoxWidth = 120;

export default function useResizeLineStyle(
  isValidating,
  tableRefEl,
  dataSource,
  fixedTableWidth,
  resizableColumns,
  storeColumnWidthKey
) {
  const resizeLineRef = useRef();
  const resizeLinePar = useRef();
  const showSizeBoxRef = useRef();
  const startX = useRef();
  const startLineLeft = useRef();

  const [tableContainerHeight, settableContainerHeight] = useSafeState(0);

  const mountingPoint = document.getElementById(FULLSCREEN_POINT);

  const freshTablCotainer = () => {
    setTimeout(() => {
      const tableContainer = tableRefEl?.getElementsByClassName(
        'ant-table-container'
      )[0];
      const tableBodyContainer =
        tableRefEl?.getElementsByClassName('ant-table-body')[0];
      if (tableBodyContainer) {
        tableBodyContainer.scrollLeft += 1;
      }

      const lineHeight = tableContainer?.getBoundingClientRect()?.height ?? 0;

      settableContainerHeight(lineHeight);
    }, 10);
  };

  useEffect(() => {
    freshTablCotainer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableRefEl, isValidating, dataSource, document?.fullscreenElement]);

  const tableHeaderMouseUp = () => {
    const showSizeBoxEl = document.getElementById('sizebox');
    const resizeLineEl = document.getElementById('resizeColumnLine');

    if (resizeLineRef.current) {
      resizeLineRef.current = null;
      resizeLinePar.current = null;
      showSizeBoxRef.current = null;
    }

    if (showSizeBoxEl) {
      mountingPoint?.removeChild(showSizeBoxEl);
    }
    if (resizeLineEl) {
      mountingPoint?.removeChild(resizeLineEl);
    }
  };

  const tableHeaderMouseDown = useCallback(
    (event) => {
      const target = event?.target;
      const docWidth = document.documentElement.clientWidth;
      startX.current = event.clientX;

      if (target?.className === 'resizable-handler') {
        tableHeaderMouseUp();
        const lineParent = target.parentNode;

        // 辅助线
        const resizeLineEl = document.createElement('div');
        resizeLineEl.id = 'resizeColumnLine';
        resizeLineEl.style.cssText = `
          width: 1px;
          height: ${tableContainerHeight}px;
          background: #C6CFFF;
          position: fixed;
          top: ${event.clientY - event.offsetY}px;
          left: ${event.clientX - event.offsetX + 5}px;
          z-index: 3998;
        `;
        resizeLinePar.current = lineParent;
        resizeLineRef.current = resizeLineEl;
        /** 鼠标点击时辅助线的起始位置 */
        startLineLeft.current = parseInt(resizeLineRef.current.style.left, 10);

        // 宽度实时展示
        const resizeLineParWidth =
          resizeLinePar.current?.getBoundingClientRect()?.width;
        const showSizeBoxEl = document.createElement('div');
        const isOverScreen =
          event.clientX - event.offsetX + showSizeBoxWidth > docWidth;
        const posLeft = isOverScreen
          ? `${event.clientX - event.offsetX - showSizeBoxWidth}px`
          : `${event.clientX - event.offsetX}px`;
        showSizeBoxEl.id = 'sizebox';
        showSizeBoxEl.style.cssText = `
          width: auto;
          height: 25px;
          padding: 2px 10px;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
          border-radius: 2px;
          border: 1px solid #EEEEEE;
          position: fixed;
          top: ${event.clientY - event.offsetY + 50}px;
          left: ${posLeft};
          z-index: 3999;
        `;
        showSizeBoxRef.current = showSizeBoxEl;
        showSizeBoxRef.current.innerHTML = `宽度： ${resizeLineParWidth}像素`;

        mountingPoint?.appendChild(resizeLineEl);
        mountingPoint?.appendChild(showSizeBoxEl);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      resizableColumns,
      tableRefEl,
      tableContainerHeight,
      isValidating,
      dataSource,
      document?.fullscreenElement,
      // tableHeaderMouseUp,
    ]
  );

  const tableHeaderMouseMove = useCallback(
    (event) => {
      const docWidth = document.documentElement.clientWidth;
      if (resizeLineRef.current) {
        const currentResizeTh = resizeLinePar?.current?.parentNode;
        const currentResizeThText =
          currentResizeTh.getElementsByClassName('resizable-title')[0]
            .textContent;
        const columnMinWidth = currentResizeThText.length * 18 + 20;

        const nextResizeThText =
          currentResizeTh?.nextElementSibling?.textContent;

        const nextColumnMinWidth = nextResizeThText.length * 18 + 20;
        /** 当该列拖拽的最小宽度为columnMinWidth时所能移动的最大距离 */
        const maxDistance =
          startLineLeft.current -
          (resizeLinePar?.current?.parentNode?.getBoundingClientRect()?.width -
            columnMinWidth);

        const distanceX = event.clientX - startX.current;

        let leftOffset = 0;
        const tableHeader =
          tableRefEl?.getElementsByClassName('ant-table-thead')[0];
        const tableColTHs = tableHeader?.getElementsByClassName(
          'ant-table-cell ant-table-cell-ellipsis'
        );
        const index = [...tableColTHs].findIndex(
          (th) => th === currentResizeTh
        );
        const nextFixCol = resizableColumns[index + 1]?.fixWidth
          ? resizableColumns[index + 1]
          : null;
        /** 理论上本列更新后的宽度 */
        let newResizeWidth = parseInt(
          currentResizeTh?.getBoundingClientRect()?.width + distanceX,
          10
        );

        /** 表格可视区域宽度 */
        const tableWidth = document.querySelector(
          `.${storeColumnWidthKey}-tablesheet`
        )?.clientWidth;

        /** 理论上本列移动后表格的总宽度 */
        const widths = [...tableColTHs].reduce((prev, cur, i) => {
          if (index === i) {
            return prev + newResizeWidth;
          }
          return prev + cur.getBoundingClientRect().width;
        }, 0);
        if (!fixedTableWidth && nextFixCol) {
          if (widths > tableWidth) {
            // 拖拽结束后有滚动条的情况
            // 本列的最小宽度可以是columnMinWidth，不受下一固定宽度列的影响
            leftOffset =
              newResizeWidth <= columnMinWidth ? maxDistance : event.clientX;
            newResizeWidth =
              newResizeWidth < columnMinWidth ? columnMinWidth : newResizeWidth;
          } else {
            // 拖拽结束后没有滚动条的情况
            // 本列的最小宽度受下一固定宽度列的约束
            // 本列的最终宽度需要维持表格的总宽度和表格可视区域宽度相等
            let minWidth = tableWidth - (widths - newResizeWidth);
            minWidth = minWidth < columnMinWidth ? columnMinWidth : minWidth;
            leftOffset =
              newResizeWidth <= minWidth
                ? startLineLeft.current -
                  (currentResizeTh?.getBoundingClientRect()?.width - minWidth)
                : newResizeWidth <= columnMinWidth
                ? maxDistance
                : event.clientX;
            newResizeWidth = parseInt(minWidth, 10);
          }
        } else if (fixedTableWidth && distanceX > 0) {
          // 所能移动的最大距离：下一列更新后的宽度-下一列当前的宽度
          const nextColWidth =
            currentResizeTh?.nextElementSibling?.getBoundingClientRect()?.width;
          const afterNextColWidth = nextColWidth - distanceX;
          const _maxDistance =
            afterNextColWidth < nextColumnMinWidth
              ? nextColWidth - nextColumnMinWidth
              : nextColWidth - afterNextColWidth;
          newResizeWidth =
            currentResizeTh?.getBoundingClientRect()?.width + _maxDistance;
          leftOffset =
            afterNextColWidth <= nextColumnMinWidth
              ? parseInt(resizeLineRef.current.style.left, 10)
              : event.clientX;
        } else {
          newResizeWidth =
            newResizeWidth < columnMinWidth ? columnMinWidth : newResizeWidth;
          leftOffset =
            newResizeWidth <= columnMinWidth ? maxDistance : event.clientX;
        }

        const isOverScreen = leftOffset + showSizeBoxWidth > docWidth;

        resizeLineRef.current.style.left = `${leftOffset}px`;

        showSizeBoxRef.current.style.left = isOverScreen
          ? `${leftOffset - showSizeBoxWidth}px`
          : `${leftOffset}px`;

        showSizeBoxRef.current.innerHTML = `宽度： ${newResizeWidth}像素`;
      }
    },
    [resizableColumns, fixedTableWidth, storeColumnWidthKey, tableRefEl]
  );

  useEffect(() => {
    const tableContainer = tableRefEl?.getElementsByClassName(
      'ant-table-container'
    )[0];
    if (tableContainer) {
      tableContainer.addEventListener('mousedown', tableHeaderMouseDown);
      document.addEventListener('mousemove', tableHeaderMouseMove);
      document.addEventListener('mouseup', tableHeaderMouseUp);
    }

    return () => {
      tableContainer?.removeEventListener?.('mousedown', tableHeaderMouseDown);
      document.removeEventListener('mousemove', tableHeaderMouseMove);
      document.removeEventListener('mouseup', tableHeaderMouseUp);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    resizableColumns,
    isValidating,
    dataSource,
    tableHeaderMouseDown,
    tableRefEl,
    document?.fullscreenElement,
  ]);
}
