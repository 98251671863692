/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';

import useSafeState from '../../../hooks/useSafeState';

export default function useFixWidthColumn(
  tableRefEl,
  dataSource,
  resizableColumns
) {
  const [fixWidthColumnIndexs, setfixWidthColumnIndexs] = useSafeState([]);

  const tableHeader = tableRefEl?.getElementsByClassName('ant-table-thead')[0];
  const tableColTHs = tableHeader?.getElementsByClassName(
    'ant-table-cell ant-table-cell-ellipsis'
  );

  useEffect(() => {
    const fixWidthIndexs = resizableColumns.reduce((pre, cur, index) => {
      if (cur?.fixWidth) {
        pre.push(index);
      }
      return pre;
    }, []);

    setfixWidthColumnIndexs(fixWidthIndexs);
  }, [tableRefEl, dataSource, resizableColumns]);

  useEffect(() => {
    if (fixWidthColumnIndexs.length > 0 && tableColTHs?.length > 0) {
      fixWidthColumnIndexs.forEach((fixWidthColIndex) => {
        const curFixWidthColTH = tableColTHs?.[fixWidthColIndex];

        const resizeChildNode = curFixWidthColTH?.getElementsByClassName(
          'resizable-box react-resizable'
        )[0];

        if (curFixWidthColTH && resizeChildNode) {
          curFixWidthColTH.removeChild(resizeChildNode);
        }
      });
    }
  }, [fixWidthColumnIndexs, tableColTHs]);
}
