import {useAtom} from 'jotai';

import {flattedMenusAtom} from '../../atoms/flattedMenu';
import {pagePermsAtom} from '../../atoms/pagePermsAtom';
import {tooltipAtom} from '../../atoms/tooltip';

export function useTooltip() {
  const [tooltip] = useAtom(tooltipAtom);
  const [pagePerms] = useAtom(pagePermsAtom);
  const [flattedMenus] = useAtom(flattedMenusAtom);

  /* 新版用户中心 pageCode === menuCode */
  const pageCode =
    pagePerms.type === 2
      ? pagePerms.pageCode
      : flattedMenus.find((menu) => menu.code === pagePerms.menuCode)?.pageCode;

  const currentPageTooltip = tooltip[pageCode];

  return currentPageTooltip;
}
