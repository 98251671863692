/* eslint-disable no-console */
const IGNORE = ['StrictMode'];

// 解决antd烦人的报错
export function ignoreConsoleInDev() {
  if (process.env.NODE_ENV !== 'development') return;

  const error = console.error;
  console.error = (msg, ...rest) => {
    if (
      typeof msg === 'string' &&
      !IGNORE.some((entry) => msg?.includes(entry))
    ) {
      // eslint-disable-next-line prefer-rest-params
      error.call(
        console,
        `%c${msg}`,
        'color: #ffffff; background: rgba(20, 201, 254, .6); padding: 2px 4px',
        ...rest
      );
    }
  };
}
