import {css} from '@emotion/react';
import React from 'react';

import {innerJoin, leftJoin} from '../../helpers/constants';
import {
  leftCheckStyle,
  middleCheckStyle,
  noCheckBlingStyle,
} from './styles.css';

const CrossCircle = (props) => {
  const {rightNodeInfo = {}, onClick = (v) => v} = props;
  const {joinCondition = ''} = rightNodeInfo;

  const onCrossCircleClick = () => {
    onClick(rightNodeInfo);
  };
  return (
    <div
      className="circle-wraper"
      css={css`
        ${joinCondition === leftJoin
          ? leftCheckStyle
          : joinCondition === innerJoin
          ? middleCheckStyle
          : noCheckBlingStyle}
      `}
      onClick={onCrossCircleClick}
    >
      <div className="circle circle1">
        {!joinCondition && <div className="suprise">!</div>}
      </div>
      <div className="circle circle2" />
    </div>
  );
};

export default CrossCircle;
