import jwtDecode from 'jwt-decode';
import {useLocalStorage} from 'react-use';

import {storageKeys} from '../constants/storageKeys';

export function useToken() {
  const [token, setToken, removeToken] = useLocalStorage(storageKeys.TOKEN);

  const payload = token && jwtDecode(token);

  return {
    token,
    setToken,
    payload,
    removeToken,
  };
}
