import {css} from '@emotion/react';
import React, {useEffect} from 'react';
import {useSearchParams} from 'react-router-dom';

import {storageKeys} from '../../constants/storageKeys';

const IFrameWindow = () => {
  const [searchParams] = useSearchParams();
  const frameUrl = decodeURIComponent(searchParams.get('frameUrl'));

  useEffect(() => {
    const iframe = document.getElementById('adaptiveFrame');

    iframe.onload = () => {
      const postData = window.localStorage.getItem(storageKeys.TOKEN);

      iframe.contentWindow.postMessage(
        {
          token: postData,
          type: 'stxz',
        },
        '*'
      );
    };
  }, []);

  return (
    <div
      css={css`
        width: 100%;
        height: 100%;
      `}
    >
      <iframe
        frameBorder={0}
        height="100%"
        id="adaptiveFrame"
        // sandbox='allow-downloads allow-forms allow-same-origin allow-scripts allow-popups allow-popups-to-escape-sandbox allow-top-navigation allow-top-navigation-by-user-activation'
        src={frameUrl}
        width="100%"
      />
    </div>
  );
};

export default IFrameWindow;
