import {css} from '@emotion/react';
import {Modal, Form, Button} from 'antd';
// import GlobalModal from '@/components/GlobalModal';
import React, {useEffect, useMemo, useState, useCallback} from 'react';

import {
  ConfigIndexBtn,
  ScrollWraper,
  ReSelect,
  AddIndexBtn,
  formItemCss,
  scrollIntoView,
  getArrRepeatValid,
  FORM_ITEM_FIELD,
} from './components';
import useTableFields from './useTableFields';

export {WIDE_TABLE_TYPE} from './useTableFields';

// 非受控组件
const ModalConfigIndex = (props) => {
  const {onChange, visible = false, createWideTableType, onCancel} = props;

  const [form] = Form.useForm();

  const {
    tableIndexs = [],
    Field_MATCH_ROW,
    Field_SHOW_STATE,
    setTotalTableState,
  } = useTableFields(createWideTableType);

  const value = useMemo(() => {
    if (tableIndexs && tableIndexs.length) {
      return tableIndexs?.map((optValues) => {
        return optValues?.columns?.map((v) => v.columnName).filter(Boolean);
      });
    }
    return [];
  }, [tableIndexs]);

  const [isModalOpen, setIsModalOpen] = useState(() => visible);
  const [fieldsValue, setFieldsValue] = useState([]);

  useEffect(() => {
    setIsModalOpen(visible);
  }, [visible]);

  useEffect(() => {
    if (isModalOpen) {
      let initValue = new Array(1).fill().map(() => []);
      if (value?.length) {
        initValue = value;
        setFieldsValue(value);
      }
      form.setFieldsValue({idx: initValue});
    }
  }, [value, form, isModalOpen, tableIndexs]);

  const getFormatformValues = useCallback(
    (formValues) => {
      return formValues.idx.map((optValues, index) => {
        return {
          name: index,
          // 索引状态, 1正常, 0失效
          indexStatus: 1,
          columns: optValues
            .map((v) => Field_MATCH_ROW.get(v))
            .map(
              ({columnAlias, curShowName, dbId, id, indexType, tableId}) => ({
                columnName: columnAlias,
                curShowName,
                dbId,
                id,
                indexType,
                tableId,
              })
            ),
        };
      });
    },
    [Field_MATCH_ROW]
  );

  const onChangeSynchronize = useCallback(
    (fv) => {
      setTimeout(() => {
        setIsModalOpen(false);
        onCancel?.(false);

        onChange?.(fv);

        setTotalTableState((prev) => ({
          ...prev,
          tableIndexs: fv,
        }));
      }, 0);
    },
    [onChange, onCancel, setTotalTableState]
  );

  const handleModalOk = useCallback(async () => {
    const formValues = await form.validateFields();
    const formatformValues = getFormatformValues(formValues);

    onChangeSynchronize(formatformValues);
  }, [form, getFormatformValues, onChangeSynchronize]);

  const handleAddItem = useCallback((add, lastIndex) => {
    setTimeout(() => {
      add();
      scrollIntoView(`${FORM_ITEM_FIELD}_${lastIndex}`);
    }, 0);
  }, []);

  const handleRemoveItem = useCallback(
    (remove, name) => {
      setTimeout(() => {
        remove(name);
        setFieldsValue(form.getFieldsValue(true).idx);
      }, 0);
    },
    [form]
  );

  const validField = useCallback(
    async (vals, curKey) => {
      const values = await form.getFieldsValue();
      return new Promise((resolve, reject) => {
        if (vals?.length) {
          const {hasRepeatValue, rejectMsg} = getArrRepeatValid(
            values.idx,
            vals,
            curKey
          );
          if (hasRepeatValue) {
            reject(new Error(rejectMsg));
          }
          const hasHideField = vals.some((v) => !Field_SHOW_STATE.get(v));
          if (hasHideField) {
            reject(new Error('选项中存在隐藏字段'));
          }
        }
        resolve();
      });
    },
    [form, Field_SHOW_STATE]
  );

  const footer = useMemo(() => {
    const def = [
      <Button
        key="cancel"
        onClick={() => {
          setIsModalOpen(false);
          onCancel?.(false);
        }}
      >
        取消
      </Button>,
      <Button key="submit" type="primary" onClick={() => handleModalOk()}>
        确定
      </Button>,
    ];
    if (fieldsValue.length) {
      def.splice(
        1,
        0,
        <Button
          key="giveup"
          type="primary"
          onClick={() => onChangeSynchronize([])}
        >
          放弃配置并确定
        </Button>
      );
    }
    return def;
  }, [
    fieldsValue,
    onCancel,
    setIsModalOpen,
    onChangeSynchronize,
    handleModalOk,
  ]);

  return (
    <>
      {!Object.hasOwn(props, 'visible') && (
        <ConfigIndexBtn onClick={() => setIsModalOpen(true)} />
      )}
      <Modal
        centered
        destroyOnClose
        mask
        bodyStyle={{padding: 0}}
        classNames="configuring-indexes"
        footer={footer}
        maskClosable={false}
        title="配置索引"
        visible={isModalOpen}
        onCancel={() => {
          setIsModalOpen(false);
          onCancel?.(false);
        }}
      >
        <Form scrollToFirstError autoComplete="off" form={form}>
          <Form.List name="idx">
            {(fields, {add, remove}) => (
              <>
                <ScrollWraper>
                  {fields.map((field, idx) => {
                    return (
                      <Form.Item
                        className={`${FORM_ITEM_FIELD}_${field.name}`}
                        css={css`
                          ${formItemCss}
                        `}
                        key={idx}
                        rules={[
                          {
                            required: true,
                            message: '请选择字段',
                          },
                          {
                            validator: (_, v) => validField(v, field.name),
                          },
                        ]}
                        {...field}
                      >
                        <ReSelect
                          createWideTableType={createWideTableType}
                          titleIdx={`索引${idx + 1}`}
                          onDelete={() => handleRemoveItem(remove, field.name)}
                        />
                      </Form.Item>
                    );
                  })}
                </ScrollWraper>
                <Form.Item noStyle>
                  <AddIndexBtn
                    onClick={() => handleAddItem(add, fields.length - 1)}
                  />
                </Form.Item>
              </>
            )}
          </Form.List>
        </Form>
      </Modal>
    </>
  );
};

export default ModalConfigIndex;
