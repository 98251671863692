import {css} from '@emotion/react';
import {Button, Space, Tabs} from 'antd';
import {useAtom} from 'jotai';
import React, {useContext, useMemo, useRef} from 'react';
import {useSearchParams} from 'react-router-dom';

import {MicroDataQueryContext} from '../..';
import {filterAtom} from '../../../../../components/Filter/atoms/filterAtom';
import {GlobalTooltip} from '../../../../../components/GlobalTooltip';
import {FORM_PERMS_MAP} from '../../../../../constants/system';
import tipsCode from '../../../../../constants/tipsCode';
import {deepClone} from '../../../../../helpers/utils';
import useHasFormPerm from '../../../../../hooks/useHasFormPerm';
import useModalControl from '../../../../../hooks/useModalControl';
import useSafeState from '../../../../../hooks/useSafeState';
import useSerialFilter from '../../../../../hooks/useSerialFilter';
import BatchEditFilterModal from '../BatchEditFilterModal';
import EditFilterModal from '../EditFilterModal';
import ExportHistoryRecord from '../ExportHistoryRecord';
import BloodRelationship from './BloodRelationship';
import DisplayFieldBtn from './DisplayFieldBtn';
import ExportBtn from './ExportBtn';
import LogDetailTable from './LogDetailTable';
import MicroFieldInfoTable from './MicroFieldInfoTable';
import WideTable from './WideTable';
import WideTableInfo from './WideTableInfo';

const {TabPane} = Tabs;

const WideTableContainer = () => {
  const [searchParams] = useSearchParams();

  const [activeKey, setActiveKey] = useSafeState('1');

  const {updateTable} = useContext(MicroDataQueryContext);

  const tableRef = useRef();

  const saveFilterGroupModalControl = useModalControl();
  const filterGroupManageModalControl = useModalControl();
  const exportHistoryModalControl = useModalControl();

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  const wideId = useMemo(() => {
    return searchParams.get('wideId');
  }, [searchParams]);

  const isDraft = searchParams.get('isDraftFile');

  const isDraftFile = useMemo(() => {
    return isDraft && isDraft === 'true';
  }, [isDraft]);

  const originFilterConditions = useSerialFilter(`dataQueryTable${wideId}`);

  const showButtons = useMemo(() => {
    return originFilterConditions.length;
  }, [originFilterConditions]);

  const onTabChange = (key) => {
    setActiveKey(key);
  };

  // const openSaveFilterGroupModal = () => {
  //   saveFilterGroupModalControl._toggle(true);
  // };

  // const openFilterGroupManageModal = () => {
  //   filterGroupManageModalControl._toggle(true);
  // };

  const clearFilterHandle = () => {
    const conditionsCopy = deepClone(conditions);
    conditionsCopy[`dataQueryTable${wideId}`] = {};
    tableRef.current?.refreshColumns?.();
    updateTable(conditionsCopy[`dataQueryTable${wideId}`]);
    setFilterParams({
      ...filterParams,
      conditions: conditionsCopy,
    });
  };

  // eslint-disable-next-line no-unused-vars
  const hasFormPermAuth = useHasFormPerm(
    FORM_PERMS_MAP.get('微观数据查询-数据宽表详情表单')
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
      `}
    >
      <WideTableInfo />
      <div
        css={css`
          border: 1px solid #dfdfdf;
          border-radius: 2px;
          padding: 4px 10px;
          overflow: hidden;
          margin-top: 10px;
          flex: 1;
          position: relative;
        `}
      >
        {isDraftFile ? (
          <div
            css={css`
              width: 100%;
              height: 80vh;
              display: flex;
              align-items: center;
              justify-content: center;
              font-size: 20px;
              font-weight: 600;
            `}
          >
            草稿不支持数据预览，请保存为数据表后查看
          </div>
        ) : (
          <Tabs
            activeKey={activeKey}
            css={css`
              .ant-tabs-tab {
                padding-bottom: 10px;
                padding-top: 6px;
              }
              .ant-tabs-tab + .ant-tabs-tab {
                margin: 0px 0 0 16px;
              }
              .ant-tabs-nav {
                margin: 0;
              }
            `}
            onChange={onTabChange}
          >
            <TabPane forceRender key="1" tab="数据预览">
              {activeKey === '1' && (
                <div
                  css={css`
                    height: 100%;
                    flex-direction: column;
                  `}
                >
                  <div
                    className="tablePageTopSection"
                    style={{paddingBottom: 8}}
                  >
                    <div
                      css={css`
                        margin-top: 8px;
                        min-height: 20px;
                      `}
                    >
                      <Space size={6}>
                        {/* <FilterGroupBtn
                          openManageModal={openFilterGroupManageModal}
                          ref={filterGroupRef}
                        /> */}
                        {showButtons ? (
                          <>
                            {/* <Button
                              size='small'
                              type='primary'
                              onClick={openSaveFilterGroupModal}
                            >
                              保存筛选
                            </Button> */}

                            <Button
                              ghost
                              size="small"
                              type="primary"
                              onClick={clearFilterHandle}
                            >
                              清除筛选
                            </Button>
                          </>
                        ) : null}
                      </Space>
                      <Space size={10} style={{float: 'right'}}>
                        {/* {hasFormPermAuth && ( */}
                        <GlobalTooltip
                          code={tipsCode.MicroDataQuery.SHOW_COLUMN}
                        >
                          <DisplayFieldBtn />
                        </GlobalTooltip>

                        <GlobalTooltip code={tipsCode.MicroDataQuery.EXPORT}>
                          <ExportBtn
                            exportHistoryModalControl={
                              exportHistoryModalControl
                            }
                          />
                        </GlobalTooltip>
                      </Space>
                    </div>
                  </div>
                  <div>
                    <WideTable
                      ref={tableRef}
                      storeColumnWidthKey="saveFilterGroupTable"
                    />
                  </div>
                </div>
              )}
            </TabPane>
            <TabPane key="2" tab="字段信息">
              {activeKey === '2' && <MicroFieldInfoTable />}
            </TabPane>

            <TabPane key="3" tab="血缘关系">
              {activeKey === '3' && <BloodRelationship />}
            </TabPane>

            <TabPane key="4" tab="更新日志">
              {activeKey === '4' && <LogDetailTable />}
            </TabPane>
          </Tabs>
        )}
      </div>
      <EditFilterModal
        modalControl={saveFilterGroupModalControl}
        storeColumnWidthKey="saveFilterGroupTable"
      />
      <BatchEditFilterModal modalControl={filterGroupManageModalControl} />
      <ExportHistoryRecord modalControl={exportHistoryModalControl} />
    </div>
  );
};

export default WideTableContainer;
