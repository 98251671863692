import {DatePicker} from 'antd';
import React, {useMemo} from 'react';

import dayjs from '../../helpers/extendedDayjs';

const TaskDatePicker = (props) => {
  const {disablePassedDate, onChange, disabled, value, format} = props;

  // const getCurStartTime = () => dayjs().add(2, 'm').format(format);

  const disabledDate = (current) => {
    return current && current < dayjs().subtract(1, 'day').endOf('day');
  };

  const disabledTime = (current) => {
    const afterToday = current > dayjs().endOf('day');
    const preCurHour = dayjs().hour();
    const preCurMinute = dayjs().minute();
    const preCurSecond = dayjs().second();

    const fillArray = (end) => new Array(end).fill().map((v, i) => i);

    const isLaterHour = dayjs(current).hour() > preCurHour;
    const isLaterMinutes = dayjs(current).minute() > preCurMinute;

    return afterToday
      ? {}
      : {
          disabledHours: () => fillArray(preCurHour),
          disabledMinutes: () => (isLaterHour ? [] : fillArray(preCurMinute)),
          disabledSeconds: () =>
            isLaterHour || isLaterMinutes ? [] : fillArray(preCurSecond),
        };
  };

  const disableDateConfig = useMemo(() => {
    return disablePassedDate
      ? {
          disabledDate,
          disabledTime,
        }
      : {};
  }, [disablePassedDate]);

  return (
    <div>
      <span style={{padding: '0 20px 0 0'}}>从</span>
      <DatePicker
        showTime
        allowClear={false}
        disabled={disabled}
        format={format}
        showNow={false}
        value={dayjs(value)}
        onChange={onChange}
        {...disableDateConfig}
      />
      &ensp;开始
    </div>
  );
};

export default TaskDatePicker;
