import request from '../../helpers/request';

/** 菜单管理-查询有权限菜单列表 */
// export const GET_AUTH_MENU_TREE = '/user/menu/getMenuRouters';
export const GET_AUTH_MENU_TREE = '/user/menu/getUserMenu';
export const getAuthMenuTree = () => {
  return request({
    url: GET_AUTH_MENU_TREE,
    method: 'get',
    params: {
      appId: 1,
    },
  });
};

/** 菜单管理-查询全部菜单列表 */
export const GET_ALL_MENU_TREE = '/user/menu/getMenus';
export const getAllMenuTree = () => {
  return request({
    url: GET_ALL_MENU_TREE,
    method: 'get',
  });
};

/** 菜单管理-新增菜单 */
export function runCreateMenu(data) {
  return request({
    url: `/user/menu/add`,
    method: 'post',
    data,
  });
}
/** 菜单管理-更新菜单 */
export function runUpdateMenu(data) {
  return request({
    url: `/user/menu/edit`,
    method: 'post',
    data,
  });
}
/** 菜单管理-调整菜单顺序 */
export function runReorderMenu(data) {
  return request({
    url: `/user/menu/adjustMenuRank`,
    method: 'post',
    data,
  });
}
/** 菜单管理-获取配置路由组件地址列表 */
export const GET_PAGE_ROUTERS = `/user/page/getRouteMapping`;
export const getPageRouters = () => {
  return request({
    url: GET_PAGE_ROUTERS,
    method: 'get',
  });
};
