export const defaultDateFormat = 'YYYY-MM-DD HH:mm:ss';

export const base64ImgPrefix = 'data:image/gif;base64,';

export const monthList = [
  ...[{label: '全部月', value: -1}],
  ...[...new Array(12).keys()].map((n) => ({
    label: `${String(n + 1)}月`,
    value: n + 1,
  })),
];

export const monthDayList = [
  ...[{label: '全部天', value: -1}],
  ...[...new Array(31).keys()].map((n) => ({
    label: String(n + 1),
    value: n + 1,
  })),
  ...[{label: '最后一天', value: 32}],
];

export const DICTTYPES = {
  // 系统设置的值
  SYS_SETTING: 'sys_setting',
  // 邮箱设置的值
  MAIL_SETTING: 'mail_setting',
  // 微观指标导入设置
  MIS_SETTING: 'mis_setting',
};

export const defaultSysDetail = {
  name: '智慧统计',
  slogan: '求真务实大数据，统计创新看这里',
  logo: '',
  cover: '',
  copyright: 'Copyright©2022 统计局版权所有',
  documentUpdateTime: '',
  address: '',
  support: '技术支持 深圳市前海数据服务有限公司',
};

export const notCacheColumnWidthTableKey = ['microIndexPreviewTable'];
