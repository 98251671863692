import {css} from '@emotion/react';
import loadComponent from '@loadable/component';
import {Form} from 'antd';
import React, {useContext} from 'react';

import {TableContext} from '../helpers/context';

/**
 * @param {import('../type').EditableTableColumnType} props
 */
export default function Cell(props) {
  const {
    index,
    dataIndex,
    editable,
    componentType,
    rules = [],
    validateTrigger = 'onChange',
    children,
    shouldFieldValidate,
    align,
    className,
    colSpan,
    rowSpan,
    style,
    title,
    onMouseEnter,
    onMouseLeave,
    ...rest
  } = props;

  const {tableForm} = useContext(TableContext);

  let EditableComponent = null;

  if (editable) {
    EditableComponent = loadComponent(() => import(`./${componentType}`));
  }

  return (
    <td
      align={align}
      className={className}
      colSpan={colSpan}
      rowSpan={rowSpan}
      style={style}
      title={title}
    >
      {EditableComponent ? (
        <Form.Item
          css={css`
            margin-bottom: ${tableForm.getFieldError(['data', index, dataIndex])
              .length > 0 && shouldFieldValidate
              ? '24px'
              : 0};
          `}
          name={[index, dataIndex]}
          preserve={false}
          rules={rules}
          validateTrigger={validateTrigger}
        >
          <EditableComponent dataIndex={dataIndex} index={index} {...rest} />
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
}
