import {css} from '@emotion/react';

export const GuidanceCss = css`
  background: rgba(249, 249, 249, 0.1);
  height: 100%;
  padding: 10px;
  & > div {
    color: #1a2230;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 5px;
  }
  ul {
    margin-bottom: 15px;
    padding: 0;
  }
  li {
    list-style: none;
    padding-left: 13px;
    position: relative;
    font-size: 12px;
    line-height: 18px;
    color: rgba(26, 34, 48, 0.7);
    &::before {
      content: '';
      width: 6px;
      height: 6px;
      background: rgba(26, 34, 48, 0.4);
      border-radius: 50%;
      position: absolute;
      left: 0;
      top: 6px;
    }
  }
  p {
    font-size: 12px;
    line-height: 18px;
    color: rgba(26, 34, 48, 0.7);
    margin-bottom: 5px;
    span {
      background: rgba(77, 94, 255, 0.2);
    }
  }
`;
