import {css} from '@emotion/react';
import {Button} from 'antd';
import {Resizable} from 're-resizable';
import React, {
  useRef,
  useEffect,
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react';
import {useLocalStorage} from 'react-use';

import IconFont from '../IconFont';
import {Page} from '../Page';

export const defaultEnable = {
  top: false,
  right: false,
  bottom: false,
  left: false,
  topRight: false,
  bottomRight: false,
  bottomLeft: false,
  topLeft: false,
};

/**
 * @typedef  {object} props
 * @property {function= | ReactNode} leftElement
 * @property {function= | ReactNode} centerTopElment
 * @property {function= | ReactNode} centerBottomElement
 * @property {function= | ReactNode} rightElement
 * @property {string} leftLimitWidth - 左侧拖拽的最大寬度
 * @property {string} rightLimitWidth - 右侧拖拽的最大寬度
 * @property {string} cacheStorageKey - 宽度本地存储key
 * @property {object={leftResizeStopCallbac,rightResizeStopCallback,centerResizeStopCallBack, centerResizeStartCallBack}} resizeStopCallback - 左 中 右模块拖转停止的回调
 */

const ResizebleFrame = (props, ref) => {
  const {
    leftLimitWidth = 600,
    rightLimitWidth = 600,
    centerTopMinHeight = 250,
    centerTopMaxHeight = 573,
    headerElement,
    leftElement,
    enableCenterTopResize = true,
    centerTopElment,
    centerBottomElement,
    rightElement,
    cacheStorageKey,
    leftCollapseTitle = '文件夹/数据表',
    rightCollapseTitle = '文件夹/数据表',
    resizeStopCallback = {},
    className,
    noCenterTopCache = false,
    centerTopElmentSize,
  } = props;

  const {
    leftResizeStopCallback = (v) => v,
    rightResizeStopCallback = (v) => v,
    centerResizeStopCallBack = (v) => v,
    centerResizeStartCallBack = (v) => v,
  } = resizeStopCallback;

  const [leftCollapseFlag, setLeftCollapseFlag] = useState(false);
  const [rightCollapseFlag, setRightCollapseFlag] = useState(false);

  const leftResizebleRef = useRef();
  const CenterTopResizebleRef = useRef();
  const rightResizebleRef = useRef();
  const cacheVariable = useRef({});

  const [value, setValue] = useLocalStorage(cacheStorageKey);

  const renderElemnt = (element) => {
    return element ?? React.isValidElement(element)
      ? element
      : typeof element === 'function'
      ? element()
      : null;
  };

  useEffect(() => {
    const {right = null, left = null, centerTop = null} = value ?? {};
    if (!leftCollapseFlag) {
      if (left) {
        leftResizebleRef.current.updateSize(left);
      }
    }
    if (!rightCollapseFlag && rightElement) {
      if (right) {
        rightResizebleRef.current.updateSize(right);
      }
    }
    if (centerTopElment && centerTop && enableCenterTopResize) {
      if (!noCenterTopCache) {
        CenterTopResizebleRef.current.updateSize(centerTop);
      }
    } else if (!enableCenterTopResize) {
      centerResizeStopCallBack(CenterTopResizebleRef);
    }
  }, [
    value,
    leftCollapseFlag,
    rightCollapseFlag,
    rightElement,
    centerTopElment,
    enableCenterTopResize,
    centerResizeStopCallBack,
    noCenterTopCache,
  ]);

  useImperativeHandle(ref, () => ({
    getCenterTopInstance: () => CenterTopResizebleRef.current,
  }));

  return (
    <Page
      className={className}
      css={css`
        min-height: 100%;
        position: relative;
        padding: 0;
      `}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          padding: 12px 0;
        `}
      >
        {/* 头部元素 */}
        {headerElement ? <div>{renderElemnt(headerElement)}</div> : null}

        {/* 余下宽高自动撑满元素 */}
        <div
          css={css`
            position: relative;
            flex: 1;
          `}
        >
          <div
            css={css`
              display: flex;
              width: 100%;
              height: 100%;
              position: absolute;
              top: 0;
              padding: 0 12px;
            `}
          >
            {/* 左边拖转元素节点 */}
            <div
              css={css`
                width: 33px;
                height: 100%;
                position: relative;
                margin-right: 10px;
                border-right: 1px solid #eeeeee;
                display: ${leftCollapseFlag ? 'flex' : 'none'};
              `}
            >
              <p
                css={css`
                  width: 14px;
                  word-wrap: break-word;
                  text-align: center;
                  font-size: 14px;
                  color: #1a2230;
                `}
              >
                {leftCollapseTitle}
              </p>
              <div
                css={css`
                  position: absolute;
                  right: -8px;
                  top: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1;
                `}
              >
                <Button
                  css={css`
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                  `}
                  icon={
                    <IconFont style={{fontSize: 10}} type="icon-arrow-right" />
                  }
                  onClick={() => {
                    setLeftCollapseFlag(false);
                  }}
                />
              </div>
            </div>
            <Resizable
              css={css`
                /* display: flex; */
                border-right: 1px solid #eeeeee;
                margin-right: 10px;
                display: ${leftCollapseFlag ? 'none' : 'flex'};
              `}
              defaultSize={{
                width: 200,
                height: '100%',
              }}
              enable={{...defaultEnable, right: true}}
              maxWidth={leftLimitWidth}
              minWidth={216}
              ref={leftResizebleRef}
              onResizeStop={(e, direction, _ref, d) => {
                cacheVariable.current.left = {
                  width: _ref.style.width,
                  height: '100%',
                };
                setValue(cacheVariable.current);
                leftResizeStopCallback(_ref);
              }}
            >
              {renderElemnt(leftElement)}
              <div
                css={css`
                  position: absolute;
                  right: -8px;
                  top: 10px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  z-index: 1;
                `}
              >
                <Button
                  css={css`
                    width: 16px;
                    height: 16px;
                    border-radius: 50%;
                  `}
                  icon={
                    <IconFont
                      style={{fontSize: 10, transform: 'rotate(180deg)'}}
                      type="icon-arrow-right"
                    />
                  }
                  onClick={() => {
                    setLeftCollapseFlag(true);
                  }}
                />
              </div>
            </Resizable>

            {/* 中间列 支持高度伸缩 */}
            <div
              className="centerElement"
              css={css`
                display: flex;
                box-sizing: border-box;
                flex: 1;
                flex-direction: column;
                overflow: hidden;
                min-height: 100%;
              `}
            >
              <Resizable
                className="centerTopElment"
                css={css`
                  margin-bottom: 10px;
                  .bottomHandleCls {
                    bottom: -10px !important;
                  }
                `}
                defaultSize={
                  centerTopElmentSize
                    ? centerTopElmentSize
                    : {width: '100%', height: '50%'}
                }
                enable={
                  enableCenterTopResize
                    ? {...defaultEnable, right: false, bottom: true}
                    : defaultEnable
                }
                handleClasses={{
                  bottom: 'bottomHandleCls',
                }}
                maxHeight={centerTopMaxHeight}
                minHeight={centerTopMinHeight}
                ref={CenterTopResizebleRef}
                onResizeStart={(event, direction, _ref, diff) => {
                  centerResizeStartCallBack({event, ref: _ref, diff});
                }}
                onResizeStop={(event, direction, _ref, diff) => {
                  cacheVariable.current.centerTop = {
                    width: '100%',
                    height: _ref.style.height,
                  };
                  setValue(cacheVariable.current);
                  centerResizeStopCallBack({event, ref: _ref, diff});
                }}
              >
                {renderElemnt(centerTopElment)}
              </Resizable>
              <div
                className="centerBottomElement"
                css={css`
                  width: 100%;
                  flex: 1;
                  overflow: auto;
                `}
              >
                {renderElemnt(centerBottomElement)}
              </div>
            </div>

            {/* 右边拖拽列 */}
            {rightElement ? (
              // eslint-disable-next-line react/jsx-no-useless-fragment
              <div className="rightElement">
                <div
                  css={css`
                    /* width: 41px; */
                    height: 100%;
                    position: relative;
                    margin-left: 10px;
                    padding-left: 10px;
                    border-left: 1px solid #eeeeee;
                    display: ${rightCollapseFlag ? 'block' : 'none'};
                  `}
                >
                  <p
                    css={css`
                      float: right;
                      width: 14px;
                      word-wrap: break-word;
                      text-align: center;
                      font-size: 14px;
                      line-height: 16px;
                      padding-top: 5px;
                      color: #1a2230;
                    `}
                  >
                    {rightCollapseTitle}
                  </p>
                  <div
                    css={css`
                      position: absolute;
                      left: -8px;
                      top: 5px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      z-index: 10;
                    `}
                  >
                    <Button
                      css={css`
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                      `}
                      icon={
                        <IconFont
                          style={{fontSize: 10, transform: 'rotate(180deg)'}}
                          type="icon-arrow-right"
                        />
                      }
                      onClick={() => {
                        setRightCollapseFlag(false);
                      }}
                    />
                  </div>
                </div>
                <Resizable
                  css={css`
                    display: flex;
                    margin-left: 8px;
                    border-left: 1px solid #eeeeee;
                    display: ${rightCollapseFlag ? 'none' : 'block'};
                  `}
                  defaultSize={{
                    width: 200,
                    height: '100%',
                  }}
                  enable={{...defaultEnable, right: false, left: true}}
                  maxWidth={rightLimitWidth}
                  minWidth={216}
                  ref={rightResizebleRef}
                  onResizeStop={(e, direction, _ref, d) => {
                    cacheVariable.current.right = {
                      width: _ref.style.width,
                      height: '100%',
                    };
                    setValue(cacheVariable.current);
                    rightResizeStopCallback(_ref);
                  }}
                >
                  {renderElemnt(rightElement)}
                  <div
                    css={css`
                      position: absolute;
                      left: -8px;
                      top: 10px;
                      display: flex;
                      align-items: center;
                      justify-content: center;
                      z-index: 10;
                    `}
                  >
                    <Button
                      css={css`
                        width: 16px;
                        height: 16px;
                        border-radius: 50%;
                      `}
                      icon={
                        <IconFont
                          style={{fontSize: 10}}
                          type="icon-arrow-right"
                        />
                      }
                      onClick={() => {
                        setRightCollapseFlag(true);
                      }}
                    />
                  </div>
                </Resizable>
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </Page>
  );
};

export default forwardRef(ResizebleFrame);
