import {css} from '@emotion/react';
import {Spin} from 'antd';
import {useAtom} from 'jotai';
import ResizeObserver from 'rc-resize-observer';
import React, {useEffect} from 'react';

import {logicEditAtom} from '../../../../../../atoms/logicEditAtom';
import {overflowScrollBar} from '../../../../../../global.css';
import useDrawCanvas from '../../hooks/useDrawCanvas';
import LogicSetModal from './LogicSetModal';
import {canvasBox, canvasEditWraper, canvasMiniMap} from './styles.css';

const CanvasEdit = (props) => {
  const {initGraphInstance} = useDrawCanvas();

  const [totalStatus] = useAtom(logicEditAtom);

  const {graphInstance, canvasLoading, logicSetModalVisible} = totalStatus;

  useEffect(() => {
    initGraphInstance();
  }, [initGraphInstance]);

  return (
    <>
      <ResizeObserver
        onResize={({width, height}) => {
          // const canvasHeight = height - 54;
          graphInstance?.resize(width, height);
        }}
      >
        <div css={canvasEditWraper} id="canvasEditWraper">
          <Spin spinning={canvasLoading}>
            <div
              className="canvasBoxEl"
              css={css`
                ${canvasBox};
                ${overflowScrollBar}
              `}
              id="canvasBoxElement"
            />
            <div
              className="canvasMiniMap"
              css={canvasMiniMap}
              id="canvasMiniMap"
            />
          </Spin>
        </div>
      </ResizeObserver>

      {logicSetModalVisible && <LogicSetModal />}
    </>
  );
};

export default CanvasEdit;
