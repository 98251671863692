/* eslint-disable react-hooks/exhaustive-deps */
import {cx} from '@emotion/css';
import {css} from '@emotion/react';
import {Table, Button, Space, Result} from 'antd';
import {useAtom} from 'jotai';
import {stringify} from 'query-string';
import React, {
  useMemo,
  useImperativeHandle,
  forwardRef,
  useRef,
  useEffect,
} from 'react';
import {useMount} from 'react-use';
import useSWR from 'swr';

import {BUTTON_PERMS_MAP} from '../../constants/system';
import request from '../../helpers/request';
import useARH from '../../helpers/resizableHeader/index.es';
import {deepClone} from '../../helpers/utils';
import useHasBtnPerm from '../../hooks/useHasBtnPerm';
import {usePaginationOriginal} from '../../hooks/usePaginationOriginal';
import {usePaginationUrlParams} from '../../hooks/usePaginationUrlParams';
import useSafeState from '../../hooks/useSafeState';
import useSerialFilter from '../../hooks/useSerialFilter';
import {useValidatePerm} from '../../hooks/useValidatePerm';
import {filterAtom} from '../Filter/atoms/filterAtom';
import {PageActionBar, PageActionBarRight} from '../Page';
import WaterMarks from '../WaterMarks';
import {
  calcColumns,
  colWidthFix,
  makeAverageWidth,
  makeColumnTitle,
  onResizeEnd,
  reorderColumns,
} from './helper';
import useFixWidthColumn from './hooks/useFixWidthColumn';
import useResizeLineStyle from './hooks/useResizeLineStyle';
import {useScroll} from './hooks/useScroll';
import {bottomLeft, customTableStyle, refreshButton} from './styles.css';
import '../../helpers/resizableHeader/style.css';

export const columnMinWidth = 52;

/**
 * @typedef {import('react').ReactNode} ReactNode
 */

/**
 * @typedef {object} CustomButtonsProps
 * @property {string} to - 点击按钮要跳转的路径
 * @property {string} title - 按钮中文字
 * @property {(data: unkonwn, mutate: function) => void} onClick - 点击按钮回调函数
 */

/**
 * @typedef {import('antd').ButtonProps & CustomButtonsProps} ButtonsProps
 */

/**
 * @typedef  {object} CustomTableProps
 * @property {ButtonsProps[] | ReactNode} topLeftButtons
 * @property {ButtonsProps[] | ReactNode} topRightButtons
 * @property {ButtonsProps[] | ReactNode} bottomLeftButtons
 * @property {string} reorderKey - 有箭头点击重新排序树数据，排序依赖唯一字段
 * @property {string} reorderBtnPermText - 排序按钮功能权限名字
 * @property {number} reorderIndex - 在第几列放排序
 * @property {string} dataApi - 表格数据请求接口
 * @property {function=} fetcher - swr fetcher
 * @property {string} storeColumnWidthKey - 带有宽度的表头的本地存储key
 * @property {function=} onDataChange - 表格数据获取后的回调
 * @property {function=} onError - 接口报错捕获
 * @property {function=} reorderCallBack - 点击排序箭头后回调函数
 * @property {function=} mutate - 刷新表格数据方法
 * @property {{btnPermCode?: string; [key: string]: any;}} extraParams - 额外查询参数
 * @property {boolean} searchParamsUsable - 是否将分页参数和其它参数放至Url中
 * @property {boolean} pagination - 是否展示分页，否传false，展示则传相关属性
 * @property {string} permCode - 表单权限code
 * @property {boolean | function=} clearFilter - 是否重置筛选按钮，如果传入函数则表示有除了筛选器以外的其他筛选条件
 * @property {boolean | function=} refresh - 是否展示刷新按钮，如果传入函数则表示有其它操作处理
 * @property {string} className - 外层使用css emotion 会转成className属性传进来
 * @property {boolean} integratedDatePicker - 是否集成了日历

/**
 * @description: table组件,内部管理状态，表头可拖拽改变列宽
 *
 * 第一次进入页面获取数据后自动计算各列宽度.
 * 使用注意点：
 * 1若有自定义width数值,会以自定义宽度为准
 * 2若有renderWidth: 'AUTO',则此列为自适应宽度列，不可拖拽改变宽度,此条比第1条优先级高
 * 3若遇到内容是number类型的，添加align: right,其它类型已配置默认左对齐
 * 4若是操作列，需要添加fixWidth: true和自定义宽度，组件会自动移除此列拖拽改变列宽功能
 * 5每列都必须有dataIndex属性
 * @param {CustomTableProps & import('antd').TableProps<a   ny>} props
 * @param {import('react').Ref<{
 *  mutate: () => void;
 *  setPage: () => void;
 * }>} ref
 */
const TableSheet = (props, ref) => {
  const {
    rowKey = 'id',
    reorderKey = '',
    reorderBtnPermText = '',
    reorderCallBack = (v) => v,
    reorderIndex = 1,
    columns = [],
    topLeftButtons,
    topRightButtons,
    bottomLeftButtons,
    dataApi,
    fetcher,
    onDataChange,
    onError = (err) => err,
    storeColumnWidthKey = '',
    extraParams = {},
    pagination = {},
    permCode,
    searchParamsUsable = true,
    scroll,
    refresh = true,
    clearFilter = true,
    className,
    clearFilterBtnStyle = {},
    integratedDatePicker = false,
    ...restProps
  } = props;

  const [isMount, setIsMount] = useSafeState(false);

  const tableRef = useRef();

  // 如果存在btnPermCode，说明该表单是否可见受功能按钮控制(即该表单存在于弹窗的情况)，应该绑定功能权限而非表单权限
  const {btnPermCode, ...restExtraParams} = extraParams;

  const [permRef, validatePermChange] = useValidatePerm({
    type: btnPermCode ? 3 : 2,
    code: btnPermCode ?? permCode,
  });

  // 是否将分页参数关联到地址栏
  const paginationParamHook = searchParamsUsable
    ? usePaginationUrlParams
    : usePaginationOriginal;

  const {pageSize: defaultPageSize, ...restPagination} = pagination;

  const {pageNum, pageSize, setPage} = paginationParamHook({
    defaultPageSize,
  });

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {conditions} = filterParams;

  // 页面表单权限逻辑
  const hasReorderAuth = useHasBtnPerm(
    BUTTON_PERMS_MAP.get(reorderBtnPermText)
  );

  // 更新一下，使defaultExpandAllRows 刷新页面或数据更新后仍生效
  const [update, setUpdate] = useSafeState(1);
  // 兼容数据给页面控制做处理
  const [dataSource, setDataSource] = useSafeState(restProps.dataSource ?? []);

  useEffect(() => {
    if (restProps.dataSource !== undefined) {
      setDataSource(restProps.dataSource);
    }
  }, [restProps.dataSource]);

  useMount(() => {
    setIsMount(true);
  });

  // 常规请求列表数据
  // 还有一种是如日志查询弹窗后，查询权限被关闭，再在弹窗中刷新日志列表时，表单不展示
  const serialfilterConditions = useSerialFilter(storeColumnWidthKey);

  const {data, mutate, error, isValidating} = useSWR(
    dataApi
      ? `${dataApi}?${stringify({
          ...(pagination === false ? {} : {pageNum, pageSize}),
          ...restExtraParams,
        })}`
      : null,

    async (url) => {
      if (btnPermCode ?? permCode) {
        await validatePermChange();
      }

      /**
       * 形如 {
       *  fieldA：{condition:1,type:1},
       *  fieldB：{condition:1,type:1},
       *  fieldC: {},
       * }
       */
      const requestConfig = {
        headers: {
          'filter-condition': JSON.stringify(serialfilterConditions),
        },
      };
      if (!permRef.current) {
        return fetcher
          ? fetcher(
              pagination === false ? {} : {pageNum, pageSize},
              requestConfig
            )
          : request.get(url, requestConfig);
      }
      return null;
    },
    {
      onSuccess: (dataRes) => {
        const dataSourceByResponse = dataRes.data ?? [];
        if (onDataChange) onDataChange(dataSourceByResponse);

        setDataSource(dataSourceByResponse);
        setUpdate(Date.now());

        // 当删除数据刚好当前页没有了，自动跳前一页数据
        if (dataSourceByResponse.length === 0 && pageNum > 1) {
          setPage(pageNum - 1, pageSize);
        }
      },
      onError,
    }
  );

  // 原型要求：https://axhub.im/ax9/5f662a6ea08fdb13/#id=lhr4w2&p=%E8%A1%A8%E5%8D%95%E9%BB%98%E8%AE%A4%E5%BC%80%E5%8F%91%E5%8E%9F%E5%88%99_v1_0&g=1
  // 所有按钮类的操作column，需要添加fixWidth: true，来单独处理成固定不可拖拽宽度
  const clacColumns = useMemo(() => {
    if (pageNum === 1 && !isValidating && isMount) {
      if (
        props.dataSource === undefined ||
        (props.dataSource !== undefined && props.dataSource !== null)
      ) {
        const _dataSource =
          props.dataSource === undefined ? dataSource : props.dataSource;
        let widthFixedCols;
        if (_dataSource.length > 0) {
          widthFixedCols = colWidthFix({
            totalCols: columns,
            columnMinWidth,
            dataSource: _dataSource,
            storeColumnWidthKey,
          });
        } else {
          widthFixedCols = makeAverageWidth(columns, storeColumnWidthKey);
        }
        return widthFixedCols;
      }
    }
    return columns;
  }, [
    columns,
    dataSource,
    props.dataSource,
    pageNum,
    storeColumnWidthKey,
    isValidating,
    isMount,
  ]);

  // 分页配置
  const paginationConfig = useMemo(() => {
    return pagination === false
      ? false
      : {
          total: data?.total ?? 0,
          current: pageNum ?? 1,
          pageSize,
          showQuickJumper: true,
          hideOnSinglePage: false,
          showSizeChanger: true,
          showTotal: (totalNum) => `共 ${totalNum} 条数据`,
          onChange: setPage,
          onShowSizeChange: setPage,
          size: 'small',
          ...restPagination,
        };
  }, [pagination, data, pageNum, pageSize, setPage]);

  const resizableHeader = useARH({
    columns: useMemo(() => {
      if (reorderKey && hasReorderAuth) {
        return reorderColumns({
          columns: clacColumns,
          dataSource,
          reorderKey,
          reorderBtnPermText,
          reorderIndex,
          mutate,
          reorderCallBack,
          storeColumnWidthKey,
        });
      }
      return calcColumns(clacColumns);
    }, [update, clacColumns, dataSource, reorderKey, reorderIndex]),

    // 某一列不能拖动，设置该列的最小展示宽度，默认 120
    defaultWidth: 80,

    // 拖动最小宽度 默认 52
    minConstraints: columnMinWidth,

    // 保存拖拽宽度至本地localStorage
    columnsState: {
      persistenceKey: `_resizeColumn_${storeColumnWidthKey}`,
      persistenceType: 'localStorage',
    },

    onResizeEnd: onResizeEndHandler,
  });

  const {components, resizableColumns} = resizableHeader;

  function onResizeEndHandler(curCol, onResize) {
    return onResizeEnd(storeColumnWidthKey, resizableHeader)(curCol, onResize);
  }

  const finalColumns = useMemo(() => {
    return makeColumnTitle(resizableColumns, mutate, storeColumnWidthKey);
  }, [resizableColumns]);

  useResizeLineStyle(tableRef.current, isValidating);
  useFixWidthColumn(tableRef.current, isValidating, resizableColumns);

  const {_scroll} = useScroll(
    scroll,
    isValidating,
    resizableHeader.tableWidth,
    storeColumnWidthKey
  );

  /**
   * @description: 表格分页、排序、筛选变化时触发
   */
  const onTableChangeHandle = (newPagination, filters, sorter, extra) => {
    const {current, pageSize: newPageSize} = newPagination;
    setPage(current, newPageSize);
  };

  /**
   * @description: 触发功能按钮，并将当前表格数据和刷新数据方法传出
   * @param {Event}
   * @param {Object}
   * @return {*}
   */
  const onExtraClick = (e, {to, onClick}) => {
    if (onClick && typeof onClick === 'function') {
      return onClick(dataSource, mutate);
    }

    return false;
  };

  /**
   * @description: 渲染表格头部左右两边的功能按钮
   * @param {ButtonsProps[] | ReactElement | ReactElement[]} buttons
   * @return {[React.ReactNode] | React.ReactNode}
   */
  const renderFuncButtons = (buttons) => {
    if (Array.isArray(buttons) && !React.isValidElement(buttons[0])) {
      return [
        ...(buttons.length > 0
          ? buttons.map(({to, onClick, title, ...rest}, idx) => (
              <Button
                key={idx}
                {...rest}
                size="small"
                onClick={(e) => onExtraClick(e, {to, onClick})}
              >
                {title}
              </Button>
            ))
          : []),
      ];
    }
    return (
      (React.isValidElement(buttons) ||
        (Array.isArray(buttons) && React.isValidElement(buttons[0]))) &&
      buttons
    );
  };

  const getTableData = () => dataSource;
  const getTablePagination = () => paginationConfig;

  const clearFilterCondition = () => {
    const conditionsCopy = deepClone(conditions);

    conditionsCopy[storeColumnWidthKey] = {};

    setFilterParams((pre) => ({...pre, conditions: conditionsCopy}));
  };

  // 重置筛选
  function clearFilterHandler() {
    clearFilterCondition();
    if (typeof clearFilter === 'function') {
      Promise.resolve().then(clearFilter);
    } else {
      Promise.resolve().then(mutate);
    }
  }

  useImperativeHandle(ref, () => ({
    getTableData,
    mutate,
    setPage,
    setDataSource,
    getTablePagination,
    setUpdate,
  }));

  /* useUnmount(() => {
    conditions.delete(storeColumnWidthKey);
    setFilterAtom({
      ...restFilterAtom,
      conditions,
    });
  }); */

  if (!storeColumnWidthKey) {
    throw new Error('缺少 storeColumnWidthKey 属性');
  }

  // 刷新列表
  const freshTableDataHandle = async () => {
    if (props.mutate) {
      await props.mutate();
    } else {
      await mutate();
    }

    if (typeof refresh === 'function') {
      await refresh();
    }
  };

  return (
    <div
      className={`${storeColumnWidthKey}-tablesheet`}
      style={{position: 'relative'}}
    >
      <div className={`${storeColumnWidthKey}-tablesheet-content`}>
        {isValidating || error?.code !== 403 ? (
          <>
            <PageActionBar className="pageActionBar">
              {renderFuncButtons(topLeftButtons)}

              {(refresh || clearFilter || topRightButtons) && (
                <PageActionBarRight>
                  {refresh && (
                    <Button
                      css={css`
                        ${refreshButton}
                      `}
                      size="small"
                      type="default"
                      onClick={freshTableDataHandle}
                    >
                      刷新表单
                    </Button>
                  )}
                  {clearFilter && (
                    <Button
                      css={css`
                        background: #ffffff;
                        color: #4d5eff;
                        height: 20px;
                        font-size: 12px;
                        line-height: 12px;
                        padding: 2px 8px;
                      `}
                      disabled={
                        !integratedDatePicker &&
                        serialfilterConditions.length === 0
                      }
                      size="small"
                      style={clearFilterBtnStyle}
                      type="primary"
                      onClick={clearFilterHandler}
                    >
                      重置筛选
                    </Button>
                  )}
                  {renderFuncButtons(topRightButtons)}
                </PageActionBarRight>
              )}
            </PageActionBar>

            <WaterMarks>
              <Table
                className={cx(storeColumnWidthKey, className)}
                columns={finalColumns}
                components={components}
                css={customTableStyle}
                dataSource={dataSource || []}
                key={update}
                loading={isValidating}
                pagination={paginationConfig}
                ref={tableRef}
                rowKey={rowKey}
                scroll={_scroll}
                showSorterTooltip={false}
                size="small"
                onChange={
                  props.onChange === undefined
                    ? onTableChangeHandle
                    : props.onChange
                }
                {...restProps}
              />
            </WaterMarks>

            <div css={bottomLeft}>
              <Space>{renderFuncButtons(bottomLeftButtons)}</Space>
            </div>
          </>
        ) : (
          <Result
            status="warning"
            subTitle="抱歉，您没有权限访问当前表单数据"
            title="权限错误"
          />
        )}
      </div>
    </div>
  );
};

export default forwardRef(TableSheet);
