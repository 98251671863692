export function treeFindPath(tree, func, path = []) {
  if (!tree) return [];
  for (const data of tree) {
    path.push(data);
    if (func(data)) return path;
    if (data.children) {
      const findChildren = treeFindPath(data.children, func, path);
      if (findChildren.length) return findChildren;
    }
    path.pop();
  }
  return [];
}

export const treeFilter = (tree, func) => {
  return tree
    .map((node) => ({...node}))
    .filter((node) => {
      node.children = node.children && treeFilter(node.children, func);
      return func(node) || (node.children && node.children.length);
    });
};

export function numberWithCommas(x) {
  x = x.toString();
  const pattern = /(-?\d+)(\d{3})/;
  while (pattern.test(x)) x = x.replace(pattern, '$1,$2');
  return x;
}

export function encodeValueList(c) {
  if (c) {
    return c.map((cv) => ({
      ...cv,
      valueList:
        cv?.valueList?.map((vl) =>
          encodeURIComponent(decodeURIComponent(vl))
        ) ?? [],
    }));
  }
  return null;
}
