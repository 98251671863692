import {Button, Modal, Space} from 'antd';
import {useAtom} from 'jotai';
import React, {useEffect} from 'react';

import useFilterDescription from '../../../../containers/DataManagement/MicroIndex/LogicEditPage/hooks/useFilterDescription';
import {deepClone} from '../../../../helpers/utils';
import TableSheetCopy from '../../../TableSheetCopy';
import {filterAtom} from '../../atoms/filterAtom';

const FilterPreviewTable = ({
  dataTableColumnList,
  scroll = {y: 180},
  freshTable,
}) => {
  const {filterRecord, getFilterRecordDes} = useFilterDescription(
    dataTableColumnList ?? []
  );

  const [filterParams, setFilterParams] = useAtom(filterAtom);
  const {tableKey, conditions, column} = filterParams;

  const onEditFilterHandle = (row) => {
    setFilterParams((pre) => ({
      ...pre,
      visible: true,
      column: {...column, ...row},
    }));
  };

  const onDelFilterHandle = (row) => {
    Modal.confirm({
      title: '确定要删除筛选器？',
      content: '筛选器删除为不可逆操作，请确认',
      centered: true,
      onOk: (close) => {
        const delKey = row.aliasName;
        const conditionsCopy = deepClone(conditions);
        const curTableFilters = conditionsCopy?.[tableKey]?.[delKey] || {};
        const {orderType} = curTableFilters;

        conditionsCopy[tableKey][delKey] = orderType ? {orderType} : {};

        setFilterParams({
          ...filterParams,
          conditions: conditionsCopy,
        });

        if (freshTable) {
          setTimeout(() => {
            freshTable(conditionsCopy);
          }, 0);
        }

        close();
      },
    });
  };

  useEffect(() => {
    getFilterRecordDes();
  }, [getFilterRecordDes]);

  const columns = [
    {
      title: '序号',
      dataIndex: 'id',
      width: 80,
      render: (val, row, index) => index + 1,
    },
    {
      title: '筛选字段',
      dataIndex: 'indexInfo',
      width: 300,
      render: (val, row) => {
        return val;
      },
    },
    {
      title: '筛选内容',
      dataIndex: 'record',
      width: 450,
      render: (val, row) => {
        return val;
      },
    },
    {
      title: '操作',
      dataIndex: 'action',
      align: 'right',
      width: 120,
      fixWidth: true,
      render: (val, row) => {
        return (
          <Space>
            <Button
              size="small"
              type="link"
              onClick={() => onEditFilterHandle(row)}
            >
              编辑
            </Button>

            <Button
              size="small"
              type="link"
              onClick={() => onDelFilterHandle(row)}
            >
              删除
            </Button>
          </Space>
        );
      },
    },
  ];
  return (
    <TableSheetCopy
      clearFilter={false}
      columns={columns}
      dataSource={filterRecord}
      pagination={false}
      refresh={false}
      rowKey="columnAlias"
      scroll={scroll}
      storeColumnWidthKey="filterPreviewTable"
    />
  );
};

export default FilterPreviewTable;
