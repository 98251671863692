import {atom} from 'jotai';

import {INDEX_SYS_SETTING} from '../api/system/sysSetting';
import {defaultSysDetail} from '../constants/base';
import request from '../helpers/request';
import {getObjectFromDictArray} from '../helpers/utils';

const sysSettingAtom = atom(defaultSysDetail);

const fetchSysSettingAtom = atom(
  (get) => get(sysSettingAtom),
  (_get, set, url) => {
    request
      .get(`${INDEX_SYS_SETTING}?_t=${Date.now()}`)
      .then((res) => {
        set(sysSettingAtom, (val) => ({
          ..._get(sysSettingAtom),
          ...getObjectFromDictArray(res.data ?? []),
        }));
      })
      .catch((e) => e);
  }
);

export {sysSettingAtom, fetchSysSettingAtom};
