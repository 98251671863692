import {keyframes} from '@emotion/css';
import {css} from '@emotion/react';

import {textOverFlow} from '../../../../../../global.css';

export const canvasEditWraper = css`
  width: 100%;
  height: 100%;
  border: 1px solid #dfdfdf;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const xFormItem = css`
  margin-bottom: 10px;
`;

export const canvasBox = css`
  flex: 1;
  height: 100% !important;
`;

export const canvasMiniMap = css`
  width: 180px;
  height: 110px;
  background: #ffffff;
  border: 1px solid rgba(223, 223, 223, 0.5);
  box-shadow: 0 2px 15px 0 rgba(0, 0, 0, 0.08);
  border-radius: 2px;
  position: absolute;
  right: 10px;
  bottom: 10px;
`;

export const joinTypeWraper = css`
  display: flex;
  justify-content: space-between;
`;

export const joinType = css`
  width: 315px;
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(26, 34, 48, 0.15);
  opacity: 0.35;
  border-radius: 2px;
  &.active {
    border: 1px solid #4d5eff;
    opacity: 1;
  }
`;

export const rulesEditWraper = css`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
`;

export const directionWraper = css`
  display: flex;
  margin-top: 10px;
`;

export const directionItem = css`
  flex: 1;
  background: #f1f2f5;
  text-align: center;
  padding-top: 6px;
  padding-bottom: 6px;
`;

export const titleStyle = css`
  padding-left: 40px;
`;

export const hoverDeleteStyle = css`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  .delIcon {
    /* display: none; */
    font-size: 14px;
    color: rgb(229, 26, 23);
  }
  /* &:hover .delIcon {
    display: block;
  } */
`;

export const nodeItemWraper = css`
  width: 180px;
  height: 100%;
  display: flex;
  align-items: center;
  background: rgba(203, 203, 203, 0.25);
  color: #1a2230;
  font-size: 14px;
  padding: 2px 4px 4px 10px;
  position: relative;
  border: 1px solid transparent;
  border-radius: 2px;
  overflow: hidden;
  .nodeItemActBtns {
    display: none;
    cursor: pointer;
  }
  &:hover {
    border: 1px solid #4d5eff;
    background: #fff;
    .nodeItemActBtns {
      display: block;
    }
  }
`;
export const nodeItemStyle = css`
  position: relative;
  flex: 1;

  ${textOverFlow}
`;

const animationTime = 1;
const errorHolderColor = '#E34D59';

const bling = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.2,
  },
});

export const placeHolderBlingStyle = css`
  input::input-placeholder {
    color: ${errorHolderColor};
    animation: ${bling} ${animationTime}s ease infinite;
  }
  input::-webkit-input-placeholder {
    color: ${errorHolderColor};
    animation: ${bling} ${animationTime}s ease infinite;
  }
  input::-moz-placeholder {
    color: ${errorHolderColor};
    animation: ${bling} ${animationTime}s ease infinite;
  }
  input::-moz-placeholder {
    color: ${errorHolderColor};
    animation: ${bling} ${animationTime}s ease infinite;
  }
  input::-ms-input-placeholder {
    color: ${errorHolderColor};
    animation: ${bling} ${animationTime}s ease infinite;
  }

  .ant-select-selection-placeholder {
    color: ${errorHolderColor};
    animation: ${bling} ${animationTime}s ease infinite;
  }
`;
