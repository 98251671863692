import {css} from '@emotion/react';

export const sheetButton = css`
  margin-right: 16px;
`;

export const bottomLeft = css`
  position: absolute;
  left: 0;
  bottom: 15px;
`;
export const totalText = css``;

export const customTableStyle = css`
  .resizable-container .resizable-box .resizable-handler .resizable-line {
    width: 1px;
  }
  .resizable-container .resizable-box .resizable-handler:active .resizable-line,
  .resizable-container .resizable-box .resizable-handler:hover .resizable-line {
    /* background: #cccfd1; */
    background: transparent;
  }
  .resizable-handler {
    background: transparent;
  }
  .red td.ant-table-cell {
    color: red;
  }
  .ant-table-thead {
    height: 45px;
  }
  .ant-table-row {
    height: 45px;
  }
  th.ant-table-cell {
    padding: 0px 10px !important;
    /* vertical-align: bottom; */
    min-height: 45px;
  }
  td.ant-table-cell {
    padding: 0px 10px !important;
    line-height: 45px;
    font-size: 14px;
    max-height: 45px;
  }
  .resizable-title {
    line-height: 50px;
  }
  @keyframes pulse {
    from {
      box-shadow: 0 0 6px #ccc inset;
    }
    50% {
      box-shadow: 0 0 12px #91bd09 inset;
    }
    to {
      box-shadow: 0 0 6px #ccc inset;
    }
  }
  .bling {
    animation: pulse 2s infinite;
    /* .ant-table-cell {
      padding: 10px 8px !important;
    } */
  }
  .ant-table .ant-table-container:after,
  .ant-table .ant-table-container:before {
    box-shadow: none !important;
  }
`;

export const refreshButton = css`
  color: rgba(26, 34, 48, 0.7);
  border-color: rgba(26, 34, 48, 0.15);
`;
