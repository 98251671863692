import {css} from '@emotion/react';
import {Button, Input} from 'antd';
import React, {useEffect, useRef} from 'react';
import {AiFillEdit, AiOutlineCheck, AiOutlineClose} from 'react-icons/ai';
import {useClickAway} from 'react-use';

import {textOverFlow} from '../../global.css';
import useSafeState from '../../hooks/useSafeState';

const textStyle = css`
  position: relative;
  width: 100%;
  min-height: 20px;
  .editRemarkBtn {
    display: none;
    padding: 0 8px;
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    background: rgba(102, 161, 255, 0.4);
  }
  &:hover .editRemarkBtn {
    display: inline-block;
  }
`;

/**
 * @param {{
 *  handleCheck: Function;
 *  handleCancel: Function;
 * } & import('antd').InputProps } props
 */
function TableCellInput(props) {
  const inputRef = useRef();
  const cancelRef = useRef();

  const {onSave, onCancel, size = 'middle', ...rest} = props;

  const onSaveHandle = () => {
    onSave(inputRef.current.input.value);
  };

  useClickAway(cancelRef, () => {
    onCancel();
  });

  useEffect(() => {
    inputRef.current?.focus();
  }, []);

  return (
    <span ref={cancelRef}>
      <Input
        css={css`
          .ant-input-group-addon {
            padding: 0;
            border: 0;
          }
        `}
        ref={inputRef}
        size={size}
        onPressEnter={onSaveHandle}
        {...rest}
        addonAfter={
          <div style={{display: 'flex'}}>
            <Button
              icon={<AiOutlineCheck color="green" />}
              size={size}
              onClick={onSaveHandle}
            />
            <Button
              icon={<AiOutlineClose color="red" />}
              size={size}
              onClick={() => {
                onCancel();
              }}
            />
          </div>
        }
      />
    </span>
  );
}

/**
 * @description: 鼠标悬浮在文字上出现编辑按钮，点击开始编辑，输入输失焦时取消编辑，点确认按钮回调新值
 * @param {object} props
 * @param {string} props.defaultValue
 * @param {string} [props.size=]
 * @param {function} props.onSave
 * @param {function} props.onCancel
 * @return {React.ReactNode}
 */
const TextHoverToInput = (props) => {
  const {defaultValue, size = 'middle', onSave, onCancel} = props;

  const [editing, setEditing] = useSafeState(false);

  const onStartEditHandle = () => {
    setEditing(true);
  };

  const onSaveHandle = async (newVal) => {
    await onSave?.(newVal);
    setEditing(false);
  };

  const onCancelHandle = () => {
    onCancel?.();
    setEditing(false);
  };

  return editing ? (
    <TableCellInput
      defaultValue={defaultValue}
      size={size}
      onCancel={onCancelHandle}
      onSave={onSaveHandle}
    />
  ) : (
    <div
      css={css`
        ${textStyle}
        ${textOverFlow}
      `}
      title={defaultValue}
    >
      {defaultValue}
      <span className="editRemarkBtn" onClick={onStartEditHandle}>
        <AiFillEdit color="#1890ff" size={18} />
      </span>
    </div>
  );
};

export {TableCellInput, TextHoverToInput};
export default TextHoverToInput;
