/* eslint-disable no-empty-function */
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import {ConfigProvider} from 'antd';
// import 'antd/dist/antd.min.css';
import 'antd/dist/antd.variable.min.css';
import zhCN from 'antd/lib/locale/zh_CN';
import React from 'react';
import ReactDOM from 'react-dom';
import {unstable_HistoryRouter as HistoryRouter} from 'react-router-dom';
import {SWRConfig} from 'swr';

import App from './App';
import {FULLSCREEN_POINT, PUBLIC_PATH} from './constants/system';
import {routeHistory} from './helpers/history';
import {ignoreConsoleInDev} from './helpers/ignoreConsoleInDev';
import request from './helpers/request';
import {laggy} from './helpers/swrMiddlewares/laggy';
import reportWebVitals from './reportWebVitals';

import './helpers/antdConfig';
import './helpers/polyfill';
import './assets/icons';

ignoreConsoleInDev();
// disableReactDevTools();
// disableDevtool({ondevtoolopen: () => {}, disableMenu: false, clearLog: true});

if (process.env.NODE_ENV === 'development') {
  // const {worker} = require('./mocks/browser');
  // worker.start({
  //   serviceWorker: {
  //     url: `${PUBLIC_PATH}/mockServiceWorker.js`,
  //   },
  // });
}

if (location.pathname === '/' && process.env.NODE_ENV === 'development') {
  location.href = PUBLIC_PATH;
}

ReactDOM.render(
  <React.StrictMode>
    <SWRConfig
      value={{
        fetcher: request.get,
        revalidateOnFocus: false,
        errorRetryCount: 1,
        use: [laggy],
      }}
    >
      <HistoryRouter basename={PUBLIC_PATH} history={routeHistory}>
        <ConfigProvider
          form={{requiredMark: false}}
          getPopupContainer={(node) => {
            return document.getElementById(FULLSCREEN_POINT);
          }}
          locale={zhCN}
        >
          <App />
        </ConfigProvider>
      </HistoryRouter>
    </SWRConfig>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
