import {css} from '@emotion/react';
import {Input, Row, Select, Tabs} from 'antd';
import React, {useContext, useEffect, useMemo} from 'react';

import {EditContext} from '..';
import {overflowScrollBar} from '../../../global.css';
import useSafeState from '../../../hooks/useSafeState';
import IconFont from '../../IconFont';
import {ALL_OPTIONS, FORMULA_CONFIG} from '../constants';

const {Option} = Select;

const {TabPane} = Tabs;

export default function Setting(props) {
  const [fieldType, setFieldType] = useSafeState('');
  const [currentCfg, setCurrentCfg] = useSafeState({});

  const [keyWords, setKeyWords] = useSafeState('');

  const {settingCollapsed, setSettingCollapsed} = useContext(EditContext);

  const formulaOptions = useMemo(() => {
    let options =
      fieldType === ''
        ? FORMULA_CONFIG.map((item) => {
            return item.config;
          }).reduce((prev, cur) => {
            return prev.concat(cur);
          }, [])
        : FORMULA_CONFIG.find((item) => item.type === fieldType).config ?? [];
    if (keyWords) {
      options = options.filter((item) => {
        return item.label.includes(keyWords);
      });
    }
    return options;
  }, [fieldType, keyWords]);

  function onFieldTypeChange(value) {
    setFieldType(value);
    setKeyWords('');
  }

  function onSearch(e) {
    setKeyWords(e.target.value);
  }

  useEffect(() => {
    setCurrentCfg((prev) => ({
      formulaType: formulaOptions[0]?.value ?? '',
    }));
  }, [formulaOptions, setCurrentCfg]);

  return (
    <div
      css={css`
        position: relative;
        width: ${settingCollapsed ? 0 : '286px'};
        border: ${settingCollapsed
          ? 'none'
          : '1px solid rgba(26, 34, 48, 0.15)'};
        border-right: none;
        padding: ${settingCollapsed ? 0 : '10px'};
        float: left;
        height: 100%;
      `}
    >
      <div
        css={css`
          display: ${settingCollapsed ? 'none' : 'block'};
          height: 100%;
        `}
      >
        <Row
          css={css`
            margin-bottom: 10px;
          `}
        >
          <Select
            css={css`
              width: 102px;
              height: 32px;
              margin-right: 8px;
            `}
            value={fieldType}
            onChange={onFieldTypeChange}
          >
            <Option value="">全部</Option>
            {Object.values(ALL_OPTIONS).map((item) => {
              return (
                <Option key={item.value} value={item.value}>
                  {item.label}
                </Option>
              );
            })}
          </Select>
          <Input
            allowClear
            css={css`
              flex: 1;
              height: 32px;
            `}
            placeholder="请输入搜索文本"
            value={keyWords}
            onChange={onSearch}
          />
        </Row>
        <Tabs
          activeKey={currentCfg.formulaType}
          css={css`
            height: calc(100% - 42px);
            &.ant-tabs-left
              > .ant-tabs-content-holder
              > .ant-tabs-content
              > .ant-tabs-tabpane,
            &.ant-tabs-left
              > div
              > .ant-tabs-content-holder
              > .ant-tabs-content
              > .ant-tabs-tabpane {
              padding-left: 0;
            }
            &.ant-tabs-left > .ant-tabs-content-holder,
            &.ant-tabs-left > div > .ant-tabs-content-holder {
              border: none;
            }
            .ant-tabs-content-holder {
              overflow-y: auto;
              ${overflowScrollBar}
              background: rgba(203, 203, 203, .1);
            }
            .ant-tabs-tab {
              padding: 0 0 0 10px !important;
              height: 28px;
              line-height: 28px;
            }
            .ant-tabs-tab-active {
              background: rgba(77, 94, 255, 0.08);
              color: #4d5eff;
            }
          `}
          tabBarStyle={{
            width: '84px',
          }}
          tabPosition="left"
          onChange={(key) => {
            setCurrentCfg({
              formulaType: key,
            });
          }}
        >
          {formulaOptions.map((item) => {
            const Guidance = item.guidance;
            return (
              <TabPane key={item.value} tab={item.label}>
                <Guidance />
              </TabPane>
            );
          })}
        </Tabs>
      </div>
      <span
        css={css`
          position: absolute;
          right: -12px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
          width: 12px;
          height: 40px;
          border: 1px solid #d8d8d8;
          border-radius: 0 10px 10px 0;
        `}
        onClick={() => {
          setSettingCollapsed(!settingCollapsed);
        }}
      >
        <IconFont
          css={css`
            transform: ${settingCollapsed ? 'rotate(-90deg)' : 'rotate(90deg)'};
            transform-origin: center;
            position: absolute;
            right: -1px;
            top: 50%;
            margin-top: -6px;
            font-size: 12px;
          `}
          type="icon-below"
        />
      </span>
    </div>
  );
}
