import {css} from '@emotion/react';
import {Card} from 'antd';
import {useAtom} from 'jotai';
import React, {useState} from 'react';

import {
  PERSONAL_OPERATION_LOG,
  PERSONAL_OPERATION_LOG_EXPORT,
} from '../../../../api/system/user';
import {pagePermsAtom} from '../../../../atoms/pagePermsAtom';
import defColumns from '../../../../components/OperationLog/defColumns';
import {LogTable} from '../../../../components/OperationLog/LogTable';
import dayjs from '../../../../helpers/extendedDayjs';
import {ActionLogsTitle, cardBodyStyle, cardHeadStyle} from './CardTitles';

const ActionLogsTable = (props) => {
  const {userInfo} = props;
  const userData = userInfo?.data;

  const [queryTime, setQueryTime] = useState([
    dayjs().subtract(90, 'day'),
    dayjs(),
  ]);

  const [pagePerms] = useAtom(pagePermsAtom);

  function onExportClick() {
    return {
      url: PERSONAL_OPERATION_LOG_EXPORT,
      params: {
        startTime: queryTime[0].startOf('day').format('YYYY-MM-DD HH:mm:ss'),
        endTime: queryTime[1].endOf('day').format('YYYY-MM-DD HH:mm:ss'),
        userName: userData?.userName,
        realName: userData?.realName,
      },
      filename: `${userData?.realName}_${
        userData?.userName
      }_${queryTime[0].format('YYYY-MM-DD')}_${queryTime[1].format(
        'YYYY-MM-DD'
      )}.csv`,
    };
  }

  const columns = defColumns.map((item) => {
    if (['createTime', 'errorMsg'].includes(item.dataIndex)) {
      item.showSorter = false;
    }
    return item;
  });

  return (
    <Card
      css={css`
        ${cardHeadStyle}
        ${cardBodyStyle}
      `}
      title={<ActionLogsTitle />}
    >
      <LogTable
        columns={columns}
        getLogApi={userData ? PERSONAL_OPERATION_LOG : null}
        handleTimeChange={setQueryTime}
        queryParams={{
          realName: userData?.realName,
          menuCode: pagePerms.menuCode,
        }}
        refresh={false}
        scroll={{y: 'auto'}}
        searchParamsUsable={false}
        storeColumnWidthKey="actionLog"
        onExportClick={onExportClick}
      />
    </Card>
  );
};

export default ActionLogsTable;
