import {css} from '@emotion/react';
import {Form, Select} from 'antd';
import React from 'react';

export default function EmailFormItem({userMailList}) {
  return (
    <Form.Item
      required
      css={css`
        .ant-form-item-label {
          opacity: 0;
        }
      `}
      label=" "
      name="mails"
      rules={[
        {
          required: true,
          message: '请选择用户',
        },
      ]}
    >
      <Select
        showSearch
        filterOption={(input, option) => (option?.label ?? '').includes(input)}
        mode="multiple"
        options={userMailList.map(({realName, userId}) => ({
          label: realName,
          value: userId,
        }))}
      />
    </Form.Item>
  );
}
