import {Button, Form, Input, notification} from 'antd';
import React, {useEffect, useState} from 'react';
import {AiOutlineLock} from 'react-icons/ai';

import {uploadPassword} from '../../../api/system/user';
import FormErrMsg from '../../../components/FormErrMsg';
import GlobalModal from '../../../components/GlobalModal';
import {REG_PASSWORD} from '../../../constants/regExp';
import {encrypt} from '../../../helpers/jsencrypt';

export const SetNewPwdModal = ({visible, completeHandle}) => {
  const [passwordForm] = Form.useForm();
  const [isLoging, setIsLoging] = useState(false);

  const onFinishHandle = async () => {
    const formValues = await passwordForm.validateFields();
    const newPassword = encrypt(formValues.newPassword);

    setIsLoging(true);

    try {
      await uploadPassword({newPassword});
      notification.success({description: '密码修改成功'});
      completeHandle(true);
    } catch (err) {
      // completeHandle(false);
      FormErrMsg.disposeErr(passwordForm, err, '密码修改失败');
    } finally {
      setIsLoging(false);
    }
  };

  const equalToPassword = (rule, value) => {
    if (passwordForm.getFieldValue('newPassword') !== value) {
      return Promise.reject(new Error('密码不一致，请重新输入'));
    }
    return Promise.resolve();
  };

  const onValuesChangeHandle = (changedValue, allValues) => {
    FormErrMsg.clearFormErrMsg(passwordForm);

    if (
      changedValue.newPassword &&
      allValues.newPassword2 &&
      changedValue.newPassword !== allValues.newPassword2
    ) {
      passwordForm.setFields([
        {name: 'newPassword2', errors: ['密码不一致，请重新输入']},
      ]);
    }

    if (
      changedValue.newPassword2 &&
      allValues.newPassword &&
      changedValue.newPassword2 !== allValues.newPassword
    ) {
      passwordForm.setFields([
        {name: 'newPassword2', errors: ['密码不一致，请重新输入']},
      ]);
    }
  };

  useEffect(() => {
    if (visible) {
      FormErrMsg.clearFormErrMsg(passwordForm);
    }
  }, [visible, passwordForm]);

  return (
    <GlobalModal
      closable={false}
      footer={false}
      getContainer={document.querySelector('.loginPage')}
      keyboard={false}
      maskClosable={false}
      title="密码修改"
      visible={visible}
    >
      <Form
        form={passwordForm}
        onFinish={onFinishHandle}
        onValuesChange={onValuesChangeHandle}
      >
        <Form.Item>
          <Form.Item
            name="newPassword"
            rules={[
              {
                required: true,
                message: '请输入新的密码',
              },
              {
                pattern: REG_PASSWORD,
                message: '请按大小写英文字母+数字+字符格式重新输入密码',
              },
            ]}
            validateTrigger="onBlur"
          >
            <Input.Password
              autoComplete="off"
              placeholder="请输入新的密码(必须是包含大写英文、小写英文、数字、符号的组合)"
              prefix={<AiOutlineLock className="input-icon" />}
            />
          </Form.Item>
          <Form.Item
            name="newPassword2"
            rules={[
              {
                required: true,
                message: '请再次输入新的密码',
              },
              {
                pattern: REG_PASSWORD,
                message: '请按大小写英文字母+数字+字符格式重新输入密码',
              },
              {validator: equalToPassword},
            ]}
            validateTrigger="onBlur"
          >
            <Input.Password
              autoComplete="off"
              placeholder="请再次输入新的密码"
              prefix={<AiOutlineLock className="input-icon" />}
            />
          </Form.Item>

          <FormErrMsg />

          <Button
            block
            disabled={isLoging}
            htmlType="submit"
            loading={isLoging}
            size="middle"
            type="primary"
          >
            {isLoging ? '保 存 中...' : '保 存'}
          </Button>
        </Form.Item>
      </Form>
    </GlobalModal>
  );
};
