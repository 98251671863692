/* eslint-disable consistent-return */
/* eslint-disable no-use-before-define */
import {pinyin} from 'pinyin-pro';

import dayjs from './extendedDayjs';
import {routeHistory} from './history';
import request from './request';

/**
 * @description: underscore中的节流函数
 * @param {*} func - 是需要执行的函数
 * @param {*} [wait=50] - 时间间隔
 * @return {*}
 */
export function throttle(func, wait = 100, options = {}) {
  let timeout;
  let context;
  let args;
  let result;

  // 上一次执行回调的时间戳
  let previous = 0;

  function later() {
    // 当设置 { leading: false } 时
    // 每次触发回调函数后设置 previous 为 0
    // 不然为当前时间
    previous = options.leading === false ? 0 : +new Date();

    // 防止内存泄漏，置为 null 便于后面根据 !timeout 设置新的 timeout
    timeout = null;

    // 执行函数
    result = func.apply(context, args);
    // eslint-disable-next-line no-multi-assign
    if (!timeout) context = args = null;
  }

  // 每次触发事件回调都执行这个函数
  // 函数内判断是否执行 func
  // func 才是我们业务层代码想要执行的函数
  // eslint-disable-next-line func-style
  const throttled = function () {
    // 记录当前时间
    const now = +new Date();

    // 第一次执行时（此时 previous 为 0，之后为上一次时间戳）
    // 并且设置了 { leading: false }（表示第一次回调不执行）
    // 此时设置 previous 为当前值，表示刚执行过，本次就不执行了
    if (!previous && options.leading === false) previous = now;

    // 距离下次触发 func 还需要等待的时间
    const remaining = wait - (now - previous);
    // eslint-disable-next-line consistent-this
    context = this;

    // eslint-disable-next-line prefer-rest-params
    args = arguments;

    // 要么是到了间隔时间了，随即触发方法（remaining <= 0）
    // 要么是没有传入 {leading: false}，且第一次触发回调，即立即触发
    // 此时 previous 为 0，wait - (now - previous) 也满足 <= 0
    // 之后便会把 previous 值迅速置为 now
    if (remaining <= 0 || remaining > wait) {
      if (timeout) {
        clearTimeout(timeout);

        // clearTimeout(timeout) 并不会把 timeout 设为 null
        // 手动设置，便于后续判断
        timeout = null;
      }

      // 设置 previous 为当前时间
      previous = now;

      // 执行 func 函数
      result = func.apply(context, args);
      // eslint-disable-next-line no-multi-assign
      if (!timeout) context = args = null;
    } else if (!timeout && options.trailing !== false) {
      // 最后一次需要触发的情况
      // 如果已经存在一个定时器，则不会进入该 if 分支
      // 如果 {trailing: false}，即最后一次不需要触发了，也不会进入这个分支
      // 间隔 remaining milliseconds 后触发 later 方法
      timeout = setTimeout(later, remaining);
    }
    return result;
  };

  // 手动取消
  // eslint-disable-next-line func-names
  throttled.cancel = function () {
    clearTimeout(timeout);
    previous = 0;
    // eslint-disable-next-line no-multi-assign
    timeout = context = args = null;
  };

  // 执行 _.throttle 返回 throttled 函数
  return throttled;
}

/**
 * @description: 模拟lodash的 _.get 方法
 * @param {Object} object
 * @param {Array|String} path - 路径例：'a.b[0].c' 或者 ['a', 'b', '0', 'c']
 * @param {*} defaultValue
 * @return {*} - 返回路径对应的值
 */
export function deepGet(object = {}, path, defaultValue) {
  return (
    (Array.isArray(path)
      ? path
      : path.replace(/\[/g, '.').replace(/\]/g, '').split('.')
    ).reduce((o, k) => (o || {})[k], object) || defaultValue
  );
}

export const generalDictParams = (originDict = [], curParams = {}) => {
  const {formErrMsg, ...curValueObject} = curParams;
  const findDictCode = (dictData, dictLabel) => {
    return dictData.find((item) => item.dictLabel === dictLabel).dictCode;
  };

  const dictResult = Object.keys(curValueObject).reduce((pre, key) => {
    const curVal = curValueObject[key];
    const curCod = findDictCode(originDict, key);
    const curObj = {dictLabel: key, dictValue: curVal, dictCode: curCod};
    pre.push(curObj);
    return pre;
  }, []);

  return dictResult;
};

// 将字典类型转成对象
export const getObjectFromDictArray = (
  arr = [],
  keyField = 'dictLabel',
  valueField = 'dictValue'
) => {
  return arr.reduce((pre, cur) => {
    const key = cur[keyField];
    const val = cur[valueField];
    pre[key] = val;
    return pre;
  }, {});
};

/**
 * 根据数据库类型值匹配对应中文名
 * @param {string | number | null} value 数据库类型值
 */
export const getDictLabel = (value, originData = []) => {
  if (value === null) {
    return value;
  }
  const matched =
    originData.filter((item) => Number(item.dictValue) === Number(value))[0] ||
    null;
  const name = matched ? matched.dictLabel : null;

  return name;
};

// 路由跳转
export const jump = (path) => routeHistory.push(path);

/**
 * @description: 前端日志
 * @param {String} htmlType - 触发的HTML元素类型
 * @param {*} routePathAndParams - 触发时所在的页面路由和参数
 * @return {void}
 */
export const createFrontLog = (htmlType, routePathAndParams) => {
  // do something
};

/**
 * @description: 检测浏览器是关闭还是刷新
 * @param {Function} whenCloseCb
 * @param {Function} whenFreshCb
 * @return {void}
 */
export const browserCloseOrFreshAction = (whenCloseCb, whenFreshCb) => {
  let beginTime = 0; // 执行onbeforeunload的开始时间
  let differTime = 0; // 时间差
  window.onunload = () => {
    differTime = new Date().getTime() - beginTime;
    if (differTime <= 5) {
      // ('浏览器关闭');
      whenCloseCb();
    } else {
      // ('浏览器刷新');
      whenFreshCb();
    }
  };
  window.onbeforeunload = () => {
    beginTime = new Date().getTime();
  };
};

/**
 * @description: 转化对象数组中的某些字段
 * @param {Array} list 对象数组
 * @param {Array} mapKeys 字段映射对象
 * @return {Array}
 */
export const dataTransKeys = (
  list = [],
  mapKeys = {label: 'label', value: 'value'}
) => {
  const keys = Object.keys(mapKeys);
  return list.reduce((pre, item) => {
    let {children = [], ...rest} = item;
    keys.forEach((key) => {
      const dataKey = mapKeys[key];
      rest[key] = rest[dataKey];
    });
    if (children.length > 0) {
      children = dataTransKeys(children, mapKeys);
    }
    return [...pre, {...rest, children}];
  }, []);
};

/**
 * @description: 转化对象数组中的某些字段
 * @param {Array} list 对象数组
 * @param {Array} mapKeys 字段映射对象
 * @return {Array}
 */
export const treeDataAddAttr = (nodes, filterFn = (v) => v) => {
  const newChildren = [];
  const nodesCopy = deepClone(nodes);
  if (!(nodesCopy && nodesCopy.length)) {
    return [];
  }
  for (const node of nodesCopy) {
    const subs = treeDataAddAttr(node.children, filterFn);
    const newNode = filterFn(node);

    if (subs && subs.length) {
      newNode.children = subs;
      newNode._show = true;
      newChildren.push(newNode);
    } else if (newNode._show) {
      newChildren.push(newNode);
    }
  }
  return newChildren.length ? newChildren : [];
};

/**
 * @description: 树形数据根据条件过滤(父级满足条件才继续往下找,)
 * @param {Array} list
 * @param {Function} filterFn
 * @return {Array}
 */
export const recusiveTreeFilter = (list = [], filterFn = (v) => v) => {
  return list.reduce((pre, item) => {
    const {children = []} = item;
    const curItemOk = filterFn(item);
    if (curItemOk) {
      if (children.length > 0) {
        const _children = recusiveTreeFilter(children, filterFn);
        const curItem = {...item, children: _children};
        pre = [...pre, curItem];
      } else {
        pre = [...pre, item];
      }
    }

    return pre;
  }, []);
};

/**
 * @description: 树形数据根据条件过滤(父级不满足条件，只要有子级满足条件，父级就保留)
 * @param {Array} list
 * @param {Function} filterFn
 * @return {Array}
 */
export const filterTree = (nodes, filterFn = (v) => v) => {
  const newChildren = [];
  const nodesCopy = deepClone(nodes);
  if (!(nodesCopy && nodesCopy.length)) {
    return [];
  }
  for (const node of nodesCopy) {
    const subs = filterTree(node.children, filterFn);
    if (filterFn(node)) {
      newChildren.push(node);
    } else if (subs && subs.length) {
      node.children = subs;
      newChildren.push(node);
    }
  }
  return newChildren.length ? newChildren : [];
};

export const findCodeAndParentInTree = (
  list = [],
  value,
  fieldName = 'code'
) => {
  let parentItem = {};

  const find = (arr, v, parent = {}) => {
    for (let index = 0; index < arr.length; index++) {
      const item = arr[index];
      const {children = []} = item;
      const fieldVal = item[fieldName];

      if (fieldVal === v) {
        parentItem = parent;

        break;
      } else if (children.length > 0) {
        find(children, v, item);
      }
    }
  };

  if (list.length > 0 && value) {
    find(list, value);
  }

  return parentItem;
};

// 在树中寻找当前节点的前一个节点和父节点
export const findIndexNodeInTree = (list = [], value, fieldName = 'id') => {
  let preItem = {};
  let parentItem = {};

  const find = (arr, v, parent = {}) => {
    for (let index = 0; index < arr?.length; index++) {
      const item = arr[index];
      const children = item.children ?? [];
      const fieldVal = item[fieldName];

      if (fieldVal === v) {
        parentItem = parent;
        preItem = index === 0 ? {} : arr[index - 1];

        break;
      } else if (children?.length > 0) {
        find(children, v, item);
      }
    }
  };

  if (list.length > 0 && value) {
    find(list, value);
  }

  return {preItem, parentItem};
};

export const joinNameInTree = (list = [], value, separator = ' / ') => {
  const names = [];

  const find = (arr, val) => {
    const curItem = list.find((v) => v.code === val) ?? {};
    const {name, parentCode} = curItem;

    names.unshift(name);
    if (parentCode && parentCode !== '-1') {
      find(arr, parentCode);
    }
  };

  if (list.length > 0 && value) {
    find(list, value);
  }

  return names.join(separator);
};

export const isObj = (v) => typeof v === 'object' && v !== null;

export const isFun = (val) =>
  Object.prototype.toString.call(val) === '[object Function]';

export const isString = (v) =>
  Object.prototype.toString.call(v) === '[object String]';

export const trimAll = (str) => {
  if (isString(str)) {
    return str.replace(/\s*/g, '');
  }
  return str;
};

export const pinyinString = (value) => {
  return trimAll(
    pinyin(value, {
      pattern: 'first',
      type: 'string',
    })
  );
};

export const isJson = (str) => {
  try {
    JSON.parse(str);
    return true;
  } catch (e) {
    return false;
  }
};

export const setTreeLevel = (data = [], level = 0) => {
  return data.map((node, k) => {
    if (node?.children?.length) {
      return {
        ...node,
        level,
        children: setTreeLevel(node.children, level + 1),
      };
    }
    return {...node, level};
  });
};

/**
 * @description: 深度比较两个对象数组是否相等
 * @param {Array} arr1
 * @param {Array} arr2
 * @return {Boolean}
 */
export const equalArray = (arr1, arr2) => {
  let Equal = true;
  if (isObj(arr1) && isObj(arr2)) {
    const aProps = Object.getOwnPropertyNames(arr1);
    const bProps = Object.getOwnPropertyNames(arr2);
    if (aProps.length !== bProps.length) {
      Equal = false;
      return Equal;
    }
    for (let i = 0; i < aProps.length; i++) {
      const propName = aProps[i];
      const propA = arr1[propName];
      const propB = arr2[propName];
      if (isObj(propA) && isObj(propB)) {
        if (!equalArray(propA, propB)) {
          Equal = false;
          return Equal;
        }
      } else if (propA !== propB) {
        Equal = false;
        return Equal;
      }
    }
  } else if (arr1 !== arr2) {
    Equal = false;
    return Equal;
  }
  return Equal;
};

/**
 * @description: 判断两个对象是否相等，同属性同值
 * @param {object} a
 * @param {object} b
 * @return {boolean}
 */
export const isObjectValueEqual = (a, b) => {
  if (a === b) return true;
  const aProps = Object.getOwnPropertyNames(a);
  const bProps = Object.getOwnPropertyNames(b);

  if (aProps.length !== bProps.length) return false;

  if (isObj(a) && isObj(b)) {
    for (const prop in a) {
      if (Object.prototype.hasOwnProperty.call(b, prop)) {
        if (typeof a[prop] === 'object' && a[prop] !== null) {
          if (!isObjectValueEqual(a?.[prop], b?.[prop])) return false;
        } else if (a?.[prop] !== b?.[prop]) {
          return false;
        }
      } else {
        return false;
      }
    }
  }
  return true;
};

/**
 * @description: 深拷贝
 * @param {object | Array} obj
 * @return {object | Array}
 */
export const deepClone = (obj, wMap = new WeakMap()) => {
  if (isObj(obj)) {
    const target = Array.isArray(obj) ? [] : {};

    if (wMap.has(obj)) {
      return wMap.get(obj);
    }

    wMap.set(obj, target);

    Reflect.ownKeys(obj).forEach((item) => {
      target[item] = isObj(obj[item]) ? deepClone(obj[item], wMap) : obj[item];
    });

    return target;
  }
  return obj;
};

export function findElementByKey(dataSource = [], value = '', key = '') {
  return dataSource.find((item) => item[key] === value);
}

export function getStyle(node, ...attrs) {
  if (!document.querySelector(node)) return [];
  const style = getComputedStyle(document.querySelector(node), null);
  return attrs.map((attr) => {
    return style[attr];
  });
}

/**
 * @description: 从一维对象数组中过滤掉某些属性
 * @param {T} arr
 * @param {Array<string>} omitProps
 * @return {T}
 */
export const omitPropInObjArray = (arr = [], omitProps = []) => {
  return arr.map((item) => {
    const itemKeys = Object.keys(item);
    return itemKeys.reduce((pre, curKey) => {
      if (!omitProps.includes(curKey)) {
        pre[curKey] = item[curKey];
      }
      return pre;
    }, {});
  });
};

export function flattern(arr, key = 'children', includeChildren) {
  return arr.reduce((prev, {[key]: children, ...cur}) => {
    if (children?.length > 0) {
      return prev
        .concat(includeChildren ? {...cur, [key]: children} : cur)
        .concat(flattern(children, key));
    }
    return prev.concat(cur);
  }, []);
}

export function getAllChildList(tree, key) {
  const result = [];
  const deep = (data) => {
    for (let i = 0, len = data.length; i < len; i++) {
      const v = data[i];
      result.push(v.key);
      if (v.children && v.children.length) {
        deep(v.children);
      }
    }
  };
  const find = (data) => {
    for (let i = 0, len = data.length; i < len; i++) {
      const v = data[i];
      if (v.key === key) {
        deep(v.children || []);
        break;
      } else if (v.children && v.children.length) {
        find(v.children);
      }
    }
  };
  find(tree);
  return result;
}

export function findItemInTree(data, code) {
  function travese(_data) {
    let res;
    for (let i = 0; i < _data.length; i++) {
      const item = _data[i];
      if (item.code === code) {
        res = item;
      } else if (item.children?.length) {
        res = travese(item.children);
      }
      if (res) {
        return res;
      }
    }
    return null;
  }
  return travese(data);
}

/**
 * 获取指定元素的所有父级节点
 */
export function getAllParentList(data, key) {
  const result = [];
  const deep = (id, isRoot) => {
    for (let i = 0, len = data.length; i < len; i++) {
      const v = data[i];
      if (v.id === id) {
        if (!isRoot) {
          result.push(v.id);
        }
        if (v.parentId && v.parentId !== '0') {
          deep(v.parentId, false);
        }
        break;
      }
    }
  };
  deep(key, true);
  return result;
}

export const downloadFileByUrl = async (
  docFetchUrl,
  fileName = '帮助文档.chm'
) => {
  const res = await request.get(docFetchUrl);
  const {data: docUrl = ''} = res?.data ?? {};

  const downloadRequest = new window.XMLHttpRequest();

  downloadRequest.open('GET', docUrl, true);
  // downloadRequest.setRequestHeader('Content-Type', 'application/octet-stream');

  downloadRequest.responseType = 'blob';

  downloadRequest.onload = () => {
    const staticUrl = window.URL.createObjectURL(downloadRequest.response);
    const aElement = document.createElement('a');

    aElement.href = staticUrl;
    aElement.download = fileName;
    aElement.style.display = 'none';

    document.body.appendChild(aElement);
    aElement.click();
    window.URL.revokeObjectURL(staticUrl);
    document.body.removeChild(aElement);
  };

  downloadRequest.send();
};

/**
 *  It can be accessed using:
 *
 * ```js
 * getDateDuration('2018-12-29', '2019-01-20')
 * ```
 */
export function getDateDuration(start, end) {
  const diff = dayjs(end).diff(start, 'day');
  if (diff < 0) throw new RangeError('开始日期不能小于结束日期');

  const duration = [start];
  if (diff === 0) return duration;

  for (let i = 1; i < diff; i++) {
    duration.push(dayjs(start).add(i, 'day').format('YYYY-MM-DD'));
  }
  return duration.concat([end]);
}

export const findMax = (arr, key, count) => {
  const sortArr = deepClone(arr.filter((a) => !a.fixWidth)).sort(
    (a, b) => a[key] - b[key]
  );
  const sliceArr = sortArr.slice(-count);

  const counts = sliceArr.length - 1;
  const resultArr = [sliceArr[counts]];

  for (let index = counts - 1; index >= 1; index--) {
    const cur = sliceArr[index];
    const pre = sliceArr[index - 1];

    if (cur[key] / pre[key] > 2) {
      resultArr.push(cur);
    }
  }

  return resultArr;
};

export function flatDataToTree(baseData = [], parentKey, rootId = 0) {
  try {
    const result = [];

    const dictionary = baseData.reduce((pre, cur) => {
      pre[cur.tableId] = {...cur, children: []};
      return pre;
    }, {});

    baseData.forEach((item) => {
      const parent = dictionary[item[parentKey]];
      if (parent) parent.children.push(dictionary[item.tableId]);
      else result.push(dictionary[rootId || item.tableId]);
    });

    return result;
  } catch (e) {
    console.error(e);
    return [];
  }
}

export function debounce(fn, wait = 50) {
  // 通过闭包缓存一个定时器 id
  let timer = null;
  // 将 debounce 处理结果当作函数返回
  // 触发事件回调时执行这个返回函数
  // eslint-disable-next-line func-names
  return function (...args) {
    // this保存给context
    // eslint-disable-next-line consistent-this
    const context = this;
    // 如果已经设定过定时器就清空上一次的定时器
    if (timer) clearTimeout(timer);

    // 开始设定一个新的定时器，定时器结束后执行传入的函数 fn
    timer = setTimeout(() => {
      fn.apply(context, args);
    }, wait);
  };
}

export function bigNumberTransform(value) {
  const isNegative = value.toString().includes('-');
  value = value.toString().split('-').join('');
  const newValue = ['', '', ''];
  let fr = 1000;
  let num = 3;
  let text1 = '';
  let fm = 1;
  while (value / fr >= 1) {
    fr *= 10;
    num += 1;
    // console.log('数字', value / fr, 'num:', num)
  }
  if (num <= 4) {
    return isNegative ? `-${value}` : value;
  } else if (num <= 8) {
    // 万
    text1 = parseInt(num - 4, 10) / 2 > 1 ? '百万' : '万';
    text1 = parseInt(num - 4, 10) / 3 > 1 ? '千万' : text1;
    if (text1 === '万') {
      fm = 10000;
    } else if (text1 === '百万') {
      fm = 1000000;
    } else if (text1 === '千万') {
      fm = 10000000;
    }
    if (value % fm === 0) {
      newValue[0] = `${parseInt(value / fm, 10)}`;
    } else {
      newValue[0] = `${parseFloat(value / fm).toFixed(2)}`;
    }
    newValue[1] = text1;
  } else {
    // 亿
    text1 = '亿';
    fm = 1;
    if (text1 === '亿') {
      fm = 100000000;
    }
    if (value % fm === 0) {
      newValue[0] = `${parseInt(value / fm, 10)}`;
    } else {
      newValue[0] = `${parseFloat(value / fm).toFixed(2)}`;
    }
    newValue[1] = text1;
  }
  if (value < 1000) {
    newValue[0] = `${value}`;
    newValue[1] = '';
  }
  return isNegative ? `-${newValue.join('')}` : newValue.join('');
}
/**
 * @description: 使用setTimeout实现异步sleep函数
 * @param {number} timeout 毫秒
 * @return {Promise}
 */
export const sleep = (timeout) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      resolve();
    }, timeout);
  });
};

export function formatDuring(millisecond) {
  const days =
    millisecond > 100 ? parseInt(millisecond / (1000 * 60 * 60 * 24), 10) : 0;
  const hours = parseInt(
    (millisecond % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60),
    10
  );
  const minutes = parseInt((millisecond % (1000 * 60 * 60)) / (1000 * 60), 10);
  const seconds = (millisecond % (1000 * 60)) / 1000;
  return `${days ? `${days}天` : ''}${hours ? `${hours}小时` : ''}${
    minutes ? `${minutes}分钟` : ''
  }  ${seconds} 秒 `;
}

export function toLowerCase(str) {
  const target = typeof str === 'string' ? str : '';
  return target.toLowerCase();
}

export const getClientHeight = (extraHeight = 0) => {
  const headerHeight = 50;
  const topBottomPadding = 14 * 2 + 16 * 2 + 20 * 2;
  const docClientHeight = document.body.clientHeight;

  // eslint-disable-next-line prettier/prettier
  const result = docClientHeight - headerHeight - topBottomPadding - extraHeight;

  return result;
};

export const dfsTransFn = (tree, func) => {
  tree.forEach((node, index, array) => {
    func(node, index, array);
    // 如果子树存在，递归调用
    if (node?.children?.length > 0) {
      dfsTransFn(node.children, func);
    }
  });
};

export const loop = (data, key, callback) => {
  for (let i = 0; i < data.length; i++) {
    if (String(data[i].id) === String(key)) {
      return callback(data[i], i, data);
    }
    if (data[i].children) {
      loop(data[i].children, key, callback);
    }
  }
};
