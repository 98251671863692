/* eslint-disable react-hooks/exhaustive-deps */
import {useCallback, useEffect, useRef} from 'react';

import {columnMinWidth} from '..';
import {FULLSCREEN_POINT} from '../../../constants/system';
import useSafeState from '../../../hooks/useSafeState';

// '宽度： xxx像素'字数之和的宽度
const showSizeBoxWidth = 120;

export default function useResizeLineStyle(tableRefEl, isValidating) {
  const resizeLineRef = useRef();
  const resizeLinePar = useRef();
  const showSizeBoxRef = useRef();

  const [tableContainerHeight, settableContainerHeight] = useSafeState(0);

  const mountingPoint = document.getElementById(FULLSCREEN_POINT);

  const freshTablCotainer = () => {
    setTimeout(() => {
      const tableContainer = tableRefEl?.getElementsByClassName(
        'ant-table-container'
      )[0];
      const tableBodyContainer =
        tableRefEl?.getElementsByClassName('ant-table-body')[0];
      if (tableBodyContainer) {
        tableBodyContainer.scrollLeft += 1;
      }

      const lineHeight = tableContainer?.getBoundingClientRect()?.height ?? 0;

      settableContainerHeight(lineHeight);
    }, 10);
  };

  const freshTablCotainerToLast = () => {
    setTimeout(() => {
      const tableContainer = tableRefEl?.getElementsByClassName(
        'ant-table-container'
      )[0];
      const tableBodyContainer =
        tableRefEl?.getElementsByClassName('ant-table-body')[0];
      if (tableBodyContainer) {
        tableBodyContainer.scrollLeft += 9999;
      }

      const lineHeight = tableContainer?.getBoundingClientRect()?.height ?? 0;

      settableContainerHeight(lineHeight);
    }, 10);
  };

  useEffect(() => {
    freshTablCotainer();
  }, [tableRefEl, isValidating, document?.fullscreenElement]);

  const tableHeaderMouseUp = () => {
    const showSizeBoxEl = document.getElementById('sizebox');
    const resizeLineEl = document.getElementById('resizeColumnLine');

    if (resizeLineRef.current) {
      resizeLineRef.current = null;
      resizeLinePar.current = null;
      showSizeBoxRef.current = null;
    }

    if (showSizeBoxEl) {
      mountingPoint?.removeChild(showSizeBoxEl);
    }
    if (resizeLineEl) {
      mountingPoint?.removeChild(resizeLineEl);
    }
  };

  const tableHeaderMouseDown = useCallback(
    (event) => {
      const target = event?.target;
      const docWidth = document.documentElement.clientWidth;

      if (target?.className === 'resizable-handler') {
        tableHeaderMouseUp();
        const lineParent = target.parentNode;

        // 辅助线
        const resizeLineEl = document.createElement('div');
        resizeLineEl.id = 'resizeColumnLine';
        resizeLineEl.style.cssText = `
          width: 1px;
          height: ${tableContainerHeight}px;
          background: #C6CFFF;
          position: fixed;
          top: ${event.clientY - event.offsetY}px;
          left: ${event.clientX - event.offsetX + 5}px;
          z-index: 3998;
        `;
        resizeLinePar.current = lineParent;
        resizeLineRef.current = resizeLineEl;

        // 宽度实时展示
        const resizeLineParWidth =
          resizeLinePar.current?.getBoundingClientRect()?.width;
        const showSizeBoxEl = document.createElement('div');
        const isOverScreen =
          event.clientX - event.offsetX + showSizeBoxWidth > docWidth;
        const posLeft = isOverScreen
          ? `${event.clientX - event.offsetX - showSizeBoxWidth}px`
          : `${event.clientX - event.offsetX}px`;
        showSizeBoxEl.id = 'sizebox';
        showSizeBoxEl.style.cssText = `
          width: auto;
          height: 25px;
          padding: 2px 10px;
          background: #FFFFFF;
          box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.1);
          border-radius: 2px;
          border: 1px solid #EEEEEE;
          position: fixed;
          top: ${event.clientY - event.offsetY + 50}px;
          left: ${posLeft};
          z-index: 3999;
        `;
        showSizeBoxRef.current = showSizeBoxEl;
        showSizeBoxRef.current.innerHTML = `宽度： ${resizeLineParWidth}像素`;

        mountingPoint?.appendChild(resizeLineEl);
        mountingPoint?.appendChild(showSizeBoxEl);
      }
    },
    [
      tableRefEl,
      tableContainerHeight,
      isValidating,
      document?.fullscreenElement,
    ]
  );

  const tableHeaderMouseMove = (event) => {
    const docWidth = document.documentElement.clientWidth;
    if (resizeLineRef.current) {
      const resizeLineParWidth =
        resizeLinePar.current?.getBoundingClientRect()?.width;

      const leftOffset =
        resizeLineParWidth <= columnMinWidth
          ? parseInt(resizeLineRef.current.style.left, 10)
          : event.clientX + 5;

      // const leftOffset = event.clientX + 5;

      const isOverScreen = leftOffset + showSizeBoxWidth > docWidth;

      resizeLineRef.current.style.left = `${leftOffset}px`;

      showSizeBoxRef.current.style.left = isOverScreen
        ? `${leftOffset - showSizeBoxWidth}px`
        : `${leftOffset}px`;

      showSizeBoxRef.current.innerHTML = `宽度： ${resizeLineParWidth}像素`;
    }
  };

  useEffect(() => {
    const tableContainer = tableRefEl?.getElementsByClassName(
      'ant-table-container'
    )[0];
    if (tableContainer) {
      tableContainer.addEventListener('mousedown', tableHeaderMouseDown);
      document.addEventListener('mousemove', tableHeaderMouseMove);
      document.addEventListener('mouseup', tableHeaderMouseUp);
    }

    return () => {
      tableContainer?.removeEventListener?.('mousedown', tableHeaderMouseDown);
      document.removeEventListener('mousemove', tableHeaderMouseMove);
      document.removeEventListener('mouseup', tableHeaderMouseUp);
    };
  }, [
    isValidating,
    tableHeaderMouseDown,
    tableRefEl,
    document?.fullscreenElement,
  ]);

  return {freshTablCotainer, freshTablCotainerToLast};
}
