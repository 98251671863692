/* eslint-disable react-hooks/exhaustive-deps */
import {css} from '@emotion/react';
import {Form, notification} from 'antd';
import React, {useEffect} from 'react';

import {ERROR_MESSAGE} from '../../helpers/request';

/**
 * @description: 排队某些字段再检查form错误
 * @param {import('antd/lib/form').FormInstance} form
 * @param { string[]} [excludesFields]
 * @return {Boolean}
 */
export const checkFormHasError = (form, excludesFields = ['formErrMsg']) => {
  return form
    .getFieldsError()
    .filter(({name}) => !excludesFields.includes(name.join('.')))
    .some((v) => v.errors.length > 0);
};

// 主动派发onChange事件，以使isFieldsTouched 返回正确值
const EmptyEl = ({onChange}) => {
  useEffect(() => {
    onChange('');
  }, []);
  return <span />;
};

const FormErrMsg = (props) => {
  const errFormStyle = css`
    margin: 0;
    .ant-form-item-no-colon {
      display: none;
    }
    .ant-form-item-control-input {
      display: none;
    }
  `;

  return (
    <Form.Item colon={false} css={errFormStyle} label=" " name="formErrMsg">
      <EmptyEl />
    </Form.Item>
  );
};

/**
 * @description: 重置报错
 * @param {import('antd/lib/form').FormInstance} form
 * @param {String} name
 */
export const resetFormErrMsg = (form, name = 'formErrMsg') => {
  form.setFields([{name, errors: null}]);
};

/**
 * @description: 清除所有报错
 * @param {import('antd/lib/form').FormInstance} form
 */
export const clearFormErrMsg = (form) => {
  const formItems = form.getFieldsValue();
  Object.keys(formItems).forEach((name) =>
    form.setFields([{name, errors: null}])
  );
};

/**
 * @description: 设置报错信息
 * @param {import('antd/lib/form').FormInstance} form
 * @param {String} name
 * @param {String} msg
 */
export const setFormErrMsg = (form, name = 'formErrMsg', msg = '') => {
  form.setFields([{name, errors: [msg]}]);
};

/**
 * @description: 处理512报错信息
 * @param {import('antd/lib/form').FormInstance} form
 * @param {Error} err
 * @param {String} [designStr='']
 */
// eslint-disable-next-line consistent-return
export const disposeErr = (form, err, designStr) => {
  const {code, msg, errorInputs = []} = err?.data || {};

  if (code === 100) {
    notification.error({description: msg});
  } else if (code === 512) {
    if (errorInputs.length > 0) {
      errorInputs.forEach(({key, message}) => {
        setFormErrMsg(form, key, message);
      });
    } else {
      notification.error({description: msg});
    }
  } else if (code === 500) {
    return false;
  } else {
    notification.error({description: designStr || msg || ERROR_MESSAGE});
  }
};

FormErrMsg.disposeErr = disposeErr;
FormErrMsg.setFormErrMsg = setFormErrMsg;
FormErrMsg.resetFormErrMsg = resetFormErrMsg;
FormErrMsg.clearFormErrMsg = clearFormErrMsg;

export default FormErrMsg;
