import {css} from '@emotion/react';
import {Tag} from 'antd';
import React from 'react';

import {STATUS_MAP} from '.';

/**
 * @param {object} props
 * @param {string} props.label
 * @param {import('.').Status} props.status
 * @param {{[key: string]: any;}=} props.data
 * @param {function=} props.onClick
 * @param {import('@emotion/react').SerializedStyles=} props.css
 */
const StatusTag = ({
  children,
  label,
  status,
  data,
  onClick,
  css: Styles,
  ...rest
}) => {
  const mappedStatus = STATUS_MAP[status];

  function handleClick() {
    if (onClick) onClick(data);
  }

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
    >
      <div
        css={css`
          background-color: ${mappedStatus?.bgColor};
          width: 8px;
          height: 8px;
          border-radius: 50%;
        `}
      />
      <Tag
        /* color={mappedStatus?.bgColor} */
        css={css`
          cursor: ${onClick ? 'pointer' : 'default'};
          color: ${mappedStatus?.fontColor};
          ${Styles}
          border: none;
          background: none;
          font-size: 14px;
        `}
        onClick={handleClick}
        {...rest}
      >
        {children ? children : label}
      </Tag>
    </div>
  );
};

export default StatusTag;
