import {keyframes} from '@emotion/css';
import {css} from '@emotion/react';

const fillColor = '#4D5EFF';

// 圆公共样式
const OStyle = css`
  width: 16px;
  height: 16px;
  border-radius: 50%;
  overflow: hidden;
  position: absolute;
  border: 0.8px solid rgba(26, 34, 48, 0.4);
  &::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    border-radius: 50%;
  }
`;

// 左关联圆样式
const leftCheckCircle1 = css`
  background: ${fillColor};
`;
const leftCheckCircle2 = css`
  left: 8px;
`;

// 内关联圆样式
const middleCheckCircle1 = css`
  &::before {
    left: 8px;
    background: ${fillColor};
  }
`;
const middleCheckCircle2 = css`
  left: 8px;
`;

// 无关联圆样式
const noCheckCircle1 = css`
  border: 0.8px dashed rgba(26, 34, 48, 0.4);
`;
const noCheckCircle2 = css`
  left: 8px;
  border: 0.8px dashed rgba(26, 34, 48, 0.4);
`;

// 容器样式
const wraperStyle = css`
  display: flex;
  position: relative;
  width: 24px;
  height: 32px;
  display: flex;
  align-items: center;
  cursor: pointer;
`;

// 左关联组合样式
export const leftCheckStyle = css`
  ${wraperStyle}
  .circle {
    ${OStyle}
  }
  .circle1 {
    ${leftCheckCircle1}
  }
  .circle2 {
    ${leftCheckCircle2}
  }
`;

// 内关联组合样式
export const middleCheckStyle = css`
  ${wraperStyle}
  .circle {
    ${OStyle}
  }
  .circle1 {
    ${middleCheckCircle1}
  }
  .circle2 {
    ${middleCheckCircle2}
  }
`;

// 无关联组合样式
export const noCheckStyle = css`
  ${wraperStyle}
  .circle {
    position: relative;
    ${OStyle}
    .suprise {
      color: #e34d59;
      position: absolute;
      top: -2.5px;
      left: 9.5px;
      font-size: 12px;
      font-weight: 600;
    }
  }
  .circle1 {
    ${noCheckCircle1}
  }
  .circle2 {
    ${noCheckCircle2}
  }
`;

// 无关联组合闪动样式
const bling = keyframes({
  '0%': {
    opacity: 0.2,
  },
  '50%': {
    opacity: 1,
  },
  '100%': {
    opacity: 0.2,
  },
});

export const noCheckBlingStyle = css`
  ${wraperStyle}
  .circle {
    position: relative;
    ${OStyle}
    .suprise {
      color: #e34d59;
      position: absolute;
      top: -2.5px;
      left: 9.5px;
      font-size: 12px;
      font-weight: 600;
      animation: ${bling} 1s ease infinite;
    }
  }
  .circle1 {
    ${noCheckCircle1}
    animation: ${bling} 1s ease infinite;
  }
  .circle2 {
    ${noCheckCircle2}
    animation: ${bling} 1s ease infinite;
  }
`;
