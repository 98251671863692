import request from '../../helpers/request';
/* ----------------------------我的收藏start---------------------------- */
// 我的收藏-新建文件夹
export function scAddFolder(data) {
  return request({
    url: '/wide/widecollect/addFolderPath',
    method: 'post',
    data,
  });
}

// 我的收藏-取消收藏
export function scCancelCollect(data) {
  return request({
    url: '/wide/widecollect/cancelCollect',
    method: 'post',
    data,
  });
}
// 我的收藏-收藏数据表
export function scCollectDo(data) {
  return request({
    url: '/wide/widecollect/collect',
    method: 'post',
    data,
  });
}

// 我的收藏-收藏列表
// isWideList 是否宽表列表,默认false
export function scCollectList(params) {
  return request({
    url: '/wide/widecollect/collectList',
    method: 'get',
    params,
  });
}
// 我的收藏-获取文件夹树列表
export function scFolderList(params) {
  return request({
    url: '/wide/widecollect/folderList',
    method: 'get',
    params,
  });
}

// 我的收藏-移动文件夹
export function scMoveTo(data) {
  return request({
    url: '/wide/widecollect/moveTo',
    method: 'post',
    data,
  });
}
// 我的收藏-删除文件夹
export function scRemoveFolder(data) {
  return request({
    url: '/wide/widecollect/removeFolder',
    method: 'post',
    data,
  });
}

// 我的收藏-重命名文件夹
export function scRenameFolder(data) {
  return request({
    url: '/wide/widecollect/rename',
    method: 'post',
    data,
  });
}

/* ----------------------------我的收藏end---------------------------- */

/* ----------------------------数据库start---------------------------- */
// 数据库-新建文件夹
export function dbAddFolder(data) {
  return request({
    url: '/wide/widepath/addFolderPath',
    method: 'post',
    data,
  });
}

// 数据库-批量移动
export function dbBatchMoveTo(data) {
  return request({
    url: '/wide/widepath/batchMoveTo',
    method: 'post',
    data,
  });
}

// 数据库-数据库列表
export function dbPathList(params) {
  return request({
    url: '/wide/widepath/dbPathList',
    method: 'get',
    params,
  });
}
// 数据库-获取某表所在数据库文件夹(不含表)列表
export function dbFolderList(params) {
  return request({
    url: '/wide/widepath/folderList',
    method: 'get',
    params,
  });
}

// 数据库-获取某表所在数据库(含表)树列表
export function dbFolderTableList(params) {
  return request({
    url: '/wide/widepath/dbPathListCurrent',
    method: 'get',
    params,
  });
}

// 数据库-移动文件夹
export function dbMoveTo(data) {
  return request({
    url: '/wide/widepath/moveTo',
    method: 'post',
    data,
  });
}
// 数据库-删除文件夹
export function dbRemoveFolder(data) {
  return request({
    url: '/wide/widepath/removeFolder',
    method: 'post',
    data,
  });
}

// 数据库-移除数据表
export function dbRemoveTable(data) {
  return request({
    url: '/wide/widepath/removeTable',
    method: 'post',
    data,
  });
}

// 移动文件夹/数据表排序
export function moveTableOrFolder(data) {
  return request({
    url: `/wide/widepath/adjustPathRank`,
    method: 'post',
    data,
  });
}

// 数据库-移除数据表查询有没有引用
export function getWideQuoteList(tablePathId) {
  return request({
    url: `/wide/widepath/getQuoteList?tablePathId=${tablePathId}`,
    method: 'get',
  });
}

// 数据库-重命名数据库
export function dbRename(data) {
  return request({
    url: '/wide/widepath/rename',
    method: 'post',
    data,
  });
}

/* ----------------------------数据库end---------------------------- */

// 宽表-宽表列表表头字段查询
// Integer ridFilterFlag；
// 1:不返回rid字段 0：返回rid字段
export const Wide_Table_Fields = '/wide/query/wide/getIndexSchema';
export function wideTableFields(wideId, ridFilterFlag) {
  // [V2.2.2]
  return request({
    url: `/wide/query/wide/getIndexSchema/${wideId}/${ridFilterFlag}`,
    method: 'get',
  });
}

// 宽表-查询宽表详情
export function wideTableDetail(wideId) {
  return request({
    url: `/wide/query/wide/getWideInfo/${wideId}`,
    method: 'get',
  });
}

// 宽表-宽表数据列表查询
export const WIDE_DATA_LIST = '/wide/query/wide/list';
export function wideDataList(data) {
  return request({
    url: '/wide/query/wide/list',
    method: 'post',
    ...data,
  });
}

// 宽表-启动更新宽表
export function startUpdateWide(id) {
  return request({
    url: `/wide/indexPath/startUpdate/${id}`,
    method: 'post',
  });
}

// 宽表-暂停更新宽表
export function stopUpdateWide(id) {
  return request({
    url: `/wide/indexPath/stopUpdate/${id}`,
    method: 'post',
  });
}

// 宽表-用户邮件列表
export function getUserMailList(params) {
  return request({
    url: `/wide/indexMaking/userMailList`,
    method: 'GET',
    params,
  });
}

// 宽表-查询数据源字段列表
export function getColumnSchemeList(params) {
  return request({
    url: '/wide/sourcecolumn/getColumnSchemeList',
    method: 'GET',
    params,
  });
}

// 宽表-查询数据血缘上游
export const QUERY_UP_BLOOD_STREAM = '/wide/consanguinity/queryUpstream';
export function queryUpBloodStream(params) {
  return request({
    url: '/wide/consanguinity/queryUpstream',
    method: 'GET',
    params,
  });
}

// 宽表-查询数据血缘下游
export const QUERY_DOWN_BLOOD_STREAM = '/wide/consanguinity/queryDownstream';
export function queryDownBloodStream(params) {
  return request({
    url: '/wide/consanguinity/queryDownstream',
    method: 'GET',
    params,
  });
}

// 缓存删除符合条件数据和删除选中数据
export function deleteDataCondition(data) {
  return request({
    url: `/wide/querycondition/add`,
    method: 'post',
    data,
  });
}

// 执行删除符合条件数据和删除选中数据
export function excuteDeleteDataCondition(data) {
  return request({
    url: `/wide/sourcetable/modifyExcelTable`,
    method: 'post',
    data,
  });
}

// 清除删除符合条件数据和删除选中数据缓存
export function clearWillDeleteDataCondition(data) {
  return request({
    url: `/wide/querycondition/clearCondition`,
    method: 'post',
    data,
  });
}
