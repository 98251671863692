import {css} from '@emotion/react';
import {Dropdown, Menu} from 'antd';
import React, {useContext, useMemo, useState} from 'react';
import {AiOutlinePlusSquare} from 'react-icons/ai';

import {MicroDataQueryContext} from '../..';
// import {ReactComponent as Newlybuild} from '../../../../../assets/icons/svg/newlybuild.svg';
import IconFont from '../../../../../components/IconFont';
import SvgIcon from '../../../../../components/SvgIcon';
import {triggerType} from '../../../../../constants/layout';

const disabledStyle = css`
  div,
  span {
    color: #00000040;
    cursor: not-allowed;
  }
`;

export default function MenuTreeNode({
  info,
  handleRename,
  extraActions,
  onlyView = false,
  cancelAttentionHandle,
  moveFolderHandle,
  batchMoveTableHandle,
  removeFolderHandle,
  attentionWideTableHandle,
  createSyncTask,
  disabled = false,
}) {
  const [dropDownVisible, setDropDownVisible] = useState(false);

  const {activeKey} = useContext(MicroDataQueryContext);

  // const hasBtnAuth = useHasBtnPerm(
  //   BUTTON_PERMS_MAP.get('微观指标表管理-微观数据表编辑')
  // );

  // dbtype: 1:mysql 2: 达梦  3: 金仓
  // 数据库 pathType:1:文件夹2：数据库3：数据表
  const isDB = useMemo(() => info?.pathType === 2, [info]);

  // 底层表被删除了的表
  const isDeleted = useMemo(() => info?.delFlag === 1, [info]);

  // 普通文件夹
  const isFolder = useMemo(
    () =>
      (info?.pathId === null || info?.pathType === 1) && info.draftFlag !== 0,
    [info]
  );

  // 草稿文件夹 draftFlag = 0 and pathId = null && parentId = -1
  const isDraftFolder = useMemo(() => {
    return info.draftFlag === 0 && info.pathId === null && info.parentId === -1;
  }, [info.draftFlag, info.parentId, info.pathId]);

  // 草稿文件 draftFlag = 0 and pathId = null && parentId != -1
  const isDraftFile = useMemo(() => {
    return info.draftFlag === 0 && info.parentId !== -1;
  }, [info.draftFlag, info.parentId]);

  const menu = useMemo(() => {
    let opts = [];
    // 0 无管理权限, 1 有管理权限
    const {managePerm, collectPathId} = info;

    if (activeKey === '1') {
      opts = isDraftFile
        ? [
            {
              name: '删除',
              key: '3',
              calback: (p) => removeFolderHandle(p, '草稿'),
            },
          ]
        : [
            {
              name: '移动到',
              key: '1',
              disabled: isDeleted,
              calback: (p) => moveFolderHandle(p),
            },
            ...(isFolder || managePerm
              ? [
                  {
                    name: '重命名',
                    key: '2',
                    disabled: isDeleted,
                    calback: (p) => handleRename(p),
                  },
                ]
              : []),
            {
              name: isFolder ? '删除' : '取消收藏',
              key: '3',
              calback: (p) =>
                isFolder
                  ? removeFolderHandle(p, '文件夹')
                  : cancelAttentionHandle(p),
            },
          ];
    }

    if (activeKey === '2') {
      opts = isFolder
        ? [
            {
              name: '移动到',
              key: '1',
              calback: (p) => moveFolderHandle(p),
            },
            {
              name: '重命名',
              key: '3',
              calback: (p) => handleRename(p),
            },
            {
              name: '删除',
              key: '4',
              calback: (p) => removeFolderHandle(p, '文件夹'),
            },
          ]
        : [
            {
              name: collectPathId ? '取消收藏' : '收藏',
              disabled: isDeleted,
              key: '1',
              calback: (p) =>
                collectPathId
                  ? cancelAttentionHandle(p)
                  : attentionWideTableHandle(p),
            },
            ...(managePerm
              ? [
                  {
                    name: '移动到',
                    key: '2',
                    disabled: isDeleted,
                    calback: (p) => moveFolderHandle(p),
                  },
                  {
                    name: '批量操作',
                    key: '3',
                    disabled: isDeleted,
                    calback: (p) => batchMoveTableHandle(p),
                  },
                ]
              : []),

            {
              name: '同步到其它库',
              key: '4',
              disabled: isDeleted,
              calback: (p) => createSyncTask(p),
            },
            ...(managePerm
              ? [
                  {
                    name: '重命名',
                    key: '5',
                    disabled: isDeleted,
                    calback: (p) => handleRename(p),
                  },
                  {
                    name: '移除',
                    key: '6',
                    calback: (p) => removeFolderHandle(p, '宽表'),
                  },
                ]
              : []),
          ];
    }

    // 其它自定义dropdown操作
    if (extraActions && Array.isArray(extraActions)) {
      opts = extraActions;
    }

    return (
      <Menu>
        {opts.map((opt) => {
          return (
            <Menu.Item
              disabled={opt.disabled}
              key={opt.key}
              onClick={({domEvent}) => {
                domEvent.preventDefault();
                domEvent.stopPropagation();

                opt.calback(info);
                setDropDownVisible(false);
                return false;
              }}
            >
              {opt.name}
            </Menu.Item>
          );
        })}
      </Menu>
    );
  }, [
    info,
    activeKey,
    extraActions,
    isDraftFile,
    isFolder,
    removeFolderHandle,
    moveFolderHandle,
    handleRename,
    cancelAttentionHandle,
    isDeleted,
    attentionWideTableHandle,
    batchMoveTableHandle,
    createSyncTask,
  ]);

  return (
    <div
      className={`menu-tree-node ${disabled && 'menu-tree-node-disabled'}`}
      css={css`
        .ant-tooltip {
          margin-left: 3px;
        }
        .ant-tooltip-inner {
          background: rgb(22, 35, 78, 0.8);
          box-shadow: 0 2px 4px 0 rgba(38, 45, 115, 0.1);
        }
        .ant-tooltip-arrow {
          display: none;
        }
        position: relative;
        background: ${isDeleted ? 'rgba(255, 0, 0, 0.3)' : ''};
        ${disabled && disabledStyle}
      `}
      id={`treeNode${info.id}`}
    >
      <div
        css={css`
          font-size: 14px;
          color: #1a2230;
          display: flex;
          align-items: center;
          justify-content: space-between;
          .indexTreeIcon {
            display: ${dropDownVisible ? 'block' : 'none'};
          }
          &:hover {
            .indexTreeIcon {
              display: block;
            }
          }
        `}
      >
        <span
          css={css`
            flex: 1;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: pre;
            width: 0;
            color: ${info.disabled ? '#ccc' : '#1a2230'};
          `}
        >
          {info.showName || info.name || info.tableName}
          {isDeleted ? '(底层表被删除)' : ''}
        </span>
        {isDraftFolder || isDB || onlyView ? null : extraActions?.key ===
          'insert' ? (
          <AiOutlinePlusSquare
            className="indexTreeIcon"
            style={{
              fontSize: 14,
              marginRight: 8,
              position: 'relative',
              zIndex: 1,
              cursor: 'pointer',
            }}
            onClick={() => {
              extraActions?.calback(info);
            }}
          />
        ) : extraActions?.key === 'sync' ? (
          <SvgIcon
            className="indexTreeIcon"
            iconClass="newlybuild"
            style={{
              fontSize: 14,
              marginRight: 8,
              position: 'relative',
              zIndex: 1,
              cursor: 'pointer',
            }}
            onClick={() => {
              extraActions?.calback(info);
            }}
          />
        ) : (
          <Dropdown
            disabled={activeKey === '2' && isFolder && !info?.managePerm}
            overlay={menu}
            overlayClassName="indexTreeMenu"
            overlayStyle={{minWidth: '88px', zIndex: 999999999}}
            trigger={triggerType}
            visible={dropDownVisible}
            onVisibleChange={(flag) => {
              setDropDownVisible(flag);
            }}
          >
            {activeKey === '2' && isFolder && !info?.managePerm ? (
              <span />
            ) : (
              <IconFont
                className="indexTreeIcon"
                style={{fontSize: 14, marginRight: 8}}
                type="icon-ellipsis"
              />
            )}
          </Dropdown>
        )}
      </div>
    </div>
  );
}
