import request from '../../helpers/request';

// 查询微观查询path列表树
export function getPathList(params) {
  return request({
    url: '/mis/query/path/list',
    method: 'get',
    params,
  });
}

// 新建文件夹
export function addFolder(data) {
  return request({
    url: '/mis/query/path/addFolder',
    method: 'post',
    data,
  });
}

// 删除文件夹
export function removeFolder(data) {
  return request({
    url: '/mis/query/path/removeFolder',
    method: 'post',
    data,
  });
}

// 关注
export function followWideTable(data) {
  return request({
    url: '/mis/query/path/follow',
    method: 'post',
    data,
  });
}

// 不再关注
export function unFollowWideTable(data) {
  return request({
    url: '/mis/query/path/unfollow',
    method: 'post',
    data,
  });
}

// 重命名文件夹
export function renameFolder(data) {
  return request({
    url: '/mis/query/path/renameFolder',
    method: 'post',
    data,
  });
}

// 移动
export function moveFolder(data) {
  return request({
    url: '/mis/query/path/move',
    method: 'post',
    data,
  });
}

// 筛选组列表
export function filterGroupList(params) {
  return request({
    url: '/wide/filterGroup/myList',
    method: 'get',
    params,
  });
}

// 筛选组列表
export function indexFilterGroupList(params) {
  return request({
    url: '/wide/filterGroup/list',
    method: 'get',
    params,
  });
}

// 设置默认筛选组
export function setDefaultFilterGroup(params) {
  return request({
    url: '/wide/filterGroup/makeDefault',
    method: 'get',
    params,
  });
}

// 筛选组详情
export function getFilterGroupDetail(data) {
  return request({
    url: '/wide/filterGroup/filterDetail',
    method: 'post',
    data,
  });
}

export function getFilterRecord(header, data) {
  return request({
    url: '/wide/filter/getFilterRecord',
    method: 'post',
    data,
    headers: header,
  });
}

export function addFilterGroup(data) {
  return request({
    url: '/wide/filterGroup/add',
    method: 'post',
    data,
  });
}

export function editFilterGroup(data) {
  return request({
    url: '/wide/filterGroup/edit',
    method: 'post',
    data,
  });
}

// 宽表列表查询
export function queryWideList(config) {
  return request({
    url: `/mis/query/wide/list`,
    method: 'post',
    ...config,
  });
}

// 批量修改筛选组
export function batchEditFilterGroup(data) {
  return request({
    url: '/wide/filterGroup/batchEdit',
    method: 'post',
    data,
  });
}

// 配置指标顺序
export function configOrder(data) {
  return request({
    url: `/mis/query/indexConfig/configOrder`,
    method: 'post',
    data,
  });
}

// 配置指标顺序
export function configShow(data) {
  return request({
    url: `/mis/query/indexConfig/configShow`,
    method: 'post',
    data,
  });
}

// 生成动态标签
export function addDynamic(data) {
  return request({
    url: 'mis/tag/addDynamic',
    method: 'post',
    data,
  });
}

// 宽表列表指标描述信息查询
export const GET_INDEX_SCHEMA = `/wide/query/wide/getIndexSchema`;

// 导出历史记录
export const GET_EXPORT_HISTORY_LIST = '/wide/queryDownload/list';

// 导出 再次导出
export const EXPORT_HISTORY = '/wide/queryDownload/historyExport';

// 微观查询导出
export const EXPORT_ACTION = '/wide/queryDownload/export';
export function exportMicroQuery(header, data) {
  return request({
    url: '/wide/queryDownload/export',
    method: 'post',
    data,
    headers: header,
  });
}

// 微观查询导出列表详情
export function downloadDetail(data) {
  return request({
    url: '/wide/queryDownload/downloadDetail',
    method: 'post',
    data,
  });
}

export function canExportAuth(params) {
  return request({
    url: `/wide/queryDownload/canExport`,
    method: 'get',
    params,
  });
}

// 宽表概览
export function getWideOverview(wideId) {
  return request({
    url: `/wide/query/path/getWideOverview/${wideId}`,
    method: 'get',
  });
}

// 查询标签枚举列表
export const GET_TAG_ENUM = `/mis/tag/tagEnum`;
export function getTagEnum(wideId) {
  return request({
    url: `${GET_TAG_ENUM}/${wideId}`,
    method: 'get',
  });
}

// 新增计算字段
export function addQueryCalIndex(data) {
  return request({
    url: '/mis/indexCal/addQueryCalIndex',
    method: 'post',
    data,
  });
}

export function getDataFixTagList(data, header) {
  return request({
    url: '/mis/tag/getDataFixTagList',
    method: 'post',
    data,
    headers: header,
  });
}
// 编辑计算字段
export function updateQueryCalIndex(data) {
  return request({
    url: '/mis/indexCal/updateQueryCalIndex',
    method: 'post',
    data,
  });
}

export function batchOperateFixTag(data, header) {
  return request({
    url: '/mis/tag/batchOperateFixTag',
    method: 'post',
    data,
    headers: header,
  });
}

// 删除计算字段
export function removeQueryCalIndex(data) {
  return request({
    url: '/mis/indexCal/removeQueryCalIndex',
    method: 'post',
    data,
  });
}

// 批量操作获取标签
export const GET_TAG_BY_NAME = '/mis/tag/getTagByName';
export function getTagByName(params) {
  return request({
    url: GET_TAG_BY_NAME,
    method: 'get',
    params,
  });
}

// 增加标签
export function addFixTag(data) {
  return request({
    url: '/mis/tag/addFix',
    method: 'post',
    data,
  });
}

// 查询指标创建者详情
export const GET_INDEX_CREATOR_INFO = `/mis/query/wide/getIndexCreatorInfo`;

// 行增加标签
export function singleRowOperateFix(data) {
  return request({
    url: '/mis/tag/singleRowOperateFix',
    method: 'post',
    data,
  });
}

// 标签列表
export function getTagGroupList(params) {
  return request({
    url: '/mis/tag/list',
    method: 'get',
    params,
  });
}

// 标签详情
export function getTagGroupDetail(data) {
  return request({
    url: '/mis/tag/filterDetail',
    method: 'post',
    data,
  });
}

// 批量提交标签管理
export function batchEditTagList(data) {
  return request({
    url: '/mis/tag/batchEdit',
    method: 'post',
    data,
  });
}

// 计算字段重新计算
export function restartCal(data) {
  return request({
    url: '/mis/query/wide/restartCal',
    method: 'post',
    data,
  });
}

// 预览计算字段
export function getQueryCalData(data) {
  return request({
    url: '/mis/indexCal/getQueryCalData ',
    method: 'post',
    data,
  });
}

// 查询query模块筛选器配置
export const GET_QUERY_FILTER = `/mis/queryFilter/getQueryFilter`;
export function getQueryFilter(params) {
  return request({
    url: GET_QUERY_FILTER,
    method: 'get',
    params,
  });
}

// 查询分层配置单位
export function getDmsMeasureUnit(params) {
  return request({
    url: '/dms/measureUnit/detail',
    method: 'get',
    params,
  });
}

// 查询公开标签配置权限
export const GET_PUBLIC_TAG_MANAGE_FLAG = `/mis/tag/publicTagManageFlag`;

// 查询当前账号对宽表的权限
export function getWideInfoForUser(params) {
  return request({
    url: '/mis/query/wide/getWideInfoForUser',
    method: 'get',
    params,
  });
}

// 逻辑外键枚举
export function getCodeTableData(data) {
  return request({
    url: 'mis/indexMaking/getCodeTableData ',
    method: 'post',
    data,
  });
}

// 获取字段sectionId
export function getIndexSectionId(params) {
  return request({
    url: 'mis/encrypt/getIndexSectionId',
    method: 'get',
    params,
  });
}

// 查询同步任务
export const GET_WIDE_JOB_LIST = '/wide/job/list';

// 创建同步任务
export function createSyncJob(data) {
  return request({
    url: '/wide/job/createSyncJob',
    method: 'POST',
    data,
  });
}

// 同步历史
export const GET_SYNC_JOB_HISTORY = '/wide/job/detail';

// 手动执行一次
export function invokeOnceJob(data) {
  return request({
    url: '/wide/job/invokeOnce',
    method: 'POST',
    data,
  });
}

// 启动同步
export function startJob(data) {
  return request({
    url: '/wide/job/normal',
    method: 'POST',
    data,
  });
}

// 暂停同步
export function pauseJob(data) {
  return request({
    url: '/wide/job/pause',
    method: 'POST',
    data,
  });
}

// 修改配置同步
export function editSyncConfig(data) {
  return request({
    url: '/wide/job/editConfig',
    method: 'POST',
    data,
  });
}

// 获取配置
export function getSyncConfig(params) {
  return request({
    url: `/wide/job/config/${params.widePathId}`,
    method: 'get',
  });
}

// 获取全部数据库
export function getAllDb(params) {
  return request({
    url: `/wide/job/allDb`,
    method: 'get',
    params,
  });
}

// 获取同步进度
export function getJobProgress(data) {
  return request({
    url: `/wide/job/getProgress`,
    method: 'post',
    data,
  });
}
