/* eslint-disable no-console */
//

import {notification} from 'antd';
import {useAtom} from 'jotai';

import {logicEditAtom} from '../../../../../atoms/logicEditAtom';
import useSafeState from '../../../../../hooks/useSafeState';
import {calcTableXColHideRepeatStyle} from '../helpers';

export default function useDataValidate() {
  const [isValidate, setIsValidate] = useSafeState(true);

  const [totalStatus, setTotalStatus] = useAtom(logicEditAtom);

  const {
    // pathId,

    // tableName,
    // tableNameErrMsg,

    graphData,
    graphInstance,

    previewXOriginColumns,
  } = totalStatus;

  const {tableStructureList} = graphData;

  const checkValidate = () => {
    let result = true;
    // 文件夹是否已选
    /* if (!pathId) {
      console.log('pathId');
      result = false;

      setIsValidate(false);
      setTotalStatus((pre) => ({
        ...pre,
        placeHolderBling: true,
      }));
    } */

    // 表名是否输入字符
    /* if (!tableName?.trim()) {
      console.log('tableName');
      result = false;
      setIsValidate(false);
      setTotalStatus((pre) => ({
        ...pre,
        tableNameErrMsg: sheetNameEmpty,
      }));
    } */

    // 表名是否全局唯一
    /* if (tableNameErrMsg) {
      console.log('tableNameErrMsg', tableNameErrMsg);
      result = false;
      setIsValidate(false);
    } */

    // 是否存在任意表间关联状态为【未关联】
    const nojoinCondition = tableStructureList.filter(
      (t) =>
        t.level !== 0 && (!t.joinCondition || t.joinLogicalColumns.length === 0)
    );
    if (nojoinCondition.length > 0) {
      console.log('nojoinCondition', nojoinCondition);
      result = false;
      setIsValidate(false);

      // 依次切换视图到未关联的节点
      const firstNode = graphInstance.getNodes().find((n) => {
        const data = n.getData();
        return data.tableId === nojoinCondition[0]?.tableId;
      });

      if (firstNode) {
        const pos = firstNode.position();

        graphInstance.transitionToPoint(pos.x, pos.y, {
          animation: {duration: 300, easing: 'linear'},
        });
      }
      notification.error({
        description: '未发现关联字段',
      });
    }

    // 是否存在关联字段被删除
    const joinCondition = tableStructureList.filter(
      (t) => t.level !== 0 && t.joinCondition && t.joinLogicalColumns.length > 0
    );

    if (joinCondition.length > 0) {
      const hasDelJoin = joinCondition.some((j) => {
        const logicalColumnFlat = j.joinLogicalColumns.flat(Infinity);

        return (
          previewXOriginColumns.filter(
            (col) => col.delFlag === 1 && logicalColumnFlat.includes(col.id)
          ).length > 0 ||
          !logicalColumnFlat.every((id) =>
            previewXOriginColumns.map((col) => col.id).includes(id)
          )
        );
      });
      if (hasDelJoin) {
        result = false;
        setIsValidate(false);
        notification.error({
          description: '表关联字段被删除，请重新选择',
        });
      }
    }

    // 所有字段被隐藏不允许提交
    const showedFields = previewXOriginColumns.filter((col) => col.isShow);

    if (showedFields.length === 0) {
      result = false;
      setIsValidate(false);
      notification.error({
        description: '至少选择一个字段显示',
      });
    }

    // 有被删除的字段不允许提交
    const hasDelFlagFields = previewXOriginColumns.filter(
      (col) => col.delFlag === 1
    );
    if (hasDelFlagFields.length > 0) {
      result = false;
      setIsValidate(false);
      notification.error({
        description: '请删除字段后提交',
      });
    }

    const hasDelCalFields = previewXOriginColumns.filter(
      (col) => col.delFlag === 2 && col.formula
    );
    if (hasDelCalFields.length > 0) {
      result = false;
      setIsValidate(false);
      notification.error({
        description: '计算字段来源被删除！',
      });
    }

    // 是否存在重复名称的显示字段
    const showedRepeatFields = calcTableXColHideRepeatStyle(
      previewXOriginColumns
    ).filter((col) => col.hasRepeat || col.columnAliasRepeat);

    if (showedRepeatFields.length > 0) {
      console.log('showedRepeatFields', showedRepeatFields);
      result = false;
      setIsValidate(false);
      setTotalStatus((pre) => ({
        ...pre,
        previewBling: true,
      }));
      notification.error({
        description: '请检查隐藏字段是否重复，字段名是否合规',
      });
    }

    // 是否存在已经被删除的表
    const hasDelTableStructureList = tableStructureList.filter(
      (t) => t.delFlag
    );
    if (hasDelTableStructureList.length > 0) {
      result = false;
      setIsValidate(false);
      notification.error({
        description: '请先删除表后提交',
      });
    }

    // 是否已至少选择一个表
    if (tableStructureList.length === 0) {
      console.log('tableStructureList.length === 0');
      result = false;
      setIsValidate(false);
      setTotalStatus((pre) => ({
        ...pre,
        showNoTableStructure: true,
      }));
    }

    return result;
  };

  return [isValidate, checkValidate];
}
