import {Button} from 'antd';
import React from 'react';

import {createFrontLog} from '../../helpers/utils';
import useHasBtnPerm from '../../hooks/useHasBtnPerm';
import usePermChange from '../../hooks/usePermChange';
import useSafeState from '../../hooks/useSafeState';
import {useValidatePerm} from '../../hooks/useValidatePerm';

/**
 * @typedef  {object} AuthProps
 * @property {boolean} createLog 是否创建日志
 * @property {number} permType 按钮权限类型: 1=页面，2=功能
 * @property {string} permCode 按钮权限code
 * @property {Function=} permChangeFetcher - 权限变化请求
 * @property {Function=} permChangeValidator - 对permChangeFetcher返回数据过滤得到权限变化结果
 */

/**
 * @description 判断权限，决定按钮是否展示
 * @param {AuthProps & import('antd').ButtonProps} props
 */
export function AuthButton(props) {
  const {
    permType = 2,
    permCode,
    createLog = false,
    onClick: originClickHandle = (v) => v,
    permChangeFetcher,
    permChangeValidator,
    ...rest
  } = props;

  const [isLoading, setIsLoading] = useSafeState(false);

  const [permRef, validatePermChange] = useValidatePerm({
    type: permType,
    code: permCode,
  });

  const handlePermChange = usePermChange(
    permChangeFetcher,
    permChangeValidator
  );

  const hasAuth = useHasBtnPerm(permCode);

  const curClickHandle = async (e) => {
    if (rest.href === '') {
      e.preventDefault();
    }
    setIsLoading(true);
    if (permChangeFetcher) {
      try {
        const res = await handlePermChange();
        if (res) {
          setIsLoading(false);
          return res;
        }
      } catch {
        return setIsLoading(false);
      }
    }
    if (permCode) {
      try {
        await validatePermChange();
      } catch {
        return setIsLoading(false);
      }
    }
    if (!permRef.current) {
      try {
        originClickHandle();
      } finally {
        setIsLoading(false);
      }

      if (createLog) createFrontLog();
    }
    setIsLoading(false);
    return null;
  };

  if (hasAuth) {
    return (
      <Button
        loading={isLoading}
        size="small"
        onClick={curClickHandle}
        {...rest}
      />
    );
  }
  return null;
}
