import {useSearchParams} from 'react-router-dom';

export function usePaginationUrlParams({
  currentPageName = 'pageNum',
  pageSizeName = 'pageSize',
  defaultPageSize = 20,
} = {}) {
  const [searchParams, setSearchParams] = useSearchParams();
  const pageNum = +searchParams.get(currentPageName) || 1;
  const pageSize = +searchParams.get(pageSizeName) || defaultPageSize;

  function setPage(newPageNum, newPageSize) {
    searchParams.set(currentPageName, newPageNum);
    if (newPageSize) searchParams.set(pageSizeName, newPageSize);
    setSearchParams(searchParams);
  }

  return {
    pageNum,
    pageSize,
    setPage,
  };
}
