import styled from '@emotion/styled';
import React from 'react';

import StatusTag from './StatusTag';

/**
 * @typedef {{
 *  label: string;
 *  status: import('.').Status;
 *  key: string;
 * }} ItemData
 */

/**
 * @param {object} props
 * @param {unknown[]} props.list
 * @param {(item: unknown, index: number) => ItemData=} props.dataMapping
 * @param {function=} props.onClick
 */
const StatusTags = ({list = [], dataMapping, onClick, children, ...rest}) => {
  const finalList = dataMapping ? list.map(dataMapping) : list;

  return (
    <>
      {finalList.map((item, index) => {
        const {key, status, label} = item;

        return (
          <StatusTag
            data={list[index]}
            key={key}
            label={label}
            status={status}
            onClick={onClick}
            {...rest}
          />
        );
      })}
      {children}
    </>
  );
};

export const StatusTagsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
`;

export default StatusTags;
