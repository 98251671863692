import {useAtom} from 'jotai';
import {useMemo} from 'react';

import {logicEditAtom} from '@/atoms/logicEditAtom';
import {sqlWideEditAtom} from '@/atoms/sqlWideEditAtom';

export const WIDE_TABLE_TYPE = {
  wideTable: 'wideTable',
  SQLWideTable: 'SQLWideTable',
};

const useTableFields = (_wideTableType) => {
  const [wideTableState, setWideTableState] = useAtom(logicEditAtom);
  const [SQLWideTableState, setSQLWideTableState] = useAtom(sqlWideEditAtom);

  const {previewXOriginColumns = [], tableIndexs: wideTableIndexs} = useMemo(
    () => wideTableState,
    [wideTableState]
  );

  const {previewXColumn = [], tableIndexs: SQLWideTableIndexs} = useMemo(
    () => SQLWideTableState,
    [SQLWideTableState]
  );

  const wideTableType = useMemo(() => {
    if (_wideTableType) return _wideTableType;
    if (new RegExp('sql', 'gi').test(location.pathname)) {
      return WIDE_TABLE_TYPE.SQLWideTable;
    }
    return WIDE_TABLE_TYPE.wideTable;
  }, [_wideTableType]);

  const tableIndexs = useMemo(() => {
    if (wideTableType === WIDE_TABLE_TYPE.SQLWideTable) {
      return SQLWideTableIndexs;
    }
    return wideTableIndexs;
  }, [wideTableType, wideTableIndexs, SQLWideTableIndexs]);

  const wideTableColumns = useMemo(() => {
    if (wideTableType === WIDE_TABLE_TYPE.SQLWideTable) {
      return previewXColumn;
    }
    return previewXOriginColumns;
  }, [wideTableType, previewXColumn, previewXOriginColumns]);

  const setTotalTableState = useMemo(() => {
    if (wideTableType === WIDE_TABLE_TYPE.SQLWideTable) {
      return setSQLWideTableState;
    }
    return setWideTableState;
  }, [wideTableType, setSQLWideTableState, setWideTableState]);

  /** 从选项中禁用已经配置隐藏索引的字段 */
  const fieldOptions = useMemo(
    () =>
      wideTableColumns.map(({curShowName, columnAlias, isShow}) => ({
        label: `${curShowName} (${columnAlias})`,
        value: columnAlias,
        columnAlias,
        isShow,
      })),
    [wideTableColumns]
  );

  const Field_MATCH_ROW = useMemo(
    () =>
      wideTableColumns.reduce((init, v) => {
        init.set(v.columnAlias, v);
        return init;
      }, new Map()),
    [wideTableColumns]
  );

  const Field_SHOW_STATE = useMemo(
    () =>
      wideTableColumns.reduce((init, v) => {
        init.set(v.columnAlias, v?.isShow);
        return init;
      }, new Map()),
    [wideTableColumns]
  );

  return {
    tableIndexs,
    fieldOptions,
    wideTableColumns,
    Field_MATCH_ROW,
    Field_SHOW_STATE,
    setTotalTableState,
  };
};

export default useTableFields;
