import {Typography} from 'antd';
import React from 'react';

const {Text} = Typography;

const TextView = (props) => {
  const {value, ...rest} = props;
  return <Text {...rest}>{value}</Text>;
};

export default TextView;
