import {DateTypeArr, TextTypeArr} from '../../TableSheetCopy/type';

export function specifyFilterTypeByColumnType(col, typeKey = 'indexType') {
  if (!col[typeKey] || col.filterType !== 'Common') return col;
  if (TextTypeArr.some((colType) => col[typeKey].includes(colType))) {
    return {
      ...col,
      showCommonTabList: ['Text'],
    };
  } else if (DateTypeArr.some((colType) => col[typeKey].includes(colType))) {
    return {
      ...col,
      showCommonTabList: ['Date', 'Text'],
    };
  }
  return {
    ...col,
    showCommonTabList: ['Text', 'Number'],
  };
}
