import {css} from '@emotion/react';
import {Button} from 'antd';
import React from 'react';

import GlobalModal from '.';
import IconFont from '../IconFont';

export default function WarningModal({
  modalControl,
  title,
  description,
  confirm,
  isNeedCancelBtn = true,
  ensureBtnText = '确定',
  cancelBtnText = '取消',
  reason = [],
  width = 346,
  ...restProps
}) {
  return (
    <GlobalModal
      closable={false}
      footer={
        isNeedCancelBtn
          ? [
              <Button
                className="cancel-btn-text-color"
                key="cancel"
                type="default"
                onClick={() => {
                  modalControl._toggle(false);
                  restProps?.onCancel();
                }}
              >
                {cancelBtnText}
              </Button>,
              <Button
                className="ensure-btn-text-color"
                key="ensure"
                type="primary"
                onClick={() => {
                  if (confirm) {
                    confirm();
                  } else {
                    modalControl._toggle(false);
                  }
                }}
              >
                {ensureBtnText}
              </Button>,
            ]
          : [
              <Button
                className="ensure-btn-text-color"
                key="ensure"
                type="primary"
                onClick={() => {
                  if (confirm) {
                    confirm();
                  } else {
                    modalControl._toggle(false);
                  }
                }}
              >
                {ensureBtnText}
              </Button>,
            ]
      }
      maskClosable={false}
      title={null}
      width={width}
      {...modalControl}
      {...restProps}
    >
      <div
        css={css`
          display: flex;
          align-items: center;
          margin-bottom: 10px;
        `}
      >
        <IconFont
          style={{fontSize: 21, color: '#FAAD14'}}
          type="icon-error-circle-filled"
        />
        <span
          css={css`
            margin-left: 10px;
            font-size: 16px;
            font-weight: 500;
            color: #1a2230;
          `}
        >
          {title}
        </span>
      </div>
      <p
        css={css`
          font-size: 14px;
          color: #1a2230;
          margin-left: 30px;
        `}
      >
        {description}
      </p>
      {reason && reason.length > 0 ? (
        <div
          css={css`
            margin-top: 12px;
            max-height: 500px;
            overflow: auto;
          `}
        >
          {reason.map((item, index) => {
            return (
              <p
                css={css`
                  margin-left: 30px;
                  display: flex;
                  align-items: center;
                  color: #1a2230;
                `}
                key={index}
              >
                <span
                  css={css`
                    background-color: #4d5eff;
                    width: 6px;
                    height: 6px;
                    border-radius: 50%;
                    margin-right: 8px;
                    display: block;
                  `}
                />
                {item}
              </p>
            );
          })}
        </div>
      ) : null}
    </GlobalModal>
  );
}
